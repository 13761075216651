import { ChangeEvent, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';

// Components
import { InputText } from 'components/FormTemplate/Fields/InputText';

// Utils
import { theme } from 'theme';
import { useQueryParams } from 'hooks/useQueryParams';
import { toggleSearchParams } from 'utils/search';

const SearchOpportunity: FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const query = useQueryParams();
  const searchParams = new URLSearchParams(location.search);

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    toggleSearchParams('search', e?.target.value, searchParams);
    history.replace({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  };

  const SearchValue = query.get('search') || '';

  return (
    <InputText
      onChange={handleSearchChange}
      labelUppercase={true}
      labelFontStyle="label"
      placeholder={t('filter.search_opportunity')}
      width="100%"
      labelWeight="bold"
      $size="small"
      value={SearchValue && SearchValue !== '' ? SearchValue : ''}
      icon={{ name: 'Search', primaryColor: theme.colors.salmon1 }}
    />
  );
};

export default SearchOpportunity;
