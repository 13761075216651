import { Flex, Line, Text } from 'components/Layout';

import { FC } from 'react';
import { LargeCard } from 'components/Cards';
import { OptionPaymentEnum } from 'container/public/PaymentResume/RecapPaymentQuote';
import { formatPrice } from 'utils/format';
import styled from 'styled-components';
import { theme } from 'theme';
import { useTranslation } from 'react-i18next';
import { useViewport } from 'hooks/useViewport';

const StyledSmallCard = styled.div`
  width: 100%;
  padding: ${theme.spacing.space24};
  border-radius: ${theme.spacing.space24};
  background-color: ${theme.colors.gray3};
`;

interface PaymentCardProps {
  prices: [number, string];
  optionPayment: OptionPaymentEnum;
  modalityPayment?: number | undefined;
  splitted?: boolean;
}

interface SmallCardProps {
  totalTitle: string;
  totalPrice: string;
  monthlyTitle?: string;
  monthlyPrice?: string;
  currency: string;
}

const SmallCard: FC<SmallCardProps> = ({
  totalTitle,
  totalPrice,
  monthlyPrice,
  monthlyTitle,
  currency,
}) => {
  return (
    <StyledSmallCard>
      {monthlyTitle && monthlyPrice && (
        <Flex
          justify="between"
          alignItems="center"
          marginBottom={{ xs: 'space12' }}
        >
          <Text
            content={monthlyTitle}
            fontStyle="heading5"
            color={theme.colors.black}
            weight="bold"
            family={theme.fontFamily.inter}
          />
          <Flex alignItems="end">
            <Text
              content={monthlyPrice}
              fontStyle="heading2"
              color={theme.colors.salmon2}
            />
            <Text
              content={currency}
              fontStyle="heading5"
              color={theme.colors.salmon2}
            />
          </Flex>
        </Flex>
      )}
      <Flex justify="between" alignItems="center">
        <Text
          content={totalTitle}
          fontStyle="heading5"
          color={theme.colors.black}
          weight="bold"
          family={theme.fontFamily.inter}
        />
        <Flex alignItems="end">
          <Text
            content={totalPrice}
            fontStyle="heading2"
            color={
              monthlyTitle && monthlyPrice
                ? theme.colors.black
                : theme.colors.salmon2
            }
          />
          <Text
            content={currency}
            fontStyle="heading5"
            color={
              monthlyTitle && monthlyPrice
                ? theme.colors.black
                : theme.colors.salmon2
            }
          />
        </Flex>
      </Flex>
    </StyledSmallCard>
  );
};

const FinalPaymentCard: FC<PaymentCardProps> = ({
  prices,
  optionPayment,
  modalityPayment,
  splitted,
}) => {
  const { t } = useTranslation();
  const [totalPrice, pricePerSpouse] = prices;
  const { isMobile } = useViewport();

  const multipleModality = modalityPayment && modalityPayment > 1;

  const getMonthlyPrice = () => {
    if (!modalityPayment) return 0;
    if (optionPayment === 'split')
      return formatPrice(parseInt(pricePerSpouse) / modalityPayment);
    return formatPrice(totalPrice / modalityPayment);
  };

  return (
    <LargeCard thinner>
      <Flex marginBottom={{ xs: 'space16', md: 'space24' }}>
        <Text
          content={t('quote.payment.payment_title')}
          fontStyle={isMobile ? 'heading4' : 'heading3'}
          weight="bold"
        />
      </Flex>
      <Flex marginBottom={{ xs: 'space24' }}>
        <Line />
      </Flex>
      <Flex
        direction={{ xs: 'column' }}
        marginBottom={{
          xs: 'space24',
        }}
      >
        {!splitted && optionPayment !== 'split' && (
          <Flex direction={{ xs: 'row' }} justify="between" alignItems="center">
            <Text
              fontStyle="body2"
              weight="medium"
              color={theme.colors.gray6}
              content={t('quote.payment.total_price')}
            />
            {/* <Text
              fontStyle="heading5"
              weight="medium"
              content={t('quote.payment.price', { price: totalPrice })}
              family={theme.fontFamily.inter}
            /> */}
          </Flex>
        )}
        {optionPayment === 'split' && (
          <Flex direction={{ xs: 'row' }} justify="between" alignItems="center">
            <Text
              fontStyle="body2"
              weight="medium"
              color={theme.colors.gray6}
              content={t('quote.payment.spouse_price')}
            />
            {/* <Text
              fontStyle="heading5"
              weight="medium"
              content={t('quote.payment.price', { price: pricePerSpouse })}
              family={theme.fontFamily.inter}
            /> */}
          </Flex>
        )}
        {/* {multipleModality && (
          <Flex direction={{ xs: 'row' }} justify="between" alignItems="center">
            <Text
              fontStyle="body2"
              weight="medium"
              color={theme.colors.gray6}
              content={t('quote.payment.per_month')}
            />
            <Text
              fontStyle="heading5"
              weight="medium"
              content={t('quote.payment.price', {
                price: getMonthlyPrice(),
              })}
              family={theme.fontFamily.inter}
            />
          </Flex>
        )} */}
      </Flex>
      <Flex direction={{ xs: 'column' }}>
        <SmallCard
          totalTitle={t('quote.payment.total')}
          totalPrice={`${
            optionPayment === 'split' ? pricePerSpouse : totalPrice
          }`}
          monthlyTitle={
            multipleModality ? t('quote.payment.per_month') : undefined
          }
          monthlyPrice={multipleModality ? `${getMonthlyPrice()}` : undefined}
          currency={t('quote.payment.currency')}
        />
      </Flex>
    </LargeCard>
  );
};

export default FinalPaymentCard;
