import React, { FC, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { theme } from 'theme';
import { Flex } from 'components/Layout';

// Container
import SpecificationCardList, {
  Specification,
} from 'container/client/ClientHome/SpecificationCardList';
import { Ghost } from 'components/Loading';
import { useForm } from 'react-hook-form';
import { TipCard } from 'components/Cards';
import { Button } from 'components/Buttons';
import { useApi } from '../../../hooks/useApi';
import { CustomerFull, EstimateFull } from '../../../types/resources';
import Invoice from '../../../components/Forms/Payment/Invoice';
import { ValidateEstimateInput } from '../../public/PaymentResume/RecapPaymentQuote';

interface DealInfosProps {
  dealId: string;
}

const DealInfos: FC<DealInfosProps> = ({ dealId }) => {
  const { t } = useTranslation();

  const { execute: getEstimate, state: getEstimateState } =
    useApi<EstimateFull>(`/estimates/${dealId}`);

  const estimate = getEstimateState.data?.value;

  const { execute: getCustomer1, state: getCustomer1State } =
    useApi<CustomerFull>(`/customers/${estimate?.customer1_id}`);

  const { execute: getCustomer2, state: getCustomer2State } =
    useApi<CustomerFull>(`/customers/${estimate?.customer2_id}`);

  const customer1 = getCustomer1State.data?.value;
  const customer2 = getCustomer2State.data?.value;

  const loading =
    getEstimateState.loading ||
    getCustomer1State.loading ||
    getCustomer2State.loading;

  const { execute: validateEstimate, state: validateEstimateState } = useApi(
    `/estimates/${estimate?.id}/customers`,
    {
      method: 'POST',
      onSuccess: () => {
        getEstimate();
      },
    },
  );

  const validateEstimateCb = useCallback(
    (data: ValidateEstimateInput) => {
      if (estimate) {
        validateEstimate({
          body: {
            customer1: {
              first_name: data.spouseLead.first_name,
              last_name: data.spouseLead.last_name,
              email: data.spouseLead.email,
              phone: data.spouseLead?.phone?.replaceAll(' ', ''),
              address: {
                street: data.spouseLead?.address,
                city: data.spouseLead?.city,
                country: data.spouseLead?.country,
                post_code: data.spouseLead?.postalCode,
              },
            },
            customer2: data.spouseFollower?.email
              ? {
                  first_name: data.spouseFollower.first_name,
                  last_name: data.spouseFollower.last_name,
                  email: data.spouseFollower.email,
                  phone: data.spouseFollower?.phone?.replaceAll(' ', ''),
                }
              : undefined,
          },
        });
      }
    },
    [estimate],
  );

  const { execute: forcePayment, state: forcePaymentState } = useApi(
    `/estimates/${estimate?.id}/pay/force`,
    {
      method: 'POST',
      onSuccess: () => {
        getEstimate();
      },
    },
  );

  const createProcedureCb = useCallback(() => {
    if (estimate) {
      forcePayment({ body: { estimate_id: estimate.id } });
    }
  }, [estimate]);

  const [lockForm, setLockForm] = React.useState<boolean>();

  const {
    register,
    control,
    formState: { errors },
    getValues,
    handleSubmit,
    setValue,
    reset,
    clearErrors,
  } = useForm<ValidateEstimateInput>();

  useEffect(() => {
    getEstimate();
  }, [dealId]);

  useEffect(() => {
    if (estimate?.customer1_id) {
      getCustomer1();
    }
    if (estimate?.customer2_id) {
      getCustomer2();
    }
  }, [estimate]);

  useEffect(() => {
    if (estimate) {
      if (customer1 && customer2) {
        setLockForm(true);
      }

      reset({
        spouseLead: {
          first_name: customer1?.first_name || '',
          last_name: customer1?.last_name || '',
          email: customer1?.email || '',
          phone: customer1?.phone || '',
          address: customer1?.address?.street,
          city: customer1?.address?.city,
          postalCode: customer1?.address?.post_code,
          country: customer1?.address?.country || '',
        },
        spouseFollower: {
          first_name: customer2?.first_name || '',
          last_name: customer2?.last_name || '',
          email: customer2?.email,
          phone: customer2?.phone || '',
        },
      });
    }
  }, [estimate, customer1, customer2]);

  const nbAdult = parseInt(`${estimate?.adult_children}`) ?? 0;
  const nbYoung = parseInt(`${estimate?.minor_children}`) ?? 0;
  const totalChildren = nbAdult + nbYoung;

  const specifications: Specification[] = [
    {
      icon: 'Ticket',
      title: t('opportunity.package'),
      value: estimate?.plan.name || '',
      description: estimate?.plan.description || '',
      hasInfo: true,
    },
    {
      icon: 'Document',
      title: t('opportunity.option_chosen', {
        count: estimate?.options?.length,
      }),
      value:
        estimate?.options
          ?.map((option) => t(`enums:${option.code}`))
          .join(' et ') || t('opportunity.no_option'),
      description: estimate?.options
        ?.map((option) => option.description)
        .join('\n'),
      hasInfo: !!estimate?.options?.length,
    },
    {
      icon: 'People',
      title: t('opportunity.children'),
      value:
        totalChildren > 0
          ? t('opportunity.nb_child_plural', {
              nbAdult: nbAdult || 0,
              nbYoung: nbYoung || 0,
            })
          : t('opportunity.nb_child'),
    },
    {
      icon: 'Home',
      title: t('opportunity.properties'),
      value: estimate?.has_real_estate
        ? t('opportunity.have_properties')
        : t('opportunity.no_properties'),
    },
  ];

  return (
    <Flex direction={{ xs: 'column' }} width="100%">
      {loading || !estimate ? (
        <Ghost width="100%" height={563} shape="rect" rx={24} />
      ) : (
        <form id="form" onSubmit={handleSubmit(validateEstimateCb)}>
          {estimate.procedure_reference ? (
            <Flex marginBottom={{ xs: 'space16' }}>
              <TipCard
                content={` Le paiement n° ${estimate.procedure_reference}  a été forcé manuellement`}
                title="Dossier créé"
                type="success"
                icon="Activity"
              />
            </Flex>
          ) : (
            <>
              {!lockForm && (
                <Flex marginBottom={{ xs: 'space16' }}>
                  <TipCard
                    content={t('forcePaymentWithoutSpouses')}
                    title="Forcer le paiement impossible"
                    type="danger"
                    icon="Danger"
                  />
                </Flex>
              )}
              <Flex
                justify="center"
                style={{ gap: theme.spacing.space12 }}
                marginBottom={{ xs: 'space32' }}
                direction={{ xs: 'column', md: 'row' }}
                width="100%"
              >
                <SpecificationCardList specifications={specifications} />
              </Flex>

              <Invoice
                register={register}
                errors={errors}
                estimate={estimate}
                customer1={customer1}
                customer2={customer2}
                clearErrors={clearErrors}
                control={control}
                getValues={getValues}
                setValue={setValue}
                readOnly={lockForm}
              />

              {lockForm ? (
                <Flex expand marginTop={{ xs: 'space32' }}>
                  <Button
                    content={'Forcer le paiement'}
                    iconLeft={{ name: 'Plus' }}
                    textColor={theme.colors.white}
                    backgroundColor={theme.colors.red1}
                    $loading={forcePaymentState.loading}
                    onClick={() => createProcedureCb()}
                    marginRight={{ xs: 'space16' }}
                    fullWidth
                  />

                  <Button
                    content={'Modifier les informations des époux'}
                    iconLeft={{ name: 'Edit' }}
                    marginLeft={{ xs: 'space16' }}
                    fullWidth
                    onClick={() => setLockForm(false)}
                  />
                </Flex>
              ) : (
                <Flex expand marginTop={{ xs: 'space32' }}>
                  <Button
                    content={t('forcePaymentAddSpouses')}
                    iconLeft={{ name: 'AddUser' }}
                    fullWidth
                    $loading={validateEstimateState.loading}
                    type="submit"
                  />
                </Flex>
              )}
            </>
          )}
        </form>
      )}
    </Flex>
  );
};

export default DealInfos;
