import { FC } from 'react';

// Components
import MUIPagination from '@mui/material/Pagination';
import { Flex } from 'components/Layout';

interface PaginationProps {
  pageCount: number;
  pageIndex: number;
  gotoPage: (updater: number | ((pageIndex: number) => number)) => void;
}

const Pagination: FC<PaginationProps> = ({
  pageCount,
  pageIndex,
  gotoPage,
}) => {
  return (
    <Flex justify="center" marginTop={{ xs: 'space24' }}>
      <MUIPagination
        page={pageIndex + 1}
        count={pageCount}
        onChange={(_, value) => gotoPage(value - 1)}
      />
    </Flex>
  );
};

export default Pagination;
