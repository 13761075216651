import { FC } from 'react';
import { Redirect } from 'react-router';

// Data

// Utils
import { isUserAllowed } from 'utils/user';
import { AdminAppRoutes } from 'AdminApp';
import { useAuth } from '../../../hooks/useAuth';
import { Role } from '../../../types/resources';

interface OpportunityGateProps {
  opportunityId: string;
}

const OpportunityGate: FC<OpportunityGateProps> = ({ children }) => {
  const { user } = useAuth();
  const userAllowed = isUserAllowed(user?.role, [Role.ADMIN, Role.MANAGER]);

  if (!user) return null;

  if (userAllowed) {
    return <>{children}</>;
  } else {
    return <Redirect to={AdminAppRoutes.HOME} />;
  }
};

export default OpportunityGate;
