import { FC, Dispatch, SetStateAction } from 'react';

import { Flex } from 'components/Layout/Flex';
import { Text } from 'components/Layout/Text';
import { IconButton } from 'components/Buttons/IconButton';
import { Button } from 'components/Buttons/Button';

import { theme } from 'theme';
import { useTranslation } from 'react-i18next';

interface ConfirmFormProps {
  handleSubmit: () => void | Promise<void>;
  setModalIsOpen: Dispatch<SetStateAction<boolean>>;
  loading: boolean;
  title?: string;
  description?: string;
  confirmText?: string;
}

export const ConfirmForm: FC<ConfirmFormProps> = ({
  handleSubmit,
  setModalIsOpen,
  loading,
  title,
  description,
  confirmText,
}) => {
  const { t } = useTranslation();
  return (
    <Flex
      direction={{ xs: 'column' }}
      paddingLeft={{ xs: 'space24' }}
      paddingRight={{ xs: 'space24' }}
    >
      <Flex alignItems="center">
        <IconButton
          iconName="Plus"
          color={theme.colors.salmon1}
          backgroundColor={theme.colors.salmon3}
          size="medium"
          rounded
        />
        <Text
          content={title ?? t('user.confirm_add')}
          fontStyle="heading5"
          weight="bold"
          marginLeft={{ xs: 'space16' }}
        />
      </Flex>
      {description && (
        <Flex marginTop={{ xs: 'space16' }}>
          <Text
            content={description}
            fontStyle="body1"
            marginLeft={{ xs: 'space16' }}
          />
        </Flex>
      )}
      <Flex justify="start" marginTop={{ xs: 'space24' }}>
        <Button
          marginRight={{ xs: 'space8' }}
          content="Annuler"
          onClick={() => setModalIsOpen(false)}
        />

        <Button
          primary
          content={confirmText ?? t('user.confirmed_add')}
          onClick={handleSubmit}
          marginLeft={{ xs: 'space8' }}
          $loading={loading}
        />
      </Flex>
    </Flex>
  );
};
