import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';

// Components
import { theme } from 'theme';
import { md } from 'theme/styles/mediaQueries';
import { Flex } from 'components/Layout/Flex';
import { Text } from 'components/Layout/Text';
import { DesktopOnly } from 'components/Layout/DesktopOnly';
import { MobileOnly } from 'components/Layout/MobileOnly';

import { useTranslation } from 'react-i18next';
import { AllFormsData, FormComment } from 'wrapper/FormWrapper';
import { IconButton } from 'components/Buttons/IconButton';

import defaultImg from 'assets/images/default-formpanel.png';
import { useViewport } from 'hooks/useViewport';
import { sortBy } from 'lodash';
import { Option } from '../../../types/resources';

const ANIMATION_DURATION = 500;
enum AnimationName {
  Slide = 'slide',
  SlideOut = 'slideout',
}

const Container = styled.aside`
  @keyframes ${AnimationName.Slide} {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0);
    }
  }
  @keyframes ${AnimationName.SlideOut} {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(100%);
    }
  }
  position: fixed;
  /* top: 70px; // header height */
  top: 0;
  right: 0;
  width: 100vw;
  height: 100%;
  padding-bottom: ${theme.spacing.space64};
  background-color: ${theme.colors.gray1};
  overflow-y: scroll;
  animation: slide;
  animation-duration: ${ANIMATION_DURATION}ms;
  z-index: 5;

  ${md(`
    width: 373px;
    border-left: 1px solid ${theme.colors.gray3};
    border-right: 1px solid ${theme.colors.gray3};
    z-index: 4;
  `)}
`;

const Line = styled.div`
  height: 1px;
  background-color: ${theme.colors.gray3};
  width: 100%;
  margin: ${theme.spacing.space16} 0;
`;

const Img = styled.img`
  width: 100%;
  height: auto;
  padding-bottom: ${theme.spacing.space64};
`;

const FlexButton = styled.button`
  display: flex;
  align-items: center;
  cursor: ${(props) => !props.disabled && 'pointer'};
  background-color: transparent;
  border: none;
`;

const Sticky = styled.div`
  position: sticky;
  top: 70px;
  background-color: ${theme.colors.gray1};
  z-index: 2;
  margin-top: 70px;
`;

export interface ContentSummaryPanel {
  label: string;
  value: string;
  onClick: () => void;
  order: number;
}

export interface SummaryPanelProps {
  formData?: AllFormsData;
  comments?: FormComment;
  goToStep: Dispatch<SetStateAction<number>>;
  goToStepDisabled: boolean;
  findStepByKey: (key: keyof AllFormsData) => number;
  disabled: boolean;
}

const SummaryPanel = (props: SummaryPanelProps) => {
  const {
    formData,
    goToStep,
    goToStepDisabled,
    comments,
    findStepByKey,
    disabled,
  } = props;

  const containerRef = useRef<HTMLElement>(null);
  const { t } = useTranslation(['summaryPanel', 'enums']);
  const { isMobile } = useViewport();
  const [isOpen, setIsOpen] = useState(false);
  const [content, setContent] = useState<ContentSummaryPanel[]>([]);

  const onClose = () => {
    if (isMobile) {
      if (containerRef?.current) {
        containerRef.current.style.animationName = 'slideout';
      }
      setTimeout(() => {
        setIsOpen(false);
      }, ANIMATION_DURATION - 1);
    }
  };

  // Function to get the value in terms of array or string
  const getFormDataValue = useCallback((value: any) => {
    if (Array.isArray(value)) {
      return value.map((option: Option) => option.name).join(' et ');
    } else {
      return (
        value?.full_name ||
        value?.name ||
        t(`${value}`, {
          ns: 'enums',
          defaultValue: '',
        }) ||
        value
      );
    }
  }, []);

  // create a content
  useEffect(() => {
    if (comments && Object.values(comments).length > 0) {
      const content = Object.values(comments).reduce(
        (arr: ContentSummaryPanel[], { label, step, comment }, index) => {
          arr.push({
            order: index,
            label: label,
            value: comment,
            onClick: () => {
              goToStep(step);
              onClose();
            },
          });

          return arr;
        },
        [],
      );

      setContent(content);
    } else if (formData) {
      const content = Object.keys(formData).reduce(
        (arr: ContentSummaryPanel[], key) => {
          const step = findStepByKey(key as keyof AllFormsData);
          const value = getFormDataValue(formData[key as keyof AllFormsData]);
          if (
            t(key, { defaultValue: '', ns: 'summaryPanel' }) &&
            value &&
            JSON.stringify(value) !== '{}'
          ) {
            arr.push({
              order: parseInt(t(`${key}.order`, { ns: 'summaryPanel' })),
              label: t(`${key}.label`, {
                defaultValue: key,
                ns: 'summaryPanel',
              }),
              value: t(`${key}.value`, {
                defaultValue: '',
                value,
              }),
              onClick: () => {
                goToStep(step);
                onClose();
              },
            });
          }
          return arr;
        },
        [],
      );

      setContent(content);
    }
  }, [formData, comments]);

  useEffect(() => {
    if (isOpen && isMobile) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
    return () => {
      document.body.style.overflow = '';
    };
  }, [isOpen, isMobile]);

  const panelRender = () => (
    <Container ref={containerRef}>
      <Sticky>
        <Flex
          direction={{ xs: 'row' }}
          paddingTop={{ xs: 'space40' }}
          marginLeft={{ xs: 'space32' }}
          marginRight={{ xs: 'space32' }}
          justify="between"
        >
          <Flex direction={{ xs: 'column' }}>
            <Text
              content={
                comments && Object.keys(comments).length > 0
                  ? t('commentTitle')
                  : t('title')
              }
              fontStyle="heading4"
              weight="bold"
              marginBottom={{ xs: 'space8' }}
            />
            <Text
              content={
                comments && Object.keys(comments).length > 0
                  ? t('commentSubtitle', {
                      count: Object.keys(comments).length,
                    })
                  : t('subtitle')
              }
              fontStyle="body2"
              color={theme.colors.gray6}
            />
          </Flex>
          <MobileOnly>
            <IconButton
              iconName="close"
              color={theme.colors.white}
              backgroundColor={theme.colors.green1}
              rounded
              onClick={onClose}
            />
          </MobileOnly>
        </Flex>
        <Line />
      </Sticky>
      <Flex
        direction={{ xs: 'column' }}
        marginLeft={{ xs: 'space32' }}
        marginRight={{ xs: 'space32' }}
        marginTop={{ xs: 'space24' }}
      >
        {content && content.length > 0 ? (
          sortBy(content, ['order']).map((c, index) => {
            return (
              <Flex
                key={`recapForm-step-${index}`}
                direction={{ xs: 'column' }}
                marginBottom={{ xs: 'space32', md: 'space24' }}
              >
                <Text
                  content={c.label}
                  fontStyle="body2"
                  weight="medium"
                  marginBottom={{ xs: 'space8' }}
                />
                {c.value && (
                  <Text
                    content={c.value}
                    fontStyle="body2"
                    color={theme.colors.gray5}
                    marginBottom={{ xs: 12 }}
                  />
                )}
                <FlexButton
                  onClick={() => c.onClick()}
                  // type="submit"
                  // id="submit-summary" // don't change this. Use for submit button
                  // form="form" // don't change this. Use for submit button
                  disabled={goToStepDisabled}
                >
                  <IconButton
                    iconName={disabled ? 'ArrowRight' : 'Edit'}
                    color={
                      goToStepDisabled
                        ? theme.colors.gray1
                        : theme.colors.salmon1
                    }
                    backgroundColor={
                      goToStepDisabled
                        ? theme.colors.gray3
                        : theme.colors.salmon3
                    }
                    rounded
                    size="small"
                  />
                  <Text
                    content={disabled ? t('goTo') : t('editText')}
                    fontStyle="body3"
                    transform="uppercase"
                    weight="bold"
                    color={
                      goToStepDisabled ? theme.colors.gray4 : theme.colors.black
                    }
                    marginLeft={{ xs: 'space8' }}
                  />
                </FlexButton>
              </Flex>
            );
          })
        ) : (
          <Img src={defaultImg} alt="Pas de réponses" />
        )}
      </Flex>
    </Container>
  );
  return (
    <>
      <DesktopOnly>{panelRender()}</DesktopOnly>
      {/* <MobileOnly>
        <ButtonContainer>
          {!isOpen && (
            <Button
              content={t('textButton.open')}
              iconRight={{
                name: iconName,
                primaryColor: theme.colors.white,
              }}
              backgroundColor={theme.colors.green1}
              textColor={theme.colors.white}
              borderRadius="16px"
              fontSize={theme.size.size1}
              size="small"
              onClick={onOpen}
              boxShadow="0px 4px 12px rgba(28, 53, 51, 0.16)"
            />
          )}
        </ButtonContainer>
        {isOpen && panelRender()}
      </MobileOnly> */}
    </>
  );
};

export default SummaryPanel;
