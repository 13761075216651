import { FC } from 'react';
import styled from 'styled-components';

import { theme } from 'theme';

import { Flex } from 'components/Layout';
import { DocumentFull, Procedure } from '../../../types/resources';
import { Preview } from '../../../components/Viewer';

interface DocumentViewerProps {
  procedure: Procedure;
  document: DocumentFull;
}

const ViewerContainer = styled(Flex)`
  position: relative;
  background-color: ${theme.colors.white};
  border: 1px solid ${theme.colors.gray3};
  border-radius: ${theme.spacing.space32};
  padding: ${theme.spacing.space72} ${theme.spacing.space40};
  width: 100%;
  overflow-y: scroll;
`;

// const FullscreenButton = styled.div`
//   position: absolute;
//   top: ${theme.spacing.space24};
//   right: ${theme.spacing.space24};
// `;

const DocumentViewer: FC<DocumentViewerProps> = ({ procedure, document }) => {
  // const [fullscreen, setFullscreen] = useState(false);

  if (!document.file_id) return null;
  // const displayFullscreen = () => {
  //   setFullscreen(true);
  // };
  // const hideFullscreen = () => {
  //   setFullscreen(false);
  // };

  return (
    <ViewerContainer alignItems="center">
      <Preview procedure={procedure} document={document} />
      {/* <FullscreenButton>
        <IconButton
          iconName="extend"
          color={theme.colors.black}
          backgroundColor={theme.colors.gray3}
          size="extra-extra-large"
          onClick={displayFullscreen}
        />
      </FullscreenButton>
      {fullscreen && (
        <Modal full onClose={hideFullscreen}>
          <Preview files={data.document.files} />
        </Modal>
      )} */}
    </ViewerContainer>
  );
};

export default DocumentViewer;
