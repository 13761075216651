import { FC } from 'react';

import NotesCard from 'components/Notes/NotesCard';
import { CommentFull, ProcedureFull } from '../../../types/resources';
import { useApi } from '../../../hooks/useApi';
import { PaginationMetadata } from '../../../hooks/useFetch';

interface NotesOpportunityProps {
  procedure: ProcedureFull;
  comments: CommentFull[];
  commentsPagination: PaginationMetadata;
  refetch: () => void;
}

interface UpdateNoteProps {
  noteId: string;
  data: {
    content?: string | null;
    isValid?: boolean | null;
  };
}

const NotesOpportunity: FC<NotesOpportunityProps> = ({
  procedure,
  comments,
  commentsPagination,
  refetch,
}) => {
  const { execute: createComment } = useApi(
    `/procedures/${procedure.id}/comments`,
    { method: 'POST' },
  );

  const { execute: updateComment } = useApi(
    `/procedures/${procedure.id}/comments`,
    { method: 'PATCH' },
  );

  const { execute: processComment } = useApi(
    `/procedures/${procedure.id}/comments`,
    { method: 'POST' },
  );

  const handleCreateNote = async (content: string) => {
    createComment({
      body: { content },
      onSuccess: refetch,
    });
  };

  const handleUpdateNote = async ({ noteId, data }: UpdateNoteProps) => {
    if (data.isValid !== undefined) {
      processComment({
        endpoint: `/procedures/${procedure.id}/comments/${noteId}/process`,
        body: { processed: data.isValid },
        onSuccess: refetch,
      });
    } else {
      updateComment({
        endpoint: `/procedures/${procedure.id}/comments/${noteId}`,
        body: { content: data.content },
        onSuccess: refetch,
      });
    }
  };

  return (
    <NotesCard
      comments={comments}
      commentsPagination={commentsPagination}
      onCreate={handleCreateNote}
      onUpdate={handleUpdateNote}
    />
  );
};

export default NotesOpportunity;
