import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Col, Row, Text } from 'components/Layout';
import { Button } from 'components/Buttons';

import {
  Document,
  Form,
  ProcedureFull,
  ProcedureStepStatus,
  ProcedureStepType,
} from '../../../../types/resources';
import { useApi } from '../../../../hooks/useApi';
import useToaster from '../../../../hooks/useToaster';

interface DocumentsVerificationProps {
  procedure: ProcedureFull;
  refetchProcedure: () => void;
}

const DocumentsVerification: FC<DocumentsVerificationProps> = ({
  procedure,
  refetchProcedure,
}) => {
  const { t } = useTranslation();
  const { success: toasterSuccess } = useToaster();

  const {
    execute: updateStep,
    state: { loading: updateStepLoading },
  } = useApi(
    `/procedures/${procedure.id}/steps/${ProcedureStepType.VERIFICATION}`,
    { method: 'PATCH' },
  );

  const { execute: getForms, state: getFormsState } = useApi<Form[]>(
    `/procedures/${procedure.id}/forms`,
  );
  const { execute: getDocuments, state: getDocumentsState } = useApi<
    Document[]
  >(`/procedures/${procedure.id}/documents?is_administrative=true`);

  const verificationStep = procedure.steps.find(
    (s) => s.step === ProcedureStepType.VERIFICATION,
  );

  const forms = getFormsState.data?.value;
  const documents = getDocumentsState.data?.value;

  useEffect(() => {
    getForms();
    getDocuments();
  }, []);

  if (!procedure || !forms || !documents) return null;

  const onValidate = async () => {
    if (verificationStep?.status === ProcedureStepStatus.IN_PROGRESS) {
      updateStep({
        body: { status: ProcedureStepStatus.IN_REVIEW },
        onSuccess: () => {
          refetchProcedure();
          toasterSuccess(
            t('documents.verification.inReview_verification_success'),
          );
        },
      });
    } else if (verificationStep?.status === ProcedureStepStatus.IN_REVIEW) {
      updateStep({
        body: { status: ProcedureStepStatus.VALIDATED },
        onSuccess: () => {
          refetchProcedure();
          toasterSuccess(
            t('documents.verification.inReview_verification_success'),
          );
        },
      });
    }
  };

  return (
    <>
      <Row marginBottom={{ xs: 'space40' }}>
        <Col xs={12}>
          <Text fontStyle="body1" content={t('documents.verification.info')} />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={3}>
          {procedure.current_step.step === ProcedureStepType.VERIFICATION && (
            <Button
              content={
                verificationStep?.status === ProcedureStepStatus.IN_PROGRESS
                  ? t('documents.verification.inReview_verification')
                  : t('documents.verification.validate_verification')
              }
              onClick={onValidate}
              primary
              $loading={updateStepLoading}
            />
          )}
        </Col>
        <Col xs={12} md={3}>
          <></>
        </Col>
      </Row>
    </>
  );
};

export default DocumentsVerification;
