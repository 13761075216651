export interface colorType {
  black: string;
  gray1: string;
  gray2: string;
  gray3: string;
  gray4: string;
  gray5: string;
  gray6: string;
  white: string;
  green1: string;
  green2: string;
  green3: string;
  green4: string;
  green5: string;
  green6: string;
  salmon1: string;
  salmon2: string;
  salmon3: string;
  salmonPastel: string;
  beige: string;
  orange1: string;
  orange2: string;
  orange3: string;
  pink1: string;
  pink2: string;
  red1: string;
  red2: string;
  red3: string;
  blue1: string;
  blue2: string;
  blue3: string;
}

export const colors: colorType = {
  black: 'rgba(44, 41, 38, 1)',
  gray1: 'rgba(252, 251, 251, 1)',
  gray2: 'rgba(244, 242, 240, 1)',
  gray3: 'rgba(227, 225, 221, 1)',
  gray4: 'rgba(192, 187, 181, 1)',
  gray5: 'rgba(142, 142, 142, 1)',
  gray6: 'rgba(83, 95, 86, 1)',
  white: 'rgba(255, 255, 255, 1)',
  beige: 'rgba(244, 240, 235, 1)',
  green1: 'rgba(40, 83, 79, 1)',
  green2: 'rgba(174, 207, 199, 1)',
  green3: 'rgba(84, 184, 107, 1)',
  green4: 'rgba(185, 211, 196, 1)',
  green5: 'rgba(214, 241, 225, 1)',
  green6: 'rgba(174, 207, 199, 0.75)',
  salmon1: 'rgba(236, 133, 105, 1)',
  salmon2: 'rgba(222, 112, 81, 1)',
  salmon3: 'rgba(248, 234, 230, 1)',
  salmonPastel: 'rgba(248, 234, 230, 1)',
  orange1: 'rgba(236, 128, 28, 1)',
  orange2: 'rgba(232, 198, 174, 1)',
  orange3: 'rgba(247, 227, 209, 1)',
  pink1: 'rgba(232, 174, 174, 1)',
  pink2: 'rgba(247, 211, 209, 1)',
  red1: 'rgba(236, 78, 28, 1)',
  red2: 'rgba(232, 174, 174, 1)',
  red3: 'rgba(246, 220, 218, 1)',
  blue1: 'rgba(28, 111, 236, 1)',
  blue2: 'rgba(174, 193, 232, 1)',
  blue3: 'rgba(209,229,247,1)',
};
