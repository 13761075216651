import { FC, useState } from 'react';
import styled from 'styled-components';

import { theme } from 'theme';

import { Text } from 'components/Layout';
import { IconButton } from './IconButton';
import { IconProps } from 'components/Images/Icon';

const Button = styled.button`
  display: flex;
  align-self: flex-start;
  align-items: center;
  cursor: pointer;
  border: none;
`;

interface MenuButtonProps {
  onClick: () => void;
  content: string;
  iconName: IconProps['name'];
  active?: boolean;
  primary?: boolean;
}

const MenuButton: FC<MenuButtonProps> = ({
  onClick,
  content,
  iconName,
  active,
  primary,
}) => {
  const [hoverState, setHoverState] = useState(false);

  const getColor = () => {
    if (primary) return theme.colors.green1;
    return theme.colors.salmon1;
  };

  const getBackgoundColor = () => {
    if (active) return theme.colors.white;
    if (hoverState) return theme.colors.green2;
    if (primary) return theme.colors.green6;
    return theme.colors.gray3;
  };

  const color = getColor();
  const backgroundColor = getBackgoundColor();

  return (
    <Button
      onClick={onClick}
      onMouseEnter={() => setHoverState(true)}
      onMouseLeave={() => setHoverState(false)}
    >
      <IconButton
        iconName={iconName}
        color={color}
        backgroundColor={backgroundColor}
      />
      <Text
        content={content}
        fontStyle="body3"
        color={theme.colors.white}
        weight={hoverState ? 'bold' : 'medium'}
        marginLeft={{ xs: 'space16' }}
        decoration="none"
      />
    </Button>
  );
};

export default MenuButton;
