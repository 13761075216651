import { FC, useEffect, useState } from 'react';
import { FieldProps } from '@rjsf/core';
import get from 'lodash/get';

import { Flex } from 'components/Layout/Flex';
import { replaceLabelWithContext } from '../Utils/ReplaceLabel';
import { InputTel } from 'components/FormTemplate/Fields/InputTel';
import RequiredField from '../Utils/RequiredField';
import { Label } from 'components/Text/Label';

export const TelField: FC<FieldProps> = ({
  required,
  schema,
  uiSchema,
  onChange,
  formData,
  disabled,
  formContext,
}) => {
  const [value, setValue] = useState<string>('');
  const title = get(schema, 'title', '');
  const label = replaceLabelWithContext(title, formContext.data);
  const placeholder = get(uiSchema, 'ui:placeholder', null);

  const _onChange = (newValue: string) => {
    onChange(newValue);
  };

  useEffect(() => {
    if (formData) {
      setValue(formData);
    }
  }, []);

  return (
    <Flex direction={{ xs: 'column' }} position="relative">
      {label && (
        <Flex
          alignItems="center"
          marginBottom={{ xs: 'space16', md: 'space24' }}
        >
          <RequiredField required={required}>
            <Label dangerouslySetInnerHTML={{ __html: label }} />
          </RequiredField>
        </Flex>
      )}

      <InputTel
        value={value}
        onChange={_onChange}
        placeholder={placeholder}
        disabled={disabled}
        required={required}
      />
    </Flex>
  );
};
