import { FC, forwardRef, useEffect, useState } from 'react';
import Select, { components, IndicatorProps } from 'react-select';
import { useTranslation } from 'react-i18next';

// Components
import { theme } from 'theme';
import { Text } from 'components/Layout/Text';
import { Flex } from 'components/Layout/Flex';

// Types
import { StylesConfig } from 'react-select/src/styles';
import { OptionTypeBase } from 'react-select/src/types';
import RequiredField from 'components/Forms/Utils/RequiredField';
import { Weights } from 'theme/styles/size';

// Utils
import { sortOptionsAlphabetically } from 'utils/format';
import { FieldError } from 'react-hook-form';
import { determineIfIsFieldErrorOrString } from 'utils/errors';

// Components
import { Icon } from 'components/Images/Icon';
import { Label } from 'components/Text/Label';

export type InputSelectProps = {
  label?: string;
  placeholder?: string;
  options: OptionTypeBase[];
  size?: 'small' | 'medium';
  isSortable?: boolean;
  isClearable?: boolean;
  required?: boolean;
  width?: string;
  onChange: (val: any) => void;
  error?: FieldError | string;
  labelWeight?: Weights;
  labelUppercase?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  needsMargin?: boolean;
};

export type InputSelectSingleProps = InputSelectProps & {
  isMulti?: false;
  value?: string | null;
};

export type InputSelectMultiProps = InputSelectProps & {
  isMulti: true;
  value?: string[] | null;
};

const DropdownIndicator: FC<IndicatorProps<any, any>> = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <Icon name="arrowDownThin" primaryColor={theme.colors.salmon1} />
    </components.DropdownIndicator>
  );
};

const InputSelect = forwardRef(
  (props: InputSelectSingleProps | InputSelectMultiProps, ref) => {
    const {
      options,
      placeholder,
      label,
      isSortable,
      onChange,
      size = 'medium',
      isClearable = false,
      required,
      value,
      width,
      error,
      disabled,
      ...otherProps
    } = props;
    const [updatedValue, setUpdatedValue] = useState<
      string | string[] | undefined | null
    >(value);

    const { t } = useTranslation();
    const customStyles: StylesConfig<any, boolean> = {
      control: (styles, state) => ({
        ...styles,
        width: width ? width : '100%',
        backgroundColor: state.isDisabled ? theme.colors.gray2 : 'white',
        height:
          size === 'small' ? theme.spacing.space56 : theme.spacing.space64,
        border: `1px solid ${
          error
            ? theme.colors.red1
            : state.selectProps.menuIsOpen
            ? theme.colors.gray4
            : theme.colors.gray3
        }`,
        borderRadius: theme.spacing.space16,
        fontSize: `16px`,
        fontWeight: 400,
        transition: 'all .1s, font-weight 0s, border 0.5s ease',
        color: `${theme.colors.black}`,
        paddingLeft: `${theme.spacing.space16}`,
        paddingRight: `${theme.spacing.space16}`,
        boxShadow: 'none',
        opacity: 1,
        '&:hover': {
          color: theme.colors.gray4,
        },
      }),
      menu: (styles) => ({
        ...styles,
        border: `1px solid ${theme.colors.gray4}`,
        borderRadius: `${theme.spacing.space16}`,
        paddingRight: 8,
        paddingTop: 8,
        paddingBottom: 8,
        paddingLeft: 16,
        marginTop: 8,
        boxShadow: 'none',
        maxHeight: 256,
        zIndex: 5,
        '&:after': {
          content: "''",
          position: 'absolute',
          top: '100%',
          height: 86,
          width: '100%',
        },
      }),
      menuList: (base) => ({
        ...base,
        height: 'auto',
        maxHeight: 237,
        paddingTop: 0,
        paddingRight: 8,
        paddingBottom: 0,
        overflowX: 'hidden',
        '::-webkit-scrollbar': {
          width: 3,
        },
        '::-webkit-scrollbar-track': {
          background: `${theme.colors.gray2}`,
          borderRadius: 10,
        },
        '::-webkit-scrollbar-thumb': {
          background: `${theme.colors.gray4}`,
          borderRadius: 10,
        },
        '::-webkit-scrollbar-thumb:hover': {
          background: `${theme.colors.gray4}`,
        },
      }),
      singleValue: (styles) => ({
        ...styles,
        color: theme.colors.black,
        fontSize: size === 'small' ? '14px' : '16px',
        lineHeight: '18px',
        fontWeight: 400,
      }),
      multiValue: (styles) => ({
        ...styles,
        backgroundColor: theme.colors.salmon3,
        padding: '4px',
        borderRadius: '16px',
      }),
      multiValueLabel: (styles) => ({
        ...styles,
        color: theme.colors.black,
        fontSize: size === 'small' ? '14px' : '16px',
        lineHeight: '18px',
        fontWeight: 400,
      }),
      valueContainer: (styles) => ({
        ...styles,
        overflow: 'initial',
      }),
      option: (styles, state: { isFocused: boolean; isDisabled: boolean }) => ({
        ...styles,
        backgroundColor: state.isFocused ? theme.colors.gray1 : 'white',
        color: 'black',
        cursor: 'pointer',
        padding: '8px 0',
        borderBottom: `1px solid ${theme.colors.gray3}`,
        marginRight: 3,
        fontSize: size === 'small' ? '14px' : '16px',
        lineHeight: '18px',
        fontWeight: 500,
        opacity: state.isDisabled ? 0.5 : 1,

        '&:last-child': {
          border: 'none',
        },
      }),
      dropdownIndicator: (styles, state) => ({
        ...styles,
        color: `${theme.colors.salmon1}`,
        cursor: 'pointer',
        svg: {
          width: '30px',
          height: '25px',
          transform: state.selectProps.menuIsOpen
            ? 'rotate(-180deg)'
            : 'rotate(0deg)',
          transition: 'transform .3s ease-in-out',
        },
        '&:hover': {
          color: `${theme.colors.salmon2}`,
        },
      }),
      indicatorSeparator: () => ({ display: 'none' }),
      placeholder: (base) => ({
        ...base,
        color: theme.colors.gray5,
        fontSize: size === 'small' ? '14px' : '16px',
        lineHeight: '20px',
        fontWeight: 400,
      }),
    };

    useEffect(() => {
      setUpdatedValue(value);
    }, [value]);

    const _onChange = (newValue: string | string[]) => {
      setUpdatedValue(newValue);

      if (onChange) {
        onChange(newValue);
      }
    };

    return (
      <Flex direction={{ xs: 'column' }} width="100%">
        {label && (
          <Flex marginBottom={{ xs: 'space8' }}>
            <RequiredField required={required}>
              <Label
                dangerouslySetInnerHTML={{ __html: label }}
                fontStyle={size === 'small' ? 'label' : 'heading7'}
              />
            </RequiredField>
          </Flex>
        )}
        <Select
          ref={ref as any}
          label={label}
          options={isSortable ? sortOptionsAlphabetically(options) : options}
          styles={customStyles}
          components={{ DropdownIndicator }}
          placeholder={placeholder}
          // defaultValue={options[0]}
          onChange={(val) => {
            const v = Array.isArray(val) ? val.map((v) => v.value) : val?.value;
            _onChange(v);
          }}
          isClearable={!props.readOnly && isClearable}
          noOptionsMessage={() => t('search.not-found')}
          value={
            Array.isArray(updatedValue)
              ? options.filter((o) => updatedValue.some((uv) => o.value === uv))
              : options.find((o) => o.value === updatedValue)
          } // hack to have controlled component
          isDisabled={disabled}
          isSearchable={!props.readOnly}
          openMenuOnClick={!props.readOnly}
          menuIsOpen={props.readOnly ? false : undefined}
          {...otherProps}
        />
        {error && (
          <Text
            content={
              error && determineIfIsFieldErrorOrString(error)
                ? error.message
                : error
            }
            fontStyle="body2"
            color={theme.colors.red1}
            marginTop={{ xs: 'space8' }}
            marginBottom={{ xs: 'space8' }}
          />
        )}
      </Flex>
    );
  },
);

InputSelect.displayName = 'InputSelect';

export default InputSelect;
