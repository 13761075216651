import { ChangeEvent, FC, useCallback, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Modal, ModalHeader } from '../../../components/Modal';
import { Flex, Line } from 'components/Layout';
import { Button } from 'components/Buttons';
import { InputText } from 'components/FormTemplate/Fields/InputText';
import { md } from 'theme/styles/mediaQueries';
import { useApi } from '../../../hooks/useApi';

interface UpdateTitleDocumentProps {
  opened: boolean;
  onClose: () => void;
  documentId: string;
  documentName: string;
  opportunityId: string;
  refetchDocuments: () => void;
}

const CustomInput = styled(InputText)`
  min-width: 320px;
  ${md(`
    min-width: 640px;
  `)}
`;

const UpdateTitleDocument: FC<UpdateTitleDocumentProps> = ({
  documentId,
  documentName,
  opened,
  onClose,
  opportunityId,
  refetchDocuments,
}) => {
  const [name, setName] = useState(documentName);
  const { t } = useTranslation();
  const { execute: updateDocument } = useApi(
    `/procedures/${opportunityId}/documents/${documentId}`,
    {
      method: 'PATCH',
      onSuccess: () => {
        onClose();
        refetchDocuments();
      },
    },
  );

  const onChangeName = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const onSubmit = useCallback(() => {
    updateDocument({
      body: { title: name },
    });
  }, [name]);

  return (
    <Modal opened={opened} onClose={onClose}>
      <Flex marginBottom={{ xs: 'space16' }} justify="between">
        <ModalHeader
          iconName="Document"
          content={t('documents.update_title_document')}
          onClose={onClose}
        />
      </Flex>
      <Flex marginBottom={{ xs: 'space32' }}>
        <Line />
      </Flex>
      <Flex marginBottom={{ xs: 'space32' }}>
        <CustomInput
          label={t('documents.request_document.label')}
          onChange={onChangeName}
          value={name}
          required
        />
      </Flex>
      <Flex direction={{ xs: 'column', md: 'row' }}>
        <Button
          content={t('modify')}
          disabled={!name}
          onClick={onSubmit}
          primary
        />
      </Flex>
    </Modal>
  );
};

export default UpdateTitleDocument;
