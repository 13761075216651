import InputCheckbox from 'components/FormTemplate/Fields/InputCheckbox';
import { Flex } from 'components/Layout/Flex';
import { FC } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { toggleSearchParams } from 'utils/search';

const CasesListOptionsFilter: FC = () => {
  const location = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(location.search);

  const handleExpressOptionChange = (val: any) => {
    toggleSearchParams('express', val, searchParams);
    history.replace({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  };

  const handlePremiumOptionChange = (val: any) => {
    toggleSearchParams('premium', val, searchParams);
    history.replace({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  };

  return (
    <Flex direction={{ xs: 'row' }} alignItems="center" gap={{ xs: 'space16' }}>
      <InputCheckbox
        label="Express"
        onChange={(value) => handleExpressOptionChange(value)}
        checked={searchParams.get('express') === 'true'}
      />
      <InputCheckbox
        label="Premium"
        onChange={(value) => handlePremiumOptionChange(value)}
        checked={searchParams.get('premium') === 'true'}
      />
    </Flex>
  );
};

export default CasesListOptionsFilter;
