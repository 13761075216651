import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Column } from 'react-table';

// Components
import { Row, Col, Flex } from 'components/Layout';
import { TextEllipsis } from 'components/Text';
import { DateInput } from 'components/Forms';
import { Button, IconButton } from 'components/Buttons';
import { Table } from 'components/Table';

// Data

// Utils
import { theme } from 'theme';
import { UploadModal } from 'components/Modal';
import AddressCard from '../cards/AddressCard';
import { parseISO } from 'date-fns';
import {
  NotarialDeed,
  Procedure,
  ProcedureFull,
  ProcedureStepType,
} from '../../../types/resources';
import { useApi } from '../../../hooks/useApi';
import DocumentPreviewModal from '../../../components/Modal/DocumentPreviewModal';

interface FormalityProps {
  procedure: ProcedureFull;
  refetchProcedure: () => void;
}

const NotarialDeedPage: FC<FormalityProps> = ({
  procedure,
  refetchProcedure,
}) => {
  const step = ProcedureStepType.NOTARY;
  const name = 'Enregistrement notarié';
  const { t } = useTranslation();
  const [uploadModalState, setUploadModalState] = useState<boolean>(false);
  const [previewModalState, setPreviewModalState] = useState<boolean>(false);
  const [selected, setSelected] = useState<NotarialDeed>();
  const pageSize = 2;

  const { execute: getData, state: getDataState } = useApi<NotarialDeed[]>(
    `/procedures/${procedure.id}/notarial-deeds`,
  );
  const notarialDeeds = getDataState.data?.value;
  const notarialDeed = notarialDeeds?.find(
    (n) => n.procedure_id === procedure.id,
  );

  const { execute: updateData, state: updateDataState } = useApi(
    `/procedures/${procedure.id}/notarial-deeds`,
    {
      method: 'PUT',
      onSuccess: () => {
        onUploadClose();
        getData();
      },
    },
  );

  const finishStep = Boolean(notarialDeed?.document_id);

  const onUploadOpen = (selected: NotarialDeed) => {
    setSelected(selected);
    setUploadModalState(true);
  };

  const onPreviewOpen = (selected: NotarialDeed) => {
    setSelected(selected);
    setPreviewModalState(true);
  };

  const onUploadClose = () => {
    setUploadModalState(false);
  };

  const onPreviewClose = () => {
    setSelected(undefined);
    setPreviewModalState(false);
  };

  const onConfirmUpload = (blob: Blob) => {
    const body = new FormData();
    body.append('file', blob, 'acte notarié.pdf');

    updateData({
      body,
      onSuccess: () => {
        onUploadClose();
        getData();
        refetchProcedure();
      },
    });
  };

  const columns: Column<Procedure>[] = [
    {
      Header: t('letters.columns.name') || 'Nom',
      id: 'id',
      accessor: (originalRow) => originalRow,
      width: 234,
      disableSortBy: true,
      Cell: () => (
        <Flex height="100%" alignItems="center">
          <TextEllipsis fontStyle="body1" content={name} weight="medium" />
        </Flex>
      ),
    },
    {
      Header: t('letters.columns.demand_date') || 'Date de demande',
      id: 'requested_at',
      accessor: (originalRow) => originalRow,
      width: 200,
      disableSortBy: true,
      Cell: () => (
        <DateInput
          onChange={(value) => {
            const isValid =
              value instanceof Date &&
              !isNaN(value.getTime()) &&
              value?.getFullYear() > 1900;

            if (isValid) {
              const body = new FormData();
              body.set('requested_at', value.toISOString());
              updateData({ body });
            }
          }}
          disableFuture
          value={
            notarialDeed?.requested_at
              ? parseISO(notarialDeed.requested_at)
              : null
          }
        />
      ),
    },
    {
      Header: t('letters.columns.received_date') || 'Date de réception',
      id: 'received_at',
      accessor: (originalRow) => originalRow,
      width: 200,
      disableSortBy: true,
      Cell: () => (
        <DateInput
          onChange={(value) => {
            const isValid =
              value instanceof Date &&
              !isNaN(value.getTime()) &&
              value?.getFullYear() > 1900;

            if (isValid) {
              const body = new FormData();
              body.set('received_at', value.toISOString());
              updateData({ body });
            }
          }}
          minDate={
            notarialDeed?.requested_at
              ? parseISO(notarialDeed.requested_at)
              : undefined
          }
          value={
            notarialDeed?.received_at
              ? parseISO(notarialDeed.received_at)
              : null
          }
          disabled={!notarialDeed?.requested_at}
        />
      ),
    },
    {
      Header: t(`letters.columns.upload_${step}`) || 'Télécharger le courrier',
      id: 'document_id',
      accessor: (originalRow) => originalRow,
      disableSortBy: true,
      width: 212,
      Cell: () => {
        const receipt = t(`formality_letters.buttons.receipt_${step}`);
        const upload = t(`formality_letters.buttons.upload_${step}`);
        return (
          <Flex alignItems="center">
            <Button
              onClick={() => {
                if (notarialDeed) {
                  if (notarialDeed.document_id) {
                    onPreviewOpen(notarialDeed);
                  } else {
                    onUploadOpen(notarialDeed);
                  }
                }
              }}
              content={notarialDeed?.document_id ? receipt : upload}
              primary={!notarialDeed?.document_id}
              disabled={
                !(notarialDeed?.received_at && notarialDeed?.requested_at)
              }
              marginRight={{
                xs: notarialDeed?.document_id ? 'space8' : 'none',
              }}
            />
            {notarialDeed?.document_id && (
              <IconButton
                primary
                color={theme.colors.white}
                backgroundColor={theme.colors.salmon1}
                iconName="Edit"
                onClick={() => {
                  onUploadOpen(notarialDeed);
                }}
                size="extra-large"
              />
            )}
          </Flex>
        );
      },
    },
  ];

  return (
    <>
      <Row marginBottom={{ xs: 'space32' }}>
        <Col xs={12}>
          <Table<Procedure>
            data={[procedure]}
            columns={columns}
            pageSize={pageSize}
            totalCount={1}
            loadMore={() => getData()}
            noSeparator
            backgroundColor={theme.colors.beige}
            rounded
            asCard
          />
        </Col>
        {selected && (
          <UploadModal
            opened={uploadModalState}
            onClose={() => setUploadModalState(false)}
            onConfirm={onConfirmUpload}
            loading={updateDataState.loading}
            tip={false}
            documentName={name}
          />
        )}
        {notarialDeed?.document_id && (
          <DocumentPreviewModal
            opened={previewModalState}
            onClose={onPreviewClose}
            procedure={procedure}
            documentId={notarialDeed.document_id}
          />
        )}
      </Row>
      <Row>
        <Col xs={12} md={4}>
          <AddressCard
            customerId={procedure.spouse1_id}
            disabled={finishStep}
            index={1}
          />
        </Col>
        <Col xs={12} md={4}>
          <AddressCard
            customerId={procedure.spouse2_id}
            disabled={finishStep}
            index={2}
          />
        </Col>
      </Row>
    </>
  );
};

export default NotarialDeedPage;
