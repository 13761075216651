import { CSSProperties, FC, PropsWithChildren } from 'react';

import { Text } from 'components/Layout';
import { FontStyles } from 'theme/styles/fonts';
import { Weights } from 'theme/styles/size';
import styled from 'styled-components';
import { theme } from '../../theme';

interface TextLinkProps {
  fontStyle?: FontStyles;
  content?: string;
  weight?: Weights;
  style?: CSSProperties | undefined;
  onClick?: (...args: unknown[]) => unknown;
}

const StyledTextLink = styled(Text)`
  cursor: pointer;
  color: ${theme.colors.salmon2};

  :hover {
    color: ${theme.colors.salmon1};
    text-decoration: underline;
  }
`;

const TextLink: FC<PropsWithChildren<TextLinkProps>> = ({
  fontStyle = 'body1',
  content,
  weight,
  onClick,
  style,
  children,
}) => (
  <StyledTextLink
    onClick={onClick}
    fontStyle={fontStyle}
    content={content}
    weight={weight}
    style={style}
  >
    {children}
  </StyledTextLink>
);

export default TextLink;
