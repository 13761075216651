const pxtorem = (size: number) => `${size / 16}rem`;

export type Spacing =
  | 'none'
  | 'auto'
  | 'space4'
  | 'space8'
  | 'space12'
  | 'space16'
  | 'space24'
  | 'space32'
  | 'space40'
  | 'space48'
  | 'space56'
  | 'space64'
  | 'space72'
  | 'space80'
  | 'space88'
  | 'space120'
  | 'space128'
  | 'space150'
  | 'space256'
  | number;

export type spacingTypes = {
  [space in Spacing]: string;
};

export const spacing: spacingTypes = {
  none: '0',
  auto: 'auto',
  space4: `${pxtorem(4)}`,
  space8: `${pxtorem(8)}`,
  space12: `${pxtorem(12)}`,
  space16: `${pxtorem(16)}`,
  space24: `${pxtorem(24)}`,
  space32: `${pxtorem(32)}`,
  space40: `${pxtorem(40)}`,
  space48: `${pxtorem(48)}`,
  space56: `${pxtorem(56)}`,
  space64: `${pxtorem(64)}`,
  space72: `${pxtorem(72)}`,
  space80: `${pxtorem(80)}`,
  space88: `${pxtorem(88)}`,
  space120: `${pxtorem(120)}`,
  space128: `${pxtorem(128)}`,
  space150: `${pxtorem(150)}`,
  space256: `${pxtorem(256)}`,
};
