import { FC, useEffect, useState } from 'react';

import { CalendarPickerView } from '@mui/x-date-pickers';
import DateInput from '../Input/DateInput';
import { FieldProps } from '@rjsf/core';
import { Flex } from 'components/Layout/Flex';
import { Label } from 'components/Text/Label';
import RequiredField from 'components/Forms/Utils/RequiredField';
import get from 'lodash/get';
import { replaceLabelWithContext } from '../Utils/ReplaceLabel';
import { format } from 'date-fns';

export const DatePickerField: FC<FieldProps> = ({
  onChange,
  schema,
  required,
  formData,
  uiSchema,
  disabled,
  formContext,
}) => {
  const [value, setValue] = useState<Date | null>(null);
  const [initialValue, setInitialValue] = useState(false);
  const maxToday: boolean = get(uiSchema, 'ui:options.maxToday', false);
  const inputFormat: string = get(
    uiSchema,
    'ui:options.inputFormat',
    'dd/MM/yyyy',
  );
  const mask: string = get(uiSchema, 'ui:options.mask', '__/__/____');
  const openTo: CalendarPickerView = get(uiSchema, 'ui:options.openTo', 'day');
  const placeholder: string = get(uiSchema, 'ui:placeholder', 'dd/mm/aaaa');
  const view: CalendarPickerView[] = get(uiSchema, 'ui:options.view', [
    'day',
    'month',
    'year',
  ]);
  const title = get(schema, 'title', '');
  const label = replaceLabelWithContext(title, formContext.data);

  useEffect(() => {
    if (formData && initialValue === false) {
      const date = new Date(formData);
      setValue(date);
      setInitialValue(true);
    }
  }, [formData]);

  return (
    <Flex direction={{ xs: 'column' }} width="inherit">
      {label && (
        <Flex marginBottom={{ xs: 'space16', md: 'space24' }}>
          <RequiredField required={required}>
            <Label dangerouslySetInnerHTML={{ __html: label }} />
          </RequiredField>
        </Flex>
      )}
      <DateInput
        value={value}
        onChange={(newValue) => {
          setValue(newValue);

          if (
            newValue instanceof Date &&
            !isNaN(newValue.getTime()) &&
            newValue?.getFullYear() > 1900
          ) {
            onChange(format(new Date(newValue), 'yyyy-MM-dd'));
          }
        }}
        disabled={disabled}
        openTo={openTo}
        inputFormat={inputFormat}
        mask={mask}
        placeholder={placeholder}
        disableFuture={maxToday}
        views={view}
      />
    </Flex>
  );
};
