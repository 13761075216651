import { createContext, FC, PropsWithChildren, useState } from 'react';
import { User } from '../types/resources';
import { getStorageValue } from '../hooks/useLocalStorage';

type IAuthContext = {
  user: User | null;
  setUser: (user: User | null) => void;
};

export const AuthContext = createContext<IAuthContext>({
  user: null,
  setUser: () => {},
});

export const AuthProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
  const [user, setUser] = useState<User | null>(() => getStorageValue('user'));

  return (
    <AuthContext.Provider value={{ user, setUser }}>
      {children}
    </AuthContext.Provider>
  );
};
