import { SVGProps } from 'react';

export enum CustomIcon {
  'backSplit' = 'backSplit',
  'newWindow' = 'newWindow',
  'slack' = 'slack',
  'extend' = 'extend',
  'check' = 'check',
  'close' = 'close',
  'plus' = 'plus',
  'minus' = 'minus',
  'arrowDownThin' = 'arrowDownThin',
  'copy' = 'copy',
  'checkWithBackground' = 'checkWithBackground',
  'swapAsc' = 'swapAsc',
  'swapDesc' = 'swapDesc',
  'ArrowDownCircle' = 'ArrowDownCircle',
  'euro' = 'euro',
  'euroCrossed' = 'euroCrossed',
  'headphones' = 'headphones',
  'TimeSquare' = 'TimeSquare',
}

export const icons: Record<CustomIcon, SVGProps<SVGSVGElement>> = {
  [CustomIcon.backSplit]: (
    <>
      <mask
        id="mask0"
        maskUnits="userSpaceOnUse"
        x="0"
        y="7"
        width="20"
        height="15"
        style={{ maskType: 'alpha' }}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.000488281 7.79395H20.0001V21.5379H0.000488281V7.79395Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0)">
        <path
          d="M6.43549 21.5379H15.5655C18.0105 21.5379 20.0005 19.5489 20.0005 17.1029V12.9689C20.0005 12.5547 19.6647 12.2189 19.2505 12.2189C18.8363 12.2189 18.5005 12.5547 18.5005 12.9689V17.1029C18.5005 18.7219 17.1835 20.0379 15.5655 20.0379H6.43549C4.81649 20.0379 3.50049 18.7219 3.50049 17.1029V12.2279C3.50049 10.6099 4.81649 9.29395 6.43549 9.29395H10.3685C10.7825 9.29395 11.1185 8.95795 11.1185 8.54395C11.1185 8.12995 10.7825 7.79395 10.3685 7.79395H6.43549C3.99049 7.79395 2.00049 9.78295 2.00049 12.2279V17.1029C2.00049 19.5489 3.99049 21.5379 6.43549 21.5379Z"
          fill="currentColor"
        />
      </g>
      <mask
        id="mask1"
        maskUnits="userSpaceOnUse"
        x="9"
        y="6"
        width="11"
        height="12"
        style={{ maskType: 'alpha' }}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.2302 17.2578L9.15559 16.2113L18.6027 6.51033L19.6773 7.55684L10.2302 17.2578Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.14 7.03351C19.4366 7.32235 19.4429 7.79748 19.1541 8.09408L10.7535 16.7205C10.4646 17.0171 9.98951 17.0234 9.69291 16.7346C9.39631 16.4457 9.39001 15.9706 9.67885 15.674L18.0795 7.04758C18.3683 6.75098 18.8434 6.74468 19.14 7.03351Z"
          fill="currentColor"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.871 15.5955C15.0078 15.7288 15.0944 15.9151 15.0972 16.1215C15.1034 16.5351 14.7741 16.8761 14.3591 16.8823L10.2279 16.9463C9.82919 16.953 9.47098 16.6042 9.46711 16.2054L9.4208 12.0733C9.41672 11.6589 9.74813 11.32 10.1618 11.3153C10.5761 11.3112 10.9157 11.6419 10.9198 12.0562L10.9582 15.4353L14.3364 15.3834C14.5442 15.3792 14.7334 15.4616 14.871 15.5955Z"
        fill="currentColor"
      />
    </>
  ),
  [CustomIcon.newWindow]: (
    <>
      <mask
        id="mask0"
        maskUnits="userSpaceOnUse"
        x="0"
        y="7"
        width="20"
        height="15"
        style={{ maskType: 'alpha' }}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.000488281 7.79443H20.0001V21.5384H0.000488281V7.79443Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0)">
        <path
          d="M6.43549 21.5384H15.5655C18.0105 21.5384 20.0005 19.5494 20.0005 17.1034V12.9694C20.0005 12.5552 19.6647 12.2194 19.2505 12.2194C18.8363 12.2194 18.5005 12.5552 18.5005 12.9694V17.1034C18.5005 18.7224 17.1835 20.0384 15.5655 20.0384H6.43549C4.81649 20.0384 3.50049 18.7224 3.50049 17.1034V12.2284C3.50049 10.6104 4.81649 9.29443 6.43549 9.29443H10.3685C10.7825 9.29443 11.1185 8.95843 11.1185 8.54443C11.1185 8.13043 10.7825 7.79443 10.3685 7.79443H6.43549C3.99049 7.79443 2.00049 9.78343 2.00049 12.2284V17.1034C2.00049 19.5494 3.99049 21.5384 6.43549 21.5384Z"
          fill="currentColor"
        />
      </g>
      <mask
        id="mask1"
        maskUnits="userSpaceOnUse"
        x="10"
        y="4"
        width="12"
        height="11"
        style={{ maskType: 'alpha' }}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.2981 4.21094L21.2721 5.3517L10.9741 14.1442L10.0001 13.0034L20.2981 4.21094Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.487 13.5738C10.2182 13.259 10.2556 12.7853 10.5704 12.5165L19.7277 4.69793C20.0426 4.42911 20.5163 4.46647 20.7851 4.78132C21.0539 5.09617 21.0166 5.56988 20.7017 5.8387L11.5444 13.6572C11.2296 13.9261 10.7558 13.8887 10.487 13.5738Z"
          fill="currentColor"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5244 5.43973C15.4004 5.29447 15.3312 5.10105 15.3474 4.89522C15.3793 4.48278 15.7384 4.17348 16.1523 4.20541L20.2718 4.52107C20.6695 4.55101 20.9942 4.93127 20.9614 5.32873L20.6281 9.44761C20.5941 9.86058 20.233 10.1676 19.8207 10.1344C19.4077 10.1004 19.0999 9.7399 19.1339 9.32693L19.4059 5.95863L16.0373 5.70006C15.8299 5.68512 15.6491 5.58575 15.5244 5.43973Z"
        fill="currentColor"
      />
    </>
  ),
  [CustomIcon.slack]: (
    <>
      <path
        d="M14.5 10C13.67 10 13 9.33 13 8.5V3.5C13 2.67 13.67 2 14.5 2C15.33 2 16 2.67 16 3.5V8.5C16 9.33 15.33 10 14.5 10Z"
        stroke="black"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.5 10H19V8.5C19 7.67 19.67 7 20.5 7C21.33 7 22 7.67 22 8.5C22 9.33 21.33 10 20.5 10Z"
        stroke="black"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.5 14C10.33 14 11 14.67 11 15.5V20.5C11 21.33 10.33 22 9.5 22C8.67 22 8 21.33 8 20.5V15.5C8 14.67 8.67 14 9.5 14Z"
        stroke="black"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.5 14H5V15.5C5 16.33 4.33 17 3.5 17C2.67 17 2 16.33 2 15.5C2 14.67 2.67 14 3.5 14Z"
        stroke="black"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 14.5C14 13.67 14.67 13 15.5 13H20.5C21.33 13 22 13.67 22 14.5C22 15.33 21.33 16 20.5 16H15.5C14.67 16 14 15.33 14 14.5Z"
        stroke="black"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.5 19H14V20.5C14 21.33 14.67 22 15.5 22C16.33 22 17 21.33 17 20.5C17 19.67 16.33 19 15.5 19Z"
        stroke="black"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 9.5C10 8.67 9.33 8 8.5 8H3.5C2.67 8 2 8.67 2 9.5C2 10.33 2.67 11 3.5 11H8.5C9.33 11 10 10.33 10 9.5Z"
        stroke="black"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.5 5H10V3.5C10 2.67 9.33 2 8.5 2C7.67 2 7 2.67 7 3.5C7 4.33 7.67 5 8.5 5Z"
        stroke="black"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
  [CustomIcon.extend]: (
    <>
      <path
        d="M15 3H21V9"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 21H3V15"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 3L14 10"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 21L10 14"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
  [CustomIcon.check]: (
    <>
      <path
        d="M20 6L9 17L4 12"
        stroke="currentColor"
        strokeWidth="1.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
  [CustomIcon.checkWithBackground]: (
    <>
      <rect x="0.5" width="24" height="24" rx="5" fill="#DE7051" />
      <path
        d="M20.5 6L9.5 17L4.5 12"
        stroke="white"
        strokeWidth="1.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
  [CustomIcon.close]: (
    <>
      <path
        d="M18 6L6 18"
        stroke="currentColor"
        strokeWidth="1.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 6L18 18"
        stroke="currentColor"
        strokeWidth="1.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
  [CustomIcon.plus]: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.75 16.1541C11.336 16.1541 11 15.8181 11 15.4041V8.07715C11 7.66315 11.336 7.32715 11.75 7.32715C12.164 7.32715 12.5 7.66315 12.5 8.07715V15.4041C12.5 15.8181 12.164 16.1541 11.75 16.1541Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.4165 12.4902H8.0835C7.6685 12.4902 7.3335 12.1542 7.3335 11.7402C7.3335 11.3262 7.6685 10.9902 8.0835 10.9902H15.4165C15.8305 10.9902 16.1665 11.3262 16.1665 11.7402C16.1665 12.1542 15.8305 12.4902 15.4165 12.4902Z"
        fill="currentColor"
      />
    </>
  ),
  [CustomIcon.minus]: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.4165 12.4902H8.0835C7.6685 12.4902 7.3335 12.1542 7.3335 11.7402C7.3335 11.3262 7.6685 10.9902 8.0835 10.9902H15.4165C15.8305 10.9902 16.1665 11.3262 16.1665 11.7402C16.1665 12.1542 15.8305 12.4902 15.4165 12.4902Z"
        fill="currentColor"
      />
    </>
  ),
  [CustomIcon.arrowDownThin]: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.46967 7.96967C4.73594 7.7034 5.1526 7.6792 5.44621 7.89705L5.53033 7.96967L12 14.439L18.4697 7.96967C18.7359 7.7034 19.1526 7.6792 19.4462 7.89705L19.5303 7.96967C19.7966 8.23594 19.8208 8.6526 19.6029 8.94621L19.5303 9.03033L12.5303 16.0303C12.2641 16.2966 11.8474 16.3208 11.5538 16.1029L11.4697 16.0303L4.46967 9.03033C4.17678 8.73744 4.17678 8.26256 4.46967 7.96967Z"
        fill="currentColor"
      />
    </>
  ),
  [CustomIcon.copy]: (
    <>
      <path
        d="M7 2C6.43168 2 5.88664 2.22576 5.48477 2.62763C5.08291 3.02949 4.85714 3.57454 4.85714 4.14286C4.85714 4.3323 4.9324 4.51398 5.06635 4.64793C5.20031 4.78189 5.38199 4.85714 5.57143 4.85714C5.76087 4.85714 5.94255 4.78189 6.0765 4.64793C6.21046 4.51398 6.28571 4.3323 6.28571 4.14286C6.28571 3.95342 6.36097 3.77174 6.49492 3.63778C6.62888 3.50383 6.81056 3.42857 7 3.42857H19.8571C20.0466 3.42857 20.2283 3.50383 20.3622 3.63778C20.4962 3.77174 20.5714 3.95342 20.5714 4.14286V17C20.5714 17.1894 20.4962 17.3711 20.3622 17.5051C20.2283 17.639 20.0466 17.7143 19.8571 17.7143C19.6677 17.7143 19.486 17.7895 19.3521 17.9235C19.2181 18.0574 19.1429 18.2391 19.1429 18.4286C19.1429 18.618 19.2181 18.7997 19.3521 18.9336C19.486 19.0676 19.6677 19.1429 19.8571 19.1429C20.4255 19.1429 20.9705 18.9171 21.3724 18.5152C21.7742 18.1134 22 17.5683 22 17V4.14286C22 3.57454 21.7742 3.02949 21.3724 2.62763C20.9705 2.22576 20.4255 2 19.8571 2H7ZM4.14286 5.57143C3.57454 5.57143 3.02949 5.79719 2.62763 6.19906C2.22576 6.60092 2 7.14596 2 7.71429V19.8571C2 20.4255 2.22576 20.9705 2.62763 21.3724C3.02949 21.7742 3.57454 22 4.14286 22H16.2857C16.854 22 17.3991 21.7742 17.8009 21.3724C18.2028 20.9705 18.4286 20.4255 18.4286 19.8571V7.71429C18.4286 7.14596 18.2028 6.60092 17.8009 6.19906C17.3991 5.79719 16.854 5.57143 16.2857 5.57143H4.14286ZM4.14286 7H16.2857C16.4752 7 16.6568 7.07525 16.7908 7.20921C16.9247 7.34316 17 7.52485 17 7.71429V19.8571C17 20.0466 16.9247 20.2283 16.7908 20.3622C16.6568 20.4962 16.4752 20.5714 16.2857 20.5714H4.14286C3.95342 20.5714 3.77174 20.4962 3.63778 20.3622C3.50383 20.2283 3.42857 20.0466 3.42857 19.8571V7.71429C3.42857 7.52485 3.50383 7.34316 3.63778 7.20921C3.77174 7.07525 3.95342 7 4.14286 7Z"
        fill="#EC8569"
      />
    </>
  ),
  [CustomIcon.swapAsc]: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7218 4.29641C11.6886 4.05236 11.4794 3.86426 11.2263 3.86426C10.9502 3.86426 10.7263 4.08812 10.7263 4.36426V12.2321L8.86194 10.3592L8.80597 10.3106C8.61057 10.1649 8.33275 10.1804 8.15483 10.3575C7.95912 10.5524 7.95839 10.8689 8.1532 11.0646L10.8717 13.7958L10.8755 13.799C10.8906 13.814 10.9068 13.828 10.9238 13.8409L10.9279 13.8444C11.1239 13.9905 11.4028 13.9743 11.5805 13.7958L14.299 11.0646L14.3473 11.0085C14.492 10.8124 14.4753 10.5346 14.2973 10.3575C14.1016 10.1627 13.785 10.1635 13.5902 10.3592L11.7263 12.2311V4.36426L11.7218 4.29641Z"
        fill="#535F56"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.90541 2.15376C4.70933 2.00771 4.4305 2.02394 4.25282 2.20244L1.5343 4.93355L1.48602 4.98974C1.34124 5.18582 1.35802 5.46356 1.53594 5.64066L1.59213 5.68894C1.7882 5.83372 2.06594 5.81694 2.24304 5.63902L4.10742 3.76565V11.6339L4.11199 11.7017C4.14509 11.9458 4.35429 12.1339 4.60742 12.1339C4.88356 12.1339 5.10742 11.91 5.10742 11.6339V3.76635L6.97134 5.63902L7.02731 5.68756C7.22271 5.83325 7.50052 5.81776 7.67844 5.64066C7.87416 5.44585 7.87489 5.12926 7.68008 4.93355L4.96672 2.20763C4.94789 2.18817 4.92748 2.17023 4.90571 2.15402L4.90541 2.15376Z"
        fill="#EC8569"
      />
    </>
  ),
  [CustomIcon.swapDesc]: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7218 4.29641C11.6886 4.05236 11.4794 3.86426 11.2263 3.86426C10.9502 3.86426 10.7263 4.08812 10.7263 4.36426V12.2321L8.86194 10.3592L8.80597 10.3106C8.61057 10.1649 8.33275 10.1804 8.15483 10.3575C7.95912 10.5524 7.95839 10.8689 8.1532 11.0646L10.8717 13.7958L10.8755 13.799C10.8906 13.814 10.9068 13.828 10.9238 13.8409L10.9279 13.8444C11.1239 13.9905 11.4028 13.9743 11.5805 13.7958L14.299 11.0646L14.3473 11.0085C14.492 10.8124 14.4753 10.5346 14.2973 10.3575C14.1016 10.1627 13.785 10.1635 13.5902 10.3592L11.7263 12.2311V4.36426L11.7218 4.29641Z"
        fill="#EC8569"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.90541 2.15376C4.70933 2.00771 4.4305 2.02394 4.25282 2.20244L1.5343 4.93355L1.48602 4.98974C1.34124 5.18582 1.35802 5.46356 1.53594 5.64066L1.59213 5.68894C1.7882 5.83372 2.06594 5.81694 2.24304 5.63902L4.10742 3.76565V11.6339L4.11199 11.7017C4.14509 11.9458 4.35429 12.1339 4.60742 12.1339C4.88356 12.1339 5.10742 11.91 5.10742 11.6339V3.76635L6.97134 5.63902L7.02731 5.68756C7.22271 5.83325 7.50052 5.81776 7.67844 5.64066C7.87416 5.44585 7.87489 5.12926 7.68008 4.93355L4.96672 2.20763C4.94789 2.18817 4.92748 2.17023 4.90571 2.15402L4.90541 2.15376Z"
        fill="#535F56"
      />
    </>
  ),
  [CustomIcon.ArrowDownCircle]: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3.5C7.313 3.5 3.5 7.313 3.5 12C3.5 16.687 7.313 20.5 12 20.5C16.687 20.5 20.5 16.687 20.5 12C20.5 7.313 16.687 3.5 12 3.5ZM12 22C6.486 22 2 17.514 2 12C2 6.486 6.486 2 12 2C17.514 2 22 6.486 22 12C22 17.514 17.514 22 12 22Z"
        fill="#28534F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9981 14.7944C11.7991 14.7944 11.6071 14.7154 11.4671 14.5734L7.9961 11.0874C7.7031 10.7934 7.7051 10.3184 7.9981 10.0264C8.2921 9.73437 8.7671 9.73437 9.0591 10.0284L11.9981 12.9824L14.9381 10.0284C15.2301 9.73437 15.7051 9.73437 15.9991 10.0264C16.2921 10.3184 16.2931 10.7934 16.0011 11.0874L12.5291 14.5734C12.3891 14.7154 12.1971 14.7944 11.9981 14.7944Z"
        fill="#28534F"
      />
    </>
  ),
  [CustomIcon.euro]: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.361 3.5C7.475 3.5 3.5 7.476 3.5 12.361C3.5 17.247 7.475 21.222 12.361 21.222C17.247 21.222 21.222 17.247 21.222 12.361C21.222 7.476 17.247 3.5 12.361 3.5ZM12.361 22.722C6.648 22.722 2 18.074 2 12.361C2 6.648 6.648 2 12.361 2C18.074 2 22.722 6.648 22.722 12.361C22.722 18.074 18.074 22.722 12.361 22.722Z"
        fill="curentColor"
      />
      <path
        d="M14.0391 11.0509H11.4291C11.6768 10.1674 12.2313 9.76815 12.9818 9.76815C13.6361 9.76815 14.113 10.0861 14.4088 10.2931L15.0002 9.02879C14.4531 8.58518 13.7729 8.34119 12.9818 8.34119C11.3848 8.34119 10.1759 9.32823 9.80254 11.0509H9.02991L8.73047 11.8605H9.69164C9.68424 11.9825 9.68055 12.1082 9.68055 12.2376C9.68055 12.3596 9.68424 12.4816 9.69164 12.5999H9.02991L8.73047 13.428H9.80254C10.1759 15.147 11.3848 16.1193 12.9818 16.1193C13.7433 16.1193 14.442 15.8938 14.9781 15.4501L14.3792 14.1858C14.1093 14.3855 13.6324 14.6923 12.9818 14.6923C12.2313 14.6923 11.6768 14.3078 11.4291 13.428H12.93L13.333 12.5999H11.2997C11.2924 12.4853 11.2887 12.3633 11.2887 12.2376C11.2887 12.1045 11.2924 11.9788 11.2997 11.8605H13.6731L14.0391 11.0509Z"
        fill="curentColor"
      />
    </>
  ),
  [CustomIcon.euroCrossed]: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.8454 3.5C7.95937 3.5 3.98438 7.476 3.98438 12.361C3.98438 17.247 7.95937 21.222 12.8454 21.222C17.7314 21.222 21.7064 17.247 21.7064 12.361C21.7064 7.476 17.7314 3.5 12.8454 3.5ZM12.8454 22.722C7.13238 22.722 2.48438 18.074 2.48438 12.361C2.48438 6.648 7.13238 2 12.8454 2C18.5584 2 23.2064 6.648 23.2064 12.361C23.2064 18.074 18.5584 22.722 12.8454 22.722Z"
        fill="currentColor"
      />
      <path
        d="M14.5235 11.0509H11.9135C12.1612 10.1674 12.7157 9.76815 13.4662 9.76815C14.1205 9.76815 14.5974 10.0861 14.8931 10.2931L15.4846 9.02879C14.9375 8.58518 14.2573 8.34119 13.4662 8.34119C11.8691 8.34119 10.6603 9.32823 10.2869 11.0509H9.51428L9.21484 11.8605H10.176C10.1686 11.9825 10.1649 12.1082 10.1649 12.2376C10.1649 12.3596 10.1686 12.4816 10.176 12.5999H9.51428L9.21484 13.428H10.2869C10.6603 15.147 11.8691 16.1193 13.4662 16.1193C14.2277 16.1193 14.9264 15.8938 15.4624 15.4501L14.8636 14.1858C14.5937 14.3855 14.1168 14.6923 13.4662 14.6923C12.7157 14.6923 12.1612 14.3078 11.9135 13.428H13.4144L13.8174 12.5999H11.7841C11.7767 12.4853 11.773 12.3633 11.773 12.2376C11.773 12.1045 11.7767 11.9788 11.7841 11.8605H14.1575L14.5235 11.0509Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.34546 21.1112C5.16146 21.1112 4.97746 21.0442 4.83246 20.9082C4.53046 20.6252 4.51546 20.1502 4.79846 19.8482L19.7985 3.84823C20.0805 3.54523 20.5565 3.53023 20.8585 3.81423C21.1605 4.09723 21.1755 4.57223 20.8925 4.87423L5.89246 20.8742C5.74446 21.0322 5.54546 21.1112 5.34546 21.1112Z"
        fill="currentColor"
      />
    </>
  ),
  [CustomIcon.headphones]: (
    <>
      <path
        d="M3 15V11C3 8.61305 3.94821 6.32387 5.63604 4.63604C7.32387 2.94821 9.61305 2 12 2C14.3869 2 16.6761 2.94821 18.364 4.63604C20.0518 6.32387 21 8.61305 21 11V15"
        stroke="#EC8569"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 16C21 16.5304 20.7893 17.0391 20.4142 17.4142C20.0391 17.7893 19.5304 18 19 18H18C17.4696 18 16.9609 17.7893 16.5858 17.4142C16.2107 17.0391 16 16.5304 16 16V13C16 12.4696 16.2107 11.9609 16.5858 11.5858C16.9609 11.2107 17.4696 11 18 11H21V16ZM3 16C3 16.5304 3.21071 17.0391 3.58579 17.4142C3.96086 17.7893 4.46957 18 5 18H6C6.53043 18 7.03914 17.7893 7.41421 17.4142C7.78929 17.0391 8 16.5304 8 16V13C8 12.4696 7.78929 11.9609 7.41421 11.5858C7.03914 11.2107 6.53043 11 6 11H3V16Z"
        fill="#EC8569"
        stroke="#EC8569"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 17V18C5 19.6569 6.34315 21 8 21H10"
        stroke="#EC8569"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <rect x="10" y="19" width="5" height="4" rx="2" fill="#EC8569" />
    </>
  ),
  [CustomIcon.TimeSquare]: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.67 2.00012H16.34C19.73 2.00012 22 4.37912 22 7.91912V16.0891C22 19.6201 19.73 22.0001 16.34 22.0001H7.67C4.28 22.0001 2 19.6201 2 16.0891V7.91912C2 4.37912 4.28 2.00012 7.67 2.00012ZM15.58 15.8101C15.83 15.8101 16.08 15.6801 16.22 15.4401C16.44 15.0891 16.32 14.6291 15.96 14.4101L12.4 12.2901V7.66912C12.4 7.26012 12.07 6.91912 11.65 6.91912C11.24 6.91912 10.9 7.26012 10.9 7.66912V12.7201C10.9 12.9801 11.04 13.2301 11.27 13.3601L15.19 15.7001C15.31 15.7801 15.45 15.8101 15.58 15.8101Z"
        fill="#EC8569"
      />
    </>
  ),
};
