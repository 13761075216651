import { FC, Fragment, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import useBreadcrumbs from 'use-react-router-breadcrumbs';

import { Flex } from 'components/Layout/Flex';
import { IconButton } from 'components/Buttons/IconButton';
import { theme } from 'theme';
import { Text } from 'components/Layout/Text';
import { Icon } from 'components/Images/Icon';

import { AdminAppRoutes } from 'AdminApp';
import { useTranslation } from 'react-i18next';
import { useApi } from '../../../hooks/useApi';
import { Procedure } from '../../../types/resources';

export interface BreadcrumbMatch {
  params: {
    [string: string]: any;
  };
}

type BreadcrumbsProps = {
  procedureId?: Procedure['id'];
  name?: string;
  reference?: string;
};

export const Breadcrumbs: FC<BreadcrumbsProps> = ({
  name,
  reference,
  procedureId,
}) => {
  const { execute: getProcedure, state: getProcedureState } = useApi<Procedure>(
    `/procedures/${procedureId}`,
  );
  const procedure = getProcedureState.data?.value;

  useEffect(() => {
    if (procedureId && !name && !reference) {
      getProcedure();
    }
  }, [procedureId, name, reference]);

  const { t } = useTranslation();
  const history = useHistory();
  const routes = [
    { path: AdminAppRoutes.HOME, breadcrumb: t('breadcrumb.homepage') },
    {
      path: AdminAppRoutes.CASES_DETAILS,
      breadcrumb: () => (
        <span>
          {t('breadcrumb.opportunity', {
            reference: reference ?? procedure?.reference,
            name: name ?? procedure?.name,
          })}
        </span>
      ),
    },
    {
      path: AdminAppRoutes.USER_UPDATE_ID,
      breadcrumb: () => <span>{name}</span>,
    },
    {
      path: AdminAppRoutes.ESTIMATES_ADD,
      breadcrumb: t('estimate.add'),
    },
    {
      path: AdminAppRoutes.ESTIMATES_DETAILS,
      breadcrumb: t('estimate.details.title'),
    },
    {
      path: AdminAppRoutes.PAYMENTS_ADD,
      breadcrumb: t('payments.add.title'),
    },
    {
      path: AdminAppRoutes.PRODUCTS_ADD,
      breadcrumb: t('product.add'),
    },
    {
      path: AdminAppRoutes.PRODUCTS_DETAILS,
      breadcrumb: () => <span>{name}</span>,
    },
    {
      path: AdminAppRoutes.PROSPECTS_ADD,
      breadcrumb: t('breadcrumb.prospects_add_new'),
    },
    {
      path: AdminAppRoutes.CUSTOMERS_ADD,
      breadcrumb: t('breadcrumb.customers_add_new'),
    },
    {
      path: AdminAppRoutes.CUSTOMERS_DETAILS,
      breadcrumb: () => <span>{name}</span>,
    },
    {
      path: AdminAppRoutes.PROSPECTS_DETAILS,
      breadcrumb: () => <span>{name}</span>,
    },
    {
      path: AdminAppRoutes.USER_ADD,
      breadcrumb: t('breadcrumb.add_user'),
    },
  ];

  const PATH_TO_NOT_SHOW = [`${AdminAppRoutes.USERS}/edition`];
  const breadcrumbs = useBreadcrumbs(routes).filter(
    ({ match }) => !PATH_TO_NOT_SHOW.includes(match.url),
  );

  return (
    <Flex alignItems="center" marginBottom={{ xs: 'space24' }}>
      <Flex marginRight={{ xs: 'space16' }}>
        <IconButton
          iconName="ArrowLeft"
          color={theme.colors.white}
          backgroundColor={theme.colors.green1}
          hoverIconColor={theme.colors.green1}
          hoverBackgroundColor={theme.colors.green4}
          size="small"
          onClick={() => history.goBack()}
          rounded
        />
      </Flex>
      {breadcrumbs.map(({ match, breadcrumb }, index) => (
        <Fragment key={match.url}>
          <Flex onClick={() => history.push(match.url)} cursor="pointer">
            <Text
              content={breadcrumb as string}
              fontStyle="body3"
              weight="medium"
            />
          </Flex>
          {index !== breadcrumbs.length - 1 && (
            <Icon
              name="ChevronRight"
              primaryColor={theme.colors.black}
              size="small"
            />
          )}
        </Fragment>
      ))}
    </Flex>
  );
};
