import styled, { keyframes, css } from 'styled-components';

import { HEADER_HEIGHT } from 'container/common/Header/Header';
import { theme } from 'theme';
import { Flex, Text } from 'components/Layout';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface PaymentStripProps {
  label: string;
  price: string;
  visible?: boolean;
}

const slideIn = keyframes`
  from {
    transform: translateY(-100%);
  }

  to {
    transform: translateY(0);
  }
`;

const slideOut = keyframes`
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-100%);
  }
`;

const Wrapper = styled.div<{ visible?: boolean }>`
  position: fixed;
  top: ${HEADER_HEIGHT}px;
  left: 0;
  right: 0;
  width: 100%;
  background: ${theme.colors.white};
  padding: ${theme.spacing.space16} ${theme.spacing.space24};
  border-bottom-right-radius: ${theme.spacing.space24};
  border-bottom-left-radius: ${theme.spacing.space24};

  ${({ visible }) => css`
    animation: ${visible ? slideIn : slideOut} 0.5s forwards;
  `}
`;

export const PaymentStrip: FC<PaymentStripProps> = ({
  label,
  price,
  visible,
}) => {
  const { t } = useTranslation();

  return (
    <Wrapper visible={visible}>
      <Flex justify="between" alignItems="center">
        <Text
          content={label}
          fontStyle="body1"
          weight="bold"
          color={theme.colors.black}
        />
        <Flex alignItems="end">
          <Text
            content={price}
            fontStyle="heading4"
            color={theme.colors.salmon2}
          />
          <Text
            content={t('quote.payment.currency')}
            fontStyle="heading6"
            color={theme.colors.salmon2}
          />
        </Flex>
      </Flex>
    </Wrapper>
  );
};

export default PaymentStrip;
