export const isUserAllowed = (
  userRole: string | undefined,
  allowedRoles: string[],
) => {
  return userRole !== undefined && allowedRoles.includes(userRole);
};

export const getFullname = (
  user?: {
    first_name?: string;
    last_name?: string;
  } | null,
) => {
  if (!user) return '';
  return `${user.first_name} ${user.last_name}`;
};
