import { FC, useState } from 'react';
import { theme } from 'theme';

// Components
import { StickerCard } from 'components/Cards';
import { Text } from 'components/Layout/Text';
import { Flex } from 'components/Layout/Flex';
import { Icon, IconProps } from 'components/Images/Icon';
import { IconButton } from 'components/Buttons';
import { Notification } from 'components/Notification/Notification';
import styled from 'styled-components';

export interface SpecificationCardProps {
  iconName: IconProps['name'];
  specification: string;
  value: string;
  description?: string;
  hasInfo?: boolean;
  onClick?: () => void;
}

const CustomStickerCard = styled(StickerCard)`
  position: relative;
`;

export const SpecificationCard: FC<SpecificationCardProps> = ({
  specification,
  value,
  iconName,
  description,
  hasInfo,
  onClick,
}) => {
  const [isInfoShow, setIsInfoShow] = useState<boolean>(false);

  return (
    <CustomStickerCard>
      <div onClick={onClick} style={{ cursor: onClick && 'pointer' }}>
        <Flex
          justify="between"
          marginBottom={{ xs: 'space8' }}
          marginTop={{ xs: 'space8' }}
          alignItems="center"
        >
          <Icon name={iconName} primaryColor={theme.colors.green1} />
          {hasInfo && (
            <IconButton
              iconName="InfoSquare"
              color={theme.colors.black}
              backgroundColor="transparent"
              onMouseEnter={() => setIsInfoShow(true)}
              onMouseLeave={() => setIsInfoShow(false)}
              style={{
                padding: 0,
                width: 'auto',
                height: 'auto',
                cursor: 'pointer',
              }}
            />
          )}
        </Flex>

        <Text
          content={specification}
          fontStyle="body3"
          color={theme.colors.gray5}
          weight="medium"
          marginBottom={{ xs: 4 }}
        />
        <Text
          content={value}
          fontStyle="body2"
          color={theme.colors.black}
          weight="bold"
        />

        {hasInfo && (
          <Notification
            title={value}
            description={description || ''}
            iconName="InfoSquare"
            isShow={isInfoShow}
          />
        )}
      </div>
    </CustomStickerCard>
  );
};
