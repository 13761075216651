import { FC, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

// Components
import { theme } from 'theme';
import { Text, Flex } from 'components/Layout';
import { InlineButton } from 'components/Buttons';
import { Icon } from 'components/Images';

import { md } from 'theme/styles/mediaQueries';
import { useViewport } from 'hooks/useViewport';

interface IconContainerProps {
  active: boolean;
}

const IconContainer = styled(Flex)<IconContainerProps>`
  height: 24px;
  transform: ${(props) => (props.active ? 'rotate(180deg)' : 'rotate(0deg)')};
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
`;

const CardContainer = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.spacing.space24};
  background-color: ${theme.colors.beige};
  border-radius: ${theme.spacing.space24};

  ${md(`
      padding: ${theme.spacing.space40};
  `)}
`;

const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${theme.colors.gray3};
  margin: 0 0 ${theme.spacing.space32} 0;

  ${md(`
    margin: ${theme.spacing.space32} 0;
  `)}
`;

const NumberContainer = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: start;
  width: ${theme.spacing.space32};
  min-width: ${theme.spacing.space32};
  height: ${theme.spacing.space32};
  min-height: ${theme.spacing.space32};
  background-color: ${theme.colors.green1};
  border-radius: 50%;
  padding: ${theme.spacing.space8};
`;

const TextContainer = styled.div<TextContainerProps>`
  opacity: ${(props) => (props.isOpen ? 1 : 0)};
  transition: opacity 0.3s ease-in-out;
  width: 100%;
`;

const BlockContainer = styled.div`
  break-inside: avoid-column;
`;

const Wrapper = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;

  ${md(`
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 40px 1fr;
  `)}
`;

const FlexGap = styled(Flex)`
  gap: ${theme.spacing.space24};
`;

const FlexPointer = styled(Flex)`
  cursor: pointer;
`;

const GhostButton = styled.div`
  height: 18px;
`;

const Ghost = styled.div``;

interface ProductProps {
  productName: string;
  mobileName: string;
  productDescription?: string | null;
  productPrice?: number;
  productId: string;
  onClick?: () => void;
  included: boolean;
  editable: boolean;
}

interface TextContainerProps {
  isOpen: boolean;
}

interface BonusCardProps {
  buttonName: string;
  onClick: () => void;
}

export interface RecapCardProps {
  products: ProductProps[];
  title: string;
  buttonName: string;
  bonusCard: BonusCardProps;
}

interface ItemDescriptionProps {
  isOpened: boolean;
  item: ProductProps;
  onClick?: () => void;
  editable: boolean;
  buttonName: string;
}

interface ColumnProps {
  products: ProductProps[];
  isOpen?: number | null;
  onOpen: (index: number) => void;
  isMobile: boolean;
  buttonName: string;
  base: number;
}

const ItemDescription: FC<ItemDescriptionProps> = ({
  isOpened,
  item,
  onClick,
  editable,
  buttonName,
}) => {
  if (!item.productDescription) return null;
  return (
    <TextContainer isOpen={isOpened}>
      {isOpened && (
        <Flex
          direction={{ xs: 'column', md: 'row' }}
          marginTop={{ xs: 'space16' }}
          justify="between"
        >
          <Text
            dangerouslySetInnerHTML={{
              __html: item.productDescription,
            }}
            fontStyle="body2"
            weight="medium"
            color={theme.colors.gray5}
          />
          {onClick && editable ? (
            <FlexPointer
              onClick={onClick}
              marginLeft={{ xs: 'none', md: 'space40' }}
              marginTop={{ xs: 'space16', md: 'none' }}
            >
              <Text
                content={buttonName}
                fontStyle="label"
                color={theme.colors.salmon2}
              />
            </FlexPointer>
          ) : (
            <GhostButton />
          )}
        </Flex>
      )}
    </TextContainer>
  );
};

const Column: FC<ColumnProps> = ({
  products,
  isOpen,
  onOpen,
  isMobile,
  buttonName,
  base,
}) => {
  const { t } = useTranslation();
  return (
    <Flex direction={{ xs: 'column' }}>
      {products.map((item, index) => {
        const {
          productId,
          productDescription,
          productName,
          productPrice,
          onClick,
          included,
          editable,
          mobileName,
        } = item;
        const translationKey =
          productPrice === 0
            ? 'quote.resume.price_taxes_included_zero'
            : 'quote.resume.price_taxes_included';
        const price = !included
          ? t(translationKey, {
              price: productPrice && productPrice / 2,
            })
          : t('included');
        const finalIndex = base + index;
        const isOpened = isOpen === finalIndex;

        return (
          <BlockContainer key={`block-${productId}-${finalIndex}`}>
            <FlexGap
              marginBottom={{ xs: 'space16' }}
              justify="between"
              direction={{ xs: 'column', md: 'row' }}
            >
              <Flex direction={{ xs: 'column' }} expand>
                <FlexGap alignItems={isMobile ? 'start' : 'center'}>
                  <NumberContainer>
                    <Text
                      content={`${finalIndex + 1}`}
                      fontStyle="heading5"
                      color={theme.colors.white}
                    />
                  </NumberContainer>
                  <Flex
                    direction={{ xs: 'column' }}
                    justify={isMobile ? 'start' : 'center'}
                    flex={1}
                  >
                    <Flex
                      alignItems={isMobile ? 'start' : 'center'}
                      justify="between"
                      flex={1}
                      marginBottom={{ xs: 'none' }}
                      expand
                      direction={{ xs: 'row' }}
                    >
                      <FlexPointer
                        alignItems="center"
                        onClick={() => productDescription && onOpen(finalIndex)}
                      >
                        <Text
                          dangerouslySetInnerHTML={{
                            __html: isMobile ? mobileName : productName,
                          }}
                          fontStyle="body2"
                          weight="bold"
                          marginRight={{ xs: 'space8', md: 'space16' }}
                        />
                        {productDescription && (
                          <IconContainer
                            justify="center"
                            alignItems="center"
                            active={isOpened}
                          >
                            <Icon name="ChevronDown" />
                          </IconContainer>
                        )}
                      </FlexPointer>
                      <Text
                        content={price}
                        fontStyle={isMobile ? 'body1' : 'heading4'}
                        color={theme.colors.gray6}
                        whiteSpace="nowrap"
                        weight="bold"
                      />
                    </Flex>
                  </Flex>
                </FlexGap>
                <Flex paddingLeft={{ xs: 'none', md: 'space56' }}>
                  <ItemDescription
                    isOpened={isOpened}
                    item={item}
                    onClick={onClick}
                    editable={editable}
                    buttonName={buttonName}
                  />
                </Flex>
              </Flex>
            </FlexGap>
            <Line />
          </BlockContainer>
        );
      })}
    </Flex>
  );
};

export const RecapCard: FC<RecapCardProps> = ({
  products,
  title,
  buttonName,
  bonusCard,
}) => {
  const [isOpen, setIsOpen] = useState<number | null>(null);
  const { isMobile } = useViewport();

  const onOpen = (index: number) => {
    if (isOpen === index) {
      setIsOpen(null);
    } else {
      setIsOpen(index);
    }
  };

  const getProductsToDisplay = (
    array: ProductProps[],
  ): [ProductProps[], ProductProps[]] => {
    const halfWayIndex = Math.floor(array.length / 2);
    const first = array.slice(0, halfWayIndex);
    const second = array.slice(halfWayIndex);
    return [first, second];
  };

  const [col1, col2] = getProductsToDisplay(products);

  const columnProps = {
    isOpen,
    isMobile,
    onOpen,
    buttonName,
  };

  return (
    <CardContainer>
      <Flex
        marginBottom={{ xs: 'space16' }}
        justify={isMobile ? 'start' : 'between'}
        alignItems="start"
        direction={{ xs: 'column', md: 'row' }}
      >
        <Text
          content={title}
          fontStyle={isMobile ? 'heading4' : 'heading3'}
          marginBottom={{ xs: 'space16', md: 'none' }}
        />
        <InlineButton
          onClick={() => bonusCard.onClick()}
          iconColor={theme.colors.salmon1}
          text={bonusCard.buttonName}
          backgroundColor={theme.colors.salmon3}
          iconName="Call"
          hoverBackgroundColor={theme.colors.salmon2}
          hoverIconColor={theme.colors.white}
        />
      </Flex>
      <Line />
      <Wrapper>
        <Column products={col1} base={0} {...columnProps} />
        <Ghost />
        <Column products={col2} base={col1.length} {...columnProps} />
      </Wrapper>
    </CardContainer>
  );
};
