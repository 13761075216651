import { ToasterProps } from 'components/Toaster/Toaster';

export enum ActionTypes {
  SHOW_TOASTER = 'SHOW_TOASTER',
  HIDE_TOASTER = 'HIDE_TOASTER',
}

interface Action {
  type: ActionTypes;
}

interface ShowToasterAction extends Action {
  type: typeof ActionTypes.SHOW_TOASTER;
  payload: ToasterProps;
}

interface HideToasterAction extends Action {
  type: typeof ActionTypes.HIDE_TOASTER;
}

export type ToasterActions = ShowToasterAction | HideToasterAction;
