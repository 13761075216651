import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/Buttons/Button';
import { Flex } from 'components/Layout';

interface ModalFooterProps {
  onClose?: () => void;
  onSubmit?: () => void;
  disabled?: boolean;
  loading?: boolean;
}

const ModalFooter: FC<ModalFooterProps> = ({
  onClose,
  onSubmit,
  disabled,
  loading,
}) => {
  const { t } = useTranslation();
  return (
    <Flex direction={{ xs: 'column', md: 'row' }}>
      {onSubmit && (
        <Button
          content={t('quote.resume.validate_changes')}
          marginRight={{ xs: 'none', md: 'space16' }}
          marginBottom={{ xs: 'space16', md: 'none' }}
          disabled={disabled}
          onClick={onSubmit}
          $loading={loading}
          primary
        />
      )}

      {onClose && (
        <Button content={t('quote.resume.cancel')} onClick={onClose} />
      )}
    </Flex>
  );
};

export default ModalFooter;
