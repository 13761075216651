import { CSSProperties, FC, ReactNode } from 'react';
import styled from 'styled-components';
import { theme } from 'theme';

const CardContainer = styled.div<CardContainerProps>`
  width: 100%;
  height: auto;
  padding: ${({ padding }) => (padding ? padding : theme.spacing.space24)};
  border: 1px solid ${theme.colors.gray3};
  border-radius: ${theme.spacing.space24};
  background-color: white;
`;

interface CardContainerProps {
  padding?: string;
}

export interface CardProps {
  children: ReactNode;
  padding?: string;
  style?: CSSProperties;
  className?: string;
}

export const Card: FC<CardProps> = ({
  children,
  padding,
  style,
  className,
}) => (
  <CardContainer padding={padding} style={style} className={className}>
    {children}
  </CardContainer>
);

export default Card;
