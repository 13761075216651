import { FC } from 'react';
import { useTranslation } from 'react-i18next';

// Container
import { NotificationsList, NotificationsListFilters } from 'container/admin';

// Components
import { Flex } from 'components/Layout/Flex';
import { Text } from 'components/Layout/Text';
import { useQueryParams } from '../../hooks/useQueryParams';
import { useApi } from '../../hooks/useApi';
import { Notification } from '../../types/resources';

const Notifications: FC = () => {
  const { t } = useTranslation();
  const query = useQueryParams();
  const pageSize = 10;
  const search = query.get('search');
  const type = query.get('type');
  const status = query.get('status');
  const {
    execute: getNotifications,
    state: { data },
  } = useApi<Notification[]>('/notifications');
  const notifications = data?.value ?? [];
  const total = data?.pagination?.total ?? 0;

  return (
    <>
      <Flex justify="between">
        <Text
          content={`${t('notification.title')} (${total})`}
          fontStyle="heading2"
        />
      </Flex>
      <NotificationsListFilters />

      <Flex marginTop={{ xs: 'space32' }}>
        <NotificationsList
          pageSize={pageSize}
          search={search}
          type={type}
          status={status}
          fetch={getNotifications}
          items={notifications}
          total={total}
        />
      </Flex>
    </>
  );
};

export default Notifications;
