import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import ErrorPage from './ErrorPage';

interface Error404Props {
  isAdmin: boolean;
  full?: boolean;
}

const Page404: FC<Error404Props> = ({ isAdmin, full }) => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <ErrorPage
      title={t('404.title', { code: '404' })}
      type="404"
      message={t('404.message')}
      buttonText={t('404.button_text')}
      onClick={() => history.push('/')}
      isAdmin={isAdmin}
      full={full}
    />
  );
};

export default Page404;
