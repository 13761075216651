import { FC, useEffect } from 'react';
import styled from 'styled-components';

import { PDFViewer } from 'components/Viewer';
import { Flex } from 'components/Layout';

import { DocumentFull, Procedure } from '../../types/resources';
import { useApi } from '../../hooks/useApi';
import { generateFileName } from '../../utils/downloadFile';
import { formatDocumentTitle } from '../../utils/format';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;

  > div:not(:last-child) {
    margin-bottom: 32px;
  }
`;

interface DocumentPreviewProps {
  procedure: Procedure;
  document: DocumentFull;
}

const DocumentPreview: FC<DocumentPreviewProps> = ({ procedure, document }) => {
  const { execute: downloadDocument, state: downloadDocumentState } = useApi<{
    url: string;
  }>(`/procedures/${document.procedure_id}/documents/${document.id}/download`);
  const url = downloadDocumentState.data?.value.url;
  const loading = downloadDocumentState.loading;
  const fileName = generateFileName(
    formatDocumentTitle(document),
    procedure.reference.toString(),
  );

  useEffect(() => {
    if (document.file_id) {
      downloadDocument();
    }
  }, [document]);

  if (!url || loading) {
    return <></>;
  }

  return (
    <Wrapper>
      <Flex width="100%" height="100%">
        <PDFViewer src={url} fileName={fileName} id={document.id} />
      </Flex>
    </Wrapper>
  );
};

export default DocumentPreview;
