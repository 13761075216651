import { FC, useEffect } from 'react';
import { env } from '../../config/env';

interface PDFViewerProps {
  src: string | Promise<ArrayBuffer>;
  fileName: string;
  id: string;
}

const PDFViewer: FC<PDFViewerProps> = ({ src, fileName, id }) => {
  useEffect(() => {
    var adobeDCView = new window.AdobeDC.View({
      clientId: env.adobe.clientId,
      locale: 'fr-FR',
      divId: `adobe-dc-view-${id}`,
    });
    adobeDCView.previewFile(
      {
        metaData: { fileName },
        content:
          typeof src === 'string'
            ? {
                location: {
                  url: src,
                },
              }
            : {
                promise: src,
              },
      },
      {
        embedMode: 'SIZED_CONTAINER',
        showAnnotationTools: false,
        focusOnRendering: true,
      },
    );
  }, []);

  return <div id={`adobe-dc-view-${id}`} />;
};

export default PDFViewer;
