import { Analytics, AnalyticsInstance } from 'analytics';
import gtag from '@analytics/google-tag-manager';

import { OptionPaymentEnum } from 'container/public/PaymentResume/RecapPaymentQuote';

import { Env } from '../config/env';
import { EstimateFull, ProductCode } from '../types/resources';
import { FormDataQuote } from '../container/public';

/* Initialize analytics */
let analytics: AnalyticsInstance | undefined;

export default analytics;

export enum Event {
  CheckoutStep = 'checkoutStep',
  CheckoutOption = 'checkoutOption',
  Purchase = 'purchase',
  Register = 'inscription_validate',
}

export function initAnalytics(env: Env) {
  const plugins = [];

  if (env.gtag.containerId) {
    plugins.push(gtag({ containerId: env.gtag.containerId }));
  }

  const isProd = env.envName === 'production';
  if (!isProd) {
    console.warn('analytics', 'initializing analytics');
  }

  analytics = Analytics({
    app: 'wedivorce',
    version: '0.1.16',
    plugins: env.envName === 'local' ? [] : plugins,
  });
}

export const handleFormDataEvent = (formData: FormDataQuote, step: number) => {
  switch (step) {
    case 0:
      analytics?.track('devis_step_accord_amiable', {
        option:
          formData?.choice0 === 'true'
            ? "Oui, je suis d'accord !"
            : "Non, ce n'est pas un divorce à l'amiable",
      });
      break;
    case 1:
      analytics?.track('devis_step_accord_procedure', {
        option: formData?.choice1 === 'true' ? 'Oui' : 'Non',
      });
      break;
    case 2:
      analytics?.track('devis_step_departement', {
        option: formData.region.code,
      });
      break;
    case 3:
      analytics?.track('devis_step_situation_de_couple', {
        option: `${formData.coupleSituation} | ${
          formData.nbYoungChildren ?? 0
        } | ${formData.nbAdultChildren ?? 0}`,
      });
      break;
    case 4:
      analytics?.track('devis_step_bien_immobiliers', {
        option: formData.properties ? 'Oui' : 'Non',
      });
      break;
    case 5:
      analytics?.track('devis_step_selection_formule', {
        option: formData.plan.code,
      });
      break;
    case 6:
      analytics?.track('devis_step_option_express', {
        option: formData?.options?.some((o) => o.code === ProductCode.Express)
          ? 'Oui'
          : 'Non',
      });
      break;
    case 7:
      analytics?.track('devis_step_option_premium', {
        option: formData?.options?.some((o) => o.code === ProductCode.Premium)
          ? 'Oui'
          : 'Non',
      });
      break;
    case 8:
      analytics?.track('devis_step_informations_contact', {
        option: formData?.email,
      });
      break;
  }
};

interface StepNineOptions {
  option?: string;
  option1?: string;
  option2?: string;
  option3?: string;
  option4?: string;
}

export const handleUpdatedDataEvent = (data?: EstimateFull) => {
  analytics?.track(Event.CheckoutStep, {
    step: 9,
  });
  if (!data) return;
  const getOptions = (): StepNineOptions => {
    let options: StepNineOptions = {};
    if (data.plan) {
      options.option = data.plan.code;
    }
    if (data.options) {
      options.option2 = data.options.map((o) => o.code).join('|');
    }
    if (data.minor_children || data.adult_children) {
      options.option3 = `${data.minor_children}|${data.adult_children}`;
    }
    return options;
  };
  const options = getOptions();
  analytics?.track(Event.CheckoutOption, options);
};

export const handleSplitEvent = (
  modality: number,
  optionPayment: OptionPaymentEnum,
) => {
  analytics?.track(Event.CheckoutStep, {
    step: 10,
  });
  analytics?.track(Event.CheckoutOption, {
    option: `${modality}x|${optionPayment}`,
  });
};

export const handleRegisterEvent = () => {
  analytics?.track(Event.Register);
};
