import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Container, Row, Col, FullPage, Flex } from 'components/Layout';
import { DocumentsDropdown } from 'container/admin';
import DocumentViewer from './DocumentViewer';
import { DocumentFull, Procedure } from '../../../types/resources';

interface SingleDocumentProps {
  document: DocumentFull;
  documents: DocumentFull[];
  procedure: Procedure;
}

const SingleDocument: FC<SingleDocumentProps> = ({
  procedure,
  document,
  documents,
}) => {
  const { t } = useTranslation();

  const onChangePage = () => {
    window.close();
  };

  return (
    <FullPage
      headerButton={t('documents.verification.quit')}
      headerTitle={t('documents.verification.title')}
      onClose={onChangePage}
    >
      <Container>
        <Row marginBottom={{ xs: 'space56' }}>
          <Col xs={12}>
            <DocumentsDropdown
              opportunityId={procedure.id}
              document={document}
              documents={documents}
            />
          </Col>
        </Row>
      </Container>
      <Flex
        width="100%"
        height="calc(100vw - 248px)"
        paddingLeft={{ xs: 'space32' }}
        paddingRight={{ xs: 'space32' }}
      >
        <DocumentViewer procedure={procedure} document={document} />
      </Flex>
    </FullPage>
  );
};

export default SingleDocument;
