export const toggleSearchParams = (
  key?: string,
  value?: string,
  searchParams?: URLSearchParams,
) => {
  if (!key || !searchParams) return;

  if (value) {
    searchParams.set(key, value);
  } else {
    searchParams.delete(key);
  }
};
