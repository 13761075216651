import { FC } from 'react';
import styled from 'styled-components';

import { theme } from 'theme';
import { md } from 'theme/styles/mediaQueries';

interface LargeCardProps {
  thinner?: boolean;
}

interface CardProps {
  thinner?: boolean;
}

const Card = styled.div<CardProps>`
  width: 100%;
  padding: ${theme.spacing.space32} ${theme.spacing.space24};
  border-radius: 24px;
  background-color: ${theme.colors.beige};
  ${md(`
    padding: ${theme.spacing.space40};
  `)}
`;

const LargeCard: FC<LargeCardProps> = ({ children, ...props }) => (
  <Card {...props}>{children}</Card>
);

export default LargeCard;
