import { env } from './env';

const externalUrls = {
  docusign: `${env.docusign.url}/documents/details/`,
};

const daysBeforeDelete = 180;

const dateFormat = 'dd/MM/yyyy';
const dateFormatWithTime = 'dd/MM/yyyy HH:mm';

const config = {
  externalUrls,
  daysBeforeDelete,
  dateFormat,
  dateFormatWithTime,
};

export default config;
