import React, { FC } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useQueryParams } from 'hooks/useQueryParams';
import { Tabulations } from 'components/Tabulations/Tabulations';
import { View, Views } from 'types/nav';

interface NavProps {
  views: View[];
  defaultView: Views;
}

const Nav: FC<NavProps> = ({ views, defaultView }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const query = useQueryParams();
  const queryParams = query.get('view');

  const _onClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    const target = event.target as HTMLButtonElement;
    history.push({
      pathname: location.pathname,
      search: `?view=${target.id}`,
    });
  };

  const list = views.map((view) => {
    const activeView =
      (queryParams && queryParams === view.name) ||
      (!queryParams && view.name === defaultView);
    const { name, disabled, roles } = view;

    return {
      disabled,
      roles,
      id: name,
      name: t(`views.${view.name}`),
      onClick: _onClick,
      className: activeView ? 'active' : '',
    };
  });

  return <Tabulations list={list} />;
};

export default Nav;
