import { FC } from 'react';

import AssignModal from './AssignModal';
import { Role } from '../../../types/resources';
import { useApi } from '../../../hooks/useApi';

export interface AssignModalSingleProps {
  refetchProceduresToAssign?: () => void;
  onClose: () => void;
  content: {
    title: string;
    type: Role;
    opportunityIds: string[];
    role: Role;
    assignedRoleId: string | undefined | null;
    spouseId: string | undefined | null;
  };
}

const AssignModalSingle: FC<AssignModalSingleProps> = ({
  refetchProceduresToAssign,
  onClose,
  content,
}) => {
  const [opportunityId] = content.opportunityIds;
  if (!opportunityId) return null;

  const { execute: patchApi } = useApi('', {
    method: 'PATCH',
  });

  const handleAssignModal = (userId: string | null) => {
    return new Promise<void>((resolve, reject) => {
      if (content.spouseId) {
        // Update procedure_spouse lawyer
        patchApi({
          endpoint: `/procedures/${opportunityId}/spouses/${content.spouseId}/lawyer`,
          body: { lawyer_id: userId },
          onSuccess: () => {
            onClose();
            refetchProceduresToAssign?.();
            resolve();
          },
          onError: reject,
        });
      } else {
        // Update procedure manager
        patchApi({
          endpoint: `/procedures/${opportunityId}`,
          body: { manager_id: userId },
          onSuccess: () => {
            onClose();
            refetchProceduresToAssign?.();
            resolve();
          },
          onError: reject,
        });
      }
    });
  };

  return (
    <AssignModal
      onClose={onClose}
      onAssign={handleAssignModal}
      content={content}
    />
  );
};

export default AssignModalSingle;
