import { CSSProperties, FC } from 'react';
import styled from 'styled-components';
import { theme } from 'theme';

const Div = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${theme.colors.gray3};
`;

export interface LineProps {
  style?: CSSProperties;
}

const Line: FC<LineProps> = ({ style, ...props }) => {
  return <Div style={style} {...props} />;
};

export default Line;
