import { FC } from 'react';

import { IconButton } from 'components/Buttons/IconButton';
import { Flex, Text } from 'components/Layout';
import { theme } from 'theme';
import { IconProps } from 'components/Images/Icon';
import { Button } from 'components/Buttons';
import { useTranslation } from 'react-i18next';
import { useViewport } from 'hooks/useViewport';

interface ModalHeaderProps {
  iconName: IconProps['name'];
  content: string;
  onClose?: () => void;
}

const ModalHeader: FC<ModalHeaderProps> = (props) => {
  const { isMobile } = useViewport();

  return isMobile ? (
    <ModalHeaderMobile {...props} />
  ) : (
    <ModalHeaderDesktop {...props} />
  );
};

const ModalHeaderDesktop: FC<ModalHeaderProps> = ({
  iconName,
  content,
  onClose,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Flex
        alignItems="center"
        justify="between"
        marginBottom={{ xs: 'space24', md: 'space16' }}
        width="100%"
      >
        <Flex alignItems="center">
          <IconButton
            iconName={iconName}
            color={theme.colors.salmon1}
            backgroundColor={theme.colors.salmon3}
            rounded
          />
          <Text
            content={content}
            fontStyle={'heading5'}
            marginLeft={{ xs: 'space16' }}
            weight="bold"
          />
        </Flex>

        {onClose && (
          <Button
            marginLeft={{ xs: 'space72' }}
            iconLeft={{ name: 'CloseSquare' }}
            content={t('close')}
            onClick={onClose}
          />
        )}
      </Flex>
    </>
  );
};

const ModalHeaderMobile: FC<ModalHeaderProps> = ({
  iconName,
  content,
  onClose,
}) => {
  return (
    <>
      <Flex
        alignItems="center"
        justify="between"
        marginBottom={{ xs: 'space24', md: 'space16' }}
        width="100%"
      >
        <Flex alignItems="center">
          <IconButton
            iconName={iconName}
            color={theme.colors.salmon1}
            backgroundColor={theme.colors.salmon3}
            rounded
          />
          <Text
            content={content}
            fontStyle={'heading5'}
            marginLeft={{ xs: 'space16' }}
            weight="bold"
          />
        </Flex>

        {onClose && (
          <Button iconLeft={{ name: 'close' }} onClick={onClose} size="small" />
        )}
      </Flex>
    </>
  );
};

export default ModalHeader;
