// Components

import { FC, useEffect } from 'react';
import { FormulaCard } from 'components/Cards/FormulaCard';
import { Ghost } from 'components/Loading';
import ProductCard from 'components/Cards/ProductCard';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useViewport } from 'hooks/useViewport';
import { useApi } from '../../hooks/useApi';
import { Plan } from '../../types/resources';

const PlanGrid = styled.div`
  display: grid;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  width: 100%;
  box-sizing: border-box;
  padding: 0 16px;

  @media screen and (min-width: 1413px) {
    display: grid;
    align-items: normal;
    grid-template-columns: repeat(auto-fill, minmax(256px, 1fr));
    justify-content: center;
    padding: 0;
    gap: 32px;
  }
`;

export interface PlanCardsProps {
  onClick: (plan: Plan) => void;
  checked: Plan;
}

const PlanCards: FC<PlanCardsProps> = ({ onClick, checked }) => {
  const { isMobile } = useViewport();
  const { t } = useTranslation();
  const { execute, state } = useApi<Plan[]>('/plans', { cache: true });

  useEffect(() => {
    execute({
      query: { enabled: true, sort: 'price', direction: 'asc' },
    });
  }, []);

  const refPrice =
    state.data?.value.reduce((acc, plan) => {
      if (plan.price < acc) {
        return plan.price;
      }
      return acc;
    }, Infinity) || 0;

  return (
    <PlanGrid>
      {state.loading ? (
        <>
          <Ghost width="100%" height={400} shape="rect" rx={24} />
          <Ghost width="100%" height={400} shape="rect" rx={24} />
          <Ghost width="100%" height={400} shape="rect" rx={24} />
        </>
      ) : (
        state.data?.value.map((plan) => {
          const { description, name, code, price, id, recommended } = plan;
          const isRefPrice = price === refPrice;
          const diffPrice = (price - refPrice) / 2;

          if (isMobile) {
            return (
              <ProductCard
                key={id}
                title={name}
                content={description}
                priceDescription={
                  isRefPrice
                    ? t('quote.resume.taxes_price_base')
                    : t('quote.resume.taxes_price_relative', {
                        price: diffPrice,
                      })
                }
                seeMoreText={t('quote.resume.see_more')}
                checked={code === checked?.code}
                onClick={() => onClick(plan)}
                boldPriceDescription
              />
            );
          }

          return (
            <FormulaCard
              key={id}
              title={name}
              content={description}
              priceDescription={
                isRefPrice
                  ? t('quote.resume.taxes_price_base')
                  : t('quote.resume.taxes_price_relative', {
                      price: diffPrice,
                    })
              }
              textButton={t('quote.resume.select')}
              checked={code === checked?.code}
              onClick={() => onClick(plan)}
              disabled={!(code === checked?.code || !checked)}
              advice={recommended ? t('quote.recommendedPlan') : undefined}
            />
          );
        })
      )}
    </PlanGrid>
  );
};

export default PlanCards;
