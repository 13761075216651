import { Flex, Line, Text } from 'components/Layout';
import { LargeCard } from 'components/Cards';

import React, { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useViewport } from 'hooks/useViewport';
import { InputRadioOption } from '../../FormTemplate/Fields/InputRadio';
import Modality, { ModalityOption } from './Modality';
import styled from 'styled-components';
import paiementSecuriseImg from 'assets/images/paiementsecurise-small.png';
import paypalImg from 'assets/images/paypal.png';
import { FieldError } from 'react-hook-form';
import { theme } from '../../../theme';

export type OptionProviderEnum = 'card' | 'paypal';

const Img = styled.img`
  width: 125px;
`;

export type ModalityPaymentValue = {
  provider: OptionProviderEnum;
  installments: number;
};

interface ModalityPaymentProps {
  handleChange: (value: ModalityPaymentValue) => void;
  value?: ModalityPaymentValue;
  error?: FieldError;
}

const ModalityPayment: FC<ModalityPaymentProps> = ({
  handleChange,
  value,
  error,
}) => {
  const { t } = useTranslation();
  const { isMobile } = useViewport();
  const optionsTimes = [1, 2, 3, 4, 6, 10, 12];
  const options: ModalityOption[] = optionsTimes.map((option) => {
    return {
      onClick: () => handleChange({ provider: 'card', installments: option }),
      time: option,
      displayTime: t('quote.payment.modality.times', { number: option }),
      withCost: option >= 6,
    };
  });

  const handleSelectChange = (value: OptionProviderEnum) => {
    handleChange({ provider: value, installments: 1 });
  };

  return (
    <Flex expand>
      <LargeCard>
        <Flex
          marginBottom={{ xs: 'space16', md: 'space24' }}
          direction={{ xs: 'column' }}
        >
          <Text
            content={t('quote.payment.modality.title')}
            fontStyle={isMobile ? 'heading4' : 'heading3'}
            weight="bold"
            marginBottom={{ xs: 'space16' }}
          />
          <Text fontStyle="body2">
            <Trans i18nKey={'quote.payment.modality.description'} />
          </Text>
        </Flex>
        <Flex marginBottom={{ xs: 'space24' }}>
          <Line />
        </Flex>
        <Flex direction={{ xs: 'column' }}>
          <InputRadioOption<OptionProviderEnum>
            value={'card'}
            onClick={() => handleSelectChange('card')}
            errored={Boolean(error)}
            checked={value?.provider === 'card'}
            name={'paymentProvider'}
            onChange={(event) =>
              handleSelectChange(event.target.value as OptionProviderEnum)
            }
          >
            <Text
              marginRight={{ xs: 'space8' }}
              fontStyle={isMobile ? 'body3' : 'body2'}
            >
              <Trans i18nKey={'quote.payment.modality.card'} />
            </Text>
            <Img
              src={paiementSecuriseImg}
              style={{ width: isMobile ? 75 : undefined }}
            />
          </InputRadioOption>

          {value?.provider === 'card' && (
            <Modality options={options} active={value.installments} />
          )}

          <Flex marginTop={{ xs: 'space24' }}>
            <InputRadioOption<OptionProviderEnum>
              value={'paypal'}
              onClick={() => handleSelectChange('paypal')}
              errored={Boolean(error)}
              checked={value?.provider === 'paypal'}
              name={'paymentProvider'}
              onChange={(event) =>
                handleSelectChange(event.target.value as OptionProviderEnum)
              }
            >
              <Text
                marginRight={{ xs: 'space8' }}
                fontStyle={isMobile ? 'body3' : 'body2'}
              >
                <Trans i18nKey={'quote.payment.modality.paypal'} />
              </Text>
              <Img
                src={paypalImg}
                style={{ width: isMobile ? 75 : undefined }}
              />
            </InputRadioOption>
          </Flex>
        </Flex>
        {error && (
          <Flex>
            <Text
              fontStyle={'body2'}
              color={theme.colors.red1}
              content={t('quote.payment.modality.required')}
            />
          </Flex>
        )}
      </LargeCard>
    </Flex>
  );
};

export default ModalityPayment;
