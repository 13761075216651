import React, { FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  Control,
  FieldError,
  UseFormClearErrors,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form';

import { theme } from 'theme';
import { lg } from 'theme/styles/mediaQueries';
import { regexes } from 'utils/regex';

import { Flex, Text, Line } from 'components/Layout';
import { InputText } from 'components/FormTemplate/Fields/InputText';
import { LargeCard } from 'components/Cards';
import { InputTel } from 'components/FormTemplate/Fields/InputTel';
import { Modify } from 'types/typescript';
import InputCountry from 'components/FormTemplate/Fields/InputCountry';
import { isValidPhoneNumber } from 'react-phone-number-input';
import {
  Customer,
  CustomerFull,
  Estimate,
  EstimateStatus,
} from '../../../types/resources';
import { ValidateEstimateInput } from '../../../container/public/PaymentResume/RecapPaymentQuote';

const InvoiceWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  gap: ${theme.spacing.space32} ${theme.spacing.space32};
`;

const InputWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  gap: ${theme.spacing.space16} ${theme.spacing.space16};
  margin-bottom: ${theme.spacing.space16};

  ${lg(`
    gap: ${theme.spacing.space32} ${theme.spacing.space16};
    grid-template-columns: 1fr 1fr; 
  `)};
`;

interface UserFields {
  email?: FieldError | undefined;
  phone?: FieldError | undefined;
  first_name?: FieldError | undefined;
  last_name?: FieldError | undefined;
  postalCode?: FieldError | undefined;
  city?: FieldError | undefined;
  address?: FieldError | undefined;
  country?: FieldError | undefined;
}

interface Errors {
  acceptCGU?: FieldError | undefined;
  acceptDivorce?: FieldError | undefined;
  acceptLawyer?: FieldError | undefined;
  spouseFollower?: UserFields | undefined;
  spouseLead?: UserFields | undefined;
}

interface InvoiceProps {
  register: UseFormRegister<ValidateEstimateInput>;
  errors: Errors;
  estimate: Estimate;
  customer1?: CustomerFull;
  customer2?: Customer | null;
  control: Control<ValidateEstimateInput, object>;
  getValues: UseFormGetValues<ValidateEstimateInput>;
  clearErrors: UseFormClearErrors<ValidateEstimateInput>;
  setValue: UseFormSetValue<ValidateEstimateInput>;
  readOnly?: boolean;
}

type UserType = 'spouseFollower' | 'spouseLead';

interface FormRowsProps {
  register: UseFormRegister<ValidateEstimateInput>;
  errors: Errors;
  formRows: Field[][];
  user: UserType;
  control: Control<ValidateEstimateInput, object>;
  getValues: UseFormGetValues<ValidateEstimateInput>;
  setValue: UseFormSetValue<ValidateEstimateInput>;
  clearErrors: UseFormClearErrors<ValidateEstimateInput>;
}

interface Field {
  type?: 'email' | 'text' | 'tel';
  name:
    | 'first_name'
    | 'last_name'
    | 'email'
    | 'phone'
    | 'city'
    | 'address'
    | 'country'
    | 'postalCode';
  regex?: RegExp;
  value?: string | null;
  validate?: (value?: string | null) => boolean | string | undefined;
  custom?: 'country';
  disabled?: boolean;
  readOnly?: boolean;
  uppercase?: boolean;
}

interface UpdateSpousesInputCustom extends ValidateEstimateInput {
  spouseFollower: Modify<
    {
      email: string;
      first_name: string;
      last_name: string;
      phone: string;
    },
    { address?: string; city?: string; postalCode?: string; country?: string }
  >;
}

interface FormInputProps {
  field: Field;
  register: UseFormRegister<UpdateSpousesInputCustom>;
  errors: Errors;
  user: UserType;
  control: Control<ValidateEstimateInput, object>;
  getValues: UseFormGetValues<ValidateEstimateInput>;
  custom?: 'country';
  setValue: UseFormSetValue<ValidateEstimateInput>;
  clearErrors: UseFormClearErrors<ValidateEstimateInput>;
  disabled?: boolean;
  readOnly?: boolean;
}

const FormInput: FC<FormInputProps> = ({
  field,
  register,
  errors,
  user,
  custom,
  setValue,
  disabled,
  readOnly,
  clearErrors,
}) => {
  const { t } = useTranslation();

  const { name, regex, value, type, validate, uppercase } = field;
  const pattern = regex
    ? {
        value: regex,
        message: t(`quote.payment.invoice.${name}_error`),
      }
    : undefined;
  if (type === 'tel') {
    return (
      <InputTel
        {...register(`${user}.${name}`, {
          pattern,
          validate,
          required: t('error.form.required'),
        })}
        type={type}
        error={errors[user]?.[name]}
        label={t(`quote.payment.invoice.${name}`)}
        placeholder={t(`quote.payment.invoice.${name}_placeholder`)}
        width="100%"
        value={value ?? ''}
        onChange={(value) => {
          setValue(`${user}.phone`, value);
          clearErrors(`${user}.phone`);
        }}
        disabled={disabled}
        readOnly={readOnly}
      />
    );
  }

  if (custom === 'country' && user === 'spouseLead') {
    return (
      <InputCountry
        {...register(`${user}.${name}`, {
          pattern,
          validate,
          required: t('error.form.required'),
        })}
        value={value as string | undefined}
        onChange={(e: string) => {
          setValue(`${user}.country`, e);
          clearErrors(`${user}.country`);
        }}
        error={errors[user]?.[name]}
        label={t(`quote.payment.invoice.${name}`)}
        placeholder={t(`quote.payment.invoice.${name}_placeholder`)}
        width="100%"
        disabled={disabled}
        readOnly={readOnly}
      />
    );
  }

  return (
    <InputText
      {...register(`${user}.${name}`, {
        required: t('error.form.required').toString(),
        pattern,
        validate,
      })}
      type={type}
      defaultValue={value as string | undefined}
      error={errors[user]?.[name]}
      label={t(`quote.payment.invoice.${name}`)}
      placeholder={t(`quote.payment.invoice.${name}_placeholder`)}
      width="100%"
      disabled={disabled}
      readOnly={readOnly}
      onChange={({ target }: React.ChangeEvent<HTMLInputElement>) => {
        if (field.uppercase) {
          target.value = target.value.toUpperCase();
        }
      }}
    />
  );
};

const FormRows: FC<FormRowsProps> = ({
  register,
  errors,
  formRows,
  user,
  control,
  getValues,
  setValue,
  clearErrors,
}) => {
  return (
    <>
      {formRows.map((fields, index) => {
        if (fields.length === 1) {
          const [field] = fields;
          return (
            <Flex key={field.name} marginBottom={{ xs: 'space16' }}>
              <FormInput
                field={field}
                register={register}
                errors={errors}
                user={user}
                control={control}
                getValues={getValues}
                custom={field.custom}
                setValue={setValue}
                disabled={field.disabled}
                readOnly={field.readOnly}
                clearErrors={clearErrors}
              />
            </Flex>
          );
        }
        return (
          <InputWrapper key={`wrapper_${index}`}>
            {fields.map((field) => {
              return (
                <FormInput
                  key={field.name}
                  field={field}
                  register={register}
                  errors={errors}
                  user={user}
                  control={control}
                  getValues={getValues}
                  custom={field.custom}
                  setValue={setValue}
                  disabled={field.disabled}
                  readOnly={field.readOnly}
                  clearErrors={clearErrors}
                />
              );
            })}
          </InputWrapper>
        );
      })}
    </>
  );
};

const Invoice: FC<InvoiceProps> = ({
  register,
  errors,
  customer1,
  estimate,
  customer2,
  control,
  getValues,
  setValue,
  clearErrors,
  readOnly,
}) => {
  const { t } = useTranslation();

  const leadRows: Field[][] = [
    [
      {
        name: 'first_name',
        regex: regexes.lettersOnly,
        value: customer1?.first_name ?? '',
        readOnly: readOnly ?? false,
        uppercase: true,
      },
    ],
    [
      {
        name: 'last_name',
        regex: regexes.lettersOnly,
        value: customer1?.last_name ?? '',
        readOnly: readOnly ?? false,
        uppercase: true,
      },
    ],
    [
      {
        name: 'email',
        regex: regexes.email,
        value: customer1?.email ?? '',
        readOnly: readOnly ?? false,
      },
    ],
    [
      {
        name: 'phone',
        value: customer1?.phone ?? '',
        readOnly: readOnly ?? false,
        type: 'tel',
      },
    ],

    [
      {
        name: 'address',
        value: customer1?.address?.street ?? '',
        disabled: readOnly ?? false,
        uppercase: true,
      },
    ],
    [
      {
        name: 'postalCode',
        value: customer1?.address?.post_code ?? '',
        disabled: readOnly ?? false,
      },
      {
        name: 'city',
        value: customer1?.address?.city ?? '',
        regex: regexes.lettersOnly,
        disabled: readOnly ?? false,
        uppercase: true,
      },
    ],
    [
      {
        name: 'country',
        custom: 'country',
        type: 'text',
        value: customer1?.address?.country ?? '',
        readOnly: readOnly ?? false,
        uppercase: true,
      },
    ],
  ];
  const followerRows: Field[][] = [
    [
      {
        name: 'first_name',
        value: customer2?.first_name ?? '',
        regex: regexes.lettersOnly,
        readOnly: readOnly ?? false,
        uppercase: true,
      },
    ],
    [
      {
        name: 'last_name',
        value: customer2?.last_name ?? '',
        regex: regexes.lettersOnly,
        readOnly: readOnly ?? false,
        uppercase: true,
      },
    ],
    [
      {
        name: 'email',
        regex: regexes.email,
        value: customer2?.email ?? '',
        readOnly: readOnly ?? false,
        validate: (value) =>
          value !== customer1?.email ||
          `${t('quote.payment.invoice.same_email')}`,
      },
    ],
    [
      {
        name: 'phone',
        type: 'tel',
        value: customer2?.phone ?? '',
        readOnly: readOnly ?? false,
        validate: (value) =>
          !isValidPhoneNumber(value ?? '')
            ? t('error.form.phone')
            : value === customer1?.phone
            ? `${t('quote.payment.invoice.same_phone')}`
            : undefined,
      },
    ],
  ];

  return (
    <>
      <Flex marginBottom={{ xs: 'space24' }}>
        <LargeCard>
          <InvoiceWrapper>
            <Flex direction={{ xs: 'column' }}>
              <Flex
                marginBottom={{ xs: 'space16', md: 'space24' }}
                direction={{ xs: 'column' }}
              >
                <Text
                  content={t('quote.payment.invoice.first_adress')}
                  fontStyle="heading3"
                  weight="bold"
                />
              </Flex>
              <Flex marginBottom={{ xs: 'space32' }}>
                <Line />
              </Flex>
              <Flex direction={{ xs: 'column' }}>
                <FormRows
                  register={register}
                  errors={errors}
                  formRows={leadRows}
                  user="spouseLead"
                  control={control}
                  getValues={getValues}
                  setValue={setValue}
                  clearErrors={clearErrors}
                />
              </Flex>
            </Flex>
          </InvoiceWrapper>
        </LargeCard>
      </Flex>
      {estimate.status !== EstimateStatus.WON && (
        <Flex>
          <LargeCard>
            <InvoiceWrapper>
              <Flex direction={{ xs: 'column' }}>
                <Flex
                  marginBottom={{ xs: 'space16', md: 'space24' }}
                  direction={{ xs: 'column' }}
                >
                  <Text
                    content={t('quote.payment.invoice.second_adress')}
                    fontStyle="heading3"
                    weight="bold"
                  />
                </Flex>
                <Flex marginBottom={{ xs: 'space32' }}>
                  <Line />
                </Flex>
                <Flex direction={{ xs: 'column' }}>
                  <FormRows
                    register={register}
                    errors={errors}
                    formRows={followerRows}
                    user="spouseFollower"
                    control={control}
                    getValues={getValues}
                    setValue={setValue}
                    clearErrors={clearErrors}
                  />
                </Flex>
              </Flex>
            </InvoiceWrapper>
          </LargeCard>
        </Flex>
      )}
    </>
  );
};

export default Invoice;
