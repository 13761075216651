import React, { FC } from 'react';
import { WidgetProps } from '@rjsf/core';
import get from 'lodash/get';
import styled from 'styled-components';

import { Button } from 'components/Buttons/Button';
import { Flex } from 'components/Layout/Flex';
import { Label } from 'components/Text/Label';
import { Directions } from 'theme/styles/flex';
import RequiredField from 'components/Forms/Utils/RequiredField';
import { theme } from 'theme';
import { useViewport } from 'hooks/useViewport';

const ResponsiveButton = styled(Button)`
  white-space: normal;
  text-align: left;
`;

const Grid = styled.div<{ direction: Directions }>`
  display: grid;
  gap: ${theme.spacing.space16};
  justify-content: start;
  justify-items: start;
  grid-auto-flow: ${(props) => props.direction};
`;

export const RadioButtonWidget: FC<WidgetProps> = (props) => {
  const { isMobile } = useViewport();

  const enumOptions: WidgetProps['options'][] = get(
    props,
    'options.enumOptions',
    [],
  );

  const direction: Directions = get(props, 'options.direction', 'column');
  const mobileDirection: Directions = get(
    props,
    'options.mobileDirection',
    'column',
  );

  return (
    <Flex direction={{ xs: 'column' }}>
      {props.label && (
        <Flex marginBottom={{ xs: 'space16', md: 'space24' }}>
          <RequiredField required={props.required}>
            <Label dangerouslySetInnerHTML={{ __html: props.label }} />
          </RequiredField>
        </Flex>
      )}
      <Grid
        direction={isMobile && mobileDirection ? mobileDirection : direction}
      >
        {enumOptions.map(({ label, value }) => (
          <ResponsiveButton
            key={value as number}
            primary={props.value?.toString() === value?.toString()}
            onClick={() => props.onChange(value)}
            value={value as string}
            content={label as string}
            disabled={
              props.value?.toString() !== value?.toString() && props.disabled
            }
          />
        ))}
      </Grid>
    </Flex>
  );
};
