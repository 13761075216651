import { UiSchemaCustom } from '.';

const children: UiSchemaCustom[] = [
  {
    'ui:order': [
      'WD_PENSION_ALIM_MAJEUR',
      'WD_MONTANT_MENSUEL_PENSION_MAJEUR',
      'WD_PENSION_BENEF_MAJEUR',
      'PENSION_BENEF_MAJEUR_ENFANT',
      'WD_ACCORD_ENFANTS_MAJEURS',
    ],
    WD_PENSION_ALIM_MAJEUR: {
      'ui:widget': 'radio',
    },
    WD_MONTANT_MENSUEL_PENSION_MAJEUR: {
      'ui:field': 'CustomCurrency',
      'ui:placeholder': '300',
    },
    WD_PENSION_BENEF_MAJEUR: {
      'ui:widget': 'select',
      'ui:placeholder': 'Choisir un bénéficiaire',
    },
    PENSION_BENEF_MAJEUR_ENFANT: {
      'ui:widget': 'select',
      'ui:placeholder': 'Choisir un bénéficiaire',
    },
    WD_ACCORD_ENFANTS_MAJEURS: {
      'ui:field': 'CustomTextArea',
    },
  },
];

export default children;
