import { Text } from 'components/Layout';
import { FC } from 'react';
import styled from 'styled-components';

import { theme } from 'theme';

import { getOpportunityStatusColors } from 'utils/layout';

const Tooltip = styled.span<{ bgColor: string }>`
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  width: 120px;
  top: calc(100% + 12px);
  left: 0%;
  margin-left: -60px;
  position: absolute;
  padding-left: 8px;

  background-color: ${(props) => props.bgColor};

  &::after {
    content: ' ';
    position: absolute;
    bottom: 100%; /* At the top of the tooltip */
    left: 90%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent ${(props) => props.bgColor}
      transparent;
  }
`;

const Container = styled.div`
  position: relative;
  display: inline-block;
  cursor: default;
`;

const OpportunityButton = styled.span<OpportunityButtonProps>`
  padding: ${theme.spacing.space8};
  border-radius: 12px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
  background-color: ${(props) => props.bgColor};
  color: ${(props) => props.color};
  border: 1px solid ${(props) => props.borderColor};
  white-space: nowrap;
`;

interface OpportunityButtonProps {
  borderColor: string;
  bgColor: string;
  color: string;
}

export interface OpportunityStatusButtonProps {
  type: 'info' | 'warning' | 'danger' | 'success';
  text: string;
  className?: string;
  hoverText?: string;
}

const OpportunityStatusButton: FC<OpportunityStatusButtonProps> = ({
  type,
  text,
  className,
  hoverText,
}) => {
  const colors = getOpportunityStatusColors(type);
  return (
    <Container>
      <OpportunityButton
        color={colors?.color}
        borderColor={colors?.borderColor}
        bgColor={colors?.bgColor}
        className={className}
      >
        {text}
      </OpportunityButton>
      {hoverText && (
        <Tooltip bgColor={colors?.bgColor}>
          <Text
            content={hoverText}
            fontStyle="body3"
            color={theme.colors.black}
            textAlign="left"
          />
        </Tooltip>
      )}
    </Container>
  );
};

export default OpportunityStatusButton;
