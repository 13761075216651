import { FC } from 'react';

interface RequiresProps {
  value: any;
}

const Requires: FC<RequiresProps> = ({ value, children }) => {
  if (!!value) return <>{children}</>;
  return null;
};

export default Requires;
