import { FC, useState } from 'react';

import { Modal } from 'components/Modal/Modal';
import { useTranslation } from 'react-i18next';
import { Text } from 'components/Layout/Text';
import { Flex, Line } from 'components/Layout';
import { Button, IconButton } from 'components/Buttons';
import { theme } from 'theme';
import { useViewport } from 'hooks/useViewport';
import { InputText } from 'components/FormTemplate/Fields/InputText';
import Toaster from 'components/Toaster/Toaster';
import { User } from '../../types/resources';

interface SpousesInformationModalProps {
  onClose: () => void;
  opened: boolean;
  spouseLead: User;
  spouseFollower: User;
  title: string;
}

const SpousesInformationModal: FC<SpousesInformationModalProps> = ({
  onClose,
  opened,
  spouseLead,
  spouseFollower,
  title,
}) => {
  const { t } = useTranslation();
  const { isMobile } = useViewport();
  const [isToasterActive, setIsToasterActive] = useState<boolean>(false);
  const spouses = [
    {
      title: t('opportunity.spouses_information_modal.spouse_leader'),
      data: spouseLead,
    },
    {
      title: t('opportunity.spouses_information_modal.spouse_follower'),
      data: spouseFollower,
    },
  ];

  const handleCopyClick = (text: string) => {
    navigator.clipboard.writeText(text);
    setIsToasterActive(true);

    setTimeout(() => {
      setIsToasterActive(false);
    }, 2000);
  };

  const InputBlock = ({
    label,
    inputText,
  }: {
    label: string;
    inputText: string;
  }) => {
    return (
      <Flex direction={{ xs: 'column' }} marginTop={{ xs: 'space16' }}>
        <Text
          content={label}
          fontStyle={'body1'}
          marginBottom={{ xs: 'space8' }}
          color={theme.colors.gray6}
        />
        <InputText
          width="100%"
          placeholder={inputText}
          disabled
          icon={{ name: 'copy', primaryColor: theme.colors.salmon1 }}
          onClick={() => handleCopyClick(inputText)}
        />
      </Flex>
    );
  };

  return (
    <>
      <Modal opened={opened} onClose={onClose} width="75vw" full={isMobile}>
        <Flex
          alignItems="center"
          justify="between"
          marginBottom={{ xs: 'space24' }}
        >
          <Flex alignItems="center">
            <IconButton
              iconName="InfoSquare"
              color={theme.colors.salmon2}
              backgroundColor={theme.colors.salmon3}
              rounded
            />
            <Text
              content={title}
              fontStyle={'heading5'}
              marginLeft={{ xs: 'space16' }}
            />
          </Flex>
          <Button
            content={t('upload.close')}
            iconLeft={{ name: 'CloseSquare' }}
            onClick={onClose}
          />
        </Flex>

        <Line />

        <Flex marginTop={{ xs: 'space40' }}>
          {spouses.map((spouse, index) => (
            <Flex
              key={spouse.data.id}
              direction={{ xs: 'column' }}
              width="50%"
              marginRight={{
                xs: index === spouses.length - 1 ? 'none' : 'space32',
              }}
            >
              <Text
                content={spouse?.title}
                fontStyle={'heading5'}
                marginBottom={{ xs: 'space8' }}
              />

              <InputBlock
                label={t('opportunity.spouses_information_modal.lastname')}
                inputText={spouse.data.last_name || ''}
              />
              <InputBlock
                label={t('opportunity.spouses_information_modal.firstname')}
                inputText={spouse.data.first_name || ''}
              />
              <InputBlock
                label={t('opportunity.spouses_information_modal.email')}
                inputText={spouse.data.email}
              />
              <InputBlock
                label={t('opportunity.spouses_information_modal.phone')}
                inputText={spouse.data.phone || ''}
              />
            </Flex>
          ))}
        </Flex>
      </Modal>

      <Toaster
        active={isToasterActive}
        type="success"
        message={t('opportunity.spouses_information_modal.text_copied')}
      />
    </>
  );
};

export default SpousesInformationModal;
