import { FC } from 'react';
import styled from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';

import { IconButton } from 'components/Buttons';
import { Flex, MobileOnly, Text } from 'components/Layout';
import { theme } from 'theme';
import { md } from 'theme/styles/mediaQueries';
import { Role, SpouseRole } from '../../../types/resources';
import { useAuth } from '../../../hooks/useAuth';

const Wrapper = styled.div`
  padding: ${theme.spacing.space12} ${theme.spacing.space16};
  border-bottom: 1px solid ${theme.colors.gray3};
  background-color: ${theme.colors.gray1};

  ${md(`
    background-color: ${theme.colors.white};
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    padding: ${theme.spacing.space16} ${theme.spacing.space24};
  `)}
`;

const AvatarWrapper = styled.div<{ shift: boolean }>`
  margin-left: ${({ shift }) => (shift ? '-12px' : '0')};
`;

const StyledIconButton = styled(IconButton)<{ shift?: boolean }>`
  border: 1px solid;
  border-color: ${({ shift }) => (shift ? theme.colors.white : 'transparent')};
`;

const NameWrapper = styled.div`
  margin-left: ${theme.spacing.space16};
`;

interface ConversationCardHeaderProps {
  recipients: {
    full_name: string;
    role: Role;
    type?: SpouseRole | null;
  }[];
}

const ConversationCardHeader: FC<ConversationCardHeaderProps> = ({
  recipients,
}) => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const isGroupedConversation = recipients.some(
    (r) => r.role === Role.CUSTOMER,
  );

  const recipientNamesText = recipients
    .map((recipient) => recipient.full_name)
    .join(', ');
  const customerDescriptionsText = isGroupedConversation
    ? t('opportunity.conversation.grouped_conversation_description')
    : t('opportunity.conversation.individual_conversation_description');
  const internalDescriptionsText = recipients
    .map((recipient) =>
      recipient?.type
        ? t(`opportunity.conversation.roles_label.${recipient?.type}`)
        : t(`opportunity.conversation.roles_label.MANAGER`),
    )
    .join(', ');
  const descriptionText =
    user?.role === Role.CUSTOMER
      ? customerDescriptionsText
      : internalDescriptionsText;

  return (
    <Wrapper>
      <Flex alignItems="center">
        <Flex alignItems="center">
          {recipients?.length === 0 && (
            <StyledIconButton
              iconName="User"
              color={theme.colors.green1}
              backgroundColor={theme.colors.green2}
              stroke="regular"
              size="large"
              rounded
            />
          )}
          {recipients.map((_, index) => (
            <AvatarWrapper
              key={`conversation-recipient-avatar-${index}`}
              shift={index > 0}
            >
              <StyledIconButton
                iconName="User"
                color={theme.colors.green1}
                backgroundColor={theme.colors.green2}
                stroke="regular"
                size="large"
                shift={index > 0}
                rounded
              />
            </AvatarWrapper>
          ))}
        </Flex>
        <NameWrapper>
          <Text
            content={
              recipientNamesText ||
              t(`opportunity.conversation.manager_no_assign`)
            }
            fontStyle="body1"
            weight="bold"
            color={theme.colors.gray6}
          />
          <Text
            content={descriptionText}
            fontStyle="body2"
            color={theme.colors.gray6}
          />
        </NameWrapper>
      </Flex>
      <MobileOnly>
        <Text
          fontStyle="body3"
          marginTop={{ xs: 'space12' }}
          color={theme.colors.gray6}
        >
          <Trans i18nKey="opportunity.conversation.description" />
        </Text>
      </MobileOnly>
    </Wrapper>
  );
};

export default ConversationCardHeader;
