import { env } from '../config/env';
import {
  useFetch,
  FetchOptions,
  UseFetchReturn,
  RequestError,
} from './useFetch';
import { useContext } from 'react';
import { AuthContext } from '../context';
import useToaster from './useToaster';
import { useTranslation } from 'react-i18next';

export function useApi<T = unknown>(
  endpoint: string,
  options?: FetchOptions<T>,
): UseFetchReturn<T> {
  const { t } = useTranslation();
  const { error } = useToaster();
  const { setUser } = useContext(AuthContext);

  return useFetch<T>(`${env.api.url}/api`, endpoint, {
    ...(options ?? {}),
    onError: (err) => {
      if (err instanceof RequestError && err.response.status === 401) {
        setUser(null);
        localStorage.setItem('user', JSON.stringify(null));
        options?.onError
          ? options.onError(err)
          : error(t('errors.unauthenticated'));
      } else if (err instanceof RequestError && err.response.status === 403) {
        options?.onError ? options.onError(err) : error(t('errors.forbidden'));
      } else if (err instanceof RequestError && err.response.status >= 500) {
        options?.onError ? options.onError(err) : error(t('errors.server'));
      } else if (err instanceof TypeError) {
        options?.onError
          ? options.onError(err)
          : error(t('errors.no_connectivity'));
      } else {
        options?.onError ? options.onError(err) : error(t('errors.unknown'));
      }
    },
  });
}
