import { TipCard } from 'components/Cards';
import { TipCardProps } from 'components/Cards/TipCard';
import { Flex } from 'components/Layout';
import { FC, useEffect, useState } from 'react';
import {
  EventScheduledEvent,
  InlineWidget,
  useCalendlyEventListener,
} from 'react-calendly';
import { Prefill } from 'react-calendly/typings/calendly';
import { useTranslation } from 'react-i18next';
import { Modal } from './Modal';
import ModalHeader from './ModalHeader';
import InputSelect from '../FormTemplate/Fields/InputSelect';

interface CalendlyModalProps {
  onClose: () => void;
  opened: boolean;
  onEventScheduled?: (e: EventScheduledEvent) => void;
  prefill?: Prefill;
  url?: string;
  tipCard?: TipCardProps;
}

type Calendar = {
  label: string;
  value: string;
};

const calendars: Calendar[] = [
  {
    label: 'PARIS',
    value:
      'https://calendly.com/agenda-paris/paris-rendez-vous-avec-vos-avocats',
  },
  {
    label: 'NANTES / RENNES',
    value: 'https://calendly.com/agenda-rennes-nantes-wedivorce',
  },
  {
    label: 'MARSEILLE /NICE',
    value: 'https://calendly.com/agenda-marseille-nice-wedivorce',
  },
  {
    label: 'BORDEAUX /TOULOUSE',
    value: 'https://calendly.com/agenda-bdx-toulouse-wedivorce',
  },
  {
    label: 'LILLE / LYON 1',
    value: 'https://calendly.com/agenda-lille-lyon1-wedivorce',
  },
  {
    label: 'MONTPELLIER / LYON 2',
    value: 'https://calendly.com/agenda-mpt-lyon2-wedivorce',
  },
  {
    label: 'STRASBOURG / NANCY',
    value: 'https://calendly.com/agenda-stsbg-nancy-wedivorce',
  },
];

const CalendlyModal: FC<CalendlyModalProps> = ({
  opened,
  onClose,
  onEventScheduled,
  prefill,
  url,
  tipCard,
}) => {
  const [selectedCalendarUrl, setSelectedCalendarUrl] = useState<string | null>(
    null,
  );

  useCalendlyEventListener({ onEventScheduled });
  const { t } = useTranslation();
  const calendlyUrl = selectedCalendarUrl ?? url;

  useEffect(() => {
    if (opened) {
      setSelectedCalendarUrl(null);
    }
  }, [opened]);

  return (
    <Modal opened={opened} onClose={onClose} width="60%" height="80%">
      <ModalHeader
        iconName="Calendar"
        content={t('appointment.taking.title')}
        onClose={onClose}
      />

      {tipCard && (
        <Flex marginBottom={{ xs: 'space16' }}>
          <TipCard {...tipCard} />
        </Flex>
      )}

      {!url && (
        <Flex>
          <InputSelect
            key={Date.now()}
            placeholder={t('appointment.taking.choose_city')}
            options={calendars}
            onChange={setSelectedCalendarUrl}
            value={selectedCalendarUrl}
            required
          />
        </Flex>
      )}

      {calendlyUrl && (
        <InlineWidget
          key={Date.now()}
          url={calendlyUrl}
          prefill={prefill}
          pageSettings={{
            backgroundColor: 'ffffff',
            hideEventTypeDetails: false,
            hideLandingPageDetails: false,
            hideGdprBanner: true,
            primaryColor: '00a2ff',
            textColor: '4d5055',
          }}
        />
      )}
    </Modal>
  );
};
export default CalendlyModal;
