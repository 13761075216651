import Toaster, { ToasterProps } from '../components/Toaster/Toaster';
import {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  useContext,
  useReducer,
} from 'react';
import { ActionTypes, ToasterActions } from './toaster/ToasterActions';

function reducer(state: ToasterProps, action: ToasterActions): ToasterProps {
  switch (action.type) {
    case ActionTypes.SHOW_TOASTER:
      return action.payload;
    case ActionTypes.HIDE_TOASTER:
      return { ...state, active: false };
    default:
      throw new Error();
  }
}

const initialState: ToasterProps = {
  active: false,
  type: 'error',
  message: '',
  list: undefined,
};

type IToasterContext = {
  state: ToasterProps;
  dispatch: Dispatch<ToasterActions>;
};

export const ToasterContext = createContext<IToasterContext>({
  state: initialState,
  dispatch: () => {},
});

export const ToasterProvider: FC<PropsWithChildren<any>> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <ToasterContext.Provider value={{ state, dispatch }}>
      {children}
    </ToasterContext.Provider>
  );
};

export const ToasterWrapper = () => {
  const {
    state: { message, type, active },
  } = useContext(ToasterContext);

  return <Toaster message={message} type={type} active={active} />;
};
