import { FC, useEffect } from 'react';

// Components
import { Row, Col } from 'components/Layout';

// Data
import StepperOpportunity from '../StepperOpportunityContainer/StepperOpportunity';
import RolesOpportunityList from '../RolesOpportunityList/RolesOpportunityList';
import { useApi } from '../../../hooks/useApi';
import { ProcedureFull, Option } from '../../../types/resources';

// Utils

interface CloseViewProps {
  procedure: ProcedureFull;
}

const ClosedView: FC<CloseViewProps> = ({ procedure }) => {
  const { execute: getOptions, state: getOptionsState } = useApi<Option[]>(
    '/products?type=OPTION&enabled=true',
    { cache: true },
  );
  const options = getOptionsState.data?.value ?? [];

  useEffect(() => {
    getOptions();
  }, []);

  if (!procedure || !options) return null;

  return (
    <>
      <Row marginBottom={{ xs: 'space32' }}>
        <Col xs={12}>
          <StepperOpportunity conversations={[]} procedure={procedure} />
        </Col>
      </Row>
      <Row marginBottom={{ xs: 'space32' }}>
        <Col xs={12}>
          <RolesOpportunityList procedure={procedure} editable={false} />
        </Col>
      </Row>
    </>
  );
};

export default ClosedView;
