import { YPFormAnswers } from './clientFormData.types';

export const withInfoClientFormData: Record<keyof YPFormAnswers, boolean> = {
  PARTIE1_NOM: false,
  PARTIE1_CIVILITE: false,
  PARTIE1_PRENOM: false,
  PARTIE1_EMAIL: false,
  WD_PARTIE1_TEL: false,
  PARTIE1_PROFESSION: false,
  WD_PARTIE1_STATUT_PRO: false,
  PARTIE1_DATE_NAISSANCE: false,
  PARTIE1_VILLE_NAISSANCE: false,
  PARTIE1_DPT_NAISSANCE: false,
  WD_PARTIE1_PAYS_NAISSANCE: false,
  PARTIE1_NATIONALITE: false,
  WD_PARTIE1_MARIAGE_RECONNU: false,
  PARTIE1_ADRESSE: false,
  PARTIE1_CP: false,
  PARTIE1_VILLE: false,
  PARTIE1_POST_IS_TRUE: false,
  PARTIE1_DATE_POST: false,
  PARTIE1_ADRESSE_POST: false,
  PARTIE1_CP_POST: false,
  PARTIE1_VILLE_POST: false,
  PARTIE2_NOM: false,
  PARTIE2_CIVILITE: false,
  PARTIE2_PRENOM: false,
  PARTIE2_EMAIL: false,
  WD_PARTIE2_TEL: false,
  PARTIE2_PROFESSION: false,
  WD_PARTIE2_STATUT_PRO: false,
  PARTIE2_DATE_NAISSANCE: false,
  PARTIE2_VILLE_NAISSANCE: false,
  PARTIE2_DPT_NAISSANCE: false,
  WD_PARTIE2_PAYS_NAISSANCE: false,
  PARTIE2_NATIONALITE: false,
  WD_PARTIE2_MARIAGE_RECONNU: false,
  PARTIE2_ADRESSE: false,
  PARTIE2_CP: false,
  PARTIE2_VILLE: false,
  PARTIE2_POST_IS_TRUE: false,
  PARTIE2_DATE_POST: false,
  PARTIE2_ADRESSE_POST: false,
  PARTIE2_CP_POST: false,
  DATE_MARIAGE: false,
  CP_COMMUNE_MARIAGE: false,
  MARIAGE_CONTRAT: false,
  REGIME_MAT: false,
  WD_PRENOM_NOTAIRE: false,
  WD_ADRESSE_NOTAIRE: false,
  CP_NOTAIRE: false,
  WD_DOM_CONJ: false,
  WD_PARTIE2_DELAI_COHAB: false,
  WD_PARTIE1_DELAI_COHAB: false,
  WD_PRECISION_DOM_CONJ: false,
  PARTIE1_NET_MENSUEL: false,
  REVENUS1_YESNO: false,
  PARTIE2_NET_MENSUEL: false,
  REVENUS2_YESNO: false,
  PRESTATION_COMP: false,
  MODALITES_VERSEMENT_PC: false,
  WD_PRESTA_CAPITAL_DATE: false,
  WD_BIENS_EPOUX: false,
  WD_PRETS_EN_COURS: false,
  WD_AUTRES_DETTES: false,
  WD_DETTE_ENTRE_EPOUX: false,
  WD_ACTES_DONATION_PRESENCE: false,
  PARTIE2_VILLE_POST: false,
  COMMUNE_MARIAGE: false,
  WD_PAYS_MARIAGE: false,
  CONTRAT_TYPE: false,
  CONTRAT_DATE: false,
  WD_NOM_NOTAIRE: false,
  WD_VILLE_NOTAIRE: false,
  WD_PAYS_NOTAIRE_BIEN: false,
  WD_DOM_CONJ_SORT: false,
  LIQ_AUTH_DATE: false,
  NOM_PAT: false,
  PARTIE1_TYPE_REVENU: false,
  REVENUS1: false,
  PARTIE2_TYPE_REVENU: false,
  REVENUS2: false,
  BENEF_PRESTA_COMP: false,
  WD_PRESTA_CAPITAL_MONTANT: false,
  WD_AUTRES_PRECISIONS: false,
  BIEN: false,
  PRETS: false,
  AUTRES_DETTES: false,
  WD_DETTES_EPOUX: false,
  WD_DONATIONS: false,
  ENFANTS: false,
  AUTRE_BIEN: false,

  TYPE_GARDE: false,
  WD_JOUR_CHGT_GARDE: false,
  WD_HORAIRE_CHGT_GARDE: false,
  PENSION_ALIM: false,
  WD_MONTANT_MENSUEL: false,
  WD_PENSION_BENEF: false,
  ALLOC: false,
  ALLOC_MONTANT: false,
  WD_ALLOC_SORT: false,
  WD_AUTRES_ACCORDS_FI: false,
  PRECISIONS_GARDE_TEXT: false,
  WD_PENSION_ALIM_MAJEUR: false,
  WD_MONTANT_MENSUEL_PENSION_MAJEUR: false,
  WD_PENSION_BENEF_MAJEUR: false,
  WD_ACCORD_ENFANTS_MAJEURS: false,
};
