import { FormDataQuote } from '../../../public';

export const withInfoFormDataQuote: Record<keyof FormDataQuote, boolean> = {
  choice0: false,
  choice1: false,
  region: false,
  coupleSituation: false,
  nbAdultChildren: true,
  nbYoungChildren: true,
  properties: false,
  plan: false,
  options: false,
  email: false,
  phone: false,
  agreecorrespondence: false,
};
