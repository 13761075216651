import { FC, useState } from 'react';
import styled from 'styled-components';
import { theme } from 'theme';
import logo from 'assets/logo/logo-white.svg';

// Components
import { Flex } from 'components/Layout/Flex';
import { IconButton } from 'components/Buttons/IconButton';

// Types
import {
  AdminMenuLinkProps,
  UserProfileProps,
} from 'container/admin/menus/AdminMenu';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { MenuButton } from 'components/Buttons';
import { Line } from '..';
import { Permission } from 'components/User';
import { useAuth } from '../../../hooks/useAuth';

const MenuContainer = styled.header`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  top: 0;
  inset: 0;
  align-items: flex-start;
  padding: 0 ${theme.spacing.space24} ${theme.spacing.space32}
    ${theme.spacing.space24};
  max-width: 186px;
  height: 100vh;
  background-color: ${theme.colors.green1};
  z-index: 6;
`;

const Image = styled.img``;

export interface SideMenuProps {
  userProfile: UserProfileProps;
  menuLinks: AdminMenuLinkProps[];
}

export const SideMenu: FC<SideMenuProps> = ({ userProfile, menuLinks }) => {
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const { logout } = useAuth();
  const { t } = useTranslation();
  const location = useLocation();

  return (
    <MenuContainer>
      <Flex direction={{ xs: 'column' }} justify="between">
        <Flex
          marginBottom={{ xs: 'space24' }}
          marginTop={{ xs: 'space32' }}
          alignSelf={'center'}
        >
          <Image src={logo} alt="WeDivorce" />
        </Flex>
        {menuLinks &&
          menuLinks.map((link) => {
            const active =
              link.url === '/'
                ? location.pathname === link.url
                : location.pathname.startsWith(link.url);
            return (
              <Permission key={link.name} roles={link.roles}>
                <Flex marginTop={{ xs: 'space24' }}>
                  <MenuButton
                    onClick={() => link.goTo(link.url)}
                    primary
                    active={active}
                    content={link.name}
                    iconName={link.icon}
                  />
                </Flex>
              </Permission>
            );
          })}
      </Flex>
      <Flex direction={{ xs: 'column' }} expand>
        <Line />
        <Flex
          alignSelf="start"
          marginTop={{ xs: 'space24' }}
          onClick={() => setOpenMenu(!openMenu)}
          style={{ cursor: 'pointer' }}
        >
          <IconButton
            letters={userProfile.initials}
            color={theme.colors.green1}
            backgroundColor={theme.colors.green2}
          />
          <IconButton
            iconName={openMenu ? 'ChevronUpCircle' : 'ChevronDownCircle'}
            color={theme.colors.white}
            backgroundColor={theme.colors.green1}
          />
        </Flex>
        {openMenu && (
          <>
            <Flex marginTop={{ xs: 'space32' }}>
              <MenuButton
                onClick={() => userProfile.goTo(userProfile.url)}
                iconName="User"
                content={t('user.my_profile')}
              />
            </Flex>
            <Flex marginTop={{ xs: 'space24' }}>
              <MenuButton
                onClick={() => logout()}
                iconName="Logout"
                content={t('sign_out')}
              />
            </Flex>
          </>
        )}
      </Flex>
    </MenuContainer>
  );
};
