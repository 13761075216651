import { Button, IconButton } from 'components/Buttons';
import { Card } from 'components/Cards';
import { Flex, Text } from 'components/Layout';
import { ChangeEvent, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { theme } from 'theme';
import { TextArea } from 'components/FormTemplate/Fields/TextArea';
import { useAuth } from '../../hooks/useAuth';

interface NoteTextareaProps {
  firstname?: string;
  lastname?: string;
  content?: string;
  onCreate: (note: string) => void;
  onCancel: () => void;
  editButtonLabel?: string;
}

const StyledCard = styled(Card)`
  border-radius: 12px;
  background-color: ${theme.colors.gray1};
  margin-top: ${theme.spacing.space24};
`;

const NoteTextarea: FC<NoteTextareaProps> = ({
  firstname,
  lastname,
  content,
  onCreate,
  onCancel,
  editButtonLabel,
}) => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const [note, setNote] = useState<string>(content || '');

  const handleTextChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setNote(e.target.value);
  };

  const handleCreateNote = () => {
    onCreate(note);
    setNote('');

    onCancel();
  };

  return (
    <StyledCard theme="red">
      <Flex alignItems="center" marginBottom={{ xs: 'space16' }}>
        <IconButton
          iconName="User"
          color={theme.colors.green1}
          backgroundColor={theme.colors.green2}
          rounded
          stroke="regular"
        />
        <Text
          content={
            firstname && lastname ? `${firstname} ${lastname}` : user?.full_name
          }
          fontStyle="body2"
          marginLeft={{ xs: 'space16' }}
          marginRight={{ xs: 'space16' }}
          weight="medium"
          color={theme.colors.gray6}
        />
      </Flex>

      <TextArea
        placeholder={t('opportunity.notes.add_note_placeholder')}
        required
        type="textarea"
        value={note}
        onChange={handleTextChange}
        width="100%"
      />
      <Flex alignItems="center" marginTop={{ xs: 'space32' }}>
        <Button
          content={editButtonLabel || t('opportunity.notes.button.add')}
          primary
          onClick={handleCreateNote}
          disabled={!note}
        />
        <Button
          content={t('cancel')}
          marginLeft={{ xs: 'space16' }}
          onClick={onCancel}
        />
      </Flex>
    </StyledCard>
  );
};

export default NoteTextarea;
