import { FC } from 'react';
import styled from 'styled-components';
import { theme } from 'theme';

import { IconButton } from 'components/Buttons';
import { Flex, Text } from 'components/Layout';
import { IconProps } from 'components/Images/Icon';

export interface FooterButton {
  text: string;
  onClick: () => void;
  icon: IconProps['name'];
  primary?: boolean;
}
interface FooterRowSelectProps {
  show: boolean;
  buttons: FooterButton[];
}

const Container = styled.div<{ show: boolean }>`
  background: ${theme.colors.white};
  width: 100vw;
  height: 72px;
  position: fixed;
  bottom: -72px;
  left: 0;
  z-index: 10;
  box-shadow: 0px 4px 16px rgba(44, 41, 38, 0.08);
  display: flex;
  justify-content: center;
  align-items: center;
  transform: ${(props) =>
    props.show ? 'translateY(-72px)' : 'translateY(72px)'};
  transition: transform 0.3s ease-in-out;
`;

const FooterRowSelect: FC<FooterRowSelectProps> = ({ show, buttons }) => {
  return (
    <Container show={show}>
      {buttons.map((button, index) => {
        const { onClick, text, icon, primary = false } = button;
        const lastButton = buttons.length - 1 === index;
        return (
          <Flex
            onClick={onClick}
            alignItems="center"
            cursor="pointer"
            key={text}
            marginRight={{ xs: lastButton ? 'none' : 'space48' }}
          >
            <IconButton
              iconName={icon}
              color={primary ? theme.colors.red1 : theme.colors.black}
              backgroundColor={primary ? theme.colors.red3 : theme.colors.gray3}
              rounded
            />
            <Text
              fontStyle="body2"
              content={text}
              marginLeft={{ xs: 'space16' }}
              weight="medium"
              color={primary ? theme.colors.red1 : theme.colors.black}
            />
          </Flex>
        );
      })}
    </Container>
  );
};

export default FooterRowSelect;
