import styled, { css } from 'styled-components';
import { FC, MouseEventHandler } from 'react';

import { theme } from 'theme';

// Components
import { Flex } from 'components/Layout/Flex';
import { Button } from 'components/Buttons/Button';
import { md } from 'theme/styles/mediaQueries';
import { useTranslation } from 'react-i18next';
import { AllFormsData } from 'wrapper/FormWrapper';
import { Text } from 'components/Layout';

const MainContainer = styled.div`
  position: absolute;
  // inset: 0;
  background-color: rgba(0, 0, 0, 0.4);
`;

const SideContainer = styled.aside<SideContainerProps>`
  position: fixed;
  top: 0;
  left: 100%;
  bottom: 0;
  width: 100vw;
  background-color: ${theme.colors.white};
  border-left: 1px solid ${theme.colors.gray3};
  z-index: 20;
  transition: transform 0.7s ease-in-out;

  ${md(`
    width: 376px;
  `)}

  ${({ isShow }) =>
    isShow &&
    css`
      transform: translateX(-100%);
      transition: transform 0.3s ease-in-out;

      ${md(`
        transform: translateX(-376px);
      `)}
    `}
`;

const Line = styled.div`
  height: 1px;
  background-color: ${theme.colors.gray3};
  width: 100%;
  margin-top: -5px;
`;

const Description = styled.div`
  color: ${theme.colors.gray6};

  & ul {
    margin-left: 24px !important;
  }

  & a {
    color: ${theme.colors.gray6};
    text-decoration: underline;
  }
`;

interface SideContainerProps {
  isShow: boolean;
}

export interface InfoPanelProps {
  isShow: boolean;
  onClose: MouseEventHandler;
  fieldName?: keyof AllFormsData;
}

const InfoPanel: FC<InfoPanelProps> = ({ isShow, onClose, fieldName }) => {
  const { t } = useTranslation('infoPanel');
  return (
    <MainContainer>
      <SideContainer isShow={isShow}>
        <Flex
          marginTop={{ xs: 'space16' }}
          marginBottom={{ xs: 'space16' }}
          marginLeft={{ xs: 'space24' }}
        >
          <Button
            content={t('close')}
            iconLeft={{ name: 'CloseSquare' }}
            onClick={onClose}
            size="small"
          />
        </Flex>
        <Line />
        {fieldName && (
          <Flex
            direction={{ xs: 'column' }}
            paddingLeft={{ xs: 'space24' }}
            paddingRight={{ xs: 'space24' }}
            paddingTop={{ xs: 'space40' }}
          >
            <Text
              content={t(`${fieldName}.title`)}
              fontStyle="heading4"
              marginBottom={{ xs: 'space40' }}
            />
            <Text
              content={t(`${fieldName}.subtitle`)}
              fontStyle="body1"
              weight="medium"
              marginBottom={{ xs: 'space8' }}
            />
            <Description
              dangerouslySetInnerHTML={{
                __html: t(`${fieldName}.description`),
              }}
            />
          </Flex>
        )}
      </SideContainer>
    </MainContainer>
  );
};

export default InfoPanel;
