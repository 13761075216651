import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

// Components
import { theme } from 'theme';
import { Text } from 'components/Layout/Text';
import { IconButton } from 'components/Buttons/IconButton';
import { Flex } from 'components/Layout/Flex';
import Line from 'components/Layout/Line';

import { AssignModalProps } from 'container/admin/AssignModal/AssignModal';
import { getFullname } from 'utils/user';
import { Permission, UserItem } from 'components/User';
import { Col, Requires, Row } from 'components/Layout';
import {
  CustomerEstimate,
  EstimateFull,
  ProcedureFull,
  Role,
} from '../../../types/resources';
import { useApi } from '../../../hooks/useApi';

const CardContainer = styled.div`
  width: 100%;
  height: auto;
  border: 1px solid ${theme.colors.gray3};
  border-radius: ${theme.spacing.space24};
  background-color: white;
`;

const FullWidthRow = styled(Row)`
  width: 100%;
`;

const StyledButton = styled.button`
  border: none;
`;

export interface RolesOpportunityCardProps {
  title: string;
  procedure: ProcedureFull;
  estimate?: EstimateFull;
  isAdmin: boolean;
  isLead: boolean;
  handleOpenAssignModal: (props: AssignModalProps['content']) => void;
  handleOpenSpousesInformationModal: () => void;
  editable?: boolean;
}

interface UserRenderingProps {
  user?: {
    id: string;
    email: string;
    first_name?: string;
    last_name?: string;
  } | null;
  title: string;
  translationOptions?: Record<any, string>;
  isAdmin?: boolean;
  role?: Role;
  isLawyer?: boolean;
  handleOpenAssignModal?: (props: AssignModalProps['content']) => void;
  assignModalContent?: AssignModalProps['content'];
  editable?: boolean;
}

const User: FC<UserRenderingProps> = ({
  user,
  title,
  translationOptions,
  isAdmin,
  isLawyer,
  handleOpenAssignModal,
  assignModalContent,
  editable,
}) => {
  const { t } = useTranslation();

  let assignmentKey: string;
  switch (assignModalContent?.type) {
    case 'LAWYER':
      assignmentKey = 'opportunity.assign_lawyer_lead';
      break;
    // case 'LAWYER_FOLLOWER':
    //   assignmentKey = 'opportunity.assign_lawyer_follower';
    //   break;
    case 'MANAGER':
      assignmentKey = 'opportunity.assign_manager';
      break;
    case 'ADMIN':
      assignmentKey = 'opportunity.assign_qc';
      break;
  }

  const getName = () => {
    if (isLawyer && user) {
      return t('roles_opportunity.role.lawyer_display', {
        name: user?.last_name,
      });
    }
    if (user) {
      return getFullname(user);
    }
    if (isAdmin) {
      return t(assignmentKey);
    }
    return t('roles_opportunity.being_assigned');
  };
  const name = getName();
  const onNameClick = () => {
    if (isAdmin && handleOpenAssignModal && assignModalContent) {
      handleOpenAssignModal(assignModalContent);
    }
  };

  const [hoverState, setHoverState] = useState(false);

  return (
    <Col xs={4}>
      <Flex direction={{ xs: 'column' }}>
        <Flex alignItems="center">
          <Text
            content={t(title, translationOptions)}
            fontStyle="body1"
            color={theme.colors.gray6}
            marginRight={{ xs: 'space8' }}
          />
          {handleOpenAssignModal && assignModalContent && editable && (
            <Permission roles={[Role.ADMIN, Role.MANAGER, Role.LAWYER]}>
              <IconButton
                color={hoverState ? theme.colors.salmon1 : theme.colors.black}
                hoverIconColor={theme.colors.salmon1}
                backgroundColor="transparent"
                iconName="EditSquare"
                rounded
                onClick={() => handleOpenAssignModal(assignModalContent)}
                style={{
                  alignSelf: 'flex-start',
                }}
              />
            </Permission>
          )}
        </Flex>
        {isAdmin ? (
          <StyledButton
            onClick={onNameClick}
            onMouseEnter={() => setHoverState(true)}
            onMouseLeave={() => setHoverState(false)}
          >
            <Text
              content={name}
              fontStyle="body1"
              weight="bold"
              color={user ? theme.colors.black : theme.colors.gray5}
              onClick={onNameClick}
            />
          </StyledButton>
        ) : (
          <Text
            content={name}
            fontStyle="body1"
            weight="bold"
            color={user ? theme.colors.black : theme.colors.gray5}
            onClick={onNameClick}
          />
        )}
      </Flex>
    </Col>
  );
};

export const RolesOpportunityCard: FC<RolesOpportunityCardProps> = ({
  title,
  procedure,
  estimate,
  isAdmin,
  isLead,
  handleOpenAssignModal,
  handleOpenSpousesInformationModal,
  editable,
}) => {
  const { t } = useTranslation();

  const { execute: getEstimateCustomers, state: getEstimateCustomersState } =
    useApi<CustomerEstimate[]>(`/estimates/${procedure.estimate_id}/customers`);
  const estimateCustomers = getEstimateCustomersState.data?.value ?? [];

  const spouseLeadUser = procedure.spouse1;
  const spouseFollowerUser = procedure.spouse2;
  const lawyerLeadUser = procedure.lawyer1;
  const lawyerFollowerUser = procedure.lawyer2;
  const managerUser = procedure.manager;
  const firstUser = isLead ? lawyerLeadUser : lawyerFollowerUser;
  const secondUser = isLead ? lawyerFollowerUser : lawyerLeadUser;

  const estimateFullyPaid =
    Boolean(estimate) && estimate?.amount_paid === estimate?.price;

  const paidCustomerLeader =
    estimateFullyPaid ||
    estimateCustomers.find(
      (ec) => ec.customer_id === spouseLeadUser?.id && ec.amount_paid > 0,
    );
  const paidCustomerFollower =
    estimateFullyPaid ||
    estimateCustomers.find(
      (ec) => ec.customer_id === spouseFollowerUser?.id && ec.amount_paid > 0,
    );

  const userProps = {
    isAdmin,
    editable,
    handleOpenAssignModal,
  };

  useEffect(() => {
    getEstimateCustomers();
  }, [procedure.id]);

  return (
    <>
      <CardContainer>
        <Flex
          alignItems="center"
          justify="between"
          paddingTop={{ xs: 'space16', md: 'space24' }}
          paddingLeft={{ xs: 'space16', md: 'space24' }}
          paddingBottom={{ xs: 'space16', md: 'space24' }}
          paddingRight={{ xs: 'space16', md: 'space24' }}
        >
          <Flex alignItems="center">
            <IconButton
              iconName="TwoUsers"
              color={theme.colors.salmon2}
              backgroundColor={theme.colors.salmon3}
              rounded
              stroke="regular"
            />
            <Text
              content={title}
              fontStyle="heading5"
              weight="bold"
              marginLeft={{ xs: 'space8', lg: 'space16' }}
              marginRight={{ xs: 'space24' }}
            />
            {isAdmin && (
              <IconButton
                iconName="InfoSquare"
                color={theme.colors.black}
                backgroundColor={theme.colors.gray3}
                rounded
                onClick={handleOpenSpousesInformationModal}
              />
            )}
          </Flex>
          <Flex>
            <Requires value={spouseLeadUser}>
              <UserItem
                letters="C1"
                userId={spouseLeadUser?.id}
                hasPaid={isAdmin ? Boolean(paidCustomerLeader) : undefined}
                content={
                  spouseLeadUser &&
                  spouseLeadUser.first_name &&
                  spouseLeadUser.last_name
                    ? getFullname(spouseLeadUser)
                    : t('roles_opportunity.being_assigned')
                }
                toggleModalPaid={() => {
                  // setSpouseIdModalPaid(spouseLeadUser?.id);
                  // setSelectedValueModalPaid(Boolean(paidCustomerLeader));
                }}
              />
            </Requires>
            <Requires value={spouseFollowerUser}>
              <Flex marginLeft={{ xs: 'space16', md: 'space32' }}>
                <UserItem
                  userId={spouseFollowerUser?.id}
                  letters="C2"
                  hasPaid={isAdmin ? Boolean(paidCustomerFollower) : undefined} // we admit all customers have paid if present in case
                  content={getFullname(spouseFollowerUser)}
                  toggleModalPaid={() => {
                    // setSpouseIdModalPaid(spouseFollowerUser?.id);
                    // setSelectedValueModalPaid(Boolean(paidCustomerFollower)); // we admit all customers have paid if present in case
                  }}
                />
              </Flex>
            </Requires>
          </Flex>
        </Flex>
        <Line />
        <Flex
          wrap="wrap"
          paddingTop={{ xs: 'space16', md: 'space24' }}
          paddingLeft={{ xs: 'space16', md: 'space24' }}
          paddingBottom={{ xs: 'space16', md: 'space24' }}
          paddingRight={{ xs: 'space16', md: 'space24' }}
        >
          {isAdmin ? (
            <FullWidthRow>
              <User
                {...userProps}
                user={lawyerLeadUser}
                title="roles_opportunity.role.lawyer_dynamic"
                translationOptions={{
                  name: `${spouseLeadUser?.first_name} ${spouseLeadUser?.last_name}.`,
                }}
                role={Role.LAWYER}
                assignModalContent={{
                  title: t('opportunity.add_lawyer_spouse', {
                    firstname: spouseLeadUser?.first_name,
                    lastname: spouseLeadUser?.last_name,
                  }),
                  type: Role.LAWYER,
                  role: Role.LAWYER,
                  opportunityIds: [procedure.id],
                  assignedRoleId: procedure.lawyer1_id,
                  spouseId: procedure.spouse1_id,
                }}
              />
              <User
                {...userProps}
                user={lawyerFollowerUser}
                title="roles_opportunity.role.lawyer_dynamic"
                translationOptions={{
                  name: `${spouseFollowerUser?.first_name} ${spouseFollowerUser?.last_name}.`,
                }}
                role={Role.LAWYER}
                assignModalContent={{
                  title: t('opportunity.add_lawyer_spouse', {
                    firstname: spouseFollowerUser?.first_name,
                    lastname: spouseFollowerUser?.last_name,
                  }),
                  type: Role.LAWYER,
                  role: Role.LAWYER,
                  opportunityIds: [procedure.id],
                  assignedRoleId: procedure.lawyer2_id,
                  spouseId: procedure.spouse2_id,
                }}
              />
              <User
                {...userProps}
                user={managerUser}
                title="roles_opportunity.role.MANAGER"
                role={Role.MANAGER}
                assignModalContent={{
                  title: t('opportunity.add_manager'),
                  type: Role.MANAGER,
                  role: Role.MANAGER,
                  opportunityIds: [procedure.id],
                  assignedRoleId: procedure.manager_id,
                  spouseId: undefined,
                }}
              />
            </FullWidthRow>
          ) : (
            <FullWidthRow>
              <User
                user={firstUser}
                title="roles_opportunity.role.LAWYER_LEAD"
                isLawyer
              />
              <User
                user={managerUser}
                title="roles_opportunity.role.your_manager"
              />
              <User
                user={secondUser}
                title="roles_opportunity.role.LAWYER_FOLLOWER"
                isLawyer
              />
            </FullWidthRow>
          )}
        </Flex>
      </CardContainer>
    </>
  );
};
