import React, { InputHTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import { theme } from 'theme';
import { Text } from 'components/Layout/Text';
import RequiredField from '../../Forms/Utils/RequiredField';
import { Flex } from 'components/Layout';
import { Label } from 'components/Text/Label';

export const InputStyle = css`
  width: 100%;
  height: ${theme.spacing.space64};
  border: 1px solid ${theme.colors.gray3};
  border-radius: ${theme.spacing.space16};
  font-size: 16px;
  color: ${theme.colors.black};
  padding: 0 ${theme.spacing.space24};

  &::placeholder {
    font-weight: 400;
    color: ${theme.colors.gray5};
  }
  &:disabled {
    background-color: ${theme.colors.gray2};
  }

  &:active,
  &:focus {
    outline: none;
    box-shadow: none;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  -moz-appearance: textfield;

  &:enabled {
    &:active,
    &:focus {
      border: 1px solid ${theme.colors.gray4};
      outline: none;
    }
  }
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: baseline;
`;

const InputComponent = styled.input<InputProps>`
  ${InputStyle}
  font-weight: ${({ disabled }) => (disabled ? '400' : 'normal')};
  padding-right: ${({ hasIcon }) => hasIcon && theme.spacing.space48};
`;

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string | false;
  error?: string;
  hasIcon?: boolean;
}

export const Input = React.forwardRef((props: InputProps, ref) => {
  const { label, error, hasIcon, ...rest } = props;

  return (
    <InputContainer>
      {label && (
        <Flex direction={{ xs: 'column' }} width="100%">
          <RequiredField>
            <Label>{label}</Label>
          </RequiredField>
        </Flex>
      )}
      <Flex direction={{ xs: 'column' }} width="100%">
        <InputComponent ref={ref as any} hasIcon={hasIcon} {...rest} />
        {error && (
          <Text fontStyle="body2" color="red">
            {error}
          </Text>
        )}
      </Flex>
    </InputContainer>
  );
});

Input.displayName = 'Input';
