import { useCallback, useContext } from 'react';
import { ToasterContext } from '../context/ToasterContext';
import { ActionTypes } from '../context/toaster/ToasterActions';

export default function useToaster() {
  const { dispatch } = useContext(ToasterContext);

  const success = useCallback(
    (message: string) => {
      dispatch({
        type: ActionTypes.SHOW_TOASTER,
        payload: { type: 'success', active: true, message },
      });
      dispatch({ type: ActionTypes.HIDE_TOASTER });
    },
    [dispatch],
  );

  const error = useCallback(
    (message: string) => {
      dispatch({
        type: ActionTypes.SHOW_TOASTER,
        payload: { type: 'error', active: true, message },
      });
      dispatch({ type: ActionTypes.HIDE_TOASTER });
    },
    [dispatch],
  );

  return { success, error };
}
