import { FC, useEffect } from 'react';

// Components
import { Col, Row } from 'components/Layout';
import { CertificateCard, ConsultationCard } from '..';
import { UpsellClientCard } from 'components/Cards';
import {
  DocumentFull,
  ProcedureFull,
  ProcedureSpouse,
  ProcedureStatus,
} from '../../../types/resources';
import { useApi } from '../../../hooks/useApi';

interface ClientCardsProps {
  procedure: ProcedureFull;
  spouse?: ProcedureSpouse;
  userId: string;
}

const ClientCards: FC<ClientCardsProps> = ({ procedure, spouse, userId }) => {
  const { execute: getDocuments, state: getDocumentsState } = useApi<
    DocumentFull[]
  >(
    `/procedures/${procedure.id}/documents?page=1&items=3&is_administrative=false`,
  );
  const documents = getDocumentsState.data?.value ?? [];

  useEffect(() => {
    getDocuments();
  }, []);

  if (procedure.status !== ProcedureStatus.OPEN) return null;

  return (
    <Row marginBottom={{ xs: 'none', md: 'space32' }}>
      <Col xs={12} md={4} desktopOnly>
        <ConsultationCard
          nbConsultation={spouse?.lawyer_consultation ?? 0}
          opportunityId={procedure.id}
          emailTo={procedure.manager?.email || 'contact@wedivorce.fr'}
          userId={userId}
        />
      </Col>
      <Col xs={12} md={4} desktopOnly>
        <UpsellClientCard />
      </Col>
      <Col xs={12} md={4} desktopOnly>
        <CertificateCard
          administrativeDocuments={documents}
          procedure={procedure}
        />
      </Col>
    </Row>
  );
};

export default ClientCards;
