import React, { FC } from 'react';
import { SpecificationCard } from 'components/Cards/Dashboard/SpecificationCard';
import { IconProps } from 'components/Images/Icon';

export interface Specification {
  icon: IconProps['name'];
  title: string;
  value: string;
  description?: string;
  hasInfo?: boolean;
  onClick?: () => void;
}

interface SpecificationCardListProps {
  specifications: Specification[];
}

const SpecificationCardList: FC<SpecificationCardListProps> = ({
  specifications,
}) => {
  return (
    <>
      {specifications.length > 0 &&
        specifications.map((specification, index) => (
          <SpecificationCard
            key={index}
            iconName={specification.icon}
            specification={specification.title}
            value={specification.value}
            description={specification.description}
            hasInfo={specification.hasInfo}
            onClick={specification.onClick}
          />
        ))}
    </>
  );
};

export default SpecificationCardList;
