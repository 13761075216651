import {
  ComponentType,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Flex, MobileOnly, Text } from 'components/Layout';
import { FormDataQuote } from 'container/public';
import { withInfoClientFormData } from 'container/client/SingleForm/utils/clientFormWithInfo';
import { withInfoFormDataQuote } from 'container/client/SingleForm/utils/formDataQuoteWithInfo';

import FormRJSF, {
  FormProps,
  FormValidation,
  IChangeEvent,
  UiSchema,
} from '@rjsf/core';
import { TFunction, useTranslation } from 'react-i18next';
import analytics from 'utils/analytics';

import { AdminAppRoutes } from 'AdminApp';
import { ArrayFormTemplate } from 'components/FormTemplate/Common/ArrayFormTemplate';
import { CheckboxWidget } from 'components/FormTemplate/Widgets/Checkbox';
import { ClientAppRoutes } from 'ClientApp';
import { CoupleSituation } from 'schemas/Quote';
import { CurrencyField } from 'components/Forms/Custom/CurrencyField';
import { DatePickerField } from 'components/Forms/Custom/DatepickerField';
import { Field } from 'components/Forms/Custom/Field';
import { FieldTemplate } from 'components/FormTemplate/Common/FieldTemplate';
// Components
import FormFooter from 'components/Layout/Footer/FormFooter';
import { Ghost } from 'components/Loading';
import InfoPanel from 'container/common/FormSidebar/InfoPanel';
// Types
import { JSONSchema7 } from 'json-schema';
import { ObjectFormTemplate } from 'components/FormTemplate/Common/ObjectFormTemplate';
import { Percentage } from 'components/Forms/Custom/InputPercentage';
import { PublicAppRoutes } from 'PublicApp';
import { RadioButtonWidget } from 'components/FormTemplate/Widgets/RadioButton';
import { Select } from 'components/FormTemplate/Widgets/Select';
import { SummaryPanel } from 'container/common';
import { TelField } from 'components/Forms/Custom/TelField';
import { TextAreaField } from 'components/Forms/Custom/TextAreaField';
import { UpDownWidget } from 'components/FormTemplate/Widgets/UpDown';
import { YPFormAnswers } from 'container/client/SingleForm/utils/clientFormData.types';
import { customFormats } from 'components/FormTemplate/CustomFormat/customFormat';
import { md } from 'theme/styles/mediaQueries';
import { scrollToTop } from 'utils/layout';
import styled from 'styled-components';
import { theme } from 'theme';
// Theme
import { transformErrors } from 'utils/errors';
import { useRouteMatch } from 'react-router';
import { useViewport } from 'hooks/useViewport';

type FormContextData =
  | Record<keyof typeof FormContextDataKeys | keyof Partial<AllFormsData>, any>
  | undefined;

export interface CustomFormProps
  extends Omit<FormProps<AllFormsData>, 'schema'> {
  schema: JSONSchema7[];
  uiSchema: UiSchema[];
  handleSubmit: (
    formData: AllFormsData,
    maxStep: boolean,
    step: number,
  ) => Promise<any>;
  submitAtStep?: boolean;
  customMaxStep?: number;
  lastTextSubmit: string;
  redirectLastStepDisabled?: () => void;
  loadingSubmit?: boolean;
  loading?: boolean;
  validate: (formData: AllFormsData, error: FormValidation) => FormValidation;
  data?: AllFormsData;
  targetStep?: number;
  comments?: FormComment;
  formContextData?: FormContextData;
  liveChange?: boolean;
  autoNavigate?: boolean;
  disableAutoNavigationAtStep?: number;
  showMobileStepBreadcrumb?: boolean;
}

export interface FormContext {
  currentStep: number;
  goToStep: Dispatch<SetStateAction<number>>;
  toggleInfoPanel: Dispatch<SetStateAction<boolean>>;
  setFieldInfoPanel: Dispatch<
    SetStateAction<keyof FormDataQuote | keyof YPFormAnswers | undefined>
  >;
  withInfoAllFormData: Record<keyof AllFormsData, boolean>;
  data?: FormContextData;
  isSubmitted: boolean;
  liveChange: boolean;
  disabledOptions: { [key: string]: string[] } | undefined;
}

export enum FormContextDataKeys {
  'c1',
  'c2',
  'PARTIE1_NOM',
  'PARTIE2_NOM',
  'PARTIE1_PRENOM',
  'PARTIE2_PRENOM',
  'PARTIE1_ADRESSE',
  'PARTIE1_CP',
  'PARTIE1_VILLE',
  'PARTIE2_ADRESSE',
  'PARTIE2_CP',
  'PARTIE2_VILLE',
}

export type FormType = 'CLIENT_FORM' | 'QUOTE_FORM';

export type AllFormsData = FormDataQuote & YPFormAnswers;

export type FormComment = Record<
  keyof AllFormsData,
  { id: string; label: string; step: number; comment: string }
>;

const Sidebar = styled(Flex)`
  max-height: 100%;
`;

const ContainerLoading = styled(Flex)`
  ${md(`
    width: calc(100vw - 373px);
    `)}
  height: calc(100vh
    - 140px);
`;

const BreadCrumb = styled.div`
  padding: 0 15px;
  margin-top: ${theme.spacing.space32};
`;

const StyledForm = styled(FormRJSF)`
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;

  & > :first-child {
    padding-bottom: 120px;
    width: 100%;
    max-width: 832px;

    ${md(`
      width: 80%;
      padding-bottom: 72px;
    `)}
  }

  ${md(`
    grid-template-columns: 1fr minmax(373px, 373px);
  `)}
` as ComponentType as new <T extends AllFormsData>() => FormRJSF<T>;

const fields = {
  CustomField: Field,
  CustomCurrency: CurrencyField,
  CustomPercentage: Percentage,
  CustomTextArea: TextAreaField,
  CustomDatepicker: DatePickerField,
  CustomTel: TelField,
};

const widgets = {
  CheckboxWidget,
  SelectWidget: Select,
  RadioWidget: RadioButtonWidget,
  UpDownWidget,
};

const withInfoAllFormData: any = {
  ...withInfoFormDataQuote,
  ...withInfoClientFormData,
};

const getQuoteStepName = (step: number, t: TFunction<'translation'>) => {
  if (step < 2) {
    return t('quote.steps.service');
  } else if (step < 3) {
    return t('quote.steps.residence');
  } else if (step < 4) {
    return t('quote.steps.children');
  } else if (step < 5) {
    return t('quote.steps.heritage');
  } else if (step < 6) {
    return t('quote.steps.formula');
  } else if (step < 7) {
    return t('quote.steps.options');
  }

  return '';
};

export const Form = (props: CustomFormProps) => {
  const {
    schema,
    uiSchema,
    handleSubmit,
    submitAtStep,
    customMaxStep,
    lastTextSubmit,
    loading,
    loadingSubmit,
    validate,
    data,
    comments,
    formContextData,
    liveChange = false,
    liveValidate,
    redirectLastStepDisabled,
    showMobileStepBreadcrumb,
  } = props;
  const formRef = useRef<FormRJSF<AllFormsData>>(null);
  const targetStep = useMemo(() => props.targetStep || 0, []);
  const { t } = useTranslation();
  const match = useRouteMatch();
  const [step, goToStep] = useState<number>(targetStep);
  const [tempFormData, setTempFormData] = useState<AllFormsData>();
  const [allFormData, setAllFormData] = useState<AllFormsData>();
  const [disabledSubmit, setDisabledSubmit] = useState<boolean>(liveChange);
  const [fieldInfoPanel, setFieldInfoPanel] = useState<keyof AllFormsData>();
  const [showInfoPanel, toggleInfoPanel] = useState<boolean>(false);
  const [validatedSteps, setValidatedSteps] = useState<number[]>([]);
  const disabled = useMemo(
    () => match.path !== AdminAppRoutes.CLIENT_FORM && props.disabled,
    [props.disabled],
  );
  const formType: FormType = useMemo(
    () => (match.path === ClientAppRoutes.FORM ? 'CLIENT_FORM' : 'QUOTE_FORM'),
    [props.disabled],
  );
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [currentSchema, setCurrentSchema] = useState<JSONSchema7>();
  const [currentUiSchema, setCurrentUiSchema] = useState<UiSchema>();
  const [formSchemas, setFormSchemas] = useState<JSONSchema7[]>(schema);
  const [formUiSchemas, setFormUiSchemas] = useState<UiSchema[]>(uiSchema);
  const [currentStepIsDirty, setCurrentStepIsDirty] = useState<boolean>(false);
  const [disabledOptions, setDisabledOptions] = useState<
    | {
        [key: string]: string[];
      }
    | undefined
  >(undefined);

  const { isMobile } = useViewport();
  const formContext = useMemo(
    () =>
      ({
        currentStep: step,
        goToStep,
        toggleInfoPanel,
        setFieldInfoPanel,
        withInfoAllFormData,
        data: formContextData,
        isSubmitted,
        liveChange,
        formType,
        disabledOptions: disabledOptions,
      } as FormContext),
    [step, formContextData, isSubmitted, disabledOptions],
  );

  useEffect(() => {
    if (schema) {
      setFormSchemas(schema);
    }
  }, [schema]);

  useEffect(() => {
    if (uiSchema) {
      setFormUiSchemas(uiSchema);
    }
  }, [uiSchema]);

  const maxStep = useMemo(() => {
    return formSchemas?.length - 1;
  }, [formSchemas]);

  useEffect(() => {
    if (tempFormData) {
      let hasChanged = false;
      const nextTempFormData: any = { ...tempFormData };

      Object.keys(tempFormData).forEach((key) => {
        if (key.includes('_COUNT')) {
          const count = tempFormData[key as keyof AllFormsData] as number;
          const fieldKey = key.replace('_COUNT', '') as keyof AllFormsData;
          const field = tempFormData[fieldKey] as any[];

          if (field && field.length !== count) {
            nextTempFormData[key as keyof AllFormsData] = field.length;
            hasChanged = true;
          }
        }
      });

      if (hasChanged) {
        setTempFormData(nextTempFormData);
      }
    }
  }, [tempFormData]);

  useEffect(() => {
    if (tempFormData && schema && schema.length > 0) {
      let nextFormSchemas = [...schema];
      let nextFormUiSchemas = [...uiSchema];

      schema.forEach((s, i) => {
        const nextSchema = schema[i + 1];

        if (nextSchema) {
          const currentPropertiesKeys = Object.keys(s.properties || {});
          const nextDependencies = nextSchema.dependencies;
          if (!nextDependencies) return;
          const nextDependenciesKeys = Object.keys(nextDependencies || {});

          nextDependenciesKeys.forEach((key) => {
            if (currentPropertiesKeys.includes(key)) {
              const nextSchemaDependencies = nextDependencies[
                key
              ] as JSONSchema7;

              if (nextSchemaDependencies.minItems !== undefined) {
                const field = tempFormData[key as keyof AllFormsData] as any[];

                if (!field || field.length < nextSchemaDependencies.minItems) {
                  nextFormSchemas = nextFormSchemas.slice(0, i + 1);
                  nextFormUiSchemas = nextFormUiSchemas.slice(0, i + 1);
                }
              }
            }
          });
        }
      });

      setFormSchemas(nextFormSchemas);
      setFormUiSchemas(nextFormUiSchemas);
    }
  }, [tempFormData, schema, step]);

  const nextText = useMemo(() => {
    if (step === maxStep) {
      return lastTextSubmit;
    } else {
      return match.path === AdminAppRoutes.CLIENT_FORM
        ? t('validate_and_next')
        : t(isMobile ? 'next_short' : 'next');
    }
  }, [step, maxStep, match, isMobile]);

  const stepName = useMemo(() => {
    return getQuoteStepName(step, t);
  }, [step]);

  useEffect(() => {
    if (formSchemas) setCurrentSchema(formSchemas[step]);
    if (formUiSchemas) setCurrentUiSchema(formUiSchemas[step]);
  }, [step, formSchemas, formUiSchemas]);

  useEffect(() => {
    setCurrentStepIsDirty(false);
  }, [step]);

  useEffect(() => {
    if (data) {
      setAllFormData({ ...formContextData, ...data, ...allFormData });
      setTempFormData({ ...formContextData, ...data, ...allFormData });

      disableSchemaOptions({ ...formContextData, ...data, ...allFormData });
    }
  }, [step, data, formContextData]);

  useEffect(() => {
    liveChange && checkSubmitDisabled(allFormData);
  }, [currentSchema, step, allFormData]);

  // Check if all required values to show or not submit button
  const checkSubmitDisabled = useCallback(
    (formData?: AllFormsData) => {
      // let hasErrors = false;
      // if (formData) {
      //   const validation = formRef?.current?.validate(formData, currentSchema);
      //   hasErrors = !!validation?.errors?.length;
      // }

      const requiredFields = currentSchema?.required as
        | [keyof FormDataQuote]
        | undefined;
      const disabled = !requiredFields?.every((el) => {
        if (el === 'properties') return formData && formData[el] !== null;
        if (el === 'region')
          return formData && JSON.stringify(formData[el]) !== '{}';
        if (el === 'plan')
          return formData && JSON.stringify(formData[el]) !== '{}';
        return formData && formData[el];
      });
      if (currentSchema) {
        setDisabledSubmit(requiredFields ? disabled : false);
      } else {
        setDisabledSubmit(true);
      }
    },
    [currentSchema, allFormData],
  );

  const findStepByKey = useCallback(
    (key: keyof AllFormsData): number => {
      const step = uiSchema?.findIndex((el: UiSchema) => el[key]);
      return step !== -1 ? step : 0;
    },
    [uiSchema],
  );

  const onSubmit = useCallback(
    (formData: AllFormsData) => {
      const hasMaxStep = step === maxStep;

      if (!disabled) {
        const newAllFormData = { ...allFormData, ...formData };
        setAllFormData(newAllFormData);
        setTempFormData(newAllFormData);

        !submitAtStep && newAllFormData
          ? handleSubmit(newAllFormData, hasMaxStep, step).then(() => {
              if (!hasMaxStep) {
                setIsSubmitted(false);
                navStep('next');
              }
            })
          : handleSubmit(formData, hasMaxStep, step).then(() => {
              if (!hasMaxStep) {
                setIsSubmitted(false);
                navStep('next');
              }
            });
      } else {
        if (!hasMaxStep) {
          navStep('next');
        } else if (redirectLastStepDisabled) {
          redirectLastStepDisabled();
        }
      }
    },
    [step, maxStep, currentSchema, tempFormData],
  );

  const navStep = useCallback(
    (direction: 'prev' | 'next') => {
      const hasMaxStep = step === maxStep;
      scrollToTop();
      toggleInfoPanel(false);

      if (direction === 'prev') {
        liveChange && analytics?.track('backToPreviousStep');
        return goToStep(step - 1);
      }
      if (direction === 'next' && !hasMaxStep) {
        setIsSubmitted(false);
        return goToStep(step + 1);
      }
    },
    [step],
  );

  const handleFooterNextStepClick = useCallback(() => {
    if (liveChange) {
      setIsSubmitted(true);

      // specific case for conditional sub-fields
      if (
        step === 3 &&
        allFormData?.coupleSituation === CoupleSituation.coupleWithChildren
      ) {
        return;
      }

      if (
        props?.autoNavigate &&
        allFormData &&
        !validatedSteps.includes(step) &&
        (props?.disableAutoNavigationAtStep
          ? step < props?.disableAutoNavigationAtStep
          : true)
      ) {
        // onSubmit(allFormData);
        setValidatedSteps((prev) => [...prev, step]);
      }
    }
  }, [
    liveChange,
    step,
    allFormData,
    onSubmit,
    validatedSteps,
    props?.autoNavigate,
  ]);

  const handleChange = ({ formData }: IChangeEvent<AllFormsData>) => {
    setCurrentStepIsDirty(true);
    setTempFormData({ ...allFormData, ...formData });
    liveChange && setAllFormData({ ...allFormData, ...formData });

    disableSchemaOptions(formData);
  };

  const disableSchemaOptions = (formData: AllFormsData) => {
    formData.BIEN?.forEach((bien, index) => {
      if (bien.WD_TYPE === 'voiture') {
        setDisabledOptions({ WD_ATTRIBUTION: ['communauté'] });
      } else {
        setDisabledOptions(undefined);
      }
    });
  };

  return (
    <>
      {loading || !currentSchema ? (
        <ContainerLoading
          justify="center"
          alignItems="center"
          direction={{ xs: 'column' }}
        >
          {[...Array(5)].map((_, index) => (
            <Flex
              key={`loading_form_${index}`}
              marginLeft={{ xs: 'space40' }}
              marginRight={{ xs: 'space40' }}
              marginBottom={{ xs: 'space40' }}
              width="80%"
            >
              <Ghost width="100%" height={56} shape="rect" rx={16} />
            </Flex>
          ))}
        </ContainerLoading>
      ) : (
        <>
          {stepName && showMobileStepBreadcrumb && (
            <MobileOnly>
              <BreadCrumb>
                <Text
                  content={`${stepName} (${step + 1}/${maxStep})`}
                  fontStyle="body3"
                  weight="medium"
                  color={theme.colors.green1}
                />
              </BreadCrumb>
            </MobileOnly>
          )}
          <StyledForm<AllFormsData>
            id="form" // don't change this. Use for submit button
            schema={currentSchema}
            uiSchema={currentUiSchema}
            ObjectFieldTemplate={ObjectFormTemplate}
            ArrayFieldTemplate={ArrayFormTemplate}
            FieldTemplate={FieldTemplate}
            fields={fields}
            widgets={widgets}
            noHtml5Validate
            showErrorList={false}
            omitExtraData
            liveOmit={liveChange}
            liveValidate={liveValidate ?? liveChange}
            onSubmit={({ formData }) => {
              onSubmit(formData);
            }}
            onChange={handleChange}
            formContext={formContext}
            formData={{ ...formContextData, ...tempFormData } as any}
            transformErrors={transformErrors}
            customFormats={customFormats}
            validate={validate}
            disabled={disabled}
            ref={formRef}
          >
            <></>
            {/* use for hide default submit from rjsf */}
          </StyledForm>
        </>
      )}
      <Sidebar>
        <SummaryPanel
          formData={allFormData}
          goToStep={goToStep}
          goToStepDisabled={disabledSubmit}
          disabled={!!disabled}
          comments={comments}
          findStepByKey={findStepByKey}
        />
        <InfoPanel
          isShow={showInfoPanel}
          fieldName={fieldInfoPanel}
          onClose={() => toggleInfoPanel(false)}
        />
      </Sidebar>
      <FormFooter
        prevText={t('back')}
        nextText={nextText}
        step={step}
        maxStep={maxStep}
        progressMaxStep={customMaxStep}
        prev={() => navStep('prev')}
        next={handleFooterNextStepClick}
        disabledPrev={step === 0}
        disabledNext={!disabled && disabledSubmit}
        $loading={loadingSubmit}
        nextPrimary={!disabled}
        autoNavigate={props?.autoNavigate}
        disableAutoNavigationAtStep={props?.disableAutoNavigationAtStep}
        minimizeButtonsSpacing={
          isMobile && step === maxStep && match.path === PublicAppRoutes.QUOTE
        }
        isDirty={currentStepIsDirty}
      />
    </>
  );
};
