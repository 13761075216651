import { FC } from 'react';
import styled from 'styled-components';

// Components
import { Flex } from 'components/Layout/Flex';

// Assets
import defaultImage from 'assets/images/avatar.png';

import { theme } from 'theme';
import { useViewport } from 'hooks/useViewport';
import { lg } from 'theme/styles/mediaQueries';
import { Text } from 'components/Layout';

// Styles
const InfoWrapper = styled(Flex)`
  padding: 0 15px;
  justify-content: center;
  flex-direction: column;

  ${lg(`
    padding: 0;
    justify-content: flex-start;
    flex-direction: row;
  `)}
`;

const Image = styled.img`
  width: ${theme.spacing.space32};
  height: ${theme.spacing.space32};
  border-radius: 50%;

  ${lg(`
    width: ${theme.spacing.space48};
    height: ${theme.spacing.space48};
  `)}
`;

const ImageWrapper = styled.div`
  position: relative;
  height: ${theme.spacing.space32};
  margin-left: initial;

  ${lg(`
    height: ${theme.spacing.space48};
  `)}
`;

const Dot = styled.div`
  background: ${theme.colors.green3};
  width: 9px;
  height: 9px;
  position: absolute;
  bottom: 0px;
  border: 1px solid #fff;
  border-radius: 100%;

  ${lg(`
    bottom: 4.8px;
  `)}
`;

const Description = styled.div`
  color: ${theme.colors.gray6};
  font-size: ${theme.font.body2.fontSize};
  line-height: ${theme.font.body2.lineHeight};
  max-width: 624px;

  ${lg(`
    font-size: ${theme.font.body1.fontSize};
    line-height: ${theme.font.body1.lineHeight};
  `)}

  strong {
    color: ${theme.colors.black};
    font-size: ${theme.font.body1.fontSize};
    line-height: ${theme.font.body1.lineHeight};
    font-weight: ${theme.weight.medium};

    ${lg(`
      font-size: ${theme.font.body1.fontSize};
      line-height: ${theme.font.body1.lineHeight};
      font-weight: ${theme.weight.bold};
    `)}
  }
`;

// Interface
export interface InfoBlockProps {
  message: string;
  required?: boolean;
  description?: string;
  displayImage?: boolean;
  displayTitle?: boolean;
  showRequired?: boolean;
}

export const Info: FC<InfoBlockProps> = ({
  message,
  description,
  displayTitle = true,
  displayImage = false,
  showRequired = false,
}) => {
  const { isMobile } = useViewport();

  return (
    <>
      <InfoWrapper>
        {displayImage && (
          <Flex
            alignItems="center"
            marginBottom={{ xs: 'space16', md: 'auto' }}
          >
            <ImageWrapper>
              <Image src={defaultImage} alt="Profile" />
              <Dot />
            </ImageWrapper>
            {displayTitle && isMobile && (
              <Text
                content={message}
                fontStyle="heading5"
                weight="bold"
                marginLeft={{ xs: 'space16' }}
              />
            )}
          </Flex>
        )}
        {displayTitle && (
          <Flex
            direction={{ xs: 'column' }}
            {...(displayImage &&
              !isMobile && { marginLeft: { lg: 'space32' } })}
          >
            {!isMobile && (
              <Text
                content={message}
                fontStyle="heading5"
                weight="bold"
                marginBottom={{ xs: 'space8' }}
              />
            )}
            {description && (
              <Description
                dangerouslySetInnerHTML={{
                  __html: description,
                }}
              />
            )}
            {showRequired && (
              <Description
                dangerouslySetInnerHTML={{
                  __html: `<span style="color:red">*</span> champs obligatoires`,
                }}
              />
            )}
          </Flex>
        )}
      </InfoWrapper>
    </>
  );
};
