import { FC } from 'react';
import { useTranslation } from 'react-i18next';

// Container
import { CustomersList, CustomersListFilters } from 'container/admin';

// Components
import { Flex } from 'components/Layout/Flex';
import { Text } from 'components/Layout/Text';
import { Button } from '../../components/Buttons';
import { AdminAppRoutes } from '../../AdminApp';
import { useHistory } from 'react-router-dom';
import { useQueryParams } from '../../hooks/useQueryParams';
import { useApi } from '../../hooks/useApi';
import { Customer } from '../../types/resources';

const Customers: FC = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const query = useQueryParams();
  const search = query.get('search');

  const {
    execute: getCustomers,
    state: { data },
  } = useApi<Customer[]>('/customers');
  const customers = data?.value ?? [];
  const total = data?.pagination?.total ?? 0;

  return (
    <>
      <Flex justify="between">
        <Text
          content={`${t('customer.customers')} (${total})`}
          fontStyle="heading2"
        />
        <Button
          content={t('customer.add.new')}
          iconLeft={{ name: 'Plus' }}
          onClick={() => history.push(AdminAppRoutes.CUSTOMERS_ADD)}
        />
      </Flex>
      <CustomersListFilters />
      <Flex marginTop={{ xs: 'space32' }}>
        <CustomersList
          search={search}
          pageSize={10}
          fetch={getCustomers}
          items={customers}
          total={total}
        />
      </Flex>
    </>
  );
};

export default Customers;
