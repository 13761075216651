import { UiSchemaCustom } from '.';

const adultChildren: UiSchemaCustom[] = [
  {
    'ui:order': [
      'NOM',
      'PRENOM',
      'SEXE',
      'DATE_NAISSANCE',
      'LIEU_NAISSANCE',
      'NATIONALITE',
      'WD_PAYS_NAISSANCE',
      'DPT',
      'ENCORE_A_CHARGE',
      'WD_PARTAGE_FRAIS_PREVU',
      'WD_PARTAGE_FRAIS_PREVU_PRECISIONS',
    ],
    NOM: {
      'ui:field': 'CustomField',
      'ui:placeholder': 'Dupont',
      'ui:options': {
        uppercase: true,
      },
    },
    PRENOM: {
      'ui:field': 'CustomField',
      'ui:placeholder': 'Jean, Paul, Jean-Pierre',
      'ui:options': {
        uppercase: true,
      },
    },
    SEXE: {
      'ui:widget': 'radio',
    },
    DATE_NAISSANCE: {
      'ui:field': 'CustomDatepicker',
      'ui:placeholder': 'Choisir une date',
      'ui:options': {
        maxToday: true,
        openTo: 'year',
        view: ['year', 'month', 'day'],
      },
    },
    LIEU_NAISSANCE: {
      'ui:field': 'CustomField',
      'ui:placeholder': 'Paris',
      'ui:options': {
        uppercase: true,
      },
    },
    DPT: {
      'ui:widget': 'select',
      'ui:placeholder': 'Choisir un département',
    },
    NATIONALITE: {
      'ui:widget': 'select',
      'ui:placeholder': 'Choisir une nationalité',
    },
    WD_PAYS_NAISSANCE: {
      'ui:widget': 'select',
      'ui:placeholder': 'Choisir un pays',
    },
    ENCORE_A_CHARGE: {
      'ui:widget': 'radio',
    },
    WD_PARTAGE_FRAIS_PREVU: {
      'ui:widget': 'radio',
    },
    WD_PARTAGE_FRAIS_PREVU_PRECISIONS: {
      'ui:field': 'CustomTextArea',
      'ui:placeholder': 'Précisions supplémentaires...',
    },
  },
];

export default adultChildren;
