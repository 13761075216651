import { FC } from 'react';
import { FieldTemplateProps } from '@rjsf/core';
import get from 'lodash/get';

import { Text } from 'components/Layout/Text';
import { Flex } from 'components/Layout/Flex';

export const FieldTemplate: FC<FieldTemplateProps> = ({
  help,
  errors,
  children,
  formContext,
}) => {
  const errorsMessage: string[] = get(errors, 'props.errors', []);
  const canShowError =
    (!formContext.liveChange || formContext.isSubmitted) &&
    errorsMessage.length > 0;

  return (
    <Flex direction={{ xs: 'column' }} width="100%" id="fieldTemplate">
      {children}
      {canShowError &&
        errorsMessage.map((error) => (
          <Text
            key={error}
            content={error}
            fontStyle="body2"
            color="red"
            marginTop={{ xs: 'space16' }}
          />
        ))}
      {help}
    </Flex>
  );
};
