import { ChangeEvent, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';

// Components
import { Flex } from 'components/Layout/Flex';

// data

// Utils
import { useQueryParams } from 'hooks/useQueryParams';
import { toggleSearchParams } from 'utils/search';
import InputSelect from '../../../components/FormTemplate/Fields/InputSelect';
import { NotificationStatus, NotificationType } from '../../../types/resources';
import { InputText } from '../../../components/FormTemplate/Fields/InputText';
import { theme } from '../../../theme';

const NotificationsListFilters: FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const query = useQueryParams();
  const searchParams = new URLSearchParams(location.search);
  const statusOptions = Object.entries(NotificationStatus).map(
    ([_, value]) => ({
      value,
      label: t(`notification.statuses.${value}`),
    }),
  );
  const typeOptions = Object.entries(NotificationType).map(([_, value]) => ({
    value,
    label: t(`notification.types.${value}`),
  }));

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    toggleSearchParams('search', e?.target.value, searchParams);
    history.replace({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  };

  const handleStatusChange = (val: NotificationStatus) => {
    toggleSearchParams('status', val, searchParams);
    history.replace({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  };

  const handleTypeChange = (val: NotificationType) => {
    toggleSearchParams('type', val, searchParams);
    history.replace({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  };

  const search = query.get('search') || '';
  const TypeValue = query.get('type') || '';
  const StatusValue = query.get('status') || '';

  return (
    <Flex marginTop={{ xs: 'space40' }} justify="start">
      <Flex>
        <InputText
          onChange={handleSearchChange}
          label={t('filter.search')}
          labelUppercase={true}
          labelFontStyle="label"
          placeholder={t('filter.search_notification')}
          width="288px"
          labelWeight="bold"
          $size="small"
          value={search && search !== '' ? search : ''}
          icon={{ name: 'Search', primaryColor: theme.colors.salmon1 }}
        />
      </Flex>
      <Flex marginLeft={{ xs: 'space32' }}>
        <InputSelect
          onChange={handleTypeChange}
          options={typeOptions}
          label={t('notification.type')}
          labelWeight="bold"
          labelUppercase={true}
          placeholder={t('notification.filters.search_by_type')}
          width="256px"
          size="small"
          isClearable
          value={TypeValue && TypeValue !== '' ? TypeValue : ''}
        />
      </Flex>

      <Flex marginLeft={{ xs: 'space32' }}>
        <InputSelect
          onChange={handleStatusChange}
          options={statusOptions}
          label={t('notification.type')}
          labelWeight="bold"
          labelUppercase={true}
          placeholder={t('notification.filters.search_by_status')}
          width="256px"
          size="small"
          isClearable
          value={StatusValue && StatusValue !== '' ? StatusValue : ''}
        />
      </Flex>
    </Flex>
  );
};

export default NotificationsListFilters;
