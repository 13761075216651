import { Role } from './resources';

export enum Views {
  Global = 'global',
  Forms = 'forms',
  Documents = 'documents',
  Verify = 'verify',
  ConventionWriting = 'convention-writing',
  Convention = 'convention',
  Recommended = 'recommended',
  Appointment = 'appointment',
  AdministrativeDocuments = 'administrative-documents',
  Notary = 'notary',
  Civil = 'civil',
  FinalSend = 'final-send',
}

export interface View {
  name: Views;
  disabled: boolean;
  roles: Role[];
}
