import { FC } from 'react';
import styled from 'styled-components';
import { theme } from 'theme';

const Input = styled.input<InputNumberSoloProps>`
  width: 58px;
  height: ${theme.spacing.space64};
  border: 1px solid ${theme.colors.gray4};
  border-radius: ${theme.spacing.space16};
  font-size: ${theme.font.body1.fontSize};
  color: ${(props) =>
    props.value > 0 ? theme.colors.black : theme.colors.gray5};
  text-align: center;

  &:active,
  &:focus {
    outline: none;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }
`;

interface InputNumberSoloProps {
  value: number;
}

export const InputOneNumber: FC<InputNumberSoloProps> = ({ value }) => {
  return <Input type="number" value={value} readOnly />;
};
