import { FC } from 'react';
import styled from 'styled-components';
import { theme } from 'theme';
import { getStepPercentage } from 'utils/math';

const Container = styled.div`
  width: 100%;
`;

const Bar = styled.div<BarProps>`
  width: ${(props) => `${props.value}%`};
  height: 8px;
  background-color: ${theme.colors.green1};
  border-radius: 0 2px 2px 0;
  transition: 0.5s width ease-in-out;
`;

interface BarProps {
  value: number;
}

export interface ProgressBarProps {
  currentStep: number;
  totalStep: number;
}

export const ProgressBar: FC<ProgressBarProps> = ({
  currentStep,
  totalStep,
}) => {
  const percentage = getStepPercentage(currentStep, totalStep);
  return (
    <Container>
      <Bar value={!isNaN(percentage) ? percentage : 0} />
    </Container>
  );
};
