import { CSSProperties, FC } from 'react';
import styled from 'styled-components';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers';
import { InputStyle } from 'components/FormTemplate/Fields/Input';
import { Flex } from 'components/Layout';
import { theme } from 'theme';

interface DateInputProps
  extends Omit<DatePickerProps<Date, Date>, 'renderInput'> {
  placeholder?: string;
  width?: string;
  height?: string;
  inputStyle?: CSSProperties;
}

const InputWrapper = styled(Flex)`
  .MuiInputAdornment-root {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: auto;
    width: 40px;
    height: 40px;
    max-height: 40px;
    margin: 0px;
    right: 5px;
    display: block;
  }
`;

const Input = styled.input<{ error?: boolean }>`
  ${InputStyle}
  position: absolute;
  padding-right: 50px;
  ${({ error }) =>
    error &&
    `
    border-color: ${theme.colors.red1} !important;
  `}
`;

const DateInput: FC<DateInputProps> = (props) => {
  const { inputStyle, ...rest } = props;
  const width = props.width ?? '100%';
  const height = props.height ?? '64px';

  return (
    <DatePicker
      {...rest}
      renderInput={({ inputRef, inputProps, InputProps, error }) => (
        <InputWrapper alignItems="center" width={width} height={height}>
          <Input
            ref={inputRef}
            {...inputProps}
            error={error}
            style={{ ...inputStyle, height }}
            placeholder={props.placeholder || 'jj/mm/aaaa'}
          />
          {InputProps?.endAdornment}
        </InputWrapper>
      )}
      inputFormat={props.inputFormat || 'dd/MM/yyyy'}
      mask={props.mask || '__/__/____'}
      views={props.views || ['day', 'month', 'year']}
    />
  );
};

export default DateInput;
