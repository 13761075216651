import { FC } from 'react';
import { Flex } from 'components/Layout';

import { Button } from 'components/Buttons';
import { InputText } from 'components/FormTemplate/Fields/InputText';
import { regexes } from 'utils/regex';
import styled from 'styled-components';
import { theme } from 'theme';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../hooks/useAuth';

type PasswordResetInput = {
  email: string;
};

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.space16};
`;

type ForgottenPasswordFormProps = {
  onSuccess: () => void;
};

const ForgottenPasswordForm: FC<ForgottenPasswordFormProps> = ({
  onSuccess,
}) => {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<PasswordResetInput>();
  const { passwordReset, isLoading } = useAuth();

  const onSubmit = async (data: PasswordResetInput) => {
    if (data) {
      passwordReset({ ...data, onSuccess });
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <InputText
        {...register('email', {
          required: t('error.form.required').toString(),
          pattern: {
            value: regexes.email,
            message: t('error.form.email').toString(),
          },
        })}
        error={errors.email}
        labelFontStyle="body1"
        labelWeight="medium"
        placeholder={t('forgottenPassword.form.email_placeholder')}
        width="100%"
        required
      />
      <Flex marginTop={{ xs: 'space16' }}>
        <Button
          content={t('forgottenPassword.form.submit')}
          type="submit"
          $loading={isLoading}
          fullWidth
          iconLeft={{ name: 'ArrowRightSquare' }}
          primary
        />
      </Flex>
    </Form>
  );
};

export default ForgottenPasswordForm;
