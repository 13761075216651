import { ChangeEvent, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';

// Components
import { Flex } from 'components/Layout/Flex';
import { InputText } from 'components/FormTemplate/Fields/InputText';

// Utils
import { theme } from 'theme';
import { useQueryParams } from 'hooks/useQueryParams';
import { toggleSearchParams } from 'utils/search';
import InputSelect from 'components/FormTemplate/Fields/InputSelect';
import { ProductType } from '../../../types/resources';

const ProductsListFilter: FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const query = useQueryParams();
  const searchParams = new URLSearchParams(location.search);
  const typeOptions = [
    {
      value: 'PRODUCT',
      label: t(`product.types.product`),
    },
    {
      value: 'OPTION',
      label: t(`product.types.option`),
    },
    {
      value: 'PLAN',
      label: t(`product.types.plan`),
    },
  ];

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    toggleSearchParams('search', e?.target.value, searchParams);
    history.replace({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  };

  const handleTypeChange = (val: ProductType) => {
    toggleSearchParams('type', val, searchParams);
    history.replace({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  };

  const search = query.get('search') || '';
  const type = query.get('type') || '';

  return (
    <Flex marginTop={{ xs: 'space40' }} justify="start">
      <Flex>
        <InputText
          onChange={handleSearchChange}
          label={t('filter.search')}
          labelUppercase={true}
          labelFontStyle="label"
          placeholder={t('filter.search_product')}
          width="288px"
          labelWeight="bold"
          $size="small"
          value={search && search !== '' ? search : ''}
          icon={{ name: 'Search', primaryColor: theme.colors.salmon1 }}
        />
      </Flex>
      <Flex marginLeft={{ xs: 'space32' }}>
        <InputSelect
          onChange={handleTypeChange}
          options={typeOptions}
          label={t('product.type')}
          labelWeight="bold"
          labelUppercase={true}
          placeholder={t('filter.search_by_type')}
          width="256px"
          size="small"
          isClearable
          value={type && type !== '' ? type : ''}
        />
      </Flex>
    </Flex>
  );
};

export default ProductsListFilter;
