import { ChangeEventHandler, FC } from 'react';
import { FieldProps } from '@rjsf/core';
import get from 'lodash/get';

import RequiredField from 'components/Forms/Utils/RequiredField';
import { TextArea } from 'components/FormTemplate/Fields/TextArea';
import { Flex } from 'components/Layout/Flex';
import { Label } from 'components/Text/Label';
import { replaceLabelWithContext } from '../Utils/ReplaceLabel';

export const TextAreaField: FC<FieldProps> = ({
  id,
  required,
  readonly,
  disabled,
  schema,
  uiSchema,
  onChange,
  value,
  formData,
  formContext,
}) => {
  const title = get(schema, 'title', '');
  const label = replaceLabelWithContext(title, formContext.data);
  const placeholder = get(uiSchema, 'ui:placeholder', null);

  const _onChange: ChangeEventHandler<HTMLTextAreaElement> = ({ target }) =>
    onChange(target.value);

  return (
    <Flex direction={{ xs: 'column' }}>
      {label && (
        <Flex
          alignItems="center"
          marginBottom={{ xs: 'space16', md: 'space24' }}
        >
          <RequiredField required={required}>
            <Label dangerouslySetInnerHTML={{ __html: label }} />
          </RequiredField>
        </Flex>
      )}
      <TextArea
        id={id}
        placeholder={placeholder}
        required={required}
        disabled={disabled || readonly}
        type="textarea"
        value={value && value !== '' ? value : formData}
        onChange={_onChange}
        width="100%"
      />
    </Flex>
  );
};
