import { FC } from 'react';

// Components
import { Container } from 'components/Layout';

// Containers
import { AdministrativeDocumentsList } from 'container/client';

const ClientDocuments: FC = () => (
  <Container>
    <AdministrativeDocumentsList />
  </Container>
);

export default ClientDocuments;
