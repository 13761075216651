import { FC, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { theme } from 'theme';

// Components
import { Text } from 'components/Layout/Text';
import { Flex } from 'components/Layout/Flex';
import { IconButton } from 'components/Buttons/IconButton';
import { InlineButton, OpportunityStatusButton } from './Buttons';

import { getProgressPercentage } from 'utils/math';
import { useTranslation } from 'react-i18next';
import { getTypeByStatus } from 'utils/status';
import { useViewport } from 'hooks/useViewport';
import { format, parseISO, isValid } from 'date-fns';
import { ProcedureStepStatus } from '../types/resources';

interface CustomFlexProps {
  currentStep: number;
}

const Wrapper = styled.div`
  position: relative;
  width: calc(100% + ${theme.spacing.space24});
  height: auto;
  overflow: hidden;
`;

const CustomFlex = styled(Flex)<CustomFlexProps>`
  position: relative;
  transform: translateX(
    ${({ currentStep }) => currentStep * -178}px
  ); // Same value as the min-width
  transition: transform 0.5s ease;
`;

const CardContainer = styled(Flex)<CardContainerProps>`
  position: relative;
  width: 100%;
  min-width: 178px;
  height: auto;
  margin: 0 ${theme.spacing.space16} 0 0;
  padding: ${theme.spacing.space16};
  border-radius: ${theme.spacing.space16};
  background-color: ${({ active, isDone }) =>
    active && !isDone ? theme.colors.beige : 'transparent'};
  border: ${({ isDone }) =>
    isDone ? `1px solid ${theme.colors.gray3}` : '1px solid transparent'};
`;

const Circle = styled.span<CircleProps>`
  position: absolute;
  top: ${(props) => (props.isLast ? '-10px' : theme.spacing.space16)};
  left: ${(props) => (props.isLast ? '' : theme.spacing.space16)};
  right: ${(props) => (props.isLast ? 0 : '')};
  width: ${theme.spacing.space16};
  height: ${theme.spacing.space16};
  border-radius: 50%;
  background-color: ${({ isGreen }) =>
    isGreen ? theme.colors.green1 : theme.colors.gray4};
  z-index: 2;
`;

const IconContainer = styled(Flex)`
  position: absolute;
  top: 7px;
  z-index: 3;
`;

const Progress = styled.div<ProgressProps>`
  position: absolute;
  top: 22px;
  left: 18px;
  width: ${(props) => (props.isLastItem ? '0%' : '110%')};
  height: 4px;
  background-color: ${theme.colors.gray4};
  z-index: 2;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: ${(props) => `${props.value}%`};
    height: 4px;
    background-color: ${theme.colors.green1};
    z-index: 1;
  }
`;

const CustomLeftButton = styled(IconButton)`
  position: absolute;
  left: ${theme.spacing.none};
  z-index: 2;
  top: 5px;
`;
const CustomRightButton = styled(IconButton)`
  position: absolute;
  right: ${theme.spacing.space24};
  z-index: 2;
  top: 5px;
`;

interface CircleProps {
  isGreen: boolean;
  isLast?: boolean;
}

interface CardContainerProps {
  active: boolean;
  isDone: boolean;
}

interface ProgressProps {
  value: number;
  isLastItem: boolean;
}

export interface Step {
  title: string;
  active: boolean;
  status?: ProcedureStepStatus;
  displayedStatus: string;
  isDone: boolean;
  onClick?: () => void;
  hideButton?: boolean;
  updated_at?: string | null;
}

export interface StepperProps {
  steps: Step[];
  defaultStep?: number;
  isClient?: boolean;
}

export const Stepper: FC<StepperProps> = ({
  steps,
  defaultStep = 0,
  isClient = true,
}) => {
  const { t } = useTranslation('');
  const { width } = useViewport();

  const [currentStep, setCurrentStep] = useState<number>(
    defaultStep > 0 ? defaultStep - 1 : defaultStep,
  );
  const [maxStep, setMaxStep] = useState<number>(steps.length - 1);
  const containerElement = useRef<HTMLElement>(null);

  // Calculating the maxSteps that can be displayed on the screen.
  useEffect(() => {
    const containerWidth = containerElement.current?.offsetWidth;
    const stepElementWidth = 178; // Same value as the min-width
    const stepsNumber = steps.length;

    if (containerWidth && stepElementWidth && stepsNumber) {
      const stepsOnScreen = Math.floor(containerWidth / stepElementWidth);
      setMaxStep(stepsNumber - stepsOnScreen + 1);
    }
  }, [containerElement, width]);

  const onNext = () => {
    if (currentStep < maxStep) {
      setCurrentStep(currentStep + 1);
    }
  };
  const onPrev = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  return (
    <Wrapper ref={containerElement as any}>
      {currentStep > 0 && (
        <CustomLeftButton
          color={theme.colors.white}
          backgroundColor={theme.colors.green1}
          hoverIconColor={theme.colors.green1}
          hoverBackgroundColor={theme.colors.green4}
          iconName="ArrowLeft"
          rounded
          onClick={onPrev}
        />
      )}
      {currentStep < maxStep && (
        <CustomRightButton
          color={theme.colors.white}
          backgroundColor={theme.colors.green1}
          hoverIconColor={theme.colors.green1}
          hoverBackgroundColor={theme.colors.green4}
          iconName="ArrowRight"
          rounded
          onClick={onNext}
        />
      )}
      <CustomFlex currentStep={currentStep}>
        {steps &&
          steps.map((step, index) => {
            const {
              title,
              active,
              onClick,
              isDone,
              hideButton,
              status,
              displayedStatus,
              updated_at,
            } = step;
            const textColor = active ? theme.colors.black : theme.colors.gray6;
            const nextIsActive =
              index !== steps.length - 1 && steps[index + 1].active;
            const percentage = getProgressPercentage(
              active,
              isDone,
              nextIsActive,
            );
            const parsedDate = updated_at ? parseISO(updated_at) : undefined;
            return (
              <CardContainer
                key={`stepper-${index}`}
                active={active}
                isDone={isDone}
                direction={{ xs: 'column' }}
                justify="end"
              >
                <Progress
                  value={percentage}
                  isLastItem={index === steps.length - 1}
                />
                {isDone ? (
                  <IconContainer>
                    <IconButton
                      iconName="ShieldDone"
                      color={theme.colors.white}
                      backgroundColor={theme.colors.green1}
                      rounded
                      size="small"
                    />
                  </IconContainer>
                ) : (
                  <Circle isGreen={active || isDone} />
                )}
                <Text
                  content={title}
                  fontStyle="body2"
                  weight="bold"
                  color={textColor}
                  marginTop={{ xs: 'space32' }}
                  marginBottom={{ xs: 'auto' }}
                />
                {active && (
                  <>
                    {!isClient && updated_at && isValid(parsedDate) && (
                      <Text
                        content={format(
                          parseISO(updated_at),
                          'dd/MM/yy à k:mm',
                        )}
                        fontStyle="body3"
                        color={theme.colors.green1}
                        marginTop={{ xs: 'space16' }}
                      />
                    )}
                    <Flex
                      marginTop={{ xs: 'space16' }}
                      marginBottom={{
                        xs: !isClient
                          ? 'none'
                          : hideButton
                          ? 'space40'
                          : 'space8',
                      }}
                    >
                      <OpportunityStatusButton
                        type={getTypeByStatus(status)}
                        text={displayedStatus}
                      />
                    </Flex>
                    {isClient && !hideButton && (
                      <InlineButton
                        iconName="ArrowRight"
                        iconColor={theme.colors.salmon2}
                        backgroundColor={theme.colors.salmon3}
                        text={t('opportunity.consult')}
                        onClick={onClick}
                        hoverBackgroundColor={theme.colors.salmon2}
                        hoverIconColor={theme.colors.white}
                      />
                    )}
                  </>
                )}
              </CardContainer>
            );
          })}
      </CustomFlex>
    </Wrapper>
  );
};
