import { format, parseISO } from 'date-fns';
import i18next from 'i18next';
import { OptionTypeBase } from 'react-select/src/types';
import { Address, DocumentFull, FormFull } from '../types/resources';

export const formatInputSelect = (
  object: Record<string, string>,
): OptionTypeBase[] => {
  return Object.keys(object).reduce((arr: OptionTypeBase[], key) => {
    if (object[key]) {
      arr.push({
        value: key,
        label: i18next.t(`enums:${key}`),
      });
    }
    return arr;
  }, []);
};

export const sortOptionsAlphabetically = (
  options: OptionTypeBase[],
): OptionTypeBase[] => {
  return options.sort((a, b) => {
    return a.label > b.label ? 1 : -1;
  });
};

export const formatPrice = (price: number): string => {
  if (price % 1 === 0) return `${price}`;
  return price.toFixed(2).replace('.', ',');
};

export const formatAddress = (address?: Address | null) => {
  if (!address) return '';
  return `${address.street} ${address.post_code} ${address.city}`;
};

export const formatHour = (date: string) => {
  return format(parseISO(date), 'HH:mm');
};

export function formatDocumentTitle(document: DocumentFull) {
  const concerned_spouse_fullname = document.concerned_spouse?.full_name;
  const suffix = concerned_spouse_fullname
    ? ` (${concerned_spouse_fullname})`
    : '';

  return `${document.title}${suffix}`;
}

export function formatFormTitle(form: FormFull) {
  const concerned_spouse_fullname = form.concerned_spouse?.full_name;
  const suffix = concerned_spouse_fullname
    ? ` (${concerned_spouse_fullname})`
    : '';

  return `${form.name}${suffix}`;
}
