import { FC, useEffect } from 'react';
import {
  Consent,
  ProcedureFull,
  ProcedureSpouseFull,
} from '../../../types/resources';
import RegisteredLettersBloc from './RegisteredLettersBloc';
import ConsentBloc from './ConsentBloc';
import { Flex } from '../../../components/Layout';
import { theme } from '../../../theme';
import { useApi } from '../../../hooks/useApi';

type RegisteredLettersProps = {
  procedure: ProcedureFull;
  spouses: ProcedureSpouseFull[];
  refetchProcedure: () => void;
};

const RegisteredLetters: FC<RegisteredLettersProps> = ({
  procedure,
  spouses,
  refetchProcedure,
}) => {
  const { execute: getConsents, state: getConsentsState } = useApi<Consent[]>(
    `/procedures/${procedure.id}/consents`,
    { method: 'GET' },
  );
  const consents = getConsentsState.data?.value ?? [];

  useEffect(() => {
    getConsents();
  }, []);

  return (
    <Flex
      direction={{ xs: 'column' }}
      style={{ rowGap: theme.spacing.space24 }}
    >
      <ConsentBloc
        procedure={procedure}
        spouses={spouses}
        consents={consents}
        refetchConsents={getConsents}
        refetchProcedure={refetchProcedure}
      />
      <RegisteredLettersBloc
        procedure={procedure}
        consents={consents}
        spouses={spouses}
        refetchProcedure={refetchProcedure}
      />
    </Flex>
  );
};

export default RegisteredLetters;
