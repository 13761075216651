import { FC, useEffect, useState } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import { Flex, Text } from '../Layout';
import { Iconly } from 'react-iconly';
import { theme } from '../../theme';
import { Trans, useTranslation } from 'react-i18next';
import { humanFileSize } from '../../utils/math';
import { IconButton } from '../Buttons';
import { compressFiles } from '../../utils/images';

type ContainerProps = {
  isFocused: boolean;
  isDragAccept: boolean;
  isDragReject: boolean;
};

const getColor = (props: ContainerProps) => {
  if (props.isDragAccept) {
    return '#00e676';
  }
  if (props.isDragReject) {
    return '#ff1744';
  }
  if (props.isFocused) {
    return '#2196f3';
  }

  return '#eeeeee';
};

const Container = styled.div`
  flex: 1;
  display: flex;
  height: 100%;
  flex-direction: column;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${(props: ContainerProps) => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
  overflow-y: auto;

  :hover {
    svg {
      color: #2196f3;
      transition: color 0.24s ease-in-out;
    }
  }
`;

const Input = styled.input``;

const DEFAULT_MAX_SIZE = 10 * 1000 * 1000; // 10 Mo
const DEFAULT_MAX_FILES = 20;
const COMPRESSION_OPTIONS = {
  quality: 0.7,
  maxWidth: 1600,
};

type FileUploaderProps = {
  hide: boolean;
};

const FileUploader: FC<FileUploaderProps> = ({ hide = false }) => {
  return hide ? null : (
    <Flex
      style={{
        height: '100%',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Iconly stroke="regular" size={58} name="Paper" />
      <Text
        style={{ margin: theme.spacing.space24 }}
        textAlign={'center'}
        fontStyle={'body2'}
      >
        <Trans i18nKey="files.uploader.hint" />
      </Text>
    </Flex>
  );
};

type FileAcceptedProps = {
  file: File;
  onRemove: (file: File) => void;
};

const FileAccepted: FC<FileAcceptedProps> = ({ file, onRemove }) => {
  const fileSize = humanFileSize(file.size);

  return (
    <Flex
      alignItems={'center'}
      marginBottom={{ xs: 'space4' }}
      style={{
        padding: theme.spacing.space8,
        backgroundColor: theme.colors.salmon1,
        borderRadius: '8px',
      }}
    >
      <Text
        weight={'bold'}
        fontStyle={'body3'}
        color={theme.colors.white}
        content={file.name}
        style={{ flex: 1 }}
      />
      <Text fontStyle={'body3'} color={theme.colors.white} content={fileSize} />
      <IconButton
        style={{ padding: 0 }}
        size={'xs'}
        iconName={'Delete'}
        color={'white'}
        backgroundColor={'transparent'}
        onClick={(event) => {
          event.stopPropagation();
          onRemove(file);
        }}
      />
    </Flex>
  );
};

type FilesAcceptedProps = {
  files: File[];
  hide?: boolean;
  limit: number;
  onRemove: (file: File) => void;
};

const FilesAccepted: FC<FilesAcceptedProps> = ({
  files,
  hide,
  limit,
  onRemove,
}) => {
  const { t } = useTranslation();

  return files.length === 0 || hide ? null : (
    <div style={{ marginBottom: theme.spacing.space8 }}>
      <Text
        marginBottom={{ xs: 'space8' }}
        fontStyle={'heading4'}
        content={t('files.accepted_files', { number: files.length, limit })}
      />
      {files.map((file, index) => (
        <FileAccepted
          key={`accepted-${index}`}
          file={file}
          onRemove={onRemove}
        />
      ))}
    </div>
  );
};

type FileRejectedProps = {
  rejection: FileRejection;
};

const FileRejected: FC<FileRejectedProps> = ({ rejection }) => {
  const { t } = useTranslation();

  return (
    <Flex direction={{ xs: 'column' }} marginBottom={{ xs: 'space8' }}>
      <Text
        fontStyle={'body2'}
        content={rejection.file.name}
        style={{ flex: 1 }}
      />

      {rejection.errors.map((e, index) => {
        return (
          <Text
            key={`error-${rejection.file.name}-${index}`}
            fontStyle={'body3'}
            color={theme.colors.red1}
            content={t(`files.${e.code}`)}
          />
        );
      })}
    </Flex>
  );
};

type FilesRejectedProps = {
  rejections: FileRejection[];
  hide?: boolean;
};

const FilesRejected: FC<FilesRejectedProps> = ({ rejections, hide }) => {
  const { t } = useTranslation();

  return rejections.length === 0 || hide ? null : (
    <div style={{ marginBottom: theme.spacing.space8 }}>
      <Text
        marginBottom={{ xs: 'space8' }}
        fontStyle={'heading4'}
        content={t('files.rejected_files', { number: rejections.length })}
      />
      {rejections.map((rejection, index) => (
        <FileRejected key={`rejected-${index}`} rejection={rejection} />
      ))}
    </div>
  );
};

type FileDropProps = {
  multiple?: boolean;
  maxFiles?: number;
  maxSize?: number;
  disabled?: boolean;
  hidePreview?: boolean;
  onDrop: (files: File[], rejections: FileRejection[]) => void;
  compressImages?: boolean;
  compressionOptions?: {
    quality?: number;
    maxWidth?: number;
    maxHeight?: number;
  };
};

const FileDrop: FC<FileDropProps> = ({
  multiple = false,
  maxFiles = DEFAULT_MAX_FILES,
  maxSize = DEFAULT_MAX_SIZE,
  onDrop,
  disabled = false,
  hidePreview = false,
  compressImages = true,
  compressionOptions = COMPRESSION_OPTIONS,
}) => {
  const { t } = useTranslation();
  const [acceptedFiles, setAcceptedFiles] = useState<File[]>([]);
  const [rejectedFiles, setRejectedFiles] = useState<FileRejection[]>([]);
  const [isProcessing, setIsProcessing] = useState(false);

  const hideUploader = acceptedFiles.length > 0 || rejectedFiles.length > 0;

  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    acceptedFiles: _acceptedFiles,
    fileRejections: _fileRejections,
  } = useDropzone({
    accept: { 'image/png': [], 'image/jpeg': [], 'application/pdf': [] },
    multiple,
    disabled,
  });

  const removeAccepted = (file: File) => {
    setAcceptedFiles((prevState) => prevState.filter((f) => f !== file));
  };

  useEffect(() => {
    setAcceptedFiles([]);
    setRejectedFiles([]);
  }, []);

  const checkFiles = (files: File[]) => {
    const rejected: FileRejection[] = [];
    const accepted: File[] = [];

    // Process accepted files
    for (const file of files) {
      if (files.length >= maxFiles) {
        rejected.push({
          file: file,
          errors: [{ message: t('files.too-many'), code: 'too-many' }],
        });
      } else if (file.size >= maxSize) {
        rejected.push({
          file: file,
          errors: [
            { message: t('files.file-too-large'), code: 'file-too-large' },
          ],
        });
      } else {
        accepted.push(file);
      }
    }

    setRejectedFiles([...rejected, ..._fileRejections]);
    setAcceptedFiles(accepted);
  };

  useEffect(() => {
    // Compress images if enabled
    if (compressImages && _acceptedFiles.length > 0) {
      setIsProcessing(true);
      try {
        compressFiles(_acceptedFiles, compressionOptions).then(
          (compressedFiles) => {
            checkFiles(compressedFiles);
          },
        );
      } catch (error) {
        console.error('Error compressing files:', error);
        checkFiles(_acceptedFiles);
      }
      setIsProcessing(false);
    } else {
      checkFiles(_acceptedFiles);
    }
  }, [
    _acceptedFiles,
    _fileRejections,
    compressImages,
    compressionOptions,
    maxFiles,
  ]);

  useEffect(() => {
    if (acceptedFiles.length || rejectedFiles.length) {
      onDrop(acceptedFiles, rejectedFiles);
    }
  }, [acceptedFiles, rejectedFiles]);

  return (
    <Container {...getRootProps({ isFocused, isDragAccept, isDragReject })}>
      <Input {...getInputProps()} />
      <FileUploader hide={hideUploader} />
      {isProcessing && (
        <Text
          textAlign="center"
          fontStyle="body2"
          color={theme.colors.salmon1}
          content={t('files.processing')}
        />
      )}
      <FilesAccepted
        files={acceptedFiles}
        limit={maxFiles}
        onRemove={removeAccepted}
        hide={hidePreview}
      />
      <FilesRejected rejections={rejectedFiles} hide={hidePreview} />
    </Container>
  );
};

export default FileDrop;
