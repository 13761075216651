const pxtorem = (size: number) => `${size / 16}rem`;

export type Sizes =
  | 'size1'
  | 'size2'
  | 'size3'
  | 'size4'
  | 'size5'
  | 'size6'
  | 'size7'
  | 'size8';

export type sizeTypes = {
  [size in Sizes]: string;
};

export const size: sizeTypes = {
  size1: `${pxtorem(12)}`,
  size2: `${pxtorem(14)}`,
  size3: `${pxtorem(16)}`,
  size4: `${pxtorem(18)}`,
  size5: `${pxtorem(20)}`,
  size6: `${pxtorem(24)}`,
  size7: `${pxtorem(32)}`,
  size8: `${pxtorem(40)}`,
};

export type Heights =
  | 'height1'
  | 'height2'
  | 'height3'
  | 'height4'
  | 'height5'
  | 'height6'
  | 'height7';

export type heightTypes = {
  [height in Heights]: string;
};

export const height: heightTypes = {
  height1: `${pxtorem(16)}`,
  height2: `${pxtorem(18)}`,
  height3: `${pxtorem(22)}`,
  height4: `${pxtorem(26)}`,
  height5: `${pxtorem(30)}`,
  height6: `${pxtorem(36)}`,
  height7: `${pxtorem(48)}`,
};

export type Weights = 'regular' | 'medium' | 'bold';

export type weightTypes = {
  [weight in Weights]: string;
};

export const weight: weightTypes = {
  regular: '400',
  medium: '500',
  bold: '700',
};

export const offset = 16;

export const gutter = 32;
