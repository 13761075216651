import { FC, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { CellProps, Column, SortingRule } from 'react-table';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import format from 'date-fns/format';

// Components
import { Button } from 'components/Buttons/Button';
import Table from 'components/Table/Table';
import { Flex } from 'components/Layout/Flex';

import { AdminAppRoutes } from 'AdminApp';
import { getRouteWithParams } from 'utils/router';
import { env } from '../../../config/env';
import { Prospect, SortDirection } from '../../../types/resources';
import { ExecuteFunction } from '../../../hooks/useFetch';

type ProspectsListProps = {
  pageSize: number;
  search: string | null;
  estimate_id?: string;
  fetch: ExecuteFunction<Prospect[]>;
  total: number;
  items: Prospect[];
};

const ProspectsList: FC<ProspectsListProps> = ({
  pageSize,
  search,
  estimate_id,
  fetch,
  total,
  items,
}) => {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [sortKey, setSortKey] = useState<string | undefined>(undefined);
  const [sortDirection, setSortDirection] = useState<SortDirection | undefined>(
    undefined,
  );
  const history = useHistory();

  useEffect(() => {
    fetch({
      query: {
        search: search || undefined,
        estimate_id,
        page: page !== undefined ? page + 1 : 1,
        items: pageSize,
        sort: sortKey,
        direction: sortDirection,
      },
    });
  }, [search, page, sortKey, sortDirection]);

  const onLoadMore = ({
    pageIndex,
    sortBy,
  }: {
    pageIndex?: number;
    sortBy?: SortingRule<Prospect>[];
  }) => {
    if (pageIndex !== undefined) {
      setPage(pageIndex);
    }

    const _sortKey = sortBy?.[0]?.id;
    if (_sortKey !== undefined) {
      setSortKey(_sortKey);
    }

    const _sortDesc = sortBy?.[0]?.desc;
    if (_sortDesc !== undefined) {
      setSortDirection(_sortDesc ? SortDirection.DESC : SortDirection.ASC);
    }
  };

  const columns = useMemo<Column<Prospect>[]>(
    () => [
      {
        Header: t('prospect.email'),
        accessor: 'email',
        width: 200,
      },
      {
        Header: t('prospect.phone'),
        accessor: 'phone',
      },
      {
        Header: t('prospect.created_at'),
        accessor: 'created_at',
        Cell: ({ value }) => format(new Date(value), "dd/MM/YYY 'à' HH:mm"),
      },
      {
        Header: t('prospect.pipedrive_link'),
        accessor: 'pipedrive_person_id',
        disableSortBy: true,
        Cell: ({ value }) =>
          value ? (
            <Link
              to={{
                pathname: `${env.pipedrive.url}/person/${value}`,
              }}
              target="_blank"
            >
              {t('prospect.pipedrive_label')}
            </Link>
          ) : null,
      },
      {
        Header: Object.assign(
          ({}) => (
            <Flex justify="end">
              <></>
            </Flex>
          ),
          {
            displayName: 'Id',
          },
        ),
        accessor: 'id',
        width: 100,
        disableSortBy: true,
        Cell: Object.assign(
          ({ cell }: CellProps<Prospect>) => (
            <Flex justify="end" expand>
              <Button
                content={t('view')}
                size="small"
                primary
                onClick={() =>
                  history.push(
                    getRouteWithParams(AdminAppRoutes.PROSPECTS_DETAILS, {
                      id: cell.row.values.id,
                    }),
                  )
                }
              />
            </Flex>
          ),
          { displayName: 'Button' },
        ),
      },
    ],
    [history],
  );

  return (
    <Table<Prospect>
      data={items}
      columns={columns}
      pageSize={pageSize}
      totalCount={total}
      loadMore={onLoadMore}
      td={{ paddingVertical: 0 }}
    />
  );
};

export default ProspectsList;
