import { CustomIcon } from 'theme/styles/icons';
import { IconlyIcon } from 'types/react-iconly';

export const getColumnIcon = (
  isSorted: boolean,
  isSortedDesc: boolean | undefined,
) => {
  if (isSortedDesc) {
    return CustomIcon.swapDesc;
  }
  if (isSorted) {
    return CustomIcon.swapAsc;
  }
  return IconlyIcon.Swap;
};
