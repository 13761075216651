import { FC } from 'react';

import { theme } from 'theme';
import { Flex, Line, Requires, Text } from 'components/Layout';
import { useViewport } from 'hooks/useViewport';

interface ListProps {
  title: string;
  count?: string;
  displayChildren?: boolean;
}

const List: FC<ListProps> = ({
  title,
  count,
  children,
  displayChildren = true,
}) => {
  const { isMobile } = useViewport();

  return (
    <>
      <Flex
        justify={isMobile ? 'center' : 'between'}
        alignItems={isMobile ? 'start' : 'center'}
        width="100%"
        marginBottom={{ xs: 'space16' }}
        direction={{ xs: 'column', md: 'row' }}
      >
        <Text
          fontStyle="heading5"
          content={title}
          color={theme.colors.black}
          weight="bold"
          marginBottom={{ xs: 'space8', md: 'none' }}
        />
        <Requires value={count}>
          <Text
            fontStyle="body2"
            content={count}
            color={theme.colors.gray6}
            weight="medium"
          />
        </Requires>
      </Flex>
      <Flex
        width="100%"
        marginBottom={{ xs: displayChildren ? 'space24' : 'none' }}
      >
        <Line />
      </Flex>
      <Requires value={displayChildren}>
        <Flex width="100%" direction={{ xs: 'column' }}>
          {children}
        </Flex>
      </Requires>
    </>
  );
};

export default List;
