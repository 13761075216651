import { FC } from 'react';

export type AvailableShapes = 'rect' | 'circle';

export type ShapeInfo = Omit<GhostProps, 'colorEnd' | 'colorStart' | 'shape'>;

export interface GhostProps {
  width: number | string;
  height: number | string;
  rx?: number;
  colorStart?: string;
  colorEnd?: string;
  shape: AvailableShapes;
}

type GetSvgProps = (
  shapeInfo: ShapeInfo,
) => (type: AvailableShapes) => Record<string, unknown>;

export const getSvgProps: GetSvgProps = (shapeInfo) => {
  const { width, height } = shapeInfo;
  return (type) => {
    const svgProps = {
      rect: {
        width,
        height,
      },
      circle: {
        width: width,
        height: height,
      },
    };

    return svgProps[type];
  };
};

type GetGhostProps = (
  shapeInfo: ShapeInfo,
) => (type: AvailableShapes) => Record<string, unknown>;

export const getShapeProps: GetGhostProps = (shapeInfo) => {
  const { width, height, rx } = shapeInfo;
  return (type) => {
    const shapeOptions = {
      rect: {
        width,
        height,
        rx,
      },
      circle: {
        cx: Number(width) / 2,
        cy: Number(height) / 2,
        r: (Number(height) + Number(width)) / 4,
      },
    };

    return shapeOptions[type];
  };
};

const Ghost: FC<GhostProps> = ({
  width,
  height,
  rx = 0,
  colorStart = '#f0f0f0',
  colorEnd = '#e0e0e0',
  shape,
}) => {
  const ShapeElement = shape;
  const shapeProps = getShapeProps({ width, height, rx })(shape);
  const svgProps = getSvgProps({ width, height, rx })(shape);

  return (
    <svg {...svgProps}>
      <ShapeElement
        {...shapeProps}
        role="img"
        data-testid="shapeElement"
        fill="url(#linear-gradient)"
      />
      <defs>
        <linearGradient
          id="linear-gradient"
          x1={'0%'}
          y1={'0%'}
          x2={'100%'}
          y2={'0%'}
        >
          <stop offset={'0%'} stopColor={colorStart}>
            <animate
              attributeName="offset"
              values={`-2; -2; 1`}
              dur="2s"
              keyTimes={'0; 0.70; 1'}
              repeatCount="indefinite"
            />
          </stop>
          <stop offset={'50%'} stopColor={colorEnd}>
            <animate
              attributeName="offset"
              values={`0; 0; 2`}
              dur="2s"
              keyTimes={'0; 0.70; 1'}
              repeatCount="indefinite"
            />
          </stop>
          <stop offset={'100%'} stopColor={colorStart}>
            <animate
              attributeName="offset"
              values={`0; 0; 3`}
              dur="2s"
              keyTimes={'0; 0.90; 1'}
              repeatCount="indefinite"
            />
          </stop>
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Ghost;
