import { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { theme } from 'theme';

const CardContainer = styled.div<CardContainerProps>`
  width: 100%;
  height: auto;
  padding: ${(props) =>
    props.padding
      ? props.padding
      : `${theme.spacing.space8} ${theme.spacing.space16} ${theme.spacing.space16}`};
  border-radius: 10px;
  background-color: ${theme.colors.beige};
`;

export interface StickerCardProps {
  children: ReactNode;
  padding?: string;
  className?: string;
}

interface CardContainerProps {
  padding?: string;
}

const StickerCard: FC<StickerCardProps> = ({
  padding,
  children,
  className,
}) => (
  <CardContainer padding={padding} className={className}>
    {children}
  </CardContainer>
);

export default StickerCard;
