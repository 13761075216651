import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

// Components
import { ClientCard } from 'components/Cards';
import { theme } from 'theme';
import { Text } from 'components/Layout/Text';
import { FC } from 'react';
import { useDownloadOpportunity } from 'utils/downloadFile';

interface AdministrativeDocumentsInfoProps {
  showDownloadButton: boolean;
  opportunityId: string;
  filenameDownload: string;
}

const Wrapper = styled(ClientCard)`
  height: auto;
`;

const Card = styled.div`
  background-color: ${theme.colors.beige};
  border-radius: ${theme.spacing.space16};
  padding: ${theme.spacing.space16};
`;

const AdministrativeDocumentsInfo: FC<AdministrativeDocumentsInfoProps> = ({
  showDownloadButton,
  opportunityId,
  filenameDownload,
}) => {
  const { t } = useTranslation();
  const { onDownload, loading } = useDownloadOpportunity();

  return (
    <Wrapper
      iconName="Document"
      title={t('administrative_documents.progress.title')}
      buttons={[
        showDownloadButton
          ? {
              content: t('closed.card.download'),
              fullWidth: true,
              iconRight: { name: 'Download' },
              primary: true,
              $loading: loading,
              onClick: async () =>
                await onDownload({ opportunityId, filename: filenameDownload }),
            }
          : null,
      ]}
    >
      <Card>
        <Text
          content={t('administrative_documents.progress.description')}
          fontStyle="body2"
          color={theme.colors.gray6}
        />
      </Card>
    </Wrapper>
  );
};

export default AdministrativeDocumentsInfo;
