import React, { FC } from 'react';
import styled, { keyframes } from 'styled-components';
import { theme } from 'theme';

import { Flex } from 'components/Layout/Flex';
import { Text } from '../Layout';

const CircleAnim = (percentage: number) => keyframes`
  to {
    stroke-dashoffset: ${percentage};
  }
`;

const SVG = styled.svg<SVGProps>`
  width: 72px;
  height: 72px;
  transform: rotate(-85deg);
  stroke-dasharray: 190;
  transform: translate;
  stroke-dashoffset: 0;

  circle:nth-of-type(2) {
    stroke-dashoffset: 190;
    animation: ${(props) => CircleAnim(props.percentage)} 1s linear forwards;
  }
`;

const Label = styled(Text)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
`;

const Wrapper = styled(Flex)`
  position: relative;
`;

interface SVGProps {
  percentage: number;
}

export interface CircleProgressProps {
  percentage: number;
}

const CircleProgress: FC<CircleProgressProps> = ({ percentage }) => {
  const getPercentage = 190 - (185 * percentage) / 100;
  return (
    <Wrapper alignItems="center">
      <Label fontStyle="body1" content={`${percentage}%`} weight="bold" />
      <SVG percentage={getPercentage} viewBox="0 0 72 72">
        <circle
          cx="36"
          cy="36"
          r="30"
          stroke={theme.colors.salmon3}
          strokeWidth="8"
          fill="transparent"
        />
        <circle
          cx="36"
          cy="36"
          r="30"
          stroke={theme.colors.salmon2}
          strokeLinecap="round"
          strokeWidth="8"
          fill="transparent"
        />
      </SVG>
    </Wrapper>
  );
};

export default CircleProgress;
