import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { ClientCard, WithdrawalCard } from '../../../components/Cards';

// Utils
import { CalendlyModal } from 'components/Modal';
import { Ghost } from 'components/Loading';
import { formatDate } from 'utils/formatDate';
import { addDays } from 'date-fns';
import { useAuth } from '../../../hooks/useAuth';
import { DivorceAgreementFull, ProcedureFull } from '../../../types/resources';
import { useApi } from '../../../hooks/useApi';
import { EventScheduledEvent } from 'react-calendly';
import { env } from '../../../config/env';

const AppointmentTakingCard: FC<{
  procedure: ProcedureFull;
  agreement?: DivorceAgreementFull;
  refetchProcedure: () => void;
}> = ({ procedure, refetchProcedure, agreement }) => {
  const { t } = useTranslation();
  const [openedCalendlyModal, toggleCalendlyModal] = useState(false);

  const legalReflexionPeriod = Number(env.procedure.legalReflectionPeriod);

  const { execute: createAppointment } = useApi(
    `/procedures/${procedure.id}/appointments`,
    {
      method: 'POST',
      onSuccess: () => {
        toggleCalendlyModal(false);
        refetchProcedure();
      },
    },
  );

  const createAppointmentCb = (event: EventScheduledEvent) => {
    const provider_id = event.data.payload.event.uri.split('/').pop();

    createAppointment({
      body: { provider_id },
    });
  };

  const { user, isCustomer } = useAuth();

  if (!agreement)
    return <Ghost width="100%" height="252px" shape="rect" rx={24} />;

  const lastReceivedLetters = agreement.received_at;

  return (
    <>
      <ClientCard
        iconName="Calendar"
        title={t('appointment.taking.title')}
        buttons={[
          !procedure?.signature_appointment_id
            ? {
                content: t('appointment.taking.button.take'),
                primary: true,
                fullWidth: true,
                onClick: () => toggleCalendlyModal(true),
              }
            : null,
        ]}
      >
        {lastReceivedLetters && (
          <WithdrawalCard
            startDate={lastReceivedLetters}
            duration={legalReflexionPeriod}
          />
        )}
      </ClientCard>
      {lastReceivedLetters && (
        <CalendlyModal
          onClose={() => toggleCalendlyModal(false)}
          opened={openedCalendlyModal}
          prefill={
            procedure.spouse2 && procedure.spouse1
              ? {
                  ...(isCustomer
                    ? {
                        name: procedure.name,
                        firstName: user?.first_name || '',
                        lastName: user?.last_name || '',
                        email: user?.email,
                        customAnswers: {
                          a1: procedure.reference.toString(),
                        },
                      }
                    : {
                        name: procedure.name,
                        firstName: procedure.spouse1?.first_name || '',
                        lastName: procedure.spouse1?.last_name || '',
                        email: procedure.spouse1?.email,
                        customAnswers: {
                          a1: procedure.reference.toString(),
                        },
                      }),
                  guests: [procedure.spouse2.email, procedure.spouse1.email],
                }
              : undefined
          }
          tipCard={{
            title: t('appointment.taking.tip.title'),
            content: t('appointment.taking.tip.description', {
              date: formatDate(
                addDays(new Date(lastReceivedLetters), legalReflexionPeriod),
              ),
            }),
            icon: 'Danger',
            type: 'danger',
          }}
          onEventScheduled={(event) => {
            createAppointmentCb(event);
          }}
        />
      )}
    </>
  );
};

export default AppointmentTakingCard;
