import { FC, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { theme } from 'theme';
import { Modal } from 'components/Modal/Modal';
import { FormulaCard } from 'components/Cards/FormulaCard';
import { ModalFooter, ModalHeader } from 'components/Modal';
import { md } from 'theme/styles/mediaQueries';
import { SubmitModalProps } from '.';
import ProductCard from 'components/Cards/ProductCard';
import { orderBy } from 'lodash';
import { Plan } from '../../types/resources';

const FormulaGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 16px 24px;
  grid-template-areas:
    '.'
    '.'
    '.';
  margin-bottom: ${theme.spacing.space24};
  ${md(`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 0px 32px;
    margin-bottom: ${theme.spacing.space16};
`)}
`;

interface PlanModalProps extends SubmitModalProps {
  plans: Plan[];
  isMobile?: boolean;
  selected: Plan;
}

const PlanModal: FC<PlanModalProps> = ({
  onClose,
  plans,
  selected,
  onSubmit,
  loading,
  opened,
  isMobile,
}) => {
  const { t } = useTranslation();
  const [checked, setChecked] = useState<Plan>(selected);

  const refPrice =
    plans?.reduce((acc, plan) => {
      if (plan.price < acc) {
        return plan.price;
      }
      return acc;
    }, Infinity) || 0;

  return (
    <Modal opened={opened} onClose={onClose}>
      <ModalHeader
        iconName="Edit"
        content={t('quote.resume.modify_product')}
        onClose={onClose}
      />
      <FormulaGrid>
        {orderBy(plans, 'unitPrice', 'asc').map((plan) => {
          const { description, name, price, id } = plan;
          const isRefPrice = price === refPrice;
          const diffPrice = (price - refPrice) / 2;

          if (isMobile) {
            return (
              <ProductCard
                key={id}
                title={name}
                content={description}
                priceDescription={
                  isRefPrice
                    ? t('quote.resume.taxes_price_base')
                    : t('quote.resume.taxes_price_relative', {
                        price: diffPrice,
                      })
                }
                seeMoreText={t('quote.resume.see_more')}
                checked={plan.id === checked.id}
                onClick={() => setChecked(plan)}
              />
            );
          }

          return (
            <FormulaCard
              key={id}
              title={name}
              content={description}
              priceDescription={
                isRefPrice
                  ? t('quote.resume.taxes_price_base')
                  : t('quote.resume.taxes_price_relative', {
                      price: diffPrice,
                    })
              }
              textButton={t('quote.resume.select')}
              checked={plan.id === checked.id}
              onClick={() => {
                setChecked(plan);
              }}
              disabled={!(plan.id === checked.id || !checked)}
            />
          );
        })}
      </FormulaGrid>
      <ModalFooter
        onClose={onClose}
        loading={loading}
        onSubmit={() => {
          onSubmit(
            {
              plan_id: checked.id,
            },
            () => {
              onClose();
            },
          );
        }}
        disabled={checked === selected || loading}
      />
    </Modal>
  );
};

export default PlanModal;
