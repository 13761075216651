import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

// Components
import { ClientCard } from 'components/Cards';
import { Text } from 'components/Layout/Text';

// Utils
import { theme } from 'theme';
import { useDownloadOpportunity } from 'utils/downloadFile';
import { IconlyIcon } from 'types/react-iconly';
import { useCustomer } from '../../../hooks/useCustomer';
import { ButtonProps } from '../../../components/Buttons/Button';

const Wrapper = styled(ClientCard)`
  height: auto;
`;

const FinalCard: FC = () => {
  const { t } = useTranslation();
  const { customer } = useCustomer();
  const { onDownload, loading } = useDownloadOpportunity();

  const buttons: ButtonProps[] = [
    {
      content: t('closed.card.download'),
      onClick: async () =>
        await onDownload({
          opportunityId: customer?.procedure_id!,
          filename: `dossier-divorce-${customer?.last_name}`,
        }),
      primary: true,
      iconRight: { name: 'Download' as IconlyIcon },
      fullWidth: true,
      $loading: loading,
    },
  ];

  return (
    <Wrapper
      iconName="Document"
      title={t('closed.card.title')}
      buttons={buttons}
    >
      <Text
        content={t('closed.card.description')}
        fontStyle="body2"
        color={theme.colors.gray6}
      />
    </Wrapper>
  );
};

export default FinalCard;
