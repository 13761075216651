import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Translations
import departments from 'translation/data/departments.json';
import translation from 'translation/fr/translation.json';
import summaryPanel from 'translation/fr/summaryPanel.json';
import enums from 'translation/fr/enums.json';
import infoPanel from 'translation/fr/infoPanel.json';

// languages files
const resources = {
  fr: {
    translation,
    departments,
    summaryPanel,
    enums,
    infoPanel,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'fr',
  fallbackLng: 'fr',
  interpolation: { escapeValue: false },
});

export default i18n;
