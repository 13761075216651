import { FC, MutableRefObject, useEffect, useRef, useState } from 'react';

import { Flex } from '../Layout/Flex';
import { Icon } from 'components/Images/Icon';
import { IconlyIcon } from 'types/react-iconly';
import { Text } from 'components/Layout/Text';
import styled from 'styled-components';
// Components
import { theme } from 'theme';
import { useTranslation } from 'react-i18next';

const StyledContainer = styled.div<ToasterProps>`
  position: fixed;
  bottom: 100%;
  left: 50%;
  width: 90%;
  max-width: 920px;
  min-height: 72px;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  padding: ${theme.spacing.space24};
  border-radius: ${theme.spacing.space16};
  background-color: ${({ type }) =>
    type === 'error' ? theme.colors.red3 : '#DFF1E6'};
  z-index: 13;
  transform: ${({ active }) =>
      active ? `translateY(calc(100% + 30px))` : 'translateY(0px)'}
    translateX(-50%);
  transition-property: all;
  transition-duration: 0.6s;
  transition-timing-function: ease;
  opacity: ${({ active }) => (active ? 1 : 0)};
`;

const List = styled(Text)`
  display: list-item;
  margin-left: ${theme.spacing.space48};
`;

const ShowList = styled(Text)`
  cursor: pointer;
`;

const Close = styled(Icon)`
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
`;

export type ToasterType = 'error' | 'success';

export interface ToasterProps {
  message: string;
  type: ToasterType;
  active: boolean;
  list?: string[];
}

const Toaster: FC<ToasterProps> = ({ message, type, active, list }) => {
  const { t } = useTranslation('errorForms');
  const getColor = type === 'success' ? '#54B86B' : theme.colors.red1;
  const getIconName =
    type === 'success' ? IconlyIcon.TickSquare : IconlyIcon.Danger;
  const [showList, toggleList] = useState(false);
  const [showToaster, toogleToaster] = useState(false);
  const intervalRef = useRef() as MutableRefObject<NodeJS.Timeout>;

  useEffect(() => {
    const interval = setInterval(() => {
      toogleToaster(false);
    }, 5000);
    if (active) {
      !showToaster && toogleToaster(true);
      toggleList(false);
      intervalRef.current = interval;
    } else {
      intervalRef.current && showList && clearInterval(interval);
    }
    return () => {
      clearInterval(interval);
    };
  }, [active]);

  const onClickDetails = () => {
    toggleList(true);
    clearInterval(intervalRef.current);
  };

  return (
    <StyledContainer type={type} message={message} active={showToaster}>
      <Flex alignItems="center" justify="between" width="100%">
        <Flex>
          <Icon name={getIconName} primaryColor={getColor} size="large" />
          <Text
            fontStyle="body1"
            textAlign="start"
            marginLeft={{ xs: 'space24' }}
            marginRight={{ xs: 'space8' }}
            color={getColor}
            weight="medium"
            content={t(message)}
          />
        </Flex>
        {list && !showList && (
          <div onClick={onClickDetails}>
            <ShowList
              fontStyle="label"
              color={theme.colors.salmon2}
              weight="medium"
              content="Voir détails"
            />
          </div>
        )}
      </Flex>
      {showList && list && (
        <>
          <Flex direction={{ xs: 'column' }} marginTop={{ xs: 'space8' }}>
            {list.map((el, i) => (
              <List
                fontStyle="body1"
                textAlign="start"
                marginLeft={{ xs: 'space24' }}
                color={getColor}
                weight="medium"
                key={`toaster-error-${i}`}
                content={el}
              />
            ))}
          </Flex>
          <div onClick={() => toogleToaster(false)}>
            <Close name="close" primaryColor={getColor} size="large" />
          </div>
        </>
      )}
    </StyledContainer>
  );
};

export default Toaster;
