import { FC, useCallback, useEffect, useState } from 'react';
import _debounce from 'lodash/debounce';

import {
  Document,
  DocumentFull,
  ProcedureAlert,
  ProcedureAlertFull,
  ProcedureAlertType,
  ProcedureFeedback,
  ProcedureFeedbackType,
  ProcedureFull,
  ProcedureSpouse,
  ProcedureSpouseFull,
} from '../../../types/resources';
import styled from 'styled-components';
import { Button, IconButton } from '../../../components/Buttons';
import { useTranslation } from 'react-i18next';
import { Flex, Text } from '../../../components/Layout';
import { useApi } from '../../../hooks/useApi';
import { theme } from '../../../theme';
import InputCheckbox from '../../../components/FormTemplate/Fields/InputCheckbox';
import DateInput from '../../../components/Forms/Input/DateInput';
import { InputFile } from '../../../components/FormTemplate/Fields/InputFile';
import { FileModal, Modal, ModalHeader } from '../../../components/Modal';
import { downloadFileNew } from '../../../utils/downloadFile';
import { InputText } from '../../../components/FormTemplate/Fields/InputText';
import { TextArea } from '../../../components/FormTemplate/Fields/TextArea';
import { formatDocumentTitle } from '../../../utils/format';

interface ConventionWritingViewProps {
  procedure: ProcedureFull;
  spouses: ProcedureSpouseFull[];
  refetchProcedure: () => void;
}

const Container = styled.div``;

type FeedbackItemProps = {
  type: ProcedureFeedbackType;
  disabled?: boolean;
  feedback?: ProcedureFeedback;
  onCreate?: (data: { type: ProcedureFeedbackType }) => void;
  onDelete?: (feedback: ProcedureFeedback) => void;
  onUpdate?: (
    feedback: ProcedureFeedback,
    data: { date?: Date | null; text?: string | null; lawyer?: string | null },
  ) => void;
};

const FeedbackItem: FC<FeedbackItemProps> = ({
  type,
  disabled,
  feedback,
  onCreate,
  onDelete,
  onUpdate,
}) => {
  const { t } = useTranslation();
  const checked = feedback !== undefined;
  const [lawyerText, setLawyerText] = useState<string>(feedback?.lawyer ?? '');
  const [freeText, setFreeText] = useState<string>(feedback?.text ?? '');

  useEffect(() => {
    setLawyerText(feedback?.lawyer ?? '');
    setFreeText(feedback?.text ?? '');
  }, [feedback]);

  const debouncedUpdate = useCallback(
    _debounce((data: { lawyer: string | null; text: string | null }) => {
      if (feedback) {
        const isSameLawyerText = feedback.lawyer === data.lawyer;
        const isSameFreeText = feedback.text === data.text;

        if (!isSameLawyerText || !isSameFreeText) {
          onUpdate?.(feedback, data);
        }
      }
    }, 1000),
    [feedback],
  );

  const onCheck = () => {
    if (!feedback) {
      onCreate?.({ type });
    } else {
      onDelete?.(feedback);
    }
  };

  useEffect(() => {
    if (feedback) {
      debouncedUpdate({ lawyer: lawyerText || null, text: freeText || null });
    }
  }, [lawyerText, freeText]);

  return (
    <Flex direction={{ xs: 'column' }}>
      <InputCheckbox
        label={t(`feedbacks.types.${type}`)}
        disabled={disabled}
        fontStyle={'body1'}
        fontWeight={'bold'}
        checked={checked}
        onChange={onCheck}
      />

      {feedback && (
        <>
          <Flex
            marginTop={{ xs: 'space16' }}
            style={{ columnGap: theme.spacing.space8 }}
            alignItems={'center'}
          >
            <Flex>
              <DateInput
                onAccept={(value) => onUpdate?.(feedback, { date: value })}
                value={feedback.date}
                onChange={(newValue) => {
                  if (newValue === null) {
                    onUpdate?.(feedback, { date: newValue });
                  }

                  if (
                    newValue instanceof Date &&
                    !isNaN(newValue.getTime()) &&
                    newValue?.getFullYear() > 1900
                  ) {
                    onUpdate?.(feedback, { date: newValue });
                  }
                }}
                width="170px"
                height="42px"
              />
            </Flex>

            {type === ProcedureFeedbackType.LAWYER && (
              <Flex>
                <InputText
                  value={lawyerText ?? undefined}
                  onChange={(event) => setLawyerText(event.target.value)}
                  placeholder="Avocat"
                  height="42px"
                  autoComplete="off"
                  autoCorrect="off"
                />
              </Flex>
            )}
          </Flex>

          <Flex>
            <TextArea
              value={freeText ?? undefined}
              onChange={(event) => setFreeText(event.target.value)}
              placeholder="Champ libre texte ..."
              width="100%"
              autoComplete="off"
              autoCorrect="off"
              style={{
                marginTop: theme.spacing.space16,
                paddingTop: theme.spacing.space8,
                paddingBottom: theme.spacing.space8,
                paddingLeft: theme.spacing.space16,
                paddingRight: theme.spacing.space16,
                minHeight: '6rem',
                maxHeight: '6rem',
              }}
            />
          </Flex>
        </>
      )}
    </Flex>
  );
};

type AlertItemSpousesProps = {
  label?: string;
  type: ProcedureAlertType;
  disabled?: boolean;
  spouses: ProcedureSpouseFull[];
  alert?: ProcedureAlertFull;
  onCreate?: (data: { type: ProcedureAlertType; spouses: string[] }) => void;
  onDelete?: (alert: ProcedureAlert) => void;
  onUpdate?: (alert: ProcedureAlert, data: { date: Date | null }) => void;
  onUpload?: (alert: ProcedureAlert, file: File) => void;
  onUploadFeedback?: (
    alert: ProcedureAlert,
    spouse: ProcedureSpouse,
    file: File,
  ) => void;
  onViewDocument?: (document_id: Document['id']) => void;
  onDownloadDocument?: (document_id: Document['id']) => void;
};

const AlertItemSpouses: FC<AlertItemSpousesProps> = ({
  label,
  type,
  disabled,
  spouses,
  alert,
  onCreate,
  onDelete,
  onUpdate,
  onUpload,
  onUploadFeedback,
  onViewDocument,
  onDownloadDocument,
}) => {
  const { t } = useTranslation();

  const checked = alert !== undefined;
  const finalLabel = label ?? t(`alerts.types.${type}`);
  const bothSpouses = spouses.length > 1;

  const onCheck = () => {
    if (!alert) {
      onCreate?.({ type, spouses: spouses.map((s) => s.spouse_id) });
    } else {
      onDelete?.(alert);
    }
  };

  const onAcceptDate = (date: Date | null) => {
    if (alert) {
      onUpdate?.(alert, { date });
    }
  };

  const uploadAlert = (file: File) => {
    if (alert && file) {
      onUpload?.(alert, file);
    }
  };

  const uploadFeedback = (spouse: ProcedureSpouse, file: File) => {
    if (alert && file) {
      onUploadFeedback?.(alert, spouse, file);
    }
  };

  const onViewAlertDocumentClick = (document_id: Document['id'] | null) => {
    if (document_id) {
      onViewDocument?.(document_id);
    }
  };

  const onDownloadAlertDocumentClick = (document_id: Document['id'] | null) => {
    if (document_id) {
      onDownloadDocument?.(document_id);
    }
  };

  return (
    <Flex direction={{ xs: 'column' }}>
      <InputCheckbox
        label={finalLabel}
        disabled={disabled}
        fontStyle={'body1'}
        fontWeight={'bold'}
        checked={checked}
        onChange={onCheck}
      />

      {alert && (
        <Flex
          marginTop={{ xs: 'space16' }}
          style={{ columnGap: theme.spacing.space8 }}
          alignItems={'center'}
        >
          <DateInput
            onAccept={onAcceptDate}
            value={alert.date}
            onChange={(newValue) => {
              if (newValue === null) {
                onAcceptDate(newValue);
              }

              if (
                newValue instanceof Date &&
                !isNaN(newValue.getTime()) &&
                newValue?.getFullYear() > 1900
              ) {
                onAcceptDate(newValue);
              }
            }}
            width="170px"
            height="42px"
          />
          <Container>
            <InputFile
              type={'file'}
              buttonLabel={'Upload alerte'}
              buttonPrimary={true}
              buttonSize={'small'}
              buttonIconRight={{ name: 'Upload', size: 'medium' }}
              buttonIconRightMargin={'space8'}
              hideFiles={true}
              size={1}
              accept={'application/pdf'}
              required
              onChange={(files) => uploadAlert(files[0])}
            />
          </Container>
          <Container>
            <IconButton
              size={'extra-large'}
              rounded={true}
              onClick={
                alert.document_id
                  ? () => onViewAlertDocumentClick(alert.document_id)
                  : undefined
              }
              color={
                alert.document_id ? theme.colors.black : theme.colors.gray4
              }
              backgroundColor={theme.colors.white}
              iconName={'Show'}
              stroke={alert.document_id ? 'bold' : 'light'}
            />
          </Container>
          <Container>
            <IconButton
              size={'extra-large'}
              rounded={true}
              onClick={
                alert.document_id
                  ? () => onDownloadAlertDocumentClick(alert.document_id)
                  : undefined
              }
              color={
                alert.document_id ? theme.colors.black : theme.colors.gray4
              }
              backgroundColor={theme.colors.white}
              iconName={'Download'}
              stroke={alert.document_id ? 'bold' : 'light'}
            />
          </Container>
          {spouses.map((spouse) => {
            const feedback = alert.feedbacks.find(
              (f) => f.spouse_id === spouse.spouse_id,
            );
            const feedbackDocumentId = feedback?.feedback_document_id;
            const label = bothSpouses
              ? `Upload retour C${spouse.number}`
              : 'Upload retour';

            return (
              <Flex key={`${spouse.spouse_id}-${alert.type}`}>
                <Container>
                  <InputFile
                    type={'file'}
                    buttonLabel={label}
                    buttonPrimary={true}
                    buttonSize={'small'}
                    buttonIconRight={{ name: 'Upload', size: 'medium' }}
                    buttonIconRightMargin={'space8'}
                    hideFiles={true}
                    size={1}
                    accept={'application/pdf'}
                    required
                    onChange={(files) => uploadFeedback(spouse, files[0])}
                  />
                </Container>

                <Container>
                  <IconButton
                    size={'extra-large'}
                    rounded={true}
                    onClick={
                      feedbackDocumentId
                        ? () => onViewAlertDocumentClick(feedbackDocumentId)
                        : undefined
                    }
                    color={
                      feedbackDocumentId
                        ? theme.colors.black
                        : theme.colors.gray4
                    }
                    backgroundColor={theme.colors.white}
                    iconName={'Show'}
                    stroke={feedbackDocumentId ? 'bold' : 'light'}
                  />
                </Container>

                <Container>
                  <IconButton
                    size={'extra-large'}
                    rounded={true}
                    onClick={
                      feedbackDocumentId
                        ? () => onDownloadAlertDocumentClick(feedbackDocumentId)
                        : undefined
                    }
                    color={
                      feedbackDocumentId
                        ? theme.colors.black
                        : theme.colors.gray4
                    }
                    backgroundColor={theme.colors.white}
                    iconName={'Download'}
                    stroke={feedbackDocumentId ? 'bold' : 'light'}
                  />
                </Container>
              </Flex>
            );
          })}
        </Flex>
      )}
    </Flex>
  );
};

const ConventionWritingView: FC<ConventionWritingViewProps> = ({
  procedure,
  spouses,
  refetchProcedure,
}) => {
  const { t } = useTranslation();
  const [document, setDocument] = useState<DocumentFull | null>(null);
  const [alertDeleteModalOpen, setAlertDeleteModalOpen] =
    useState<boolean>(false);
  const [feedbackDeleteModalOpen, setFeedbackDeleteModalOpen] =
    useState<boolean>(false);
  const [alertToDelete, setAlertToDelete] = useState<ProcedureAlert | null>(
    null,
  );
  const [feedbackToDelete, setFeedbackToDelete] =
    useState<ProcedureFeedback | null>(null);

  const openDeleteAlertModal = (alert: ProcedureAlert) => {
    setAlertDeleteModalOpen(true);
    setAlertToDelete(alert);
  };

  const openDeleteFeedbackModal = (feedback: ProcedureFeedback) => {
    setFeedbackDeleteModalOpen(true);
    setFeedbackToDelete(feedback);
  };

  const closeDeleteAlertModal = () => {
    setAlertDeleteModalOpen(false);
  };

  const closeDeleteFeedbackModal = () => {
    setFeedbackDeleteModalOpen(false);
  };

  const {
    execute: createDivorceAgreement,
    state: { loading: createDivorceAgreementLoading },
  } = useApi(`/procedures/${procedure.id}/divorce-agreements`, {
    method: 'POST',
    onSuccess: () => refetchProcedure(),
  });

  const { execute: getAlerts, state: getAlertsState } = useApi<
    ProcedureAlertFull[]
  >(`/procedures/${procedure.id}/alerts`);
  const alerts = getAlertsState.data?.value ?? [];

  const { execute: createAlert } = useApi(
    `/procedures/${procedure.id}/alerts`,
    {
      method: 'POST',
      onSuccess: () => getAlerts(),
    },
  );

  const { execute: deleteAlert, state: deleteAlertState } = useApi(
    `/procedures/${procedure.id}/alerts`,
    {
      method: 'DELETE',
      onSuccess: () => {
        closeDeleteAlertModal();
        getAlerts();
      },
    },
  );

  const { execute: updateAlert } = useApi(
    `/procedures/${procedure.id}/alerts`,
    {
      method: 'PATCH',
      onSuccess: () => getAlerts(),
    },
  );

  const { execute: uploadAlert } = useApi(
    `/procedures/${procedure.id}/alerts`,
    {
      method: 'POST',
      onSuccess: () => getAlerts(),
    },
  );

  const { execute: getFeedbacks, state: getFeedbacksState } = useApi<
    ProcedureFeedback[]
  >(`/procedures/${procedure.id}/feedbacks`);
  const feedbacks = getFeedbacksState.data?.value ?? [];

  const { execute: createFeedback } = useApi(
    `/procedures/${procedure.id}/feedbacks`,
    {
      method: 'POST',
      onSuccess: () => getFeedbacks(),
    },
  );

  const { execute: deleteFeedback, state: deleteFeedbackState } = useApi(
    `/procedures/${procedure.id}/feedbacks`,
    {
      method: 'DELETE',
      onSuccess: () => {
        closeDeleteFeedbackModal();
        getFeedbacks();
      },
    },
  );

  const { execute: updateFeedback, state: updateFeedbackState } = useApi(
    `/procedures/${procedure.id}/feedbacks`,
    { method: 'PATCH' },
  );

  const { execute: downloadDocument } = useApi<{ url: string }>(``);

  const { execute: getDocument } = useApi<DocumentFull>(
    `/procedures/${procedure.id}/documents`,
  );

  const viewDocumentCb = (document_id: Document['id']) => {
    getDocument({
      endpoint: `/procedures/${procedure.id}/documents/${document_id}`,
      onSuccess: (response) => {
        setDocument(response.value);
      },
    });
  };

  const downloadDocumentCb = (document_id: Document['id']) => {
    getDocument({
      endpoint: `/procedures/${procedure.id}/documents/${document_id}`,
      onSuccess: (response) => {
        const document = response.value;

        downloadDocument({
          endpoint: `/procedures/${procedure.id}/documents/${document.id}/download`,
          onSuccess: async (result) => {
            try {
              await downloadFileNew(
                result.value.url,
                formatDocumentTitle(document),
                procedure.reference.toString(),
              );
            } catch (e) {
              console.error(e);
            }
          },
        });
      },
    });
  };

  const deleteAlertCb = useCallback(() => {
    if (alertToDelete) {
      deleteAlert({
        endpoint: `/procedures/${procedure.id}/alerts/${alertToDelete.id}`,
      });
    }
  }, [alertToDelete]);

  const updateAlertCb = (
    alert: ProcedureAlert,
    data: { date: Date | null },
  ) => {
    updateAlert({
      endpoint: `/procedures/${procedure.id}/alerts/${alert.id}`,
      body: data,
    });
  };

  const uploadAlertCb = (alert: ProcedureAlert, file: File) => {
    const body = new FormData();
    body.set('file', file);

    uploadAlert({
      endpoint: `/procedures/${procedure.id}/alerts/${alert.id}/upload`,
      body,
    });
  };

  const uploadFeedbackCb = (
    alert: ProcedureAlert,
    spouse: ProcedureSpouse,
    file: File,
  ) => {
    const body = new FormData();
    body.set('file', file);
    body.set('spouse_id', spouse.spouse_id);

    uploadAlert({
      endpoint: `/procedures/${procedure.id}/alerts/${alert.id}/feedbacks/upload`,
      body,
    });
  };

  const createAlertCb = (data: {
    type: ProcedureAlertType;
    spouses: string[];
  }) => {
    createAlert({ body: data });
  };

  const createFeedbackCb = (data: { type: ProcedureFeedbackType }) => {
    createFeedback({ body: data });
  };

  const deleteFeedbackCb = useCallback(() => {
    if (feedbackToDelete) {
      deleteFeedback({
        endpoint: `/procedures/${procedure.id}/feedbacks/${feedbackToDelete.id}`,
      });
    }
  }, [feedbackToDelete]);

  const updateFeedbackCb = (
    feedback: ProcedureFeedback,
    data: { date?: Date | null; text?: string | null; lawyer?: string | null },
  ) => {
    updateFeedback({
      endpoint: `/procedures/${procedure.id}/feedbacks/${feedback.id}`,
      body: data,
    });
  };

  useEffect(() => {
    getAlerts();
    getFeedbacks();
  }, [procedure.id]);

  const alertPaGc = alerts.find((a) => a.type === ProcedureAlertType.PA_GC);
  const alertPaGa = alerts.find((a) => a.type === ProcedureAlertType.PA_GA);
  const feedbackDocuments = feedbacks.find(
    (f) => f.type === ProcedureFeedbackType.DOCUMENTS,
  );
  const feedbackLawyer = feedbacks.find(
    (f) => f.type === ProcedureFeedbackType.LAWYER,
  );

  return (
    <>
      <Container>
        {/* Header buttons */}
        <Flex marginBottom={{ xs: 'space32' }}>
          <Flex>
            <Button
              disabled={Boolean(procedure.divorce_agreement_id)}
              content={t('convention.release')}
              primary
              $loading={createDivorceAgreementLoading}
              onClick={() => createDivorceAgreement()}
            />
          </Flex>
        </Flex>

        {/* Alerts section */}
        <Flex
          direction={{ xs: 'column' }}
          marginBottom={{ xs: 'space32' }}
          style={{ rowGap: theme.spacing.space24 }}
        >
          <Text fontStyle={'heading3'} content={t('alerts.title')} />

          {/* Alert PC */}
          {spouses.map((spouse) => {
            const existingAlert = alerts.find(
              (a) =>
                a.type === ProcedureAlertType.PC &&
                a.feedbacks.some((f) => f.spouse_id === spouse.spouse_id),
            );

            const spouseName = spouse.spouse.full_name;
            const lawyerName = spouse.lawyer?.full_name ?? 'N/A';
            const label = `${t(
              `alerts.types.${ProcedureAlertType.PC}`,
            )} ${spouseName} - ${lawyerName}`;

            return (
              <AlertItemSpouses
                key={`${spouse.spouse_id}-${ProcedureAlertType.PC}`}
                type={ProcedureAlertType.PC}
                spouses={[spouse]}
                label={label}
                alert={existingAlert}
                onCreate={createAlertCb}
                onDelete={openDeleteAlertModal}
                onUpdate={updateAlertCb}
                onUpload={uploadAlertCb}
                onUploadFeedback={uploadFeedbackCb}
                onViewDocument={viewDocumentCb}
                onDownloadDocument={downloadDocumentCb}
              />
            );
          })}

          {/* Alert PA GC */}
          <AlertItemSpouses
            type={ProcedureAlertType.PA_GC}
            spouses={spouses}
            alert={alertPaGc}
            onCreate={createAlertCb}
            onDelete={openDeleteAlertModal}
            onUpdate={updateAlertCb}
            onUpload={uploadAlertCb}
            onUploadFeedback={uploadFeedbackCb}
            onViewDocument={viewDocumentCb}
            onDownloadDocument={downloadDocumentCb}
          />

          {/* Alert PA GA */}
          <AlertItemSpouses
            type={ProcedureAlertType.PA_GA}
            spouses={spouses}
            alert={alertPaGa}
            onCreate={createAlertCb}
            onDelete={openDeleteAlertModal}
            onUpdate={updateAlertCb}
            onUpload={uploadAlertCb}
            onUploadFeedback={uploadFeedbackCb}
            onViewDocument={viewDocumentCb}
            onDownloadDocument={downloadDocumentCb}
          />

          <FeedbackItem
            key={ProcedureFeedbackType.DOCUMENTS}
            type={ProcedureFeedbackType.DOCUMENTS}
            feedback={feedbackDocuments}
            onCreate={createFeedbackCb}
            onDelete={openDeleteFeedbackModal}
            onUpdate={updateFeedbackCb}
            disabled={updateFeedbackState.loading}
          />

          <FeedbackItem
            key={ProcedureFeedbackType.LAWYER}
            type={ProcedureFeedbackType.LAWYER}
            feedback={feedbackLawyer}
            onCreate={createFeedbackCb}
            onDelete={openDeleteFeedbackModal}
            onUpdate={updateFeedbackCb}
            disabled={updateFeedbackState.loading}
          />
        </Flex>
      </Container>

      {document && (
        <FileModal
          opened={Boolean(document)}
          onClose={() => setDocument(null)}
          hideTip
          document={document}
          procedure={procedure}
          refetchDocuments={() => {}}
        />
      )}

      {/* Delete alert modal */}
      <Modal opened={alertDeleteModalOpen} onClose={closeDeleteAlertModal}>
        <Flex marginBottom={{ xs: 'space16' }} justify="between">
          <ModalHeader
            iconName="Document"
            content={"Suppression d'une alerte"}
            onClose={closeDeleteAlertModal}
          />
        </Flex>
        <Flex marginTop={{ xs: 'space16' }}>
          <Text
            content={
              "En validant la suppression d'une alerte, tout son contenu sera supprimé. Cliquez sur oui pour valider la suppression et sur non pour l'annuler."
            }
            fontStyle="body1"
            marginLeft={{ xs: 'space16' }}
          />
        </Flex>
        <Flex justify="start" marginTop={{ xs: 'space24' }}>
          <Button
            marginRight={{ xs: 'space8' }}
            content="Oui, supprimer l'alerte"
            onClick={deleteAlertCb}
            $loading={deleteAlertState.loading}
          />
          <Button
            primary
            content={'Non, annuler la suppression'}
            onClick={closeDeleteAlertModal}
            marginLeft={{ xs: 'space8' }}
          />
        </Flex>
      </Modal>

      {/* Delete feedback modal */}
      <Modal
        opened={feedbackDeleteModalOpen}
        onClose={closeDeleteFeedbackModal}
      >
        <Flex justify="between">
          <ModalHeader
            iconName="Document"
            content={"Suppression d'un retour avocat"}
            onClose={closeDeleteFeedbackModal}
          />
        </Flex>
        <Flex>
          <Text
            content={
              "En validant la suppression d'un retour avocat, tout son contenu sera supprimé. Cliquez sur oui pour valider la suppression et sur non pour l'annuler."
            }
            fontStyle="body1"
            marginLeft={{ xs: 'space16' }}
          />
        </Flex>
        <Flex justify="start" marginTop={{ xs: 'space24' }}>
          <Button
            marginRight={{ xs: 'space8' }}
            content="Oui, supprimer le retour de l'avocat"
            onClick={deleteFeedbackCb}
            $loading={deleteFeedbackState.loading}
          />
          <Button
            primary
            content={'Non, annuler la suppression'}
            onClick={closeDeleteFeedbackModal}
            marginLeft={{ xs: 'space8' }}
          />
        </Flex>
      </Modal>
    </>
  );
};

export default ConventionWritingView;
