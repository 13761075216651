import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CellProps, Column } from 'react-table';

// Components
import { Row, Col, Flex } from 'components/Layout';
import { TextEllipsis } from 'components/Text';
import { DateInput } from 'components/Forms';
import { Button } from 'components/Buttons';
import { Table } from 'components/Table';

// Data

// Utils
import { theme } from 'theme';
import { UploadModal } from 'components/Modal';
import AddressCard from '../cards/AddressCard';
import { useDownloadOpportunity } from 'utils/downloadFile';
import { AdminAppRoutes } from 'AdminApp';
import { parseISO } from 'date-fns';
import {
  FinalSending,
  ProcedureFull,
  ProcedureStepType,
  User,
} from '../../../types/resources';
import { useApi } from '../../../hooks/useApi';
import { useHistory } from 'react-router-dom';
import DocumentPreviewModal from '../../../components/Modal/DocumentPreviewModal';

function isSetGuard<T>(value: T | null | undefined): value is T {
  return value !== null && value !== undefined;
}

interface FinalSendingProps {
  procedure: ProcedureFull;
  refetchProcedure: () => void;
}

const FinalSendingPage: FC<FinalSendingProps> = ({
  procedure,
  refetchProcedure,
}) => {
  const step = ProcedureStepType.FINAL_SENDING;
  const name = 'Envoi final';
  const history = useHistory();
  const { t } = useTranslation();
  const { onDownload, loading: downloadLoading } = useDownloadOpportunity();
  const [uploadModalState, setUploadModalState] = useState<boolean>(false);
  const [previewModalState, setPreviewModalState] = useState<boolean>(false);
  const [selected, setSelected] = useState<FinalSending>();
  const pageSize = 2;
  const spouses = [procedure.spouse1, procedure.spouse2].filter(isSetGuard);

  const { execute: finishProcedure, state: finishProcedureState } = useApi(
    `/procedures/${procedure.id}/finish`,
    {
      method: 'POST',
      onSuccess: () => {
        history.push(AdminAppRoutes.CASES);
      },
    },
  );

  const { execute: getData, state: getDataState } = useApi<FinalSending[]>(
    `/procedures/${procedure.id}/final-sending`,
  );
  const finalSendingArray = getDataState.data?.value ?? [];

  const { execute: updateData, state: updateDataState } = useApi(
    `/procedures/${procedure.id}/final-sending`,
    {
      method: 'PUT',
      onSuccess: () => {
        onUploadClose();
        getData();
      },
    },
  );

  const finishStep = spouses.every((s) =>
    finalSendingArray.find((f) => f.spouse_id === s.id && f.document_id),
  );

  const onUploadOpen = (selected: FinalSending) => {
    setSelected(selected);
    setUploadModalState(true);
  };

  const onPreviewOpen = (selected: FinalSending) => {
    setSelected(selected);
    setPreviewModalState(true);
  };

  const onUploadClose = () => {
    setSelected(undefined);
    setUploadModalState(false);
  };

  const onPreviewClose = () => {
    setSelected(undefined);
    setPreviewModalState(false);
  };

  const onConfirmUpload = useCallback(
    (blob: Blob) => {
      if (selected) {
        const body = new FormData();
        body.append('file', blob, 'dossier final.pdf');
        body.append('spouse_id', selected.spouse_id);

        updateData({
          body,
          onSuccess: () => {
            onUploadClose();
            getData();
            refetchProcedure();
          },
        });
      }
    },
    [selected],
  );

  const columns: Column<User>[] = [
    {
      Header: t('letters.columns.name') || 'Nom',
      accessor: 'full_name',
      disableSortBy: true,
      Cell: ({ value }) => (
        <Flex height="100%" alignItems="center">
          <TextEllipsis fontStyle="body1" content={value} weight="medium" />
        </Flex>
      ),
    },
    {
      Header: t('letters.columns.send_date') || "Date d'envoi",
      id: 'sent_at',
      accessor: (originalRow) => originalRow,
      disableSortBy: true,
      width: 200,
      Cell: ({ cell }: CellProps<User>) => {
        const spouse = cell.row.original;
        const finalSending = finalSendingArray.find(
          (f) => f.spouse_id === spouse.id,
        );

        return (
          <DateInput
            onChange={(value) => {
              const isValid =
                value instanceof Date &&
                !isNaN(value.getTime()) &&
                value?.getFullYear() > 1900;

              if (isValid) {
                const body = new FormData();
                body.set('sent_at', value.toISOString());
                body.set('spouse_id', spouse.id);

                updateData({ body });
              }
            }}
            value={
              finalSending?.sent_at ? parseISO(finalSending.sent_at) : null
            }
          />
        );
      },
    },
    {
      Header: t(`letters.columns.upload_${step}`) || 'Télécharger le courrier',
      id: 'document_id',
      accessor: (originalRow) => originalRow,
      disableSortBy: true,
      width: 200,
      Cell: ({ cell }: CellProps<User>) => {
        const receipt = t(`formality_letters.buttons.receipt_${step}`);
        const upload = t(`letters.columns.upload_${step}`);
        const spouse = cell.row.original;
        const finalSending = finalSendingArray.find(
          (f) => f.spouse_id === spouse.id,
        );

        return (
          <Button
            onClick={() => {
              if (finalSending) {
                if (finalSending.document_id) {
                  onPreviewOpen(finalSending);
                } else {
                  onUploadOpen(finalSending);
                }
              }
            }}
            content={finalSending?.document_id ? receipt : upload}
            primary={!finalSending?.document_id}
            disabled={!finalSending?.sent_at}
          />
        );
      },
    },
  ];

  return (
    <>
      <Row marginBottom={{ xs: 'space32' }}>
        <Col xs={12}>
          <Table<User>
            data={spouses}
            columns={columns}
            pageSize={pageSize}
            totalCount={spouses.length}
            loadMore={() => getData()}
            noSeparator
            backgroundColor={theme.colors.beige}
            rounded
            asCard
          />
        </Col>
        {selected && (
          <UploadModal
            opened={uploadModalState}
            onClose={onUploadClose}
            onConfirm={onConfirmUpload}
            loading={updateDataState.loading}
            tip={false}
            documentName={name}
          />
        )}
        {selected?.document_id && (
          <DocumentPreviewModal
            opened={previewModalState}
            onClose={onPreviewClose}
            procedure={procedure}
            documentId={selected.document_id}
          />
        )}
      </Row>
      <Row marginBottom={{ xs: 'space32' }}>
        <Col xs={12}>
          <Button
            content={t('opportunity.finish')}
            disabled={!finishStep}
            primary
            $loading={finishProcedureState.loading || downloadLoading}
            onClick={async () => {
              await onDownload({
                opportunityId: procedure.id,
                filename: `${procedure.reference}-${procedure.name}`,
                onComplete: () => finishProcedure(),
              });
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={4}>
          <AddressCard
            customerId={procedure.spouse1_id}
            disabled={finishStep}
            index={1}
          />
        </Col>
        <Col xs={12} md={4}>
          <AddressCard
            customerId={procedure.spouse2_id}
            disabled={finishStep}
            index={2}
          />
        </Col>
      </Row>
    </>
  );
};

export default FinalSendingPage;
