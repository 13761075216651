import { FC } from 'react';
import styled from 'styled-components';

// Components
import { Container } from 'components/Layout';

// Container
import { RecapPaymentQuote } from 'container/public';

// Placeholder for GhostForm height in container
const GhostFormFooter = styled.div`
  height: 87px;
`;

const PaymentResume: FC = () => {
  return (
    <Container fluid="xl">
      <RecapPaymentQuote />
      <GhostFormFooter />
    </Container>
  );
};

export default PaymentResume;
