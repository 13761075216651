import { FC } from 'react';

import { theme } from 'theme';
import { Modal } from 'components/Modal/Modal';
import { useTranslation } from 'react-i18next';
import { Text } from 'components/Layout/Text';
import { Flex } from 'components/Layout';
import { Button, IconButton } from 'components/Buttons';

interface DeleteModalProps {
  onClose: () => void;
  onSubmit: (callback: () => void) => void;
  opened: boolean;
  title: string;
  content: string;
  loading: boolean;
}

const DeleteModal: FC<DeleteModalProps> = ({
  onClose,
  onSubmit,
  opened,
  title,
  content,
  loading,
}) => {
  const { t } = useTranslation();

  return (
    <Modal opened={opened} onClose={onClose}>
      <Flex marginBottom={{ xs: 'space16' }}>
        <IconButton
          iconName="Danger"
          color={theme.colors.white}
          backgroundColor={theme.colors.red1}
          rounded
        />
        <Flex alignItems="center">
          <Text
            content={title}
            fontStyle={'heading5'}
            marginLeft={{ xs: 'space16' }}
            weight="bold"
          />
        </Flex>
      </Flex>

      <Text
        fontStyle="body1"
        content={content}
        marginBottom={{ xs: 'space16' }}
        color={theme.colors.gray6}
      />

      <Flex direction={{ xs: 'row' }}>
        <Button
          content={t('cancel')}
          marginRight={{ xs: 'space24' }}
          onClick={onClose}
        />
        <Button
          content={t('delete')}
          textColor={theme.colors.white}
          backgroundColor={theme.colors.red1}
          onClick={() => {
            const callback = () => {
              onClose();
            };
            onSubmit(callback);
          }}
          $loading={loading}
        />
      </Flex>
    </Modal>
  );
};

export default DeleteModal;
