import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ConversationCard from 'components/Conversation/Card/Card';
import { Ghost } from 'components/Loading';
import { useHistory, useLocation } from 'react-router';
import { useAuth } from '../../../hooks/useAuth';
import { useApi } from '../../../hooks/useApi';
import {
  Conversation,
  ConversationFull,
  MessageFull,
  Role,
  User,
} from '../../../types/resources';
import { useQueryParams } from '../../../hooks/useQueryParams';
import { theme } from '../../../theme';
import ConversationList from '../../../components/Conversation/List/List';
import { Flex } from '../../../components/Layout';
import { useViewport } from '../../../hooks/useViewport';

interface ClientConversation {
  customerId: string;
  caseId: string;
}

const DEFAULT_ITEMS_PER_PAGE = 25;

const ClientConversation: FC<ClientConversation> = ({ customerId, caseId }) => {
  const location = useLocation<{ defaultMessage?: string }>();
  const { t } = useTranslation();
  const { user } = useAuth();
  const { isMobile } = useViewport();
  const history = useHistory();
  const query = useQueryParams();
  const conversationParam = query.get('conversation');
  const [activeConversationId, setActiveConversationId] = useState<
    string | undefined
  >(undefined);

  const { execute: getConversations, state: getConversationsState } = useApi<
    ConversationFull[]
  >(`/procedures/${caseId}/conversations?participant_id=${customerId}`);
  const conversations = getConversationsState.data?.value ?? [];
  const conversation = conversations.find((c) => c.id === activeConversationId);
  const loading = getConversationsState.loading;

  const [currentPage, setCurrentPage] = useState<number>(1);

  // Get messages
  const { execute: getMessages, state: getMessagesState } = useApi<
    MessageFull[]
  >(`/conversations/${conversation?.id}/messages`);
  const messages = getMessagesState.data?.value ?? [];
  const getMessagesCb = useCallback(() => {
    if (activeConversationId) {
      getMessages({
        endpoint: `/conversations/${activeConversationId}/messages`,
        query: {
          page: currentPage,
          items: DEFAULT_ITEMS_PER_PAGE,
        },
      });
    }
  }, [activeConversationId]);

  // Create messages
  const { execute: createMessage } = useApi<MessageFull[]>(
    `/conversations/${conversation?.id}/messages`,
    {
      method: 'POST',
      onSuccess: () => {
        getConversations();
        getMessages();
      },
    },
  );

  const navigateToConversation = (id: Conversation['id']) =>
    history.push({
      pathname: location.pathname,
      search: `?conversation=${id}`,
    });

  // Fetch user conversations when page opens
  useEffect(() => {
    getConversations();
  }, []);

  // Set active conversation when URL is specified
  useEffect(() => {
    if (conversations.length && conversationParam) {
      const conversation = conversations.find(
        (c) => c.id === conversationParam,
      );
      setActiveConversationId(conversation?.id);
    }
  }, [conversations, conversationParam]);

  // Fetch conversation messages when active conversation changes
  useEffect(() => {
    getMessagesCb();
  }, [activeConversationId]);

  // Navigate to first conversation of the list if no URL param is specified
  useEffect(() => {
    if (conversations.length && !conversationParam) {
      const [firstConversation] = conversations;
      navigateToConversation(firstConversation.id);
    }
  }, [conversations]);

  const handleSendMessage = useCallback(
    (content: { message: string; attachments: File[] }) => {
      if (!activeConversationId) return;

      const body = new FormData();
      body.append('content', content.message);

      for (const attachment of content.attachments) {
        body.append('attachments', attachment, attachment.name);
      }

      createMessage({ body });
    },
    [activeConversationId],
  );

  const messagesByDate = new Map<string, MessageFull[]>();

  messages.forEach((message) => {
    const messageDate = message.created_at.split('T')[0];

    if (!messagesByDate.has(messageDate)) {
      messagesByDate.set(messageDate, []);
    }

    messagesByDate.get(messageDate)?.push(message);
  });
  const formattedMessages = Array.from(messagesByDate, ([date, items]) => ({
    date,
    items,
  }));

  function getAuthorName(author?: Pick<User, 'id' | 'first_name' | 'role'>) {
    if (!author) {
      return 'Utilisateur introuvable';
    }
    if (author.id === user?.id) {
      return t('opportunity.conversation.me');
    }
    if (author.role !== Role.CUSTOMER) {
      return `${author.first_name} de WeDivorce`;
    }

    return author.first_name;
  }

  const conversationParticipants =
    conversation?.participants
      ?.filter((participant) => participant.id !== user?.id)
      ?.map((participant) => ({
        full_name: participant.first_name,
        role: participant.role,
      })) || [];
  const conversationMessages = formattedMessages.map((message) => ({
    date: message.date,
    items: message.items.map(({ author, ...item }) => ({
      ...item,
      fullname: getAuthorName(author),
      showOrangeIcon: author?.id === user?.id,
      hideRightSide: true,
    })),
  }));

  const handleFetchMore = () => {
    return; // TODO
    if (getMessagesState.data?.pagination?.total === messages.length) return;

    getMessages({
      query: {
        page: 1,
        items: DEFAULT_ITEMS_PER_PAGE,
      },
    });
  };

  const allConversations =
    conversations.map((conversation) => ({
      ...conversation,
      recipients: conversation.participants.map((participant) => {
        return {
          full_name: undefined,
          first_name: participant.first_name,
        };
      }),
      lastMessage: conversation.last_message?.content || '',
      active: conversation.id === activeConversationId,
      onClick: () => navigateToConversation(conversation.id),
    })) || [];

  return (
    <Flex
      direction={{ xs: 'column', lg: 'row' }}
      style={{ gap: theme.spacing.space12 }}
    >
      <div
        style={
          isMobile
            ? { padding: theme.spacing.space16 }
            : {
                width: '276px',
                minWidth: '276px',
              }
        }
      >
        {getConversationsState.loading ? (
          <Ghost width="100%" height="300px" shape="rect" rx={24} />
        ) : (
          <ConversationList conversations={allConversations} />
        )}
      </div>
      {conversation && (
        <ConversationCard
          conversation={conversation}
          recipients={conversationParticipants}
          messages={conversationMessages}
          onSendMessage={handleSendMessage}
          fetchMore={handleFetchMore}
          defaultContent={location?.state?.defaultMessage}
          canSendMessage={
            getConversationsState.data
              ? !getConversationsState.data.value.some(
                  (conv) => conv.unprocessed_messages,
                )
              : true
          }
        />
      )}
    </Flex>
  );
};

export default ClientConversation;
