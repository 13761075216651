import { ChangeEvent, FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';
import { useRouteMatch } from 'react-router-dom';

// Components
import { Modal } from 'components/Modal';
import { Text, Flex } from 'components/Layout';
import { IconButton, Button } from 'components/Buttons';
import { InputText } from 'components/FormTemplate/Fields/InputText';

import { theme } from 'theme';
import { md } from 'theme/styles/mediaQueries';
import { regexes } from 'utils/regex';
import analytics from 'utils/analytics';
import { useApi } from '../../../hooks/useApi';
import useToaster from '../../../hooks/useToaster';
export interface QuoteShareModalProps {
  opened: boolean;
  closeModal: () => void;
}

const CustomFlex = styled(Flex)`
  ${md(`
    min-width: 429px;
  `)}
`;

const QuoteShareModal: FC<QuoteShareModalProps> = ({ opened, closeModal }) => {
  const { t } = useTranslation();
  const { success: toasterSuccess, error: toasterError } = useToaster();
  const match = useRouteMatch();
  const [emailValue, setEmailValue] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [isValid, setIsValid] = useState<boolean>(false);
  const estimateId = get(match, 'params.id', '');
  const {
    execute: shareEstimate,
    state: { loading },
  } = useApi(`/estimates/${estimateId}/share`, { method: 'POST' });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget) {
      setEmailValue(e.currentTarget.value);
    }
  };

  const emailCheck = (email: string) => {
    const emailRegex = regexes.email;
    return emailRegex.test(email);
  };

  const handleBlur = () => {
    if (emailValue !== '' && !emailCheck(emailValue)) {
      setError(t('error.form.email'));
    }

    if (emailCheck(emailValue)) {
      setIsValid(true);
      setError('');
    } else {
      setIsValid(false);
    }
  };
  const onShare = async () => {
    handleBlur();

    if (isValid) {
      shareEstimate({
        body: { email: emailValue },
        onSuccess: () => {
          toasterSuccess(t('quote.resume.share_success'));
          closeModal();
          analytics?.track('QuotationShared');
        },
        onError: () => {
          toasterError(t('quote.resume.share_error'));
        },
      });
    }
  };

  useEffect(() => {
    if (error !== '') {
      handleBlur();
    }
  }, [emailValue]);

  useEffect(() => {
    if (opened) {
      setIsValid(false);
      setEmailValue('');
      setError('');
    }
  }, [opened]);

  return (
    <Modal opened={opened} height="auto" onClose={closeModal}>
      <CustomFlex alignItems="center">
        <IconButton
          color={theme.colors.salmon2}
          backgroundColor={theme.colors.salmon3}
          iconName="Edit"
          rounded
        />
        <Text
          fontStyle="heading5"
          content={t('quote.resume.share_title')}
          marginLeft={{ xs: 'space16' }}
        />
      </CustomFlex>
      <Text
        fontStyle="body2"
        content={t('quote.resume.share_description')}
        color={theme.colors.gray5}
        weight="medium"
        marginTop={{ xs: 'space16' }}
        marginBottom={{ xs: 'space16' }}
      />
      <Text
        fontStyle="body1"
        content={t('quote.resume.share_label')}
        color={theme.colors.gray5}
        marginBottom={{ xs: 'space8' }}
      />
      <InputText
        type="email"
        placeholder="john.doe@gmail.com"
        value={emailValue}
        onChange={handleChange}
        error={error}
        width="100%"
      />
      <Flex alignItems="center" marginTop={{ xs: 'space16' }}>
        <Button
          content={t('quote.resume.cancel')}
          marginRight={{ xs: 'space16' }}
          onClick={closeModal}
        />
        <Button
          content={t('quote.resume.share_actionButtonName')}
          primary
          $loading={loading}
          disabled={!!error || emailValue === ''}
          onClick={onShare}
        />
      </Flex>
    </Modal>
  );
};

export default QuoteShareModal;
