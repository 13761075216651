import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { theme } from 'theme';

// Components
import { Button } from 'components/Buttons';
import { DesktopOnly, Flex, Line, MobileOnly, Text } from 'components/Layout';
import { InputText } from 'components/FormTemplate/Fields/InputText';
import { Ghost } from 'components/Loading';
import { Card } from 'components/Cards';

// Hooks
import { useViewport } from 'hooks/useViewport';
import { Department, Role, User } from '../../../types/resources';
import { useApi } from '../../../hooks/useApi';

export const FlexWithBorderBottom = styled(Flex)`
  border-bottom: 1px solid ${theme.colors.gray3};
  padding: ${theme.spacing.space24} 0;

  &:first-child {
    padding: 14px 0;
  }

  &:last-child {
    border-bottom: none;
  }
`;

export interface AssignModalProps {
  onClose: () => void;
  content: {
    title: string;
    type: Role;
    opportunityIds: string[];
    role: Role;
    assignedRoleId: string | undefined | null;
    spouseId: string | undefined | null;
  };
  onAssign: (userId: string | null) => Promise<void>;
}

const AssignModal: FC<AssignModalProps> = ({ onClose, content, onAssign }) => {
  const { t } = useTranslation();
  const { isMobile } = useViewport();
  const [inputValue, setInputValue] = useState<string>('');
  const [loadingSubmitId, setLoadingSubmitId] = useState<string>();

  const { execute: getDepartments, state: getDepartmentsState } = useApi<
    Department[]
  >('/departments', { cache: true });
  const departments = getDepartmentsState.data?.value ?? [];

  const { execute: getUsers, state: getUsersState } = useApi<User[]>(`/users`);
  const users = getUsersState.data?.value ?? [];
  const loading = getUsersState.loading;

  useEffect(() => {
    getDepartments();
  }, []);

  const filteredUsers = users.filter((user) => {
    return user.id !== content.assignedRoleId;
  });

  const handleChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(target.value);
  };

  useEffect(() => {
    getUsers({
      query: {
        role: content.role,
        search: inputValue || undefined,
        sort: 'last_name',
      },
    });
  }, [inputValue, content]);

  const renderLoadingDesktop = () => (
    <>
      <FlexWithBorderBottom
        width="100%"
        justify={'between'}
        alignItems="center"
      >
        <Ghost width="100%" height={40} shape="rect" />
      </FlexWithBorderBottom>
      <FlexWithBorderBottom
        width="100%"
        justify={'between'}
        alignItems="center"
      >
        <Ghost width="100%" height={40} shape="rect" />
      </FlexWithBorderBottom>
      <FlexWithBorderBottom
        width="100%"
        justify={'between'}
        alignItems="center"
      >
        <Ghost width="100%" height={40} shape="rect" />
      </FlexWithBorderBottom>
    </>
  );

  return (
    <>
      <Flex justify="between">
        <Button
          content={t('close')}
          onClick={() => onClose()}
          iconLeft={{ name: 'close' }}
          primary={isMobile}
          size="small"
        />
        <Text
          content={content?.title || ''}
          fontStyle={isMobile ? 'heading5' : 'heading3'}
          textAlign={isMobile ? 'start' : 'center'}
          marginTop={{ xs: 'space32', md: 'space8' }}
          marginBottom={{ xs: 'none', md: 'space24' }}
        />
        <Button
          content={t('unassign')}
          onClick={() => onAssign(null)}
          iconLeft={{ name: 'Delete' }}
          size="small"
        />
      </Flex>
      <Flex
        marginTop={{ xs: 'space32', md: 'space32' }}
        marginBottom={{ xs: 'space16' }}
        width="100%"
      >
        <InputText
          icon={{ name: 'Search', primaryColor: theme.colors.salmon2 }}
          value={inputValue}
          onChange={handleChange}
          placeholder={t('search')}
          width="100%"
        />
      </Flex>
      <MobileOnly>
        {filteredUsers.map((user) => {
          return (
            <Flex key={user.id} marginBottom={{ xs: 'space16' }}>
              <Card>
                <Flex justify="between" marginBottom={{ xs: 'space24' }}>
                  <Flex direction={{ xs: 'column' }}>
                    <Text
                      fontStyle="body2"
                      content={
                        content.role === Role.LAWYER
                          ? t('opportunity.master')
                          : t('name')
                      }
                      weight="medium"
                      color={theme.colors.green1}
                      marginBottom={{ xs: 'space8' }}
                    />
                    <Text
                      fontStyle="body2"
                      content={user.full_name}
                      weight="bold"
                      color={theme.colors.black}
                    />
                  </Flex>
                  <Flex direction={{ xs: 'column' }}>
                    <Text
                      fontStyle="body2"
                      content={t('opportunity.department')}
                      weight="medium"
                      color={theme.colors.green1}
                      marginBottom={{ xs: 'space8' }}
                    />
                    <Text
                      fontStyle="body2"
                      content={
                        departments.find((d) => d.code === user.department_code)
                          ?.full_name || t('opportunity.unavailable')
                      }
                      color={theme.colors.black}
                      weight="bold"
                    />
                  </Flex>
                </Flex>
                <Line />
                <Button
                  content={t('assign')}
                  iconRight={{
                    name: 'ArrowRightSquare',
                  }}
                  fullWidth
                  onClick={async () => {
                    setLoadingSubmitId(user.id);
                    await onAssign(user.id);
                    setLoadingSubmitId('');
                  }}
                  marginTop={{ xs: 'space24' }}
                  $loading={loadingSubmitId === user.id}
                />
              </Card>
            </Flex>
          );
        })}
      </MobileOnly>
      <DesktopOnly>
        <FlexWithBorderBottom
          justify="between"
          width="100%"
          marginTop={{ xs: 'space16' }}
        >
          <Text
            content={t('user.last_name')}
            fontStyle="body2"
            color={theme.colors.gray5}
          />
          <Text
            content={t('user.department')}
            fontStyle="body2"
            color={theme.colors.gray5}
          />
          <Text content="" fontStyle="body2" width="100px" />
        </FlexWithBorderBottom>
        {loading && renderLoadingDesktop()}
        {filteredUsers.map((user) => {
          return (
            <FlexWithBorderBottom
              key={user.id}
              justify={'between'}
              alignItems="center"
              width="100%"
            >
              <Flex>
                <Text
                  content={user.full_name}
                  fontStyle="body1"
                  weight="medium"
                  width="150px"
                />
              </Flex>
              <Flex>
                <Text
                  content={
                    departments.find((d) => d.code === user.department_code)
                      ?.full_name || t('department.unavailable')
                  }
                  fontStyle="body1"
                  weight="medium"
                  width="150px"
                  marginLeft={{ xs: 'space8' }}
                />
              </Flex>
              <Flex justify="end">
                <Button
                  content={t('assign')}
                  primary
                  iconRight={{
                    name: 'ArrowRightSquare',
                    primaryColor: theme.colors.white,
                  }}
                  onClick={async () => {
                    setLoadingSubmitId(user.id);
                    await onAssign(user.id);
                    setLoadingSubmitId('');
                  }}
                  $loading={loadingSubmitId === user.id}
                />
              </Flex>
            </FlexWithBorderBottom>
          );
        })}
      </DesktopOnly>
    </>
  );
};

export default AssignModal;
