import { FC, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';

// Components

// Data

// Utils
import { useViewport } from 'hooks/useViewport';
import { getRouteWithParams } from 'utils/router';
import { PublicAppRoutes } from 'PublicApp';
import FormFooter from 'components/Layout/Footer/FormFooter';
import { scrollToTop } from 'utils/layout';
import { handleUpdatedDataEvent } from 'utils/analytics';
import { useAuth } from '../../../hooks/useAuth';
import {
  EstimateFull,
  EstimateStatus,
  Prospect,
} from '../../../types/resources';
import QuoteRegisterModal from './QuoteRegisterModal';

interface ResumeFooterProps {
  data?: EstimateFull;
  loading: boolean;
}

const ResumeFooter: FC<ResumeFooterProps> = ({ data, loading }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { isMobile } = useViewport();
  const match = useRouteMatch();
  const [openedRegisterModal, setOpenedRegisterModal] =
    useState<boolean>(false);
  const dealId = get(match, 'params.id', '');
  const { user } = useAuth();

  if (
    data &&
    !loading &&
    data?.status === EstimateStatus.WON &&
    data?.price !== data?.amount_paid
  ) {
    return (
      <>
        <QuoteRegisterModal
          estimate={data}
          opened={openedRegisterModal}
          closeModal={() => setOpenedRegisterModal(false)}
        />
        <FormFooter
          step={8}
          maxStep={9}
          onClick={() => {
            if (user) {
              history.push(
                getRouteWithParams('/recapitulatif-paiement/:id', {
                  id: dealId,
                }),
              );
              scrollToTop();
            } else {
              handleUpdatedDataEvent(data);
              setOpenedRegisterModal(true);
            }
          }}
          nextText={
            isMobile ? t('quote.payment_step_short') : t('quote.payment_step')
          }
          nextPrimary={true}
        />
      </>
    );
  }

  if (
    !loading &&
    data?.status === EstimateStatus.WON &&
    data?.price === data?.amount_paid
  ) {
    return (
      <FormFooter
        step={8}
        maxStep={8}
        onClick={() => {
          history.push('/connexion');
        }}
        nextText={t('quote.login')}
        nextPrimary={true}
      />
    );
  }

  return (
    <>
      {data && (
        <QuoteRegisterModal
          estimate={data}
          opened={openedRegisterModal}
          closeModal={() => setOpenedRegisterModal(false)}
        />
      )}
      <FormFooter
        step={8}
        maxStep={9}
        onClick={() => {
          if (user) {
            history.push(
              getRouteWithParams('/recapitulatif-paiement/:id', { id: dealId }),
            );
            scrollToTop();
          } else {
            handleUpdatedDataEvent(data);
            setOpenedRegisterModal(true);
          }
        }}
        prev={() => {
          const prospect = data?.prospects.reduce<Prospect | undefined>(
            (min, p) => {
              if (!min || p.created_at < min.created_at) return p;
              else return min;
            },
            undefined,
          );

          if (!user) {
            history.push({
              pathname: PublicAppRoutes.REGISTER,
              state: {
                from: history.location.pathname,
                email: prospect?.email,
                phone: prospect?.phone,
                estimate: data,
              },
            });
          }
        }}
        prevText={
          !user
            ? t(`quote.create_account${isMobile ? '_short' : ''}`)
            : undefined
        }
        iconLeft="Download"
        nextText={
          isMobile ? t('quote.payment_step_short') : t('quote.payment_step')
        }
        nextPrimary={true}
      />
    </>
  );
};

export default ResumeFooter;
