export const regexes = {
  email: /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/,
  phone: /^$|\+(?:[0-9] ?){6,14}[0-9]$/,
  lettersOnly: /[^0-9]$/,
  numbersOnly: /[0-9]$/,
  postalCode: /^[0-9]{5}$/,
  currencyNumber: /^[0-9]*\.?[0-9]*$/,
  password:
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^.&_*?])(?=.*[a-zA-Z0-9!@#$%^.&_*?]).{8,}$/,
};
