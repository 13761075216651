import { FC } from 'react';
import { Text } from 'components/Layout/Text';
import styled from 'styled-components';
// Components
import { theme } from 'theme';

const CardContainer = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.spacing.space40} ${theme.spacing.space64};
  background-color: ${theme.colors.beige};
  border-radius: ${theme.spacing.space24};
`;

export interface SuccessCardProps {
  title: string;
  description: string;
}

export const SuccessCard: FC<SuccessCardProps> = ({
  title,
  description,
  children,
}) => {
  return (
    <CardContainer>
      <Text
        content={title}
        fontStyle="heading3"
        marginBottom={{ xs: 'space16' }}
      />
      <Text
        content={description}
        fontStyle="body1"
        color={theme.colors.gray5}
      />
      {children}
    </CardContainer>
  );
};
