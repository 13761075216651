import Loader from 'react-loader-spinner';
import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { env } from '../../config/env';

import { theme } from 'theme';

import { Flex } from 'components/Layout';

interface AlmaWidgetProps {
  amount: number;
}

const ALMA_WIDGET_VERSION = '3.1.3'; // https://github.com/alma/widgets
const ALMA_CSS = `https://cdn.jsdelivr.net/npm/@alma/widgets@${ALMA_WIDGET_VERSION}/dist/widgets.min.css`;
const ALMA_SCRIPT = `https://cdn.jsdelivr.net/npm/@alma/widgets@${ALMA_WIDGET_VERSION}/dist/widgets.umd.js`;
const ALMA_WIDGET_ID = 'alma-widget';

const AlmaWrapper = styled.div`
  .alma-payment-plans-container {
    border-radius: ${theme.spacing.space12} !important;
    width: 100% !important;
  }
`;

export const AlmaWidget: FC<AlmaWidgetProps> = ({ amount }) => {
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = ALMA_SCRIPT;
    script.async = true;
    document.body.appendChild(script);

    const link = document.createElement('link');
    link.href = ALMA_CSS;
    link.rel = 'stylesheet';
    document.head.appendChild(link);

    script.onload = () => {
      setLoading(false);
    };

    return () => {
      document.body.removeChild(script);
      document.head.removeChild(link);
    };
  }, []);

  useEffect(() => {
    if (!loading && window?.Alma && amount) {
      const widget = window.Alma.Widgets.initialize(
        env.alma.merchantId,
        window.Alma.ApiMode.LIVE,
      );
      widget.add(window.Alma.Widgets.PaymentPlans, {
        container: `#${ALMA_WIDGET_ID}`,
        purchaseAmount: amount * 100,
        locale: 'fr',
        monochrome: false,
      });
    }
  }, [loading, amount, window?.Alma]);

  return (
    <AlmaWrapper>
      {loading ? (
        <Flex justify="center" alignItems="center">
          <Loader
            type="TailSpin"
            height={24}
            width={24}
            color={theme.colors.salmon1}
          />
        </Flex>
      ) : (
        <div id={ALMA_WIDGET_ID} />
      )}
    </AlmaWrapper>
  );
};

export default AlmaWidget;
