import { formatPrice } from './format';

export const getStepPercentage = (current: number, total: number): number => {
  return (current / total) * 100;
};

export const getProgressPercentage = (
  active: boolean,
  isDone: boolean,
  nextIsActive: boolean,
): number => {
  if ((active && nextIsActive) || isDone) return 110;
  if (active) return 50;
  return 0;
};

export const getPricePerSpouse = (price: number) => {
  return formatPrice(price / 2);
};

export function humanFileSize(bytes: number) {
  const thresh = 1000;
  const decimals = 2;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' o';
  }

  const units = ['ko', 'Mo', 'Go'];
  let u = -1;
  const r = 10 ** decimals;

  do {
    bytes /= thresh;
    ++u;
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  );

  return bytes.toFixed(decimals) + ' ' + units[u];
}
