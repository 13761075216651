import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

// Components
import { ClientCard } from 'components/Cards';
import { theme } from 'theme';
import { Text } from 'components/Layout/Text';
import { env } from '../../../config/env';

const Wrapper = styled(ClientCard)`
  height: auto;
`;

const Card = styled.div`
  background-color: ${theme.colors.beige};
  border-radius: ${theme.spacing.space16};
  padding: ${theme.spacing.space16};
`;

const RegisteredLettersInfo = () => {
  const { t } = useTranslation();
  const legalReflexionPeriod = Number(env.procedure.legalReflectionPeriod);

  return (
    <Wrapper iconName="Message" title={t('registered_letters.info.title')}>
      <Card>
        <Text
          content={t('registered_letters.info.card_title')}
          fontStyle="body1"
          weight="bold"
          marginBottom={{ xs: 'space8' }}
        />
        <Text
          dangerouslySetInnerHTML={{
            __html: t('registered_letters.info.card_description', {
              duration: legalReflexionPeriod,
            }),
          }}
          fontStyle="body2"
          color={theme.colors.gray6}
        />
      </Card>
    </Wrapper>
  );
};

export default RegisteredLettersInfo;
