import { AlmaWidget } from 'components/Alma';
import { FC } from 'react';
import { Flex } from 'components/Layout/Flex';
import LargeCard from './LargeCard';
import Line from 'components/Layout/Line';
import { PricesProps } from 'container/public/QuoteResume/PriceResume';
import { Text } from 'components/Layout/Text';
import { md } from 'theme/styles/mediaQueries';
import styled from 'styled-components';
import { theme } from 'theme';
import { useTranslation } from 'react-i18next';
import { useViewport } from 'hooks/useViewport';

const CustomLargeCard = styled(LargeCard)`
  height: 100%;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  position: relative;
  top: -30px;

  ${md(`
    position: initial;
    border-top-right-radius: ${theme.spacing.space24};
    border-top-left-radius: ${theme.spacing.space24};
  `)}
`;

interface PaymentCardProps {
  prices: PricesProps;
}

const PaymentCard: FC<PaymentCardProps> = ({ prices }) => {
  const { t } = useTranslation();
  const { isMobile } = useViewport();
  const { fullyPaid } = prices;

  if (fullyPaid) {
    return (
      <CustomLargeCard>
        <Flex marginBottom={{ xs: 'space16', md: 'space24' }}>
          <Text
            content={t('quote.resume.title_full')}
            fontStyle="heading3"
            weight="bold"
          />
        </Flex>
        <Flex marginBottom={{ xs: 'space24' }}>
          <Line />
        </Flex>
        <Flex direction={{ xs: 'column' }}>
          <Text
            content={t('quote.resume.fully_paid')}
            fontStyle="body2"
            color={theme.colors.gray5}
            weight="medium"
          />
        </Flex>
      </CustomLargeCard>
    );
  }

  return (
    <CustomLargeCard>
      <Flex
        direction={{ xs: 'column' }}
        marginBottom={{ xs: 'space16', md: 'space24' }}
      >
        <Text
          content={t(`quote.resume.payment_title${isMobile ? '_short' : ''}`)}
          fontStyle="heading3"
          weight="bold"
        />
        {isMobile && (
          <Text
            content={t(`quote.resume.pay_in_installments`)}
            fontStyle="label"
            weight="bold"
            color={theme.colors.salmon2}
            marginTop={{ xs: 'space16' }}
          />
        )}
      </Flex>
      <Flex marginBottom={{ xs: 'space24' }}>
        <Line />
      </Flex>
      <Flex direction={{ xs: 'column' }}>
        <Text
          content={t(
            `quote.resume.payment_description${isMobile ? '_short' : ''}`,
          )}
          fontStyle="body2"
          marginBottom={{ xs: 'space24', md: 'space40' }}
          color={theme.colors.gray5}
          weight="medium"
        />
        {/* <Flex direction={{ xs: 'column' }} marginBottom={{ xs: 'space8' }}>
          <SmallCard
            title={t('quote.resume.payment_four_times')}
            price={t('quote.resume.payment_monthly', { price: priceFourTimes })}
            isMobile={isMobile}
          />
          <SmallCard
            title={t('quote.resume.payment_twelve_times')}
            price={t('quote.resume.payment_monthly', {
              price: priceTwelveTimes,
            })}
            isMobile={isMobile}
          />
        </Flex>
        <Text
          fontStyle="body2"
          content={t('quote.resume.with_fees')}
          color={theme.colors.gray5}
        /> */}
      </Flex>
      <AlmaWidget amount={prices.totalPrice / 2} />
    </CustomLargeCard>
  );
};

export default PaymentCard;
