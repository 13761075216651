import { FC } from 'react';
import { FieldProps } from '@rjsf/core';

import { Input } from 'components/FormTemplate/Fields/Input';
import RequiredField from 'components/Forms/Utils/RequiredField';

import get from 'lodash/get';
import { Flex } from 'components/Layout/Flex';
import styled from 'styled-components';
import { Label } from 'components/Text/Label';
import { theme } from 'theme';
import { replaceLabelWithContext } from '../Utils/ReplaceLabel';

const InputContainer = styled.div`
  position: relative;
`;

const Span = styled.span`
  position: absolute;
  right: ${theme.spacing.space24};
  top: 50%;
  transform: translateY(-50%);
`;

export const Percentage: FC<FieldProps> = ({
  id,
  required,
  readonly,
  disabled,
  schema,
  uiSchema,
  onChange,
  formData,
  name,
  formContext,
}) => {
  const title = get(schema, 'title', '');
  const label = replaceLabelWithContext(title, formContext.data);

  const placeholder = get(uiSchema, 'ui:placeholder', null);

  return (
    <Flex direction={{ xs: 'column' }}>
      {label && (
        <Flex
          alignItems="center"
          marginBottom={{ xs: 'space16', md: 'space24' }}
        >
          <RequiredField required={required}>
            <Label dangerouslySetInnerHTML={{ __html: label }} />
          </RequiredField>
        </Flex>
      )}
      <InputContainer>
        <Input
          id={id}
          placeholder={placeholder}
          required={required}
          disabled={disabled || readonly}
          type="number"
          name={name}
          value={formData || ''}
          onChange={({ target }) => onChange(Number(target.value).toString())}
          max={100}
          min={1}
          step={10}
          hasIcon={true}
        />
        <Span>%</Span>
      </InputContainer>
    </Flex>
  );
};
