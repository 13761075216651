import { FC, useEffect } from 'react';
import styled from 'styled-components';

import logoBlack from 'assets/logo/logo-black.svg';
import { PasswordChangeForm } from 'container/public';

import { Text } from 'components/Layout';

import { md } from 'theme/styles/mediaQueries';
import { theme } from 'theme';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Page404 } from '../common';
import { useApi } from '../../hooks/useApi';

const BackgroundWrapper = styled.div`
  background-image: url('/auth-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  height: calc(100vh - 70px);
  display: flex;

  ${md(`
    padding: ${theme.spacing.space40} 0;
  `)}
`;

const FormWrapper = styled.div`
  margin: auto;
  max-width: 600px;
  padding: ${theme.spacing.space56} ${theme.spacing.space40};
  background: ${theme.colors.gray1};
  border: 1px solid ${theme.colors.gray3};

  ${md(`
    border-radius: ${theme.spacing.space24};
  `)}
`;

const Image = styled.img``;

const PasswordChange: FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const hash = searchParams.get('token');
  const { execute: activateRequest } = useApi(`/auth/password-reset`);
  const displayErrorPage = () => {
    return <Page404 isAdmin={false} full />;
  };

  if (!hash) {
    return displayErrorPage();
  }

  useEffect(() => {
    if (hash) {
      activateRequest({
        query: { hash: hash },
        onError: () => {
          displayErrorPage();
        },
      });
    }
  }, [hash]);

  return (
    <>
      <BackgroundWrapper>
        <FormWrapper>
          <Image src={logoBlack} alt="WeDivorce" />
          <Text
            content={t('passwordChange.title')}
            fontStyle="heading4"
            color={theme.colors.black}
            marginTop={{ xs: 'space32' }}
            marginBottom={{ xs: 'space24' }}
          />
          <Text
            content={t('passwordChange.subTitle')}
            fontStyle="body1"
            color={theme.colors.black}
            marginBottom={{ xs: 'space32' }}
          />
          <PasswordChangeForm hash={hash} />
        </FormWrapper>
      </BackgroundWrapper>
    </>
  );
};

export default PasswordChange;
