import { Text } from 'components/Layout';
import React, {
  ChangeEventHandler,
  Dispatch,
  MouseEventHandler,
  PropsWithChildren,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import styled from 'styled-components';
import { theme } from 'theme';

interface InputRadioOptionProps<T> {
  value: T;
  label?: string;
  defaultSelected?: boolean;
  checked?: boolean;
  name?: string;
  errored?: boolean;
  onClick?: MouseEventHandler<HTMLDivElement>;
  onChange?: ChangeEventHandler<HTMLInputElement>;
}

interface InputRadioProps<T> {
  name: string;
  options: InputRadioOptionProps<T>[];
  handleChange: Dispatch<SetStateAction<T>>;
}

const Container = styled.div<{ errored?: boolean }>`
  height: auto;
  width: 100%;
  display: flex;
  align-items: center;
  border: 1px solid ${theme.colors.gray4};
  border-radius: ${theme.spacing.space24};
  height: 70px;
  padding: 0 24px;
  margin-bottom: ${theme.spacing.space16};
  cursor: pointer;

  ${({ errored }) =>
    errored &&
    `
    border-color: ${theme.colors.red1};
    color: ${theme.colors.red1};
  `}
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  box-sizing: border-box;
`;

const RadioButtonLabel = styled.label`
  position: absolute;
  top: calc(50% - 12px);
  left: 0;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: transparent;
  border: 2px solid ${theme.colors.green1};
  display: flex;
  align-items: center;
  justify-content: center;
`;
const RadioButton = styled.input<{ name?: string; errored?: boolean }>`
  opacity: 0;
  z-index: 1;
  cursor: pointer;
  width: 24px;
  height: 24px;
  margin-right: 16px;
  &:checked + ${RadioButtonLabel} {
    &::after {
      content: '';
      border-radius: 50%;
      background-color: ${theme.colors.green1};
      width: 16px;
      height: 16px;
    }
  }
`;

export const InputRadioOption = <T extends string>(
  props: PropsWithChildren<InputRadioOptionProps<T>>,
) => {
  const { label, children, value, onClick, onChange, checked, name, errored } =
    props;

  return (
    <Container errored={errored} onClick={onClick}>
      <Item>
        <RadioButton
          type="radio"
          name={name}
          value={value}
          checked={checked}
          onChange={onChange}
        />
        <RadioButtonLabel />
      </Item>
      {label && <Text content={label} fontStyle="body1" />}
      {children}
    </Container>
  );
};

const InputRadio = <T extends string>(props: InputRadioProps<T>) => {
  const { options, handleChange, name } = props;
  const [select, setSelect] = useState<string>();

  const handleSelectChange = (value: T) => {
    if (value !== select) {
      setSelect(value);
      handleChange(value);
    }
  };

  useEffect(() => {
    if (options?.length > 0) {
      const defaultSelected = options.find((option) => option?.defaultSelected);

      if (defaultSelected) {
        setSelect(defaultSelected.value);
      }
    }
  }, []);

  return (
    <>
      {options.map(({ value, label }) => (
        <InputRadioOption
          key={value}
          value={value}
          onClick={() => handleSelectChange(value)}
          checked={select === value}
          name={name}
          label={label}
          onChange={(event) => handleSelectChange(event.target.value as T)}
        />
      ))}
    </>
  );
};

export default InputRadio;
