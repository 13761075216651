import { createBrowserHistory } from 'history';
import * as Sentry from '@sentry/react';
import { Env } from './env';

export function initSentry(env: Env) {
  const isProd = env.envName === 'production';
  if (!isProd) {
    console.warn('sentry', 'initializing sentry');
  }

  if (Sentry.isInitialized()) {
    return;
  }

  const history = createBrowserHistory();

  Sentry.init({
    enabled: !!env.sentry.dsn,
    dsn: env.sentry.dsn,
    environment: env.envName,
    release: env.version,
    integrations: [
      Sentry.reactRouterV5BrowserTracingIntegration({ history }),
      Sentry.browserProfilingIntegration(),
      Sentry.replayIntegration(),
    ],

    attachStacktrace: true,
    tracesSampleRate: 1.0, //  Capture 100% of the transactions

    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],

    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}
