import { FC, useCallback, useEffect } from 'react';
import { Document, DocumentFull, Procedure } from '../../types/resources';
import { useApi } from '../../hooks/useApi';
import { Flex, Line, Text } from '../Layout';
import { Button, IconButton } from '../Buttons';
import { theme } from '../../theme';
import { Preview } from '../Viewer';
import styled from 'styled-components';
import { Modal } from './Modal';
import { useTranslation } from 'react-i18next';
import { downloadFileNew } from '../../utils/downloadFile';
import { formatDocumentTitle } from '../../utils/format';

const CustomModal = styled(Modal)`
  padding: 0;
`;

const Wrapper = styled.div`
  display: grid;
  height: 100%;
`;

const CustomLine = styled(Line)`
  height: 100%;
`;

const CustomFlex = styled(Flex)`
  width: 740px;
`;

const PickerWrapper = styled.div`
  width: 100%;
  height: 500px;
`;

const GhostElement = styled.div``;

const Header = styled(Flex)``;

const RelativeWrapper = styled.div`
  position: relative;
`;

const FixedWrapper = styled.div`
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${theme.colors.white};
`;

type DocumentPreviewModalProps = {
  procedure: Procedure;
  documentId: Document['id'];
  opened: boolean;
  onClose: () => void;
};

const DocumentPreviewModal: FC<DocumentPreviewModalProps> = ({
  procedure,
  documentId,
  opened,
  onClose,
}) => {
  const { t } = useTranslation();

  const { execute: getDocument, state: getDocumentState } =
    useApi<DocumentFull>(`/procedures/${procedure.id}/documents/${documentId}`);
  const document = getDocumentState.data?.value;

  const { execute: downloadDocument, state: downloadDocumentState } = useApi<{
    url: string;
  }>(`/procedures/${procedure.id}/documents/${documentId}/download`);

  const getDocumentCb = useCallback(() => {
    getDocument();
  }, [documentId, procedure]);

  const downloadDocumentCb = useCallback(() => {
    if (document?.file_id) {
      downloadDocument({
        onSuccess: async (result) => {
          try {
            await downloadFileNew(
              result.value.url,
              formatDocumentTitle(document),
              procedure.reference.toString(),
            );
          } catch (e) {
            console.error(e);
          }
        },
      });
    }
  }, [procedure, document]);

  const handleDownloadClick = () => {
    downloadDocumentCb();
  };

  useEffect(() => {
    if (opened) {
      getDocumentCb();
    }
  }, [procedure, documentId, opened]);

  if (!document?.file) return null;

  return (
    <CustomModal opened={opened} onClose={onClose}>
      <RelativeWrapper>
        <Header
          alignItems="center"
          justify="between"
          paddingTop={{ xs: 'space24' }}
          paddingRight={{ xs: 'space16' }}
          paddingBottom={{ xs: 'space24' }}
          paddingLeft={{ xs: 'space16' }}
        >
          <Flex alignItems="center">
            <IconButton
              iconName="Document"
              color={theme.colors.salmon2}
              backgroundColor={theme.colors.salmon3}
              rounded
            />
            <Text
              fontStyle="heading5"
              content={formatDocumentTitle(document)}
              marginLeft={{ xs: 'space16' }}
            />
          </Flex>
          <Button
            content={t('upload.download')}
            iconLeft={{ name: 'Download' }}
            onClick={() => handleDownloadClick()}
            $loading={downloadDocumentState.loading}
          />
        </Header>
        <Line />
        <Wrapper>
          <CustomFlex
            direction={{ xs: 'column' }}
            justify="between"
            alignItems="center"
          >
            <PickerWrapper>
              <Preview procedure={procedure} document={document} />
            </PickerWrapper>
            <GhostElement />
          </CustomFlex>
          <CustomLine />
        </Wrapper>
        <FixedWrapper>
          <Line />
          <Flex
            direction={{ xs: 'column', md: 'row' }}
            justify="between"
            paddingRight={{ xs: 'space40' }}
            paddingTop={{ xs: 'space16' }}
            paddingLeft={{ xs: 'space40' }}
            paddingBottom={{ xs: 'space16' }}
          >
            <Button
              content={t('upload.back')}
              marginRight={{ xs: 'none', md: 'space16' }}
              marginBottom={{ xs: 'space16', md: 'none' }}
              onClick={onClose}
              iconLeft={{ name: 'ArrowLeftSquare' }}
            />
          </Flex>
        </FixedWrapper>
      </RelativeWrapper>
    </CustomModal>
  );
};

export default DocumentPreviewModal;
