// Components
import { Col, Container, Row } from 'components/Layout';

import { Button } from 'components/Buttons';
import { FC, useEffect } from 'react';
import { SuccessCard } from 'components/Cards/SuccessCard';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useQueryParams } from 'hooks/useQueryParams';
import analytics, { Event } from 'utils/analytics';
import { useApi } from '../../hooks/useApi';
import { EstimateFull, Product } from '../../types/resources';
import { useAuth } from '../../hooks/useAuth';

const SuccessfulPayment: FC = () => {
  const { t } = useTranslation();
  const { user, isLoading } = useAuth();
  const history = useHistory();
  const query = useQueryParams();

  const estimateId = query.get('estimate_id') || '';
  const totalPaid = query.get('totalPaid') || 0;
  const modality = query.get('modality') || 1;

  const { execute: getProducts, state: getProductsState } = useApi<Product[]>(
    `/products?enabled=true`,
  );
  const products = getProductsState.data?.value;

  const { execute: getEstimate, state: getEstimateState } =
    useApi<EstimateFull>(`/estimates/${estimateId}`);
  const estimate = getEstimateState.data?.value;

  useEffect(() => {
    getEstimate();
    getProducts();
  }, []);

  const handleCta = () => {
    history.push({
      pathname: '/',
    });
  };

  useEffect(() => {
    if (!estimate || !products?.length || !analytics) return;

    const productsItems = estimate.products.reduce<
      {
        id: Product['code'];
        name: Product['name'];
        price: Product['price'];
        quantity: number;
      }[]
    >((acc, ep) => {
      const product = products.find((p) => p.id === ep.product_id);
      if (product) {
        acc.push({
          id: product.code,
          name: product.name,
          price: product.price,
          quantity: ep.quantity,
        });
      }

      return acc;
    }, []);

    analytics.track(Event.Purchase, {
      ecommerce: {
        purchase: {
          actionField: {
            id: estimateId,
            revenueDirect: totalPaid,
          },
          products: productsItems,
          financialModality: modality,
          financialOption: estimate.split_payments ? 'split' : 'totality',
        },
      },
    });
  }, [estimate, products, analytics]);

  if (isLoading) return <></>;

  return (
    <>
      <Container fluid="xl">
        <Row marginTop={{ xs: 'space40', md: 'space40' }}>
          <Col xs={0} md={2}>
            <></>
          </Col>
          <Col xs={12} md={8}>
            <SuccessCard
              title={
                !!user
                  ? t('quote.success_logged.title')
                  : t('quote.success.title')
              }
              description={
                !!user
                  ? t('quote.success_logged.description')
                  : t('quote.success.description')
              }
            >
              {!!user && (
                <Button
                  primary
                  content={t('quote.success_logged.cta')}
                  onClick={handleCta}
                  marginTop={{ xs: 'space24' }}
                />
              )}
            </SuccessCard>
          </Col>
          <Col xs={0} md={2}>
            <></>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default SuccessfulPayment;
