import { FC, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { theme } from 'theme';
import { Flex } from 'components/Layout/Flex';
import { Text } from 'components/Layout/Text';
import { LargeCard } from 'components/Cards';
import Line from 'components/Layout/Line';
import { useViewport } from 'hooks/useViewport';
import QuoteShareModal from 'container/public/QuoteResume/QuoteShareModal';
import { PricesProps } from 'container/public/QuoteResume/PriceResume';
import { md } from 'theme/styles/mediaQueries';

const StyledSmallCard = styled(Flex)`
  width: 100%;
  padding: ${theme.spacing.space24} ${theme.spacing.space16};
  border-radius: ${theme.spacing.space24};
  background-color: ${theme.colors.gray3};
  margin-bottom: ${theme.spacing.space16};

  &:last-of-type {
    margin-bottom: ${theme.spacing.none};
  }
`;

const CustomLargeCard = styled(LargeCard)`
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;

  ${md(`
    border-bottom-right-radius: ${theme.spacing.space24};
    border-bottom-left-radius: ${theme.spacing.space24};
  `)}
`;

interface PriceCardProps {
  prices: PricesProps;
}

interface SmallCardProps {
  title: string;
  price: string;
  priceColor: string;
  smallText?: boolean;
}

const SmallCard: FC<SmallCardProps> = ({
  title,
  price,
  priceColor,
  smallText,
}) => {
  const { t } = useTranslation();
  const { isMobile } = useViewport();

  return (
    <StyledSmallCard
      direction={{ xs: 'column', md: 'row' }}
      marginBottom={{ xs: 'space16', md: 'none' }}
      justify="between"
      alignItems={isMobile ? 'start' : 'center'}
    >
      <Text
        content={title}
        fontStyle={isMobile ? 'body1' : smallText ? 'body2' : 'heading5'}
        weight={isMobile ? 'medium' : 'bold'}
        color={isMobile ? theme.colors.gray5 : theme.colors.black}
        family={theme.fontFamily.inter}
        marginBottom={{ xs: 'space8', md: 'none' }}
      />
      <Flex alignItems="end">
        <Text
          content={`${price}${isMobile ? '€' : ''}`}
          fontStyle={
            isMobile ? 'heading2' : smallText ? 'heading3' : 'heading2'
          }
          color={priceColor}
        />
        {!isMobile && (
          <Text
            content={t('quote.payment.currency')}
            fontStyle="heading5"
            color={priceColor}
          />
        )}
      </Flex>
    </StyledSmallCard>
  );
};

const PriceCard: FC<PriceCardProps> = ({ prices }) => {
  const { t } = useTranslation();
  const { totalPrice, pricePerSpouse } = prices;
  const { isMobile } = useViewport();
  const justify = isMobile ? 'start' : 'between';
  const align = isMobile ? 'start' : 'center';
  const [openedShareModal, setOpenedShareModal] = useState(false);

  return (
    <>
      <QuoteShareModal
        opened={openedShareModal}
        closeModal={() => setOpenedShareModal(false)}
      />
      <CustomLargeCard>
        <Flex
          direction={{ xs: 'column', md: 'row' }}
          marginBottom={{ xs: 'space24' }}
          justify={justify}
          alignItems={align}
        >
          <Text
            content={t(`quote.resume.price_title${isMobile ? '_short' : ''}`)}
            fontStyle="heading3"
            weight="bold"
            marginBottom={{ xs: 'space16', md: 'none' }}
          />
          <div
            style={{ cursor: 'pointer' }}
            onClick={() => setOpenedShareModal(true)}
          >
            <Text
              content={t('quote.resume.share')}
              fontStyle="label"
              color={theme.colors.salmon2}
              weight="bold"
              transform="uppercase"
              hoverColor={theme.colors.salmon1}
            />
          </div>
        </Flex>
        <Flex marginBottom={{ xs: 'space24' }}>
          <Line />
        </Flex>
        <Flex direction={{ xs: 'column' }}>
          <Text
            content={t('quote.resume.price_description')}
            fontStyle="body2"
            marginBottom={{ xs: 'space24' }}
            color={theme.colors.gray5}
            weight="medium"
          />
          <Flex direction={{ xs: 'column' }}>
            <SmallCard
              title={t('quote.payment.spouse_price')}
              price={t('quote.resume.price_without_currency', {
                price: pricePerSpouse,
              })}
              priceColor={theme.colors.salmon2}
            />
            <SmallCard
              title={t('quote.resume.price_total')}
              price={t('quote.resume.price_without_currency', {
                price: totalPrice,
              })}
              priceColor={theme.colors.black}
              smallText
            />
          </Flex>
        </Flex>
      </CustomLargeCard>
    </>
  );
};

export default PriceCard;
