import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';

import { theme } from 'theme';
import { Spacing } from 'theme/styles/spacing';

// Components
import { Text } from 'components/Layout/Text';
import { Flex } from 'components/Layout/Flex';
import { IconButton } from 'components/Buttons/IconButton';
import { Icon } from 'components/Images';
import { IconProps } from 'components/Images/Icon';
import { DropdownIcon } from 'components/Buttons';

import { useViewport } from 'hooks/useViewport';

type CardType = 'info' | 'danger' | 'warning' | 'success';

const CardContainer = styled.div<CardContainerProps>`
  width: 100%;
  height: auto;
  padding: ${theme.spacing.space24};
  background-color: ${(props) => {
    switch (props.type) {
      case 'warning':
        return theme.colors.salmon3;
      case 'danger':
        return theme.colors.pink2;
      case 'info':
        return theme.colors.beige;
      case 'success':
        return '#DFF1E6';
      default:
    }
  }};
  border-radius: ${theme.spacing.space16};
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  border: none;
  margin-top: ${theme.spacing.space16};
  background-color: transparent;
  cursor: pointer;
`;

interface CardContainerProps {
  type: CardType;
}

export interface TipCardProps {
  title: string;
  type: CardType;
  icon?: IconProps['name'];
  content?: string;
  onClick?: () => void;
  buttonText?: string;
  accordion?: boolean;
}

interface StyledText {
  fontFamily?: string;
  fontWeight?: string;
  textTransform?: string;
  marginLeft?: string;
  marginBottom?: string;
  fontSize?: string;
}

const StyledText = styled.p<StyledText>`
  font-family: ${(props) => props.fontFamily || theme.fontFamily.inter};
  font-weight: ${(props) => props.fontWeight || theme.weight.regular};
  text-transform: ${(props) =>
    props.textTransform || theme.font.heading5.textTransform};
  margin-left: ${(props) => props.marginLeft || theme.spacing.space16};
  ${(props) =>
    props.marginBottom ? `margin-bottom: ${props.marginBottom}` : ''};
  font-size: ${(props) => props.fontSize || theme.size.size3};
`;

const AnimatedStyledText = styled(StyledText)<
  StyledText & { accordionOpen: boolean }
>`
  transition: max-height 0.3s ease-in-out;
  overflow: hidden;
  max-height: ${(props) => (props.accordionOpen ? '1000px' : '0')};
`;

const TipCard: FC<TipCardProps> = ({
  title,
  content,
  icon = 'Notification',
  type,
  onClick,
  buttonText,
  children,
  accordion = false,
}) => {
  const { isMobile } = useViewport();
  const [isHover, setIsHover] = useState<boolean>(false);
  const [accordionOpen, setAccordionOpen] = useState<boolean>(false);
  const [titleBottomSpacing, setTitleBottomSpacing] = useState<Spacing>(
    !accordion && (content || buttonText) ? 'space16' : 'none',
  );

  const toggleAccordion = () => {
    setAccordionOpen((prev) => {
      if (!prev) {
        setTitleBottomSpacing('space16');
      }
      return !prev;
    });
  };

  useEffect(() => {
    if (accordion) {
      if (isMobile) {
        setTitleBottomSpacing('none');
      } else {
        setAccordionOpen(false);
        setTitleBottomSpacing(
          !accordion && (content || buttonText) ? 'space16' : 'none',
        );
      }
    }
  }, [accordion, isMobile]);

  return (
    <CardContainer type={type}>
      <Flex
        marginBottom={{
          xs: titleBottomSpacing,
        }}
      >
        <Flex>
          <Icon
            name={icon}
            set="light"
            stroke="bold"
            primaryColor="black"
            size="medium"
          />
        </Flex>
        {title && (
          <StyledText
            dangerouslySetInnerHTML={{ __html: title }}
            marginBottom="0"
            marginLeft={isMobile ? theme.spacing.space8 : undefined}
            fontWeight={content ? theme.weight.bold : 'regular'}
            fontSize={isMobile ? theme.size.size2 : undefined}
          />
        )}
        {accordion && (
          <Flex marginLeft={{ xs: 'space8' }}>
            <DropdownIcon onClick={toggleAccordion} active={accordionOpen} />
          </Flex>
        )}
        {children && <Flex>{children}</Flex>}
      </Flex>
      {!accordion && content && (
        <StyledText
          dangerouslySetInnerHTML={{ __html: content }}
          fontSize={theme.size.size2}
          color={theme.colors.gray6}
          marginLeft="0"
          marginBottom="0"
        />
      )}
      {accordion && content && (
        <AnimatedStyledText
          dangerouslySetInnerHTML={{ __html: content }}
          fontSize={theme.size.size2}
          color={theme.colors.gray6}
          marginLeft="0"
          marginBottom="0"
          accordionOpen={accordionOpen}
          onTransitionEnd={() => {
            !accordionOpen && setTitleBottomSpacing('none');
          }}
        />
      )}
      {buttonText && (
        <ButtonWrapper
          onClick={onClick}
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
        >
          <IconButton
            iconName="ArrowRight"
            color={isHover ? theme.colors.white : theme.colors.red1}
            backgroundColor={isHover ? theme.colors.red1 : theme.colors.red2}
            size="small"
            onClick={onClick}
            rounded
          />
          <Text
            content={buttonText}
            fontStyle="label"
            transform="uppercase"
            weight="bold"
            marginLeft={{ xs: 'space8' }}
          />
        </ButtonWrapper>
      )}
    </CardContainer>
  );
};

export default TipCard;
