import { DeleteModal, FileModal, UploadModal } from 'components/Modal';
import { Dispatch, FC, SetStateAction, useCallback, useState } from 'react';
import { Flex, Line, Text } from 'components/Layout';

import { ButtonProps } from 'components/Buttons/Button';
import { IconlyIcon } from 'types/react-iconly';
import { ListItem } from 'components/List';
import { theme } from 'theme';
import { useTranslation } from 'react-i18next';
import { DocumentFull, ProcedureFull } from '../../../../types/resources';
import { useApi } from '../../../../hooks/useApi';
import useToaster from '../../../../hooks/useToaster';
import { generateFileName } from '../../../../utils/downloadFile';
import { formatDocumentTitle } from '../../../../utils/format';

interface DocumentInProgressProps {
  procedure: ProcedureFull;
  count?: number;
  documents: DocumentFull[];
  refetchDocuments: () => void;
  editTitle: Dispatch<
    SetStateAction<{
      id: string;
      name: string;
    } | null>
  >;
}

const DocumentInProgress: FC<DocumentInProgressProps> = ({
  procedure,
  documents,
  refetchDocuments,
  editTitle,
}) => {
  const { t } = useTranslation();
  const { success: toasterSuccess } = useToaster();
  const [selectedDocument, setSelectedDocument] = useState<DocumentFull | null>(
    null,
  );
  const [documentToUpload, setDocumentToUpload] = useState<DocumentFull | null>(
    null,
  );
  const [documentToDeleteId, setDocumentToDeleteId] = useState<string>('');
  const [modalDeleteOpened, toggleModalDelete] = useState<boolean>(false);

  const {
    execute: deleteDocument,
    state: { loading: deleteDocumentLoading },
  } = useApi(`/procedures/${procedure.id}/documents`, {
    method: 'DELETE',
    onSuccess: () => {
      toasterSuccess(t('documents.modal.success'));
      handleCloseDeleteModal();
      refetchDocuments();
    },
  });

  const deleteDocumentCb = (documentId: string) => {
    deleteDocument({
      endpoint: `/procedures/${procedure.id}/documents/${documentId}`,
    });
  };

  const {
    execute: uploadDocument,
    state: { loading: uploadDocumentLoading },
  } = useApi(`/procedures/${procedure.id}/documents`, {
    method: 'POST',
    onSuccess: () => {
      onModalClose();
      refetchDocuments();
    },
  });

  const handleOpenDeleteModal = (id: string) => {
    setDocumentToDeleteId(id);
    toggleModalDelete(true);
  };

  const handleCloseDeleteModal = () => {
    setDocumentToDeleteId('');
    toggleModalDelete(false);
  };
  const onModalClose = () => {
    setDocumentToUpload(null);
  };

  const onConfirmUpload = useCallback(
    (blob: Blob) => {
      if (documentToUpload) {
        const filename = generateFileName(
          formatDocumentTitle(documentToUpload),
          procedure.reference.toString(),
        );
        const body = new FormData();
        body.append('file', blob, filename);

        uploadDocument({
          endpoint: `/procedures/${procedure.id}/documents/${documentToUpload.id}/upload`,
          body,
          onSuccess: onModalClose,
        });
      }
    },
    [documentToUpload],
  );

  return (
    <>
      <Flex direction={{ xs: 'column' }} width="100%">
        <Flex
          marginBottom={{ xs: 'space16' }}
          justify="between"
          alignItems="center"
        >
          <Text
            fontStyle="heading5"
            weight="bold"
            content={t('documents.in_progress')}
          />
          <Text
            fontStyle="body2"
            weight="medium"
            content={t('documents.count', {
              count: documents.length,
            })}
            color={theme.colors.gray6}
          />
        </Flex>
        <Flex
          width="100%"
          marginBottom={{
            xs: documents && documents.length > 0 ? 'space24' : 'none',
          }}
        >
          <Line />
        </Flex>
      </Flex>
      {documents.map((document) => {
        const buttons: Array<ButtonProps | null> = [
          {
            onClick: () => setDocumentToUpload(document),
            content: t('documents.add'),
            primary: true,
            disabled: false,
          },
          document.file_id
            ? {
                onClick: () => setSelectedDocument(document),
                content: t('documents.see'),
                primary: false,
                disabled: false,
              }
            : null,
          {
            content: t('documents.buttons.delete'),
            primary: false,
            onClick: () => handleOpenDeleteModal(document.id),
            iconLeft: {
              name: 'Delete' as keyof typeof IconlyIcon,
            },
          },
        ];
        return (
          <Flex width="100%" marginBottom={{ xs: 'space16' }} key={document.id}>
            <ListItem
              title={formatDocumentTitle(document)}
              iconName="Document"
              primary={true}
              buttons={buttons}
              type={document.document_template_id}
              hasLink={true}
              editTitle={() =>
                editTitle({ id: document.id, name: document.title })
              }
            />
          </Flex>
        );
      })}
      {!!documentToUpload && (
        <UploadModal
          opened={!!documentToUpload}
          onClose={onModalClose}
          onConfirm={onConfirmUpload}
          loading={uploadDocumentLoading}
          template={documentToUpload.document_template_id}
          comments={documentToUpload.documents_comments}
          documentName={
            formatDocumentTitle(documentToUpload) || 'Ajouter un document'
          }
          documentFilename={generateFileName(
            formatDocumentTitle(documentToUpload),
            procedure.reference.toString(),
          )}
        />
      )}
      {!!selectedDocument && (
        <FileModal
          opened={!!selectedDocument}
          onClose={() => setSelectedDocument(null)}
          document={selectedDocument}
          refetchDocuments={refetchDocuments}
          procedure={procedure}
        />
      )}
      <DeleteModal
        onClose={handleCloseDeleteModal}
        onSubmit={() => {
          deleteDocumentCb(documentToDeleteId);
        }}
        opened={modalDeleteOpened}
        title={t('documents.modal.title')}
        content={t('documents.modal.content')}
        loading={deleteDocumentLoading}
      />
    </>
  );
};

export default DocumentInProgress;
