import { FC } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { Container, Row, Col } from 'components/Layout';
import { SuccessCard } from 'components/Cards/SuccessCard';
import { Button } from 'components/Buttons';
import { useQueryParams } from 'hooks/useQueryParams';
import { useHistory } from 'react-router';
import { PublicAppRoutes } from 'PublicApp';
import { getRouteWithParams } from 'utils/router';

const ErrorPayment: FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const query = useQueryParams();
  const dealId = query.get('estimate_id');

  return (
    <>
      <Container fluid="xl">
        <Row marginTop={{ xs: 'none', md: 'space40' }}>
          <Col xs={0} md={2}>
            <></>
          </Col>
          <Col xs={12} md={8}>
            <SuccessCard
              title={t('quote.error.title')}
              description={t('quote.error.description')}
            >
              {dealId && (
                <Button
                  marginTop={{ xs: 'space32' }}
                  content={t('back')}
                  iconLeft={{ name: 'ArrowLeft' }}
                  onClick={() =>
                    history.push(
                      getRouteWithParams(PublicAppRoutes.QUOTE_RESUME, {
                        id: dealId as string,
                      }),
                    )
                  }
                />
              )}
            </SuccessCard>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ErrorPayment;
