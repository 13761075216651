import { FC, useEffect } from 'react';
import { get } from 'lodash';
import { useRouteMatch } from 'react-router';

/** Components */

/** Container */
import { SingleDocument } from 'container/admin';
import { useApi } from '../../hooks/useApi';
import { DocumentFull, ProcedureFull } from '../../types/resources';

const DocumentPage: FC = () => {
  const match = useRouteMatch();
  const procedureId = get(match, 'params.opportunityId', '');
  const id = get(match, 'params.documentId', null);

  const { execute: getProcedure, state: getProcedureState } =
    useApi<ProcedureFull>(`/procedures/${procedureId}`);
  const procedure = getProcedureState.data?.value;

  const { execute: getDocuments, state: getDocumentsState } = useApi<
    DocumentFull[]
  >(`/procedures/${procedureId}/documents?is_administrative=true`);
  const documents = getDocumentsState.data?.value;

  const { execute: getDocument, state: getDocumentState } =
    useApi<DocumentFull>(`/procedures/${procedureId}/documents/${id}`);
  const document = getDocumentState.data?.value;

  useEffect(() => {
    getProcedure();
    getDocuments();
  }, []);

  useEffect(() => {
    getDocument();
  }, [id]);

  if (!procedure || !documents || !document) return null;

  return (
    <SingleDocument
      procedure={procedure}
      documents={documents}
      document={document}
    />
  );
};

export default DocumentPage;
