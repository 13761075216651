import { FC, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Controller, useForm } from 'react-hook-form';

// Component
import { Text, Flex } from 'components/Layout';
import { ConfirmForm } from 'components/ConfirmPopin/ConfirmPopin';
import { Modal } from 'components/Modal';
import { InputText } from 'components/FormTemplate/Fields/InputText';
import { Button } from 'components/Buttons';
import { InputTel } from 'components/FormTemplate/Fields/InputTel';

//data
import { Breadcrumbs } from 'container/admin';

// Utils
import { AdminAppRoutes } from 'AdminApp';
import { theme } from 'theme';
import { regexes } from 'utils/regex';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { useApi } from '../../hooks/useApi';
import InputCheckbox from '../../components/FormTemplate/Fields/InputCheckbox';
import useToaster from '../../hooks/useToaster';
import { UserGender } from '../../types/resources';
import InputSelect from '../../components/FormTemplate/Fields/InputSelect';

const FullWidth = styled.div`
  grid-column: 1/-1;
`;

type CreateCustomerInput = {
  email: string;
  first_name: string;
  last_name: string;
  department_code: string;
  phone: string;
  gender: string | undefined;
  accept_terms_date?: Date;
  accept_data_business_usage?: Date;
  accept_marketing_contact?: boolean;
  address?: CreateCustomerAddressInput;
};

type CreateCustomerAddressInput = {
  street: string;
  city: string;
  post_code: string;
  country: string;
};

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const Form = styled.form`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 38px 32px;
  width: 928px;
  margin-top: ${theme.spacing.space48};
`;

const CustomersAdd: FC = () => {
  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
    clearErrors,
    setValue,
  } = useForm<CreateCustomerInput>();
  const { success: toastSuccess, error: toastError } = useToaster();
  const { t } = useTranslation();
  const [address, setAddress] = useState<boolean>(false);
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [result, setResult] = useState<CreateCustomerInput>();
  const history = useHistory();

  const genderOptions = Object.values(UserGender).map((value) => ({
    value: value,
    label: t(`gender.${value}`),
  }));

  const onSubmit = (data: CreateCustomerInput) => {
    setResult(data);
    setModalIsOpen(true);
  };

  const onClose = () => {
    setModalIsOpen(false);
  };

  const {
    execute: createCustomerRequest,
    state: { loading, success },
  } = useApi('/customers', { method: 'POST' });

  const goBack = () => {
    history.push(AdminAppRoutes.CUSTOMERS);
  };

  const createCustomer = useCallback(() => {
    if (result) {
      createCustomerRequest({
        body: {
          ...result,
          address: address ? result.address : undefined,
        },
        onSuccess: () => {
          toastSuccess(t('customer.add.success'));
        },
        onError: () => {
          toastError(t('customer.add.error'));
        },
      });
    }
  }, [result]);

  useEffect(() => {
    if (success) {
      goBack();
    }
  }, [success]);

  return (
    <Container>
      <Modal opened={modalIsOpen} onClose={onClose}>
        <ConfirmForm
          title={t('customer.add.confirm_title')}
          description={t('customer.add.confirm_description')}
          confirmText={t('customer.add.confirm')}
          handleSubmit={createCustomer}
          setModalIsOpen={setModalIsOpen}
          loading={loading}
        />
      </Modal>
      <Breadcrumbs />
      <Flex direction={{ xs: 'row' }} alignItems="center">
        <Text
          content={t('customer.add.title')}
          fontStyle="heading2"
          weight="bold"
          marginRight={{ xs: 'space32' }}
        />
      </Flex>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <InputText
          {...register('email', {
            required: t('error.form.required').toString(),
            pattern: {
              value: regexes.email,
              message: t('error.form.email'),
            },
          })}
          error={errors.email}
          label={t('customer.email')}
          placeholder={t('customer.email')}
          width="100%"
          required
        />

        <Controller
          control={control}
          name="phone"
          rules={{
            required: t('error.form.required').toString(),
            validate: (value?: string) => {
              if (value && !isValidPhoneNumber(value)) {
                return t('error.form.phone').toString();
              }
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <InputTel
              label={t('customer.phone')}
              placeholder={t('customer.phone')}
              error={error}
              {...field}
              onChange={(value) => {
                setValue(`phone`, value);
                clearErrors('phone');
              }}
              required
            />
          )}
        />

        <InputText
          {...register('first_name', {
            required: t('error.form.required').toString(),
          })}
          error={errors.first_name}
          label={t('customer.first_name')}
          placeholder={t('customer.first_name')}
          width="100%"
          required
          uppercase
        />
        <InputText
          {...register('last_name', {
            required: t('error.form.required').toString(),
          })}
          error={errors.last_name}
          label={t('customer.last_name')}
          placeholder={t('customer.last_name')}
          width="100%"
          required
          uppercase
        />

        <Controller
          control={control}
          name="gender"
          render={({ field, fieldState: { error } }) => (
            <InputSelect
              label={t('user.gender')}
              placeholder={t('user.gender')}
              options={genderOptions}
              error={error}
              {...field}
            />
          )}
        />

        <Flex></Flex>

        <FullWidth>
          <InputCheckbox
            onChange={(value) => {
              setAddress(value);
            }}
            fontWeight="bold"
            align="center"
            checkPoint
            checked={address}
            label={t(`customer.address.address`)}
          />
        </FullWidth>

        {address && (
          <>
            <InputText
              {...register('address.street', {
                required: address
                  ? t('error.form.required').toString()
                  : undefined,
              })}
              error={errors.address?.street}
              label={t('customer.address.street')}
              placeholder={t('customer.address.street')}
              width="100%"
              required={address}
            />

            <InputText
              {...register('address.city', {
                required: address
                  ? t('error.form.required').toString()
                  : undefined,
              })}
              error={errors.address?.city}
              label={t('customer.address.city')}
              placeholder={t('customer.address.city')}
              width="100%"
              required={address}
            />

            <InputText
              {...register('address.post_code', {
                required: address
                  ? t('error.form.required').toString()
                  : undefined,
              })}
              error={errors.address?.post_code}
              label={t('customer.address.post_code')}
              placeholder={t('customer.address.post_code')}
              width="100%"
              required={address}
            />

            <InputText
              {...register('address.country', {
                required: address
                  ? t('error.form.required').toString()
                  : undefined,
              })}
              error={errors.address?.country}
              label={t('customer.address.country')}
              placeholder={t('customer.address.country')}
              width="100%"
              required={address}
            />
          </>
        )}

        <Controller
          control={control}
          name="accept_terms_date"
          rules={{
            required: t('error.form.required').toString(),
            validate: (value) => {
              if (!value) {
                return t('error.form.checkbox').toString();
              }
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <InputCheckbox
              {...field}
              checked={!!field.value}
              onChange={(val) => {
                setValue(`accept_terms_date`, val ? new Date() : undefined);
              }}
              fontWeight="bold"
              align="center"
              checkPoint
              error={!!error}
              label={t(`customer.accept_terms_date`)}
            />
          )}
        />
        <Controller
          control={control}
          name="accept_data_business_usage"
          rules={{
            required: t('error.form.required').toString(),
          }}
          render={({ field, fieldState: { error } }) => (
            <InputCheckbox
              {...field}
              checked={!!field.value}
              onChange={(val) => {
                setValue(
                  `accept_data_business_usage`,
                  val ? new Date() : undefined,
                );
              }}
              fontWeight="bold"
              align="center"
              checkPoint
              error={!!error}
              label={t(`customer.accept_data_business_usage`)}
            />
          )}
        />
        <Controller
          control={control}
          name="accept_marketing_contact"
          rules={{
            validate: (value) => {
              if (value === undefined) {
                return t('error.form.checkbox').toString();
              }
            },
          }}
          defaultValue={false}
          render={({ field, fieldState: { error } }) => (
            <InputCheckbox
              {...field}
              checked={!!field.value}
              onChange={(value) => {
                setValue(`accept_marketing_contact`, !!value);
              }}
              fontWeight="bold"
              align="center"
              checkPoint
              error={!!error}
              label={t(`customer.accept_marketing_contact`)}
            />
          )}
        />

        <FullWidth>
          <Flex>
            <Button
              onClick={goBack}
              content={t('back')}
              marginRight={{ sm: 'space24' }}
            />
            <Button
              content={t('customer.add.title')}
              type="submit"
              $loading={loading}
              primary
            />
          </Flex>
        </FullWidth>
      </Form>
    </Container>
  );
};

export default CustomersAdd;
