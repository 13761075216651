import { ProcedureStepStatus, ProcedureStepType } from '../types/resources';

export const getTypeByStatus = (status?: ProcedureStepStatus) => {
  switch (status) {
    case ProcedureStepStatus.IN_PROGRESS:
      return 'warning';
    case ProcedureStepStatus.IN_REVIEW:
      return 'info';
    case ProcedureStepStatus.LOCKED:
      return 'danger';
    default:
    case ProcedureStepStatus.VALIDATED:
      return 'success';
  }
};

export const getHideByStatus = (
  step: ProcedureStepType,
  status: ProcedureStepStatus,
) => {
  if (step === ProcedureStepType.WRITING_AGREEMENT) {
    return true;
  }
  if (step === ProcedureStepType.VERIFICATION) {
    return true;
  }
  if (
    step === ProcedureStepType.NOTARY &&
    status === ProcedureStepStatus.IN_PROGRESS
  ) {
    return true;
  }
  if (
    step === ProcedureStepType.MARITAL_STATUS &&
    status === ProcedureStepStatus.IN_PROGRESS
  ) {
    return true;
  }
  if (step === ProcedureStepType.CLOSED) {
    return true;
  }
  if (status === ProcedureStepStatus.LOCKED) {
    return true;
  }
};
