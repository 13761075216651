import { FC, MouseEventHandler, useState } from 'react';
// Components
import { Icon, IconProps } from 'components/Images/Icon';
import styled, { CSSProperties } from 'styled-components';

import { Text } from 'components/Layout/Text';
// Utils
import { getColors } from 'utils/layout';
import { hover } from 'theme/styles/hover';
import { theme } from 'theme';

const ButtonContainer = styled.button<ButtonContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  padding: ${(props) => (props.size === theme.spacing.space24 ? '4px' : '8px')};
  border: ${(props) => (props.border ? `1px solid ${props.border}` : 'none')};
  border-radius: ${(props) => props.borderRadius};
  background-color: ${(props) => props.backgroundColor};
  transition: all 0.5s ease;

  &:not(:disabled) {
    cursor: ${(props) => (props.onClick ? 'pointer' : 'inherit')};
  }

  ${(props) =>
    hover(`
    &:hover {
      background-color: ${props.hoverBackgroundColor};
    }
  `)}
`;

interface ButtonContainerProps {
  backgroundColor: string;
  borderRadius: string;
  border?: string;
  size?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  hoverBackgroundColor?: string;
  type?: string;
}

interface Props {
  color: string;
  backgroundColor: string;
  border?: string;
  onClick?: MouseEventHandler<HTMLButtonElement> | (() => void);
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  primary?: boolean;
  rounded?: boolean;
  set?: IconProps['set'];
  stroke?: IconProps['stroke'];
  size?: IconProps['size'] | 'xs';
  hoverBackgroundColor?: string;
  hoverIconColor?: string;
  style?: CSSProperties;
  className?: string;
  notification?: 'xs' | 'md';
}

interface IconNameProps extends Props {
  iconName: IconProps['name'];
  letters?: string;
}

interface LettersProps extends Props {
  letters: string;
  iconName?: IconProps['name'];
}

export type IconButtonProps = IconNameProps | LettersProps;

export const IconButton: FC<IconButtonProps> = ({
  backgroundColor,
  color,
  border,
  iconName,
  letters,
  onClick,
  onMouseEnter,
  onMouseLeave,
  stroke = 'bold',
  set,
  primary,
  rounded,
  size = 'medium',
  hoverBackgroundColor,
  hoverIconColor,
  style,
  className,
  notification,
}) => {
  const [hoverState, setHoverState] = useState(false);

  const getButtonSize = (size: IconProps['size'] | 'xs') => {
    switch (size) {
      case 'xs':
        return theme.spacing.space24;
      case 'small':
        return theme.spacing.space32;
      case 'large':
        return theme.spacing.space48;
      case 'extra-large':
        return theme.spacing.space56;
      case 'extra-extra-large':
        return theme.spacing.space64;
      case 'extra-extra-extra-large':
        return theme.spacing.space128;
      case 'medium':
      default:
        return theme.spacing.space40;
    }
  };

  const handleMouseEnter = () => {
    setHoverState(true);
    if (onMouseEnter) {
      onMouseEnter();
    }
  };

  const handleMouseLeave = () => {
    setHoverState(false);
    if (onMouseLeave) {
      onMouseLeave();
    }
  };

  const getTextStyle = (size: IconProps['size'] | 'xs') => {
    switch (size) {
      case 'extra-large':
        return 'body1';
      default:
        return 'body2';
    }
  };

  const { bgColor, bdColor, txtColor } = getColors(
    primary,
    false,
    backgroundColor,
    color,
    border,
  );
  const radius = rounded ? '50%' : '12px';
  if (letters)
    return (
      <ButtonContainer
        onClick={onClick}
        backgroundColor={bgColor}
        borderRadius={radius}
        border={bdColor}
        size={getButtonSize(size)}
        style={style}
        className={className}
        as={onClick ? 'button' : 'span'}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Text
          content={letters}
          fontStyle={getTextStyle(size)}
          color={hoverState && hoverIconColor ? hoverIconColor : txtColor}
          textAlign="center"
          weight="bold"
        />
      </ButtonContainer>
    );
  if (iconName) {
    return (
      <ButtonContainer
        onClick={onClick}
        backgroundColor={bgColor}
        borderRadius={radius}
        border={bdColor}
        size={getButtonSize(size)}
        hoverBackgroundColor={hoverBackgroundColor}
        style={style}
        className={className}
        as={onClick ? 'button' : 'span'}
        type={onClick ? 'button' : ''}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Icon
          name={iconName}
          primaryColor={
            hoverState && hoverIconColor ? hoverIconColor : txtColor
          }
          size={size === 'xs' ? 'small' : size}
          set={set}
          stroke={stroke}
          fill="none"
          notification={notification}
        />
      </ButtonContainer>
    );
  }

  return null;
};
