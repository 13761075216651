import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { OptionTypeBase } from 'react-select/src/types';
import { WidgetProps } from '@rjsf/core';
import get from 'lodash/get';

import { Flex } from 'components/Layout/Flex';
import InputSelect from 'components/FormTemplate/Fields/InputSelect';
import { Label } from 'components/Text/Label';
import RequiredField from 'components/Forms/Utils/RequiredField';
import { FormContext, FormContextDataKeys } from 'wrapper/FormWrapper';
import { replaceLabelWithContext } from 'components/Forms/Utils/ReplaceLabel';
import { isEqual, isObject } from 'lodash';
import { JSONSchema7 } from 'json-schema';

interface CustomEnums {
  key: string;
  title: string;
}

export const Select: FC<WidgetProps> = (props) => {
  const { schema, uiSchema, onChange, value, formData, required, disabled } =
    props;
  const inputEl = useRef<{ select: { clearValue: () => void } }>(null);
  const formContext: FormContext = props.formContext;
  const placeholder = get(uiSchema, 'ui:placeholder', null);
  const sort = get(uiSchema, 'ui:sort', false);
  const title = get(schema, 'title', '');
  const label = replaceLabelWithContext(title, formContext.data);
  const enums = get(schema, 'enum', []) as CustomEnums[] | string[];

  const options = useMemo(
    () =>
      enums.map((o, index) => ({
        value: isObject(o) ? o.key : schema.enum?.[index] || o,
        label: isObject(o)
          ? (formContext?.data &&
              formContext?.data[o.key as keyof typeof FormContextDataKeys]) ||
            replaceLabelWithContext(o.title, formContext.data)
          : replaceLabelWithContext(
              (schema as JSONSchema7 & { enumNames?: string[] }).enumNames?.[
                index
              ] || o,
              formContext.data,
            ),
        isDisabled:
          get(formContext, `disabledOptions[${uiSchema.key}]`)?.includes(
            isObject(o) ? o.key : o,
          ) || false,
      })),
    [enums, formContext],
  );

  const [currentOptions, setCurrentOptions] =
    useState<OptionTypeBase[]>(options);

  const _onChange = (option: string) => onChange(option);

  useEffect(() => {
    if (!isEqual(options, currentOptions)) {
      inputEl.current?.select.clearValue();
    }
    setCurrentOptions(options);
  }, [options, formContext]);

  return (
    <Flex direction={{ xs: 'column' }} width="inherit">
      {label && (
        <Flex marginBottom={{ xs: 'space16', md: 'space24' }}>
          <RequiredField required={props.required}>
            <Label dangerouslySetInnerHTML={{ __html: label }} />
          </RequiredField>
        </Flex>
      )}
      <InputSelect
        ref={inputEl}
        onChange={_onChange}
        options={currentOptions}
        placeholder={placeholder}
        required={required}
        value={value && value !== '' ? value : formData}
        isSortable={sort}
        disabled={disabled}
      />
    </Flex>
  );
};
