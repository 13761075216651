import { FC, PropsWithChildren, useEffect, useRef } from 'react';

import { Button } from 'components/Buttons';
import { CustomIcon } from 'theme/styles/icons';
import { Flex } from 'components/Layout';
import { IconlyIcon } from 'types/react-iconly';
import { ProgressBar } from 'components/Forms/ProgressBar';
import { md } from 'theme/styles/mediaQueries';
import styled from 'styled-components';
// Components
import { theme } from 'theme';

const FooterContainer = styled(Flex)`
  justify-content: space-between;
  position: relative;
  padding: ${theme.spacing.space16};
  width: 100%;
  height: 70px;
  border-top: 2px solid ${theme.colors.gray3};
  flex-wrap: nowrap;
  white-space: nowrap;

  ${md(`
    justify-content: center;
  `)}
`;

const ProgressBarWrapper = styled.div`
  position: absolute;
  width: 100%;
  top: -3px;
  z-index: 1;
`;

export interface FormFooterProps {
  step: number;
  maxStep: number;
  next?: () => void;
  prev?: () => void;
  prevText?: string;
  nextText: string;
  disabledPrev?: boolean;
  disabledNext?: boolean;
  hideNext?: boolean;
  nextButtonComponent?: FC;
  nextPrimary?: boolean;
  progressMaxStep?: number;
  $loading?: boolean;
  onClick?: () => void;
  iconRight?: keyof typeof IconlyIcon | keyof typeof CustomIcon;
  iconLeft?: keyof typeof IconlyIcon | keyof typeof CustomIcon;
  autoNavigate?: boolean;
  disableAutoNavigationAtStep?: number;
  minimizeButtonsSpacing?: boolean;
  isDirty?: boolean;
}

const Footer = styled.footer`
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  z-index: 4;
`;

const FormFooter: FC<PropsWithChildren<FormFooterProps>> = ({
  step,
  maxStep,
  progressMaxStep,
  next,
  prev,
  prevText,
  nextText,
  disabledPrev,
  disabledNext,
  hideNext,
  children,
  nextPrimary = true,
  $loading = false,
  onClick,
  iconRight,
  iconLeft,
  autoNavigate = false,
  disableAutoNavigationAtStep,
  minimizeButtonsSpacing = false,
  isDirty = false,
}) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const timer = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const shouldAutoNavigate =
      autoNavigate &&
      (disableAutoNavigationAtStep === undefined ||
        step < disableAutoNavigationAtStep);

    if (shouldAutoNavigate) {
      if (!disabledNext && isDirty && step !== maxStep) {
        timer.current = setTimeout(() => {
          buttonRef?.current?.click();
        }, 600);

        return () => {
          if (timer?.current) {
            clearTimeout(timer.current);
          }
        };
      }
    }
  }, [disabledNext, autoNavigate, buttonRef?.current, isDirty]);

  const handleNextClick = () => {
    if (timer?.current) {
      clearTimeout(timer.current);
    }

    if (next) {
      next();
    }
    if (onClick) {
      onClick();
    }
  };

  return (
    <Footer>
      <ProgressBarWrapper>
        <ProgressBar
          currentStep={step}
          totalStep={progressMaxStep || maxStep}
        />
      </ProgressBarWrapper>
      <FooterContainer alignItems="center">
        {prevText && (
          <Button
            content={prevText}
            iconLeft={{ name: iconLeft || 'ArrowLeftSquare' }}
            onClick={prev}
            disabled={step === 0 || disabledPrev}
            marginRight={{ xs: minimizeButtonsSpacing ? 'none' : 'space16' }}
            fontSize="14px"
            size="small"
          />
        )}
        <Button
          ref={buttonRef}
          id="submit-footer" // don't change this. Use for submit button
          form="form" // don't change this. Use for submit button
          hide={hideNext}
          content={nextText}
          iconRight={{ name: iconRight || 'ArrowRightSquare' }}
          onClick={handleNextClick}
          primary={nextPrimary}
          disabled={disabledNext}
          marginLeft={{ xs: minimizeButtonsSpacing ? 'space8' : 'space16' }}
          type="submit"
          fontSize="14px"
          size="small"
          $loading={$loading}
        />
        {children}
      </FooterContainer>
    </Footer>
  );
};

export default FormFooter;
