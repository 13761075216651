import { FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { theme } from 'theme';

import { Chip } from 'components/Cards';
import { Icon, IconProps } from 'components/Images/Icon';
import { Flex, Requires, Text } from 'components/Layout';
import { DocumentLink } from 'components/Documents';
import { Button, OpportunityStatusButton } from 'components/Buttons';
import { ButtonProps } from 'components/Buttons/Button';

import { md } from 'theme/styles/mediaQueries';
import { DocumentTemplateId } from '../../types/resources';

interface ListItemProps {
  title: string;
  iconName: IconProps['name'];
  primary?: boolean;
  validated?: boolean;
  chip?: string;
  dates?: string[];
  buttons?: Array<ButtonProps | null>;
  type?: DocumentTemplateId;
  hasLink?: boolean;
  iconColor?: string;
  editTitle?: () => void;
  onChipClick?: () => void;
}

interface ItemProps {
  primary?: boolean;
}

const Item = styled.div<ItemProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: ${theme.spacing.space16} ${theme.spacing.space24};
  background-color: ${theme.colors.white};
  border: 1px solid ${theme.colors.gray2};
  border-radius: ${theme.spacing.space24};
  min-height: ${theme.spacing.space80};
  ${md(`
    min-height: ${theme.spacing.space88};
  `)}
  ${({ primary }) =>
    primary &&
    `
    background-color: ${theme.colors.beige};
    border: none;
  `};
`;

const Anchor = styled.div<{ editable: boolean }>`
  cursor: ${({ editable }) => (editable ? 'pointer' : 'default')};
  display: flex;
  align-items: center;
  &:hover {
    text-decoration: ${({ editable }) => (editable ? 'underline' : 'none')};
  }
`;

const CustomStatus = styled(OpportunityStatusButton)`
  margin-right: ${theme.spacing.space32};
  height: 34px;
`;

const IconWrapper = styled.div`
  min-width: 16px;
  min-height: 16px;
  margin-right: 16px;
`;

const ListItem: FC<ListItemProps> = ({
  title,
  iconName,
  primary,
  validated,
  chip,
  dates,
  buttons,
  type,
  hasLink,
  children,
  iconColor,
  editTitle,
  onChipClick,
}) => {
  const { t } = useTranslation();
  return (
    <Item primary={primary}>
      <Flex>
        <Icon
          name={iconName}
          size="extra-extra-extra-large"
          primaryColor={iconColor || theme.colors.black}
        />
      </Flex>
      <Flex>
        <Anchor title={title} editable={!!editTitle} onClick={editTitle}>
          <Text
            fontStyle="body1"
            content={title}
            marginLeft={{ xs: 'space16' }}
            marginTop={{ xs: 'auto' }}
            marginBottom={{ xs: 'auto' }}
            marginRight={{ xs: 'space8' }}
            weight="medium"
            title={title}
          />
          {editTitle && (
            <IconWrapper>
              <Icon name="EditSquare" size="small" />
            </IconWrapper>
          )}
        </Anchor>
      </Flex>
      <Flex
        alignItems="center"
        marginRight={{ xs: 'none' }}
        marginLeft={{ xs: 'auto' }}
      >
        {children}
        {chip && (
          <Flex marginRight={{ xs: 'space16' }}>
            <Chip content={chip} onClick={onChipClick} />
          </Flex>
        )}
        {validated && (
          <CustomStatus
            type="success"
            text={t('forms_opportunity.validated_single')}
          />
        )}
        {dates && dates.length > 0 && (
          <Flex>
            {dates.map((date: string, index: number) => {
              return (
                <Text
                  key={index}
                  fontStyle="body2"
                  content={date}
                  marginRight={{
                    xs:
                      index === dates.length - 1 && !buttons
                        ? 'none'
                        : 'space32',
                  }}
                  weight="medium"
                  color={theme.colors.gray6}
                />
              );
            })}
          </Flex>
        )}
        <Flex
          alignItems="center"
          direction={{ xs: 'column', sm: 'row' }}
          gap={{ xs: 'space16' }}
        >
          {buttons && buttons.length > 0 && (
            <>
              <Requires value={hasLink}>
                <Flex>
                  <DocumentLink type={type} />
                </Flex>
              </Requires>
              {buttons.map((props: ButtonProps | null, index: number) => {
                if (props) {
                  return (
                    <Button
                      {...props}
                      key={index}
                      marginRight={{
                        xs: index === buttons.length - 1 ? 'none' : 'space16',
                      }}
                    />
                  );
                }
              })}
            </>
          )}
        </Flex>
      </Flex>
    </Item>
  );
};

export default ListItem;
