import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Modal } from '.';
import { Flex } from 'components/Layout';
import { Button } from 'components/Buttons';
import { useApi } from '../../hooks/useApi';
import {
  DocumentTemplate,
  DocumentTemplateId,
  User,
} from '../../types/resources';
import InputSelect from '../FormTemplate/Fields/InputSelect';
import { InputText } from '../FormTemplate/Fields/InputText';
import ModalHeader from './ModalHeader';

interface DocumentAskModalProps {
  opened: boolean;
  loading?: boolean;
  spouses: User[];
  onClose: () => void;
  onSubmit: (data: {
    title: string;
    document_template_id: DocumentTemplateId;
    spouse_id?: string | null;
  }) => void;
}

const DocumentAskModal: FC<DocumentAskModalProps> = ({
  opened,
  loading,
  spouses,
  onClose,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const [template, setTemplate] = useState<DocumentTemplate | undefined>(
    undefined,
  );
  const [spouseId, setSpouseId] = useState<string | null | undefined>(
    undefined,
  );
  const [title, setTitle] = useState<string | null | undefined>(undefined);
  const spousesOptions = [
    { label: 'Document commun', value: null },
    ...spouses.map((s) => ({
      label: s.full_name,
      value: s.id,
    })),
  ];

  const { execute: getDocumentsTemplates, state: getDocumentsTemplatesState } =
    useApi<DocumentTemplate[]>(`/documents-templates`);
  const documentsTemplates = getDocumentsTemplatesState.data?.value ?? [];

  const documentTemplatesOptions = documentsTemplates.map((dt) => ({
    label: t(`documents_templates.${dt.id}`),
    value: dt.id,
  }));

  const confirm = useCallback(() => {
    if (template && title) {
      onSubmit({
        title,
        document_template_id: template.id,
        spouse_id: spouseId,
      });
    }
  }, [title, template, spouseId]);

  useEffect(() => {
    if (opened) {
      getDocumentsTemplates({ query: { is_administrative: true } });
      setTemplate(undefined);
    }
  }, [opened]);

  return (
    <Modal opened={opened} onClose={onClose}>
      <ModalHeader
        iconName="Document"
        content="Demander un nouveau document"
        onClose={onClose}
      />

      <Flex direction={{ xs: 'column' }} style={{ minHeight: '350px' }}>
        <Flex marginBottom={{ xs: 'space16' }}>
          <InputSelect
            onChange={(value) => {
              const selectedTemplate = documentsTemplates.find(
                (dt) => dt.id === value,
              );
              setTemplate(selectedTemplate);
              setTitle(selectedTemplate?.title ?? '');
            }}
            options={documentTemplatesOptions}
            placeholder={t('documents.request_document.type_placeholder')}
            label={t('documents.request_document.type')}
            size="small"
            value={template?.id}
            required
            width="100%"
          />
        </Flex>

        {template !== undefined && (
          <Flex marginBottom={{ xs: 'space16' }}>
            <InputSelect
              onChange={(value) => {
                const selectedSpouse =
                  value === null
                    ? value
                    : spouses.find((s) => s.id === value)?.id;
                setSpouseId(selectedSpouse);
                setTitle(template.title);
              }}
              options={spousesOptions}
              placeholder={t('upload.spouse_selection_placeholder')}
              label={t('upload.spouse_selection')}
              size="small"
              value={template?.id}
              required
              width="100%"
            />
          </Flex>
        )}

        {template !== undefined && spouseId !== undefined && (
          <Flex marginBottom={{ xs: 'space16' }}>
            <InputText
              onChange={(value) => {
                setTitle(value.target.value ?? '');
              }}
              placeholder={t('upload.title_selection_placeholder')}
              label={t('upload.title_selection')}
              value={title ?? undefined}
              $size="small"
              labelFontStyle={'label'}
              required
              width="100%"
            />
          </Flex>
        )}
      </Flex>

      <Flex marginTop={{ xs: 'space32' }} justify={'end'}>
        <Button
          content="Confirmer"
          marginRight={{ xs: 'space16' }}
          onClick={confirm}
          $loading={loading}
          disabled={!template}
        />
        <Button
          content={t('convention.warning.cancel')}
          onClick={onClose}
          primary
        />
      </Flex>
    </Modal>
  );
};

export default DocumentAskModal;
