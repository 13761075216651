import { FC, useState } from 'react';
import { Flex, Text } from '../components/Layout';
import { Button, IconButton } from '../components/Buttons';
import { theme } from '../theme';
import { Modal } from '../components/Modal';

export interface ConfirmConfig {
  title?: string;
  description?: string;
  confirmLabel?: string;
  cancelLabel?: string;
  dangerouslySetInnerHTML?: boolean;
}

export interface ConfirmOptions extends ConfirmConfig {}

interface UseConfirmReturn {
  confirm: (options?: ConfirmOptions, actionParams?: any) => Promise<void>;
  ConfirmationDialog: FC;
  isLoading: boolean;
}

const defaultConfig: Required<ConfirmConfig> = {
  title: 'Êtes-vous sûr ?',
  description: 'Cette action ne peut pas être annulée.',
  confirmLabel: 'Confirmer',
  cancelLabel: 'Annuler',
  dangerouslySetInnerHTML: false,
};

export function useConfirm(
  onConfirm: (data?: any) => Promise<void>,
): UseConfirmReturn {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [config, setConfig] = useState<Required<ConfirmConfig>>(defaultConfig);
  const [pendingAction, setPendingAction] = useState<
    (() => Promise<void>) | null
  >(null);

  const confirm = async (options: ConfirmOptions = {}, actionParams?: any) => {
    const { ...configOptions } = options;

    setConfig({
      ...defaultConfig,
      ...configOptions,
    });

    setPendingAction(() => async () => {
      setIsLoading(true);
      try {
        await onConfirm(actionParams);
      } finally {
        setIsLoading(false);
        setIsOpen(false);
      }
    });

    setIsOpen(true);
  };

  const handleConfirm = async () => {
    if (pendingAction) {
      try {
        await pendingAction();
      } catch (error) {
        console.error('Error during confirmation:', error);
      }
    }
  };

  const ConfirmationDialog: FC = () => (
    <Modal opened={isOpen} onClose={() => setIsOpen(false)}>
      <Flex marginBottom={{ xs: 'space16' }}>
        <IconButton
          iconName="Danger"
          color={theme.colors.white}
          backgroundColor={theme.colors.salmon1}
          rounded
        />
        <Flex alignItems="center">
          <Text
            content={config.title}
            fontStyle={'heading5'}
            marginLeft={{ xs: 'space16' }}
            weight="bold"
          />
        </Flex>
      </Flex>

      {config.dangerouslySetInnerHTML ? (
        <Text
          fontStyle="body1"
          dangerouslySetInnerHTML={{ __html: config.description }}
          marginBottom={{ xs: 'space16' }}
          color={theme.colors.gray6}
        />
      ) : (
        <Text
          fontStyle="body1"
          content={config.description}
          marginBottom={{ xs: 'space16' }}
          color={theme.colors.gray6}
        />
      )}

      <Flex direction={{ xs: 'row' }}>
        <Button
          content={config.cancelLabel}
          marginRight={{ xs: 'space24' }}
          onClick={() => setIsOpen(false)}
        />
        <Button
          content={config.confirmLabel}
          textColor={theme.colors.white}
          backgroundColor={theme.colors.salmon1}
          onClick={handleConfirm}
          $loading={isLoading}
        />
      </Flex>
    </Modal>
  );

  return { confirm, ConfirmationDialog, isLoading };
}
