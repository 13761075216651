import { Flex, Line, Text } from 'components/Layout';
import { LargeCard } from 'components/Cards';

import { OptionPaymentEnum } from 'container/public/PaymentResume/RecapPaymentQuote';
import { Dispatch, FC, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import InputRadio from '../../FormTemplate/Fields/InputRadio';
import { useViewport } from 'hooks/useViewport';

interface OptionPayment {
  handleChange: Dispatch<SetStateAction<OptionPaymentEnum>>;
  defaultOptionSelected?: OptionPaymentEnum;
}

const OptionPayment: FC<OptionPayment> = ({
  handleChange,
  defaultOptionSelected,
}) => {
  const { t } = useTranslation();
  const { isMobile } = useViewport();

  return (
    <Flex expand>
      <LargeCard>
        <Flex
          marginBottom={{ xs: 'space16', md: 'space24' }}
          direction={{ xs: 'column' }}
        >
          <Text
            content={t('quote.payment.options.title')}
            fontStyle={isMobile ? 'heading4' : 'heading3'}
            weight="bold"
            marginBottom={{ xs: 'space16' }}
          />
          <Text
            fontStyle="body2"
            content={t('quote.payment.options.description')}
          />
        </Flex>
        <Flex marginBottom={{ xs: 'space24' }}>
          <Line />
        </Flex>
        <Flex direction={{ xs: 'column' }}>
          <InputRadio<OptionPaymentEnum>
            name={'paymentOption'}
            options={[
              {
                value: 'split',
                label: t('quote.payment.options.split'),
                defaultSelected: defaultOptionSelected === 'split',
              },
              {
                value: 'totality',
                label: t('quote.payment.options.totality'),
                defaultSelected: defaultOptionSelected === 'totality',
              },
            ]}
            handleChange={handleChange}
          />
        </Flex>
      </LargeCard>
    </Flex>
  );
};

export default OptionPayment;
