import { FC, MouseEventHandler, useState } from 'react';
import styled from 'styled-components';

// Components
import { theme } from 'theme';
import { Text } from 'components/Layout/Text';
import InputCheckbox from 'components/FormTemplate/Fields/InputCheckbox';
import { Flex, Line } from 'components/Layout';
import { lg } from 'theme/styles/mediaQueries';
import { useViewport } from 'hooks/useViewport';
import { Icon } from 'components/Images/Icon';
import { useTranslation } from 'react-i18next';

const CardContainer = styled.div<CardContainerProps>`
  width: 100%;
  height: auto;
  padding: ${theme.spacing.space32} ${theme.spacing.space24};
  background-color: ${theme.colors.beige};
  border-radius: ${theme.spacing.space24};
  transition: opacity 0.3s ease-in-out;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};

  ${lg(`
    padding: ${theme.spacing.space32};
    min-width: 500px;
  `)}
`;

const CheckboxContainer = styled.div<CheckboxContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: ${theme.spacing.space16} ${theme.spacing.space16};
  border: 1px solid ${theme.colors.gray3};
  border-radius: ${theme.spacing.space16};
  background-color: ${theme.colors.white};
  cursor: pointer;
`;

const IconContainer = styled(Flex)<IconContainerProps>`
  height: 24px;
  transform: ${(props) => (props.isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
  transition: transform 0.3s ease-in-out;
`;

const TextContainer = styled.div<TextContainerProps>`
  opacity: ${(props) => (props.isOpen ? 1 : 0)};
  transition: opacity 0.3s ease-in-out;
`;

interface IconContainerProps {
  isOpen: boolean;
}

interface TextContainerProps {
  isOpen: boolean;
}

interface CardContainerProps {
  disabled?: boolean;
}

interface CheckboxContainerProps {
  onClick?: MouseEventHandler<HTMLDivElement>;
}

export interface OptionCardProps {
  title: string;
  label?: string | null;
  content?: string | null;
  price: string;
  priceDescription: string;
  textButton: string;
  checked?: boolean;
  onClick?: MouseEventHandler<HTMLDivElement>;
}

export const OptionCard: FC<OptionCardProps> = ({
  title,
  label,
  content,
  price,
  priceDescription,
  textButton,
  onClick,
  checked = false,
}) => {
  const { isMobile } = useViewport();
  const [isOpen, setIsOpen] = useState<boolean>(!isMobile);
  const { t } = useTranslation();

  return (
    <CardContainer>
      <Flex
        direction={{ xs: 'column', lg: 'row' }}
        marginBottom={{ xs: 'space16', lg: 'space24' }}
        justify="between"
      >
        <Flex alignItems="center">
          <Text
            content={title}
            fontStyle="heading4"
            marginBottom={{ xs: 'space24', lg: 'none' }}
          />
        </Flex>
        <Flex
          alignItems="center"
          direction={{ xs: 'columnReverse', lg: 'row' }}
        >
          <Text
            dangerouslySetInnerHTML={{ __html: priceDescription }}
            fontStyle="body1"
            weight="medium"
            color={theme.colors.gray5}
          />
          <Text
            content={price}
            fontStyle="heading2"
            color={theme.colors.salmon1}
            marginLeft={{ xs: 'none', lg: 'space8' }}
            marginBottom={{ xs: 'space16', lg: 'none' }}
          />
        </Flex>
      </Flex>
      {!isMobile && <Line />}
      <Flex
        direction={{ xs: 'column' }}
        marginBottom={{ xs: 'space48', lg: 'space32' }}
      >
        {label && (
          <Flex
            direction={{ xs: 'column', md: 'row' }}
            justify="between"
            alignItems={isMobile ? 'start' : 'center'}
            marginTop={{ xs: 'none', md: 'space16', lg: 'space32' }}
            onClick={() => {
              setIsOpen(!isOpen);
            }}
            style={{ cursor: 'pointer' }}
          >
            <Text
              content={label}
              fontStyle="body1"
              color={theme.colors.gray6}
              weight={isMobile ? 'medium' : 'bold'}
              marginRight={{ xs: 'none', md: 'space16', lg: 'space32' }}
            />
            <Flex
              alignItems="center"
              justify="center"
              marginTop={{ xs: 'space16', md: 'none' }}
            >
              <IconContainer
                justify="center"
                alignItems="center"
                isOpen={isOpen}
              >
                <Icon name="ChevronDown" primaryColor={theme.colors.salmon1} />
              </IconContainer>
              {isMobile && (
                <Text
                  content={t('quote.resume.see_more')}
                  fontStyle="body2"
                  color={theme.colors.salmon1}
                  weight="medium"
                  marginLeft={{ xs: 'space16' }}
                />
              )}
            </Flex>
          </Flex>
        )}
        {content && (
          <TextContainer isOpen={isOpen}>
            {isOpen && (
              <Text
                content={content}
                fontStyle={isMobile ? 'body2' : 'body1'}
                color={theme.colors.gray6}
                marginTop={{ xs: 'space16' }}
                weight={'regular'}
              />
            )}
          </TextContainer>
        )}
      </Flex>
      <CheckboxContainer onClick={onClick}>
        <InputCheckbox
          label={textButton}
          fontWeight="bold"
          checked={checked}
          justify="center"
          align="center"
          rounded
          checkPoint
        />
      </CheckboxContainer>
    </CardContainer>
  );
};
