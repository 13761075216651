import { FC, useEffect } from 'react';

import { Flex, Requires } from 'components/Layout';
import { UserValidation } from 'components/User';
import { Ghost } from 'components/Loading';
import styled from 'styled-components';
import { format } from 'date-fns';
import {
  DivorceAgreementFull,
  DocumentFull,
  Lawyer,
  ProcedureFull,
} from '../../../types/resources';
import { Preview } from '../../../components/Viewer';
import { useApi } from '../../../hooks/useApi';

interface ConventionPreviewProps {
  procedure: ProcedureFull;
  agreement: DivorceAgreementFull;
  lawyer?: Lawyer | null;
}

const PreviewWrapper = styled.div`
  width: 100%;
  height: 600px;
`;

const ConventionPreview: FC<ConventionPreviewProps> = ({
  procedure,
  agreement,
  lawyer,
}) => {
  const { execute: getProjectDocument, state: getProjectDocumentState } =
    useApi<DocumentFull>(
      `/procedures/${procedure.id}/documents/${agreement.project_document_id}`,
    );
  const projectDocument = getProjectDocumentState.data?.value;

  const spouse1Validation = agreement.validations.find(
    (v) => v.user_id === procedure.spouse1_id,
  );

  const spouse2Validation = agreement.validations.find(
    (v) => v.user_id === procedure.spouse2_id,
  );

  const managerValidation = agreement.validations.find(
    (v) => v.user_id === procedure.manager_id,
  );

  const internalValidator = lawyer ?? procedure.manager;
  const isLocked = agreement.locked;

  useEffect(() => {
    if (agreement.project_document_id) {
      getProjectDocument();
    }
  }, [agreement]);

  if (!agreement)
    return <Ghost width="100%" height={400} shape="rect" rx={24} />;

  return (
    <Flex direction={{ xs: 'column' }} width="100%">
      <Flex marginBottom={{ xs: 'space72' }}>
        {procedure.spouse1 && (
          <UserValidation
            user={procedure.spouse1}
            locked={isLocked}
            valid={Boolean(spouse1Validation?.validated_at) || false}
            validationDate={
              spouse1Validation?.validated_at
                ? format(
                    new Date(spouse1Validation.validated_at),
                    "'Le' dd/MM/yyyy 'à' HH:mm",
                  )
                : undefined
            }
          />
        )}
        {procedure.spouse2 && (
          <UserValidation
            user={procedure.spouse2}
            locked={isLocked}
            valid={Boolean(spouse2Validation?.validated_at) || false}
            validationDate={
              spouse2Validation?.validated_at
                ? format(
                    new Date(spouse2Validation.validated_at),
                    "'Le' dd/MM/yyyy 'à' HH:mm",
                  )
                : undefined
            }
          />
        )}

        {internalValidator && (
          <UserValidation
            user={internalValidator}
            locked={isLocked}
            valid={Boolean(managerValidation?.validated_at) || false}
            validationDate={
              managerValidation?.validated_at
                ? format(
                    new Date(managerValidation.validated_at),
                    "'Le' dd/MM/yyyy 'à' HH:mm",
                  )
                : undefined
            }
          />
        )}
      </Flex>
      {projectDocument?.file && (
        <Requires value={projectDocument}>
          <PreviewWrapper>
            <Preview procedure={procedure} document={projectDocument} />
          </PreviewWrapper>
        </Requires>
      )}
    </Flex>
  );
};

export default ConventionPreview;
