import { FC } from 'react';
import styled from 'styled-components';
import { theme } from 'theme';

// Components
import { Text } from 'components/Layout/Text';
import { Flex } from 'components/Layout/Flex';
import { useViewport } from 'hooks/useViewport';

const Card = styled.div`
  background-color: ${theme.colors.beige};
  border-radius: ${theme.spacing.space16};
  padding: ${theme.spacing.space16};
  width: 100%;
`;

const Bar = styled.div`
  position: relative;
  width: 100%;
  height: ${theme.spacing.space8};
  background-color: ${theme.colors.green4};
  border-radius: ${theme.spacing.space16};
  transition: 0.5s width ease-in-out;
`;

const Progress = styled.div<ProgressProps>`
  position: absolute;
  inset: 0;
  width: ${(props) => props.percentage + '%'};
  max-width: 100%;
  height: ${theme.spacing.space8};
  background-color: ${theme.colors.green1};
  border-radius: ${theme.spacing.space16};
  transition: 0.5s width ease-in-out;
`;

interface ProgressProps {
  percentage: number;
}

export interface FormsProgressCardProps {
  title: string;
  description: string;
  percentage: number;
  showDescriptionOnMobile?: boolean;
}

const ProgressCard: FC<FormsProgressCardProps> = ({
  title,
  description,
  percentage,
  showDescriptionOnMobile,
}) => {
  const { isMobile } = useViewport();

  return (
    <Card>
      <Flex justify="between" marginBottom={{ xs: 'space16' }}>
        <Text content={title} fontStyle="body1" weight="bold" />
        <Text content={`${percentage}%`} fontStyle="body1" weight="bold" />
      </Flex>
      <Bar>
        <Progress percentage={percentage} />
      </Bar>
      {(!isMobile || (isMobile && showDescriptionOnMobile)) && (
        <Text
          dangerouslySetInnerHTML={{ __html: description }}
          fontStyle="body2"
          marginTop={{ xs: 'space16' }}
          color={theme.colors.gray6}
        />
      )}
    </Card>
  );
};

export default ProgressCard;
