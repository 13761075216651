import { FC, useEffect, useMemo, useState } from 'react';
import { CellProps, Column, SortingRule } from 'react-table';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Components
import { Button } from 'components/Buttons/Button';
import Table from 'components/Table/Table';
import { Flex } from 'components/Layout/Flex';

import { AdminAppRoutes } from 'AdminApp';
import { getRouteWithParams } from 'utils/router';
import { Icon } from '../../../components/Images';
import { theme } from '../../../theme';
import { Product, SortDirection } from '../../../types/resources';
import { ExecuteFunction } from '../../../hooks/useFetch';

type ProductsListProps = {
  pageSize: number;
  search: string | null;
  type: string | null;
  fetch: ExecuteFunction<Product[]>;
  total: number;
  items: Product[];
};

const ProductsList: FC<ProductsListProps> = ({
  pageSize,
  type,
  search,
  fetch,
  items,
  total,
}) => {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [sortKey, setSortKey] = useState<string | undefined>(undefined);
  const [sortDirection, setSortDirection] = useState<SortDirection | undefined>(
    undefined,
  );
  const history = useHistory();

  useEffect(() => {
    fetch({
      query: {
        search: search || undefined,
        type: type || undefined,
        page: page !== undefined ? page + 1 : 1,
        items: pageSize,
        sort: sortKey,
        direction: sortDirection,
      },
    });
  }, [search, type, page, sortKey, sortDirection]);

  const onLoadMore = ({
    pageIndex,
    sortBy,
  }: {
    pageIndex?: number;
    sortBy?: SortingRule<Product>[];
  }) => {
    if (pageIndex !== undefined) {
      setPage(pageIndex);
    }

    const _sortKey = sortBy?.[0]?.id;
    if (_sortKey !== undefined) {
      setSortKey(_sortKey);
    }

    const _sortDesc = sortBy?.[0]?.desc;
    if (_sortDesc !== undefined) {
      setSortDirection(_sortDesc ? SortDirection.DESC : SortDirection.ASC);
    }
  };

  const columns = useMemo<Column<Product>[]>(
    () => [
      {
        Header: t('product.type'),
        accessor: 'type',
        width: 100,
        Cell: ({ value }) => t(`product.types.${value.toLowerCase()}`),
      },
      {
        Header: t('product.code'),
        accessor: 'code',
        width: 100,
      },
      {
        Header: t('product.name'),
        accessor: 'name',
        width: 230,
      },
      {
        Header: t('product.price'),
        accessor: 'price',
        width: 70,
        Cell: ({ value }) => `${value} €`,
      },
      {
        Header: 'Activé',
        accessor: 'enabled',
        width: 70,
        Cell: ({ value }) => (
          <Icon
            name={value ? 'check' : 'close'}
            fill="none"
            primaryColor={value ? theme.colors.green3 : theme.colors.red1}
          />
        ),
      },
      {
        Header: Object.assign(
          ({}) => (
            <Flex justify="end">
              <></>
            </Flex>
          ),
          {
            displayName: 'Id',
          },
        ),
        accessor: 'id',
        width: 100,
        disableSortBy: true,
        Cell: Object.assign(
          ({ cell }: CellProps<Product>) => (
            <Flex justify="end" expand>
              <Button
                content={t('view')}
                size="small"
                primary
                onClick={() =>
                  history.push(
                    getRouteWithParams(AdminAppRoutes.PRODUCTS_DETAILS, {
                      id: cell.row.values.id,
                    }),
                  )
                }
              />
            </Flex>
          ),
          { displayName: 'Button' },
        ),
      },
    ],
    [history],
  );

  return (
    <Table<Product>
      data={items}
      columns={columns}
      pageSize={pageSize}
      totalCount={total}
      loadMore={onLoadMore}
      td={{ paddingVertical: 0 }}
    />
  );
};

export default ProductsList;
