import { FC } from 'react';
import styled from 'styled-components';
import { theme } from 'theme';

// Components
import { Flex, Text } from 'components/Layout';
import { Button } from 'components/Buttons/Button';

import wave from 'assets/images/waveform.png';
import wave2 from 'assets/images/waveform2.png';
import { md } from 'theme/styles/mediaQueries';

interface CustomContainerProps {
  isAdmin: boolean;
  full?: boolean;
}

const CustomContainer = styled(Flex)<CustomContainerProps>`
  position: fixed;
  top: 70px; // header height
  left: 0;
  width: 100vw;
  height: calc(100vh - 70px); // header height
  background-color: ${theme.colors.green1};
  padding: ${theme.spacing.space32} ${theme.offset}px;
  overflow-y: hidden;

  ${md(`
    padding: 184px 232px 0;
  `)}

  ${(props) =>
    props.isAdmin &&
    md(`
      z-index: 6;
      top: 0;
      width: 100vw; // header width on admin only
      height: 100vh;
  `)};
  ${(props) =>
    props.full &&
    md(`
      z-index: 6;
      top: 0;
      height: 100vh;
  `)};
`;

const ImageContainer = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
`;

const CustomText = styled(Text)`
  ${md(`
    max-width: 550px;
  `)}
`;

const Image = styled.img`
  width: 100%;
`;

export interface Page404Props {
  title: string;
  type: '404' | '500';
  message: string;
  buttonText: string;
  onClick: () => void;
  isAdmin?: boolean;
  full?: boolean;
}

const ErrorPage: FC<Page404Props> = ({
  title,
  type,
  message,
  onClick,
  buttonText,
  isAdmin = false,
  full,
}) => {
  return (
    <CustomContainer
      justify="start"
      alignItems="start"
      direction={{ xs: 'column' }}
      isAdmin={isAdmin}
      full={full}
    >
      <Text
        content={title}
        fontStyle="heading4"
        textAlign="left"
        color={theme.colors.gray2}
        transform="uppercase"
        marginBottom={{ xs: 'space16' }}
      />
      <CustomText
        content={message}
        fontStyle="heading1"
        color={theme.colors.white}
        marginBottom={{ xs: 'space56' }}
      />
      <Button content={buttonText} primary onClick={onClick} />
      <ImageContainer>
        <Image src={type === '404' ? wave : wave2} alt="Wave image" />
      </ImageContainer>
    </CustomContainer>
  );
};

export default ErrorPage;
