import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Controller, useForm } from 'react-hook-form';

// Component
import { Text, Flex } from 'components/Layout';
import { ConfirmForm } from 'components/ConfirmPopin/ConfirmPopin';
import { Modal } from 'components/Modal';
import { InputText } from 'components/FormTemplate/Fields/InputText';
import { Button } from 'components/Buttons';

//data
import { Breadcrumbs } from 'container/admin';

// Utils
import { AdminAppRoutes } from 'AdminApp';
import { theme } from 'theme';
import { useApi } from '../../hooks/useApi';
import { InputCurrency } from '../../components/FormTemplate/Fields/InputCurrency';
import { toString } from 'lodash';
import { TextArea } from '../../components/FormTemplate/Fields/TextArea';
import InputCheckbox from '../../components/FormTemplate/Fields/InputCheckbox';
import InputSelect from '../../components/FormTemplate/Fields/InputSelect';

type CreateProductInput = {
  name: string;
  code: string;
  price: number;
  type: 'PRODUCT' | 'OPTION' | 'PLAN';
  enabled: boolean;
  description?: string;
  label?: string;
  short_name?: string;
};

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const Form = styled.form`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 38px 32px;
  width: 928px;
  margin-top: ${theme.spacing.space48};
  margin-bottom: ${theme.spacing.space32};
`;

const ProductsAdd: FC = () => {
  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm<CreateProductInput>();
  const { t } = useTranslation();
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [result, setResult] = useState<CreateProductInput>();
  const history = useHistory();
  const productTypesOptions = [
    {
      value: 'PRODUCT',
      label: t(`product.types.product`),
    },
    {
      value: 'OPTION',
      label: t(`product.types.option`),
    },
    {
      value: 'PLAN',
      label: t(`product.types.plan`),
    },
  ];

  const onSubmit = (data: CreateProductInput) => {
    setResult(data);
    setModalIsOpen(true);
  };

  const onClose = () => {
    setModalIsOpen(false);
  };

  const {
    execute: createProductRequest,
    state: { loading, success },
  } = useApi('/products', { method: 'POST' });

  const goBack = () => {
    history.push(AdminAppRoutes.PRODUCT);
  };

  const createProduct = async () => {
    if (result) {
      createProductRequest({ body: result });
    }
  };

  useEffect(() => {
    if (success) {
      goBack();
    }
  }, [success]);

  return (
    <Container>
      <Modal opened={modalIsOpen} onClose={onClose}>
        <ConfirmForm
          title={t('product.add_confirm_title')}
          confirmText={t('product.add_confirm')}
          handleSubmit={createProduct}
          setModalIsOpen={setModalIsOpen}
          loading={loading}
        />
      </Modal>
      <Breadcrumbs />
      <Flex direction={{ xs: 'row' }} alignItems="center">
        <Text
          content={t('product.add')}
          fontStyle="heading2"
          weight="bold"
          marginRight={{ xs: 'space32' }}
        />
      </Flex>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <InputText
          {...register('name', {
            required: t('error.form.required').toString(),
          })}
          error={errors.name}
          label={t('product.name')}
          placeholder={t('product.name')}
          width="100%"
          required
        />
        <InputText
          {...register('code', {
            required: t('error.form.required').toString(),
          })}
          error={errors.code}
          label={t('product.code')}
          placeholder={t('product.code')}
          width="100%"
          required
        />

        <Controller
          control={control}
          name="type"
          rules={{ required: t('error.form.user_role').toString() }}
          render={({ field, fieldState: { error } }) => (
            <InputSelect
              label={t('product.type')}
              placeholder={t('product.type')}
              options={productTypesOptions}
              error={error}
              {...field}
              onChange={(e) => {
                field.onChange(e);
              }}
              required
            />
          )}
        />

        <Controller
          control={control}
          name="price"
          rules={{
            required: t('error.form.required').toString(),
          }}
          render={({ field, fieldState: { error } }) => (
            <InputCurrency
              {...field}
              value={toString(field.value)}
              onChange={(value) => {
                setValue(`price`, value);
              }}
              error={error}
              label={t('product.price')}
              placeholder={t('product.price')}
              width="100%"
              required
            />
          )}
        />
        <TextArea
          {...register('description')}
          error={errors.description}
          label={t('product.description')}
          placeholder={t('product.description')}
          width="100%"
        />
        <TextArea
          {...register('label')}
          error={errors.label}
          label={t('product.label')}
          placeholder={t('product.label')}
          width="100%"
        />
        <InputText
          {...register('short_name')}
          error={errors.short_name}
          label={t('product.short_name')}
          placeholder={t('product.short_name')}
          width="100%"
        />
        <Controller
          control={control}
          name="enabled"
          render={({ field, fieldState: { error } }) => (
            <InputCheckbox
              {...field}
              checked={!!field.value}
              onChange={(value) => {
                setValue(`enabled`, value);
              }}
              fontWeight="bold"
              justify="center"
              align="center"
              checkPoint
              error={!!error}
              label={t(`product.enabled`)}
            />
          )}
        />

        <Flex>
          <Button
            onClick={goBack}
            content={t('back')}
            marginRight={{ sm: 'space24' }}
          />
          <Button
            content={t('product.add')}
            type="submit"
            $loading={loading}
            primary
          />
        </Flex>
      </Form>
    </Container>
  );
};

export default ProductsAdd;
