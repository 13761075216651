import React, { FC } from 'react';
import styled from 'styled-components';

// Components
import { theme } from 'theme';

// Assets
import logo from 'assets/logo/logo-white.svg';

// Theme
import { md } from 'theme/styles/mediaQueries';
import { Flex } from '../Flex';

const HeaderContainer = styled.header`
  position: fixed;
  top: 0;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  height: 70px;
  align-items: center;
  background-color: ${theme.colors.green1};
  padding: 0 ${theme.spacing.space16};
  z-index: 6;

  ${md(`
		justify-content: flex-start;
    padding: 0 ${theme.spacing.space72};
	`)}
`;

const UnregisterHeader: FC = () => {
  return (
    <HeaderContainer>
      <Flex
        onClick={() => {
          window.location.href = 'https://wedivorce.fr';
        }}
        style={{ cursor: 'pointer' }}
        marginLeft={{ xs: 0, md: 'space32' }}
        marginRight={{ xs: 0, md: 'space32' }}
      >
        <img src={logo} alt="WeDivorce" />
      </Flex>
    </HeaderContainer>
  );
};

export default UnregisterHeader;
