import React, { FC } from 'react';
import { FieldProps } from '@rjsf/core';
import get from 'lodash/get';

import { Input } from 'components/FormTemplate/Fields/Input';
import RequiredField from 'components/Forms/Utils/RequiredField';

import { Flex } from 'components/Layout/Flex';
import { Label } from 'components/Text/Label';
import { replaceLabelWithContext } from '../Utils/ReplaceLabel';

const symbolsArr = ['e', 'E', '+', '-', '.'];

/**
 * InputText with a H5 label use for dynamic flows
 * @param param0
 */
export const Field: FC<FieldProps> = ({
  required,
  readonly,
  disabled,
  type,
  schema,
  uiSchema,
  onChange,
  formData,
  formContext,
}) => {
  const title = get(schema, 'title', '');
  const label = replaceLabelWithContext(title, formContext.data);
  const placeholder = get(uiSchema, 'ui:placeholder', null);
  const inputType = get(
    uiSchema,
    'ui:options.inputType',
    (type || schema.type) === 'string' ? 'text' : `${type || schema.type}`,
  );
  const inputUppercase = get(uiSchema, 'ui:options.uppercase', false);

  const _onChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    if (inputType === 'number') {
      onChange(Number(target.value).toString());
    }

    if (inputUppercase) {
      target.value = target.value.toUpperCase();
    }

    onChange(target.value);
  };

  return (
    <Flex direction={{ xs: 'column' }} position="relative">
      {label && (
        <Flex
          alignItems="center"
          marginBottom={{ xs: 'space16', md: 'space24' }}
        >
          <RequiredField required={required}>
            <Label dangerouslySetInnerHTML={{ __html: label }} />
          </RequiredField>
        </Flex>
      )}
      <Input
        placeholder={placeholder}
        required={required}
        disabled={disabled || readonly}
        type={inputType}
        value={
          inputUppercase
            ? (formData as string)?.toUpperCase() || ''
            : formData || ''
        }
        onChange={_onChange}
        onKeyDown={(e) =>
          inputType === 'number' &&
          symbolsArr.includes(e.key) &&
          e.preventDefault()
        }
      />
    </Flex>
  );
};
