import { FC, useEffect } from 'react';
import styled from 'styled-components';
import format from 'date-fns/format';

// Utils
import { theme } from 'theme';

// Components
import { Col, Container, Row } from 'components/Layout';

// Data
import { FormalityFile } from 'container/client';
import { ViewGate } from 'container/common';
import { useCustomer } from '../../hooks/useCustomer';
import {
  FinalSending,
  ProcedureFull,
  ProcedureStepType,
} from '../../types/resources';
import { useApi } from '../../hooks/useApi';
import { ClientCard, TipCard } from '../../components/Cards';
import { useTranslation } from 'react-i18next';

const CustomContainer = styled(Container)`
  padding-top: ${theme.spacing.space32};
  padding-bottom: ${theme.spacing.space32};
`;

const ClientFormalityFinalSending: FC = () => {
  const { t } = useTranslation();
  const { customer } = useCustomer();
  const step = ProcedureStepType.FINAL_SENDING;

  const { execute: getProcedure, state: getProcedureState } =
    useApi<ProcedureFull>('');
  const procedure = getProcedureState.data?.value;

  const { execute: getFinalSending, state: getFinalSendingState } = useApi<
    FinalSending[]
  >(`/procedures/${procedure?.id}/final-sending`);
  const finalSending = getFinalSendingState.data?.value.find(
    (f) => f.spouse_id === customer?.id,
  );

  useEffect(() => {
    if (customer?.procedure_id) {
      getProcedure({
        endpoint: `/procedures/${customer.procedure_id}`,
        query: { customer_id: customer.id },
      });
    }
  }, [customer?.procedure_id]);

  useEffect(() => {
    if (procedure && customer) {
      getFinalSending();
    }
  }, [procedure, customer]);

  if (!procedure) return null;

  const notify = finalSending?.document_id
    ? t(`formality_letters.demand_${step}.notify`)
    : t(`formality_letters.demand_${step}.notifySoon`);

  const content = finalSending?.sent_at
    ? t(`formality_letters.demand_${step}.received`, {
        date: format(new Date(finalSending.sent_at), 'dd/MM/yyyy'),
      })
    : t(`formality_letters.demand_${step}.description`);

  return (
    <ViewGate procedure={procedure} steps={[ProcedureStepType.FINAL_SENDING]}>
      <CustomContainer fluid="xl">
        <Row>
          <Col xs={12} md={4} vertical alignSelf="auto">
            <ClientCard
              iconName="Document"
              title={t(`formality_letters.demand_${step}.title`)}
            >
              <TipCard title={notify} type="info" content={content} />
            </ClientCard>
          </Col>
          <Col xs={12} md={8}>
            <FormalityFile
              procedure={procedure}
              step={step}
              letter={{
                date: finalSending?.sent_at,
                document_id: finalSending?.document_id,
              }}
            />
          </Col>
        </Row>
      </CustomContainer>
    </ViewGate>
  );
};

export default ClientFormalityFinalSending;
