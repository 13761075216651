import { ChangeEvent, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';

// Components
import { Flex } from 'components/Layout/Flex';
import { InputText } from 'components/FormTemplate/Fields/InputText';

// data

// Utils
import { theme } from 'theme';
import { useQueryParams } from 'hooks/useQueryParams';
import { toggleSearchParams } from 'utils/search';

const EstimatesListFilters: FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const query = useQueryParams();
  const searchParams = new URLSearchParams(location.search);

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    toggleSearchParams('search', e?.target.value, searchParams);
    history.replace({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  };

  const SearchValue = query.get('search') || '';

  return (
    <Flex marginTop={{ xs: 'space40' }} justify="start">
      <Flex>
        <InputText
          onChange={handleSearchChange}
          label={t('filter.search')}
          labelUppercase={true}
          labelFontStyle="label"
          placeholder={t('filter.search_estimate')}
          width="288px"
          labelWeight="bold"
          $size="small"
          value={SearchValue && SearchValue !== '' ? SearchValue : ''}
          icon={{ name: 'Search', primaryColor: theme.colors.salmon1 }}
        />
      </Flex>
    </Flex>
  );
};

export default EstimatesListFilters;
