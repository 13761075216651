import { FC, useCallback } from 'react';

// Components
import { Flex, Text } from 'components/Layout';
import { IconButton } from 'components/Buttons';

// Utils
import { theme } from 'theme';
import { getRouteWithParams } from 'utils/router';
import { AdminAppRoutes } from 'AdminApp';
import { useHistory } from 'react-router';
import { isUserAllowed } from 'utils/user';
import { Icon } from 'components/Images';
import { useAuth } from '../../hooks/useAuth';
import { Role } from '../../types/resources';

interface UserItemProps {
  content: string;
  letters?: string;
  userId?: string;
  hasPaid?: boolean;
  toggleModalPaid?: () => void;
}

const UserItem: FC<UserItemProps> = ({
  userId,
  content,
  letters,
  hasPaid,
  toggleModalPaid,
}) => {
  const history = useHistory();
  const { user } = useAuth();
  const userAllowed = isUserAllowed(user?.role, [Role.ADMIN, Role.MANAGER]);

  const redirect = useCallback(() => {
    if (userAllowed && userId) {
      history.push({
        pathname: getRouteWithParams(AdminAppRoutes.CUSTOMERS_DETAILS, {
          id: userId,
        }),
        state: { prevPath: location.pathname },
      });
    }
  }, [userId]);

  return (
    <Flex alignItems="center" cursor={userAllowed ? 'pointer' : 'default'}>
      <IconButton
        backgroundColor={theme.colors.green2}
        color={theme.colors.green1}
        iconName="User"
        letters={letters}
        rounded
        size="small"
        stroke="regular"
        onClick={redirect}
      />
      <Text
        content={content}
        fontStyle="body2"
        weight="medium"
        marginLeft={{ xs: 'space8', lg: 'space16' }}
        color={theme.colors.gray6}
        onClick={redirect}
      />
      {typeof hasPaid === 'boolean' && (
        <Flex
          marginLeft={{ xs: 'space8', lg: 'space16' }}
          onClick={toggleModalPaid}
        >
          <Icon name={hasPaid ? 'euro' : 'euroCrossed'} />
        </Flex>
      )}
    </Flex>
  );
};

export default UserItem;
