import React, { FC, useCallback, useState } from 'react';
import { Flex, Text } from 'components/Layout';
import { useHistory } from 'react-router';

import { Button } from 'components/Buttons';
import { InputText } from 'components/FormTemplate/Fields/InputText';
import { regexes } from 'utils/regex';
import styled from 'styled-components';
import { theme } from 'theme';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../hooks/useAuth';
import { Link } from 'react-router-dom';
import { PublicAppRoutes } from '../../PublicApp';
import { useQueryParams } from '../../hooks/useQueryParams';

type SignInUserInput = {
  email: string;
  password: string;
};

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.space16};
`;

type PasswordInputType = 'password' | 'text';

const SignInForm: FC = () => {
  const { t } = useTranslation();
  const [error, setError] = useState<string | null>(null);
  const history = useHistory();
  const query = useQueryParams();
  const from = query.get('from');

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<SignInUserInput>();
  const [passwordType, setPasswordType] =
    useState<PasswordInputType>('password');
  const { login, isLoading } = useAuth();

  const onSubmit = useCallback(
    (data: SignInUserInput) => {
      setError(null);

      if (data) {
        login({
          ...data,
          onSuccess: () => {
            history.push(from ?? '/');
          },
          onError: (err: any) => {
            const errText =
              err?.response?.status === 429
                ? t('signin.errors.too_many_attempts')
                : t('signin.errors.unauthorized');
            setError(errText);
          },
        });
      }
    },
    [setError, from],
  );

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <InputText
        {...register('email', {
          required: t('error.form.required').toString(),
          pattern: {
            value: regexes.email,
            message: t('error.form.email').toString(),
          },
        })}
        error={errors.email}
        labelFontStyle="body1"
        labelWeight="medium"
        placeholder={t('signIn.form.email_placeholder')}
        width="100%"
        required
      />
      <InputText
        {...register('password', {
          required: t('error.form.required').toString(),
        })}
        type={passwordType}
        error={errors.password}
        labelFontStyle="body1"
        labelWeight="medium"
        placeholder={t('signIn.form.password_placeholder')}
        width="100%"
        icon={{
          name: passwordType === 'password' ? 'Hide' : 'Show',
        }}
        onClick={() => {
          setPasswordType((prev) =>
            prev === 'password' ? 'text' : 'password',
          );
        }}
        required
      />
      <Link
        style={{ alignSelf: 'flex-start' }}
        to={PublicAppRoutes.FORGOTTEN_PASSWORD}
      >
        {t('signIn.form.forgotten_password')}
      </Link>
      {error !== null ? (
        <Text
          textAlign={'center'}
          fontStyle="body2"
          color="red"
          content={error}
        />
      ) : null}
      <Flex marginTop={{ xs: 'space16' }}>
        <Button
          content={t('signIn.form.submit')}
          type="submit"
          $loading={isLoading}
          fullWidth
          iconLeft={{ name: 'ArrowRightSquare' }}
          primary
        />
      </Flex>
    </Form>
  );
};

export default SignInForm;
