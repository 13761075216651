import { CSSProperties, FC } from 'react';
import styled from 'styled-components';
import { theme } from 'theme';

const Div = styled.div`
  display: flex;
  width: 1px;
  background-color: ${theme.colors.gray3};
`;

export interface VerticalLineProps {
  style?: CSSProperties;
}

const VerticalLine: FC<VerticalLineProps> = ({ style, ...props }) => {
  return <Div style={style} {...props} />;
};

export default VerticalLine;
