import { JSONSchema7 } from 'json-schema';
import { UiSchema } from '@rjsf/core';
import { ProductCode } from '../types/resources';

export enum CoupleSituation {
  'coupleWithoutChildren' = 'Couple sans enfant',
  'coupleWithChildren' = 'Couple avec enfant(s)',
}

const schemasList: JSONSchema7[] = [
  {
    title: 'Emma de WeDivorce',
    description: `👉 Pour nous aider à établir votre tarif personnalisé, répondez simplement à ces quelques questions. <br /><br/><strong>Êtes-vous d’accord sur le principe d’un divorce à l’amiable ?</strong>`,
    type: 'object',
    properties: {
      choice0: {
        title: '',
        type: 'string',
        oneOf: [
          {
            const: 'true',
            title: 'Oui, je suis d’accord !',
          },
          {
            const: 'false',
            title: 'Non, ce n’est pas un divorce à l’amiable',
          },
        ],
        default: null,
      },
    },
    required: ['choice0'],
    dependencies: {
      choice0: {
        oneOf: [
          {
            properties: {
              choice0: {
                enum: [null, 'true'],
              },
              tip_card_info: {
                type: 'string',
                title: '<strong>Qu’est-ce que le divorce amiable ?</strong>',
                description:
                  "Vous pouvez choisir le divorce à l’amiable si les deux conjoints sont d'accord sur le principe du divorce et les conditions de votre séparation (enfants, partage de biens...). S’il reste quelques points de discussion avec votre conjoint(e), vos avocats sont là pour vous accompagner.",
              },
            },
          },
          {
            properties: {
              choice0: {
                enum: ['false'],
              },
              // tip_card_danger: {
              //   type: 'string',
              //   title: '<strong>Vous n’êtes pas d’accord ?</strong>',
              //   description:
              //     'Cliquez sur ce lien pour retrouver nos offres en matière de séparations',
              //   const: {
              //     link: 'Contactez-nous',
              //     src: 'https://wedivorcestg.wpengine.com/prendre-rendez-vous/',
              //   },
              // },
            },
          },
        ],
      },
    },
  },
  {
    title: 'Emma de WeDivorce',
    description: `<strong>Êtes-vous d’accord pour utiliser ensemble les services de WeDivorce pour votre procédure de divorce à l’amiable ?</strong>`,
    type: 'object',
    properties: {
      choice1: {
        title: '',
        type: 'string',
        oneOf: [
          {
            const: 'true',
            title: 'Oui',
          },
          {
            const: 'false',
            title: 'Non',
          },
        ],
        default: null,
      },
    },
    required: ['choice1'],
    dependencies: {
      choice1: {
        oneOf: [
          {
            properties: {
              choice1: {
                enum: [null, 'true'],
              },
              tip_card_info: {
                type: 'string',
                title: 'Comment suis-je représenté juridiquement ?',
                description:
                  'Chaque époux sera mis en relation avec un avocat indépendant, membre du réseau WeDivorce, qui sera proposé à chaque époux individuellement.',
              },
            },
          },
          {
            properties: {
              choice1: {
                enum: ['false'],
              },
              // tip_card_danger: {
              //   type: 'string',
              //   title:
              //     '<strong>Vous n’êtes actuellement pas éligible à nos services</strong>',
              //   description:
              //     'Prenez un rendez-vous avec un conseiller à l’aide du lien ci-dessous pour que nos équipes puissent vous accompagner.',
              //   const: {
              //     link: 'Contacter un conseiller',
              //     src: 'https://wedivorcestg.wpengine.com/prendre-rendez-vous/',
              //   },
              // },
            },
          },
        ],
      },
    },
  },
  {
    title: 'Emma de WeDivorce',
    description: `<strong>Dans quel département résidez-vous ?</strong>`,
    type: 'object',
    properties: {
      region: {
        title: '',
        type: 'object',
        properties: {
          code: {
            type: 'string',
          },
          full_name: {
            type: 'string',
          },
        },
        default: null,
      },
    },
    required: ['region'],
  },
  {
    title: 'Emma de WeDivorce',
    description: `<strong>Laquelle de ces situations vous correspond le plus ?</strong>`,
    type: 'object',
    properties: {
      coupleSituation: {
        title: '',
        type: 'string',
        oneOf: [
          {
            const: CoupleSituation.coupleWithoutChildren,
            title: CoupleSituation.coupleWithoutChildren,
          },
          {
            const: CoupleSituation.coupleWithChildren,
            title: CoupleSituation.coupleWithChildren,
          },
        ],
        default: '',
      },
    },
    required: ['coupleSituation'],
    dependencies: {
      coupleSituation: {
        oneOf: [
          {
            properties: {
              coupleSituation: {
                const: '',
              },
              tip_card_info: {
                type: 'string',
                title: 'Précision sur les enfants majeurs',
                description:
                  'Si vous avez des enfants majeurs, cela ne modifie pas le tarif de votre devis.',
              },
            },
          },
          {
            properties: {
              coupleSituation: {
                enum: [CoupleSituation.coupleWithoutChildren],
              },
              tip_card_info: {
                type: 'string',
                title: 'Précision sur les enfants majeurs',
                description:
                  'Si vous avez des enfants majeurs, cela ne modifie pas le tarif de votre devis.',
              },
            },
          },
          {
            properties: {
              coupleSituation: {
                enum: [CoupleSituation.coupleWithChildren],
              },
              nbYoungChildren: {
                type: 'integer',
                title: 'Nombre de mineur(s) ?',
                default: 0,
              },
              nbAdultChildren: {
                type: 'integer',
                title: 'Nombre de majeur(s) ?',
                default: 0,
              },
              tip_card_info: {
                type: 'string',
                title: 'Précision sur les enfants majeurs',
                description:
                  'Si vous avez des enfants majeurs, cela ne modifie pas le tarif de votre devis.',
              },
            },
          },
        ],
      },
    },
  },
  {
    title: 'Emma de WeDivorce',
    description: `<strong>Avez-vous du patrimoine immobilier ?</strong><br />Biens communs, biens indivis, bien propres ou bien vendus récemment (moins d'un an)`,
    type: 'object',
    properties: {
      properties: {
        title: '',
        type: 'boolean',
        oneOf: [
          {
            const: true,
            title: 'Oui',
          },
          {
            const: false,
            title: 'Non',
          },
        ],
        default: null,
      },
    },
    required: ['properties'],
    dependencies: {
      properties: {
        oneOf: [
          {
            properties: {
              properties: {
                enum: [null, true],
              },
              tip_card_info: {
                type: 'string',
                title: '<strong>Précision sur les biens immobiliers</strong>',
                description:
                  "Vous possédez du patrimoine immobilier au sens de la procédure si :<br />- vous avez des biens immobilier en commun<br />- des biens indivis<br />- des biens propres<br />- vous avez récemment vendu ce(s) bien(s) immobilier (moins d'1 an)<br /><br />Afin d'assurer votre sécurité juridique post divorce, vos avocats devront impérativement vérifier la qualification des biens possédés.",
              },
            },
          },
          {
            properties: {
              properties: {
                enum: [false],
              },
              tip_card_info: {
                type: 'string',
                title: '<strong>Précision sur les biens immobiliers</strong>',
                description:
                  "Afin d'assurer votre sécurité juridique post divorce, vos avocats devront impérativement vérifier la qualification des biens possédés.",
              },
            },
          },
        ],
      },
    },
  },
  {
    title: 'Emma de WeDivorce',
    description: `👉 Sélectionnez la formule de services qui vous convient le mieux.`,
    type: 'object',
    properties: {
      plan: {
        title: '',
        type: 'object',
        properties: {
          id: {
            type: 'string',
          },
          code: {
            type: 'string',
          },
          name: {
            type: 'string',
          },
        },
        default: null,
      },
    },
    required: ['plan'],
  },
  {
    title: 'Emma de WeDivorce',
    description: `Dites-nous si vous êtes dans l'urgence.`,
    type: 'object',
    properties: {
      options: {
        title: '',
        type: 'array',
        items: {
          type: 'object',
          properties: {
            id: {
              type: 'string',
            },
            code: {
              type: 'string',
              enum: [ProductCode.Express],
            },
            name: {
              type: 'string',
            },
          },
        },
      },
    },
  },
  {
    title: 'Emma de WeDivorce',
    description: `Dites-nous si vous avez besoin d'un accompagnement renforcé.`,
    type: 'object',
    properties: {
      options: {
        title: '',
        type: 'array',
        items: {
          type: 'object',
          properties: {
            id: {
              type: 'string',
            },
            code: {
              type: 'string',
              enum: [ProductCode.Premium],
            },
            name: {
              type: 'string',
            },
          },
        },
      },
    },
  },
  {
    title: 'Emma de WeDivorce',
    description:
      '🏁 Dernière étape ! Pour accéder à votre tarif personnalisé, merci de nous fournir vos coordonnées.',
    type: 'object',
    properties: {
      email: {
        title: 'E-mail',
        type: 'string',
        format: 'custom-regex-email',
      },
      phone: {
        type: 'string',
        title: 'Téléphone',
      },
      agreecorrespondence: {
        type: 'boolean',
        title:
          "J’accepte la <a href='https://www.wedivorce.fr/politique-de-confidentialite/' target='_blank'>politique de confidentialité</a> des données de WeDivorce, et notamment le rappel gratuit et sans engagement, et l’envoi d’un devis par email.",
      },
    },
    required: ['email', 'agreecorrespondence'],
  },
];

const uiSchema: UiSchema[] = [
  {
    choice0: {
      'ui:widget': 'radio',
      'ui:options': {
        direction: 'row',
        mobileDirection: 'row',
      },
    },
  },
  {
    choice1: {
      'ui:widget': 'radio',
      'ui:options': {
        direction: 'column',
        mobileDirection: 'row',
      },
    },
  },
  {
    region: {
      'ui:widget': 'select',
      'ui:placeholder': 'Choisir un département',
    },
  },
  {
    coupleSituation: {
      'ui:widget': 'radio',
      'ui:options': {
        mobileDirection: 'row',
      },
    },
    nbYoungChildren: {
      'ui:widget': 'UpDownWidget',
      'ui:options': {
        label: true,
      },
    },
    nbAdultChildren: {
      'ui:widget': 'UpDownWidget',
      'ui:options': {
        label: true,
      },
    },
  },
  {
    properties: {
      'ui:widget': 'radio',
      'ui:options': {
        direction: 'column',
      },
    },
  },
  {
    'ui:options': {
      fullWidth: true,
    },
    plan: {},
  },
  {
    options: {},
  },
  {
    options: {},
  },
  {
    email: {
      'ui:field': 'CustomField',
      'ui:emptyValue': '',
      'ui:options': {
        label: true,
        inputType: 'email',
      },
      'ui:placeholder': 'martin.dupont@gmail.com',
    },
    phone: {
      'ui:field': 'CustomTel',
      'ui:options': {
        label: true,
      },
      'ui:placeholder': '06 12 34 56 78',
    },
  },
];

// injection version schema
const schemas = schemasList.map((schema) => ({
  $schema: 'http://json-schema.org/draft-07/schema#',
  ...schema,
}));

export { schemas, uiSchema };
