import { FC } from 'react';
import styled from 'styled-components';

import { Text } from 'components/Layout';
import { BreakpointsSpacing } from 'theme/styles/responsive';
import { FontStyles } from 'theme/styles/fonts';
import { Weights } from 'theme/styles/size';

interface LabelProps {
  fontStyle?: FontStyles;
  marginBottom?: BreakpointsSpacing;
  dangerouslySetInnerHTML?: { __html: string };
  weight?: Weights;
}

const StyledLabel = styled(Text)`
  display: inline;
`;

export const Label: FC<LabelProps> = ({
  fontStyle,
  marginBottom,
  dangerouslySetInnerHTML,
  weight,
}) => (
  <StyledLabel
    fontStyle={fontStyle || 'heading7'}
    dangerouslySetInnerHTML={dangerouslySetInnerHTML}
    marginBottom={marginBottom}
    weight={weight}
  />
);
