import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { SpecificationCard } from 'components/Cards';
import { Row, Col } from 'components/Layout';
import { useApi } from '../../../../hooks/useApi';
import { ProceduresStats } from '../../../../types/resources';

const OpportunitiesCountStatus: FC = () => {
  const { t } = useTranslation();

  const { execute: getProceduresStats, state: getProceduresStatsState } =
    useApi<ProceduresStats>(`/procedures/stats`);
  const stats = getProceduresStatsState.data?.value;

  useEffect(() => {
    getProceduresStats();
  }, []);

  const opportunitiesStatus = [
    {
      status: t('opportunity.new_files'),
      number: stats?.new_count,
    },
    {
      status: t('opportunity.in_form_step'),
      number: stats?.forms_step_count,
    },
    {
      status: t('opportunity.in_document_step'),
      number: stats?.documents_step_count,
    },
    {
      status: t('opportunity.to_archive'),
      number: stats?.archived_count,
    },
  ];

  return (
    <Row marginBottom={{ xs: 'space32' }}>
      {opportunitiesStatus.map((opportunity, index) => (
        <Col
          key={`opportunities-status-${index}`}
          md={4}
          lg={3}
          marginTop={{ xs: 'space8' }}
        >
          <SpecificationCard
            iconName="Folder"
            specification={opportunity.status}
            value={opportunity.number?.toString() || '...'}
          />
        </Col>
      ))}
    </Row>
  );
};

export default OpportunitiesCountStatus;
