import { FC, ReactElement } from 'react';
import styled from 'styled-components';

// Components
import { Flex, Text } from 'components/Layout';
import { IconButton, Button } from 'components/Buttons';
import { ButtonProps } from 'components/Buttons/Button';
import { IconProps } from 'components/Images/Icon';
import { Card } from 'components/Cards';

// Utils
import { theme } from 'theme';
import { BreakpointsDirection } from 'theme/styles/responsive';

const StyledFlex = styled(Flex)<StyledFlexProps>`
  &:nth-child(n + 2) {
    margin-left: ${(props) => (props.textDirection === 'row' ? `16px` : '0')};
    margin-top: ${(props) => (props.textDirection === 'column' ? `16px` : '0')};
  }
`;

const CustomCard = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

interface StyledFlexProps {
  textDirection?: string;
}

export interface ClientCardProps {
  iconName?: IconProps['name'];
  title: string;
  buttons?: Array<ButtonProps | null>;
  buttonsDirections?: BreakpointsDirection;
  onClick?: () => void;
  className?: string;
  rightSide?: ReactElement<any, any>;
  leftSide?: ReactElement<any, any>;
}

export const ClientCard: FC<ClientCardProps> = ({
  iconName,
  title,
  children,
  buttons = [],
  onClick = () => {},
  buttonsDirections = { xs: 'row' },
  className,
  rightSide,
  leftSide,
}) => {
  const direction = Object.values(buttonsDirections)[0];
  return (
    <CustomCard className={className}>
      <Flex direction={{ xs: 'column' }}>
        <Flex marginBottom={{ xs: 'space16' }}>
          <Flex direction={{ xs: 'column' }}>
            <Flex alignItems="center">
              {iconName && (
                <IconButton
                  iconName={iconName}
                  color={theme.colors.salmon2}
                  backgroundColor={theme.colors.salmon3}
                  rounded
                  stroke="regular"
                />
              )}
              <Text
                content={title}
                fontStyle="heading4"
                marginLeft={{ xs: 'space16' }}
              />
            </Flex>
            {leftSide}
          </Flex>
          <Flex marginLeft={{ xs: 'auto' }} marginRight={{ xs: 'none' }}>
            {rightSide}
          </Flex>
        </Flex>
        {children}
      </Flex>
      {buttons.length > 0 && (
        <Flex
          direction={buttonsDirections}
          marginTop={{ xs: children ? 'space16' : 'space8' }}
        >
          {buttons.map((props: ButtonProps | null, index: number) => {
            if (props) {
              return (
                <StyledFlex key={index} textDirection={direction} width="100%">
                  <Button
                    {...props}
                    onClick={props.onClick ? props.onClick : onClick}
                  />
                </StyledFlex>
              );
            }
          })}
        </Flex>
      )}
    </CustomCard>
  );
};
