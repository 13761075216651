import { FC, MouseEventHandler, useState } from 'react';
import { lg, md } from 'theme/styles/mediaQueries';

import { DropdownIcon } from 'components/Buttons';
import { Flex } from 'components/Layout/Flex';
import InputCheckbox from 'components/FormTemplate/Fields/InputCheckbox';
// Components
import { Text } from 'components/Layout/Text';
import styled from 'styled-components';
import { theme } from 'theme';
import { useViewport } from 'hooks/useViewport';

const CardContainer = styled(Flex)<CardContainerProps>`
  width: 100%;
  height: auto;
  transition: opacity 0.3s ease-in-out;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: auto;
  padding: ${theme.spacing.space40} ${theme.spacing.space32};
  background-color: ${theme.colors.beige};
  border-radius: ${theme.spacing.space24};

  ul {
    list-style: outside disc;
  }

  ul li {
    position: relative;
    left: 1em;
    margin-bottom: ${theme.spacing.space8};
  }
`;

const FixedHeightText = styled(Text)`
  min-height: auto;

  ${lg(`
    min-height: 52px;
  `)}
`;

const CheckboxContainer = styled.div<CheckboxContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${theme.spacing.space16} ${theme.spacing.space40};
  border: 1px solid ${theme.colors.gray3};
  border-radius: ${theme.spacing.space16};
  background-color: ${theme.colors.white};
  cursor: pointer;

  ${md(`
    max-width: 216px;
  `)}
`;

const CardButton = styled.div`
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
  width: 216px;
  padding: 10px;
  border-radius: 13px;
  background-color: ${theme.colors.green1};
  text-transform: uppercase;
`;

interface CardContainerProps {
  disabled?: boolean;
}

interface CheckboxContainerProps {
  onClick?: MouseEventHandler<HTMLDivElement>;
}

export interface FormulaCardProps {
  title: string;
  content?: string | null;
  priceDescription: string;
  advice?: string;
  disabled?: boolean;
  checked?: boolean;
  textButton: string;
  onClick?: MouseEventHandler<HTMLDivElement>;
}

export const FormulaCard: FC<FormulaCardProps> = ({
  title,
  children,
  content,
  priceDescription,
  textButton,
  onClick,
  advice,
  checked = false,
}) => {
  const { isMobile } = useViewport();
  const [textState, setTextState] = useState<boolean>(isMobile ? false : true);

  return (
    <CardContainer>
      {!!advice && (
        <CardButton>
          <Text
            content={advice}
            fontStyle="body3"
            color={theme.colors.white}
            transform="uppercase"
            weight="bold"
            textAlign="center"
          />
        </CardButton>
      )}
      <Card>
        <Flex direction={{ xs: 'column' }}>
          <Flex
            alignItems="center"
            justify="center"
            marginBottom={{ xs: 'space24' }}
            onClick={() => isMobile && setTextState(!textState)}
          >
            <FixedHeightText
              content={title}
              fontStyle="heading4"
              textAlign="center"
            />
            <Flex mobileOnly marginLeft={{ xs: 'space8' }}>
              <DropdownIcon
                onClick={() => isMobile && setTextState(!textState)}
                active={textState}
              />
            </Flex>
          </Flex>
          {children}
          {content && textState && (
            <Text
              content={content}
              fontStyle={isMobile ? 'body2' : 'body1'}
              color={theme.colors.gray5}
              textAlign="center"
              marginBottom={{ xs: 'space24' }}
            />
          )}
        </Flex>
        <Flex direction={{ xs: 'column' }} alignItems="center">
          <Text
            content={priceDescription}
            fontStyle="body1"
            color={theme.colors.green1}
            textAlign="center"
            width="medium"
            weight="bold"
            marginBottom={{ xs: 'space12' }}
          />
          <CheckboxContainer onClick={onClick}>
            <InputCheckbox
              label={textButton}
              fontWeight="bold"
              checked={checked}
              rounded
              checkPoint
            />
          </CheckboxContainer>
        </Flex>
      </Card>
    </CardContainer>
  );
};
