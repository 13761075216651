import { FC } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { Flex } from 'components/Layout/Flex';
import { FormList } from 'components/Forms';

import { UserValidation } from 'components/User';
import {
  FormFull,
  FormStatus,
  ProcedureFull,
  ProcedureStepFull,
} from '../../../types/resources';

interface FormsListProps {
  procedure: ProcedureFull;
  step: ProcedureStepFull;
  forms: FormFull[];
}

const FormsList: FC<FormsListProps> = ({ procedure, forms, step }) => {
  const { t } = useTranslation();
  const formsToComplete = forms.filter(
    (f) => f.status === FormStatus.TO_COMPLETE,
  );
  const formsToReview = forms.filter((f) => f.status === FormStatus.IN_REVIEW);
  const formsCompleted = forms.filter((f) => f.status === FormStatus.COMPLETED);
  const formsFinish = forms.filter((f) => f.status === FormStatus.VALIDATED);

  const spouseLeadValid = step.validations.some(
    (v) => v.user_id === procedure.spouse1_id,
  );
  const spouseFollowerValid = step.validations.some(
    (v) => v.user_id === procedure.spouse2_id,
  );

  return (
    <Flex
      direction={{ xs: 'column' }}
      width="100%"
      marginBottom={{ xs: 'space16' }}
    >
      <Flex marginBottom={{ xs: 'space32' }} desktopOnly>
        {procedure.spouse1 && (
          <UserValidation user={procedure.spouse1} valid={spouseLeadValid} />
        )}
        {procedure.spouse2 && (
          <UserValidation
            user={procedure.spouse2}
            valid={spouseFollowerValid}
          />
        )}
      </Flex>
      <FormList
        title={t('forms_opportunity.to_complete')}
        count={t('forms_opportunity.to_complete_count', {
          count: formsToComplete?.length,
        })}
        data={formsToComplete}
        opportunityId={procedure.id}
      />
      <FormList
        title={t('forms_opportunity.completed')}
        count={t('forms_opportunity.completed_count', {
          count: formsCompleted?.length,
        })}
        data={formsCompleted}
        opportunityId={procedure.id}
      />
      <FormList
        title={t('forms_opportunity.in_review')}
        count={t('forms_opportunity.in_review_count', {
          count: formsToReview?.length,
        })}
        data={formsToReview}
        opportunityId={procedure.id}
      />
      <FormList
        title={t('forms_opportunity.finish')}
        count={t('forms_opportunity.finish_count', {
          count: formsFinish?.length,
        })}
        data={formsFinish}
        opportunityId={procedure.id}
      />
    </Flex>
  );
};

export default FormsList;
