import { FC } from 'react';

import { theme } from 'theme';

import { IconButton } from 'components/Buttons';
import { Flex, Text } from 'components/Layout';
import { IconProps } from 'components/Images/Icon';

interface ItemProps {
  content: string;
  icon: IconProps['name'];
  align?: string;
}

const Item: FC<ItemProps> = ({ icon, content, align }) => {
  const alignItems = align === 'top' ? 'start' : 'center';
  return (
    <Flex
      direction={{ xs: 'row' }}
      marginBottom={{ xs: 'space8' }}
      alignItems={alignItems}
    >
      <IconButton
        iconName={icon}
        color={theme.colors.green1}
        backgroundColor={theme.colors.green2}
        rounded
      />
      <Text
        fontStyle="body2"
        weight="medium"
        content={content}
        marginLeft={{ xs: 'space16' }}
      />
    </Flex>
  );
};
interface IconListProps {
  title: string;
  items: ItemProps[];
}

const IconList: FC<IconListProps> = ({ title, items }) => {
  return (
    <Flex
      paddingTop={{ xs: 'space8' }}
      paddingLeft={{ xs: 'space24' }}
      direction={{ xs: 'column' }}
    >
      <Text
        fontStyle="body1"
        weight="bold"
        marginBottom={{ xs: 'space24' }}
        content={title}
      />
      {items.map((item) => {
        return <Item key={item.content} {...item} />;
      })}
    </Flex>
  );
};

export default IconList;
