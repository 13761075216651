import { CSSProperties, FC, useState } from 'react';
import styled from 'styled-components';

import { IconButton } from 'components/Buttons/IconButton';
import { Text } from 'components/Layout/Text';
import { md } from 'theme/styles/mediaQueries';
import { IconProps } from 'components/Images/Icon';

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;

  &:disabled {
    opacity: 0.5;
  }

  ${md(`
    justify-content: start; 
  `)}
`;

interface InlineButtonProps {
  onClick?: () => void;
  iconColor: string;
  text: string;
  backgroundColor: string;
  iconName: IconProps['name'];
  hoverBackgroundColor?: string;
  hoverIconColor?: string;
  noWrap?: boolean;
  style?: CSSProperties;
  disabled?: boolean;
}

const InlineButton: FC<InlineButtonProps> = ({
  onClick,
  iconColor,
  text,
  backgroundColor,
  iconName,
  hoverBackgroundColor,
  hoverIconColor,
  noWrap = true,
  style,
  disabled,
}) => {
  const [hoverState, setHoverState] = useState(false);

  return (
    <Button
      style={style}
      onClick={onClick}
      onMouseEnter={() => setHoverState(true)}
      onMouseLeave={() => setHoverState(false)}
      disabled={disabled}
    >
      <IconButton
        color={hoverState ? hoverIconColor || iconColor : iconColor}
        backgroundColor={
          hoverState ? hoverBackgroundColor || backgroundColor : backgroundColor
        }
        iconName={iconName}
        rounded
        size="small"
      />
      <Text
        content={text}
        fontStyle="label"
        whiteSpace={noWrap ? 'nowrap' : 'normal'}
        marginLeft={{ xs: 'space8' }}
      />
    </Button>
  );
};

export default InlineButton;
