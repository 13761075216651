import { FC } from 'react';
import styled from 'styled-components';

import { Flex } from 'components/Layout';
import { Icon } from 'components/Images';

interface DropdownProps {
  onClick: () => void;
  active: boolean;
}

const IconContainer = styled(Flex)<DropdownProps>`
  height: 24px;
  transform: ${(props) => (props.active ? 'rotate(180deg)' : 'rotate(0deg)')};
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
`;

const DropdownIcon: FC<DropdownProps> = ({ active, onClick }) => {
  return (
    <IconContainer
      justify="center"
      alignItems="center"
      onClick={onClick}
      active={active}
    >
      <Icon name="ChevronDown" />
    </IconContainer>
  );
};

export default DropdownIcon;
