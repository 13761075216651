import { useState, Dispatch, SetStateAction } from 'react';

export type setIsModalOpen = Dispatch<SetStateAction<boolean>>;

export type UseModalType = (
  initialMode?: boolean,
) => [boolean, setIsModalOpen, () => void];

export const useModal: UseModalType = (initialMode = false) => {
  const [isModalOpen, setIsModalOpen] = useState(initialMode);
  const toggle = () => setIsModalOpen(!isModalOpen);

  return [isModalOpen, setIsModalOpen, toggle];
};
