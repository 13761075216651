import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Text } from '../../Layout';
import { theme } from '../../../theme';
import styled from 'styled-components';
import { md } from '../../../theme/styles/mediaQueries';

interface CheckboxContainerProps {
  selected?: boolean;
}

const CheckboxContainer = styled.div<CheckboxContainerProps>`
  display: flex;
  align-items: center;
  padding: ${theme.spacing.space16} ${theme.spacing.space16};
  border-radius: ${theme.spacing.space24};
  cursor: pointer;

  ${(props) => `
    border: 1px solid ${
      props?.selected ? theme.colors.salmon2 : theme.colors.gray4
    };
    background-color: ${
      props?.selected ? theme.colors.salmonPastel : 'transparent'
    };
  `}

  ${md(`
    max-width: 216px;
  `)}
`;

const OptionWrapper = styled(Flex)`
  gap: ${theme.spacing.space16};
`;

export interface ModalityOption {
  onClick: () => void;
  time: number;
  displayTime: string;
  withCost: boolean;
}

interface OptionClassified {
  withCost: ModalityOption[];
  withoutCost: ModalityOption[];
}

interface OptionsProps {
  options: ModalityOption[];
  active?: number;
}

const Modality: FC<OptionsProps> = ({ options, active }) => {
  const { t } = useTranslation();
  const optionsClassified: OptionClassified = useMemo(() => {
    return options.reduce(
      (acc, val) => {
        if (val.withCost) {
          acc.withCost.push(val);
        } else {
          acc.withoutCost.push(val);
        }
        return acc;
      },
      { withCost: [], withoutCost: [] } as OptionClassified,
    );
  }, [options]);

  return (
    <Flex direction={{ xs: 'column', lg: 'row' }} justify="between" wrap="wrap">
      <Flex
        direction={{ xs: 'column' }}
        marginRight={{ xs: 'none', lg: 'space16' }}
      >
        <Text
          content={t('quote.payment.modality.without_costs')}
          fontStyle="body2"
          color={theme.colors.gray5}
          marginBottom={{ xs: 'space12' }}
        />
        <OptionWrapper alignItems="center">
          {optionsClassified.withoutCost.map((option) => {
            const { onClick, time, displayTime } = option;
            const checked = active === time;
            return (
              <CheckboxContainer
                key={`option_${displayTime}`}
                onClick={onClick}
                selected={checked}
              >
                <Text
                  fontStyle="heading3"
                  color={checked ? theme.colors.salmon2 : theme.colors.black}
                  content={displayTime}
                />
              </CheckboxContainer>
            );
          })}
        </OptionWrapper>
      </Flex>
      <Flex
        direction={{ xs: 'column' }}
        marginTop={{ xs: 'space16', lg: 'none' }}
      >
        <Text
          content={t('quote.payment.modality.with_costs')}
          fontStyle="body2"
          color={theme.colors.gray5}
          marginBottom={{ xs: 'space12' }}
        />
        <OptionWrapper alignItems="center">
          {optionsClassified.withCost.map((option) => {
            const { onClick, time, displayTime } = option;
            const checked = active === time;
            return (
              <CheckboxContainer
                key={`option_${displayTime}`}
                onClick={onClick}
                selected={checked}
              >
                <Text
                  fontStyle="heading3"
                  color={checked ? theme.colors.salmon2 : theme.colors.black}
                  content={displayTime}
                />
              </CheckboxContainer>
            );
          })}
        </OptionWrapper>
      </Flex>
    </Flex>
  );
};

export default Modality;
