import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Flex, Line, Text } from 'components/Layout';
import { ListItem } from 'components/List';
import { formatDate } from 'utils/formatDate';
import { Consent, ProcedureSpouseFull } from '../../../types/resources';

interface RegisteredLettersConsentProps {
  consents: Consent[];
  spouses: ProcedureSpouseFull[];
}

const RegisteredLettersConsent: FC<RegisteredLettersConsentProps> = ({
  spouses,
  consents,
}) => {
  const { t } = useTranslation();

  const getSendText = (sent_at?: string | null) => {
    if (sent_at) {
      return t('registered_letters.send_date', {
        date: formatDate(new Date(sent_at)),
      });
    } else {
      return t('registered_letters.in_progress_send');
    }
  };

  const getReceiveText = (
    sent_at?: string | null,
    received_at?: string | null,
  ) => {
    if (received_at) {
      return t('registered_letters.received_date', {
        date: formatDate(new Date(received_at)),
      });
    } else if (sent_at) {
      return t('registered_letters.in_progress_received');
    } else {
      return '';
    }
  };

  return (
    <Flex
      direction={{ xs: 'column' }}
      width="100%"
      marginBottom={{ xs: 'space16' }}
    >
      <Flex marginBottom={{ xs: 'space16' }} alignItems="center">
        <Text
          fontStyle="heading5"
          weight="bold"
          content={t('registered_letters.consent')}
        />
      </Flex>
      <Flex marginBottom={{ xs: 'space24' }}>
        <Line />
      </Flex>
      {consents.map((consent, index) => {
        const dates: string[] = [
          getSendText(consent?.sent_at),
          getReceiveText(consent?.sent_at, consent?.validated_at),
        ];
        const spouse = spouses.find((s) => s.spouse_id === consent.customer_id);
        const spouseName = spouse?.spouse.full_name ?? '';

        return (
          <Flex marginBottom={{ xs: 'space16' }} key={index}>
            <ListItem title={spouseName} iconName="Message" dates={dates} />
          </Flex>
        );
      })}
    </Flex>
  );
};

export default RegisteredLettersConsent;
