export const replaceLabelWithContext = (
  label: string,
  context?: Record<string, string>,
): string => {
  if (label.includes('{{c1}}') && context?.c1)
    return label.replace('{{c1}}', context.c1);
  if (label.includes('{{c2}}') && context?.c2)
    return label.replace('{{c2}}', context.c2);
  if (label.includes('Conjoint 1') && context?.c1)
    return label.replace('Conjoint 1', context.c1);
  if (label.includes('Conjoint 2') && context?.c2)
    return label.replace('Conjoint 2', context.c2);
  return label;
};
