type CompressOptions = {
  quality?: number;
  maxWidth?: number;
  maxHeight?: number;
};

export function compressFile(
  file: File,
  options?: CompressOptions,
): Promise<File> {
  return new Promise((resolve) => {
    // Exist if file is not an image
    if (!file.type.startsWith('image')) {
      resolve(file);
    }
    // Compress if file is an image
    else {
      const img = new Image();
      img.onload = async function () {
        const source = {
          width: img.naturalWidth,
          height: img.naturalHeight,
        };

        const target = {
          width: options?.maxWidth ?? source.width,
          height: options?.maxHeight ?? source.height,
        };
        const aspectRatio = Math.min(
          1,
          target.width / source.width,
          target.height / source.height,
        );
        target.width = source.width * aspectRatio;
        target.height = source.height * aspectRatio;

        const canvas = document.createElement('canvas');
        canvas.width = target.width;
        canvas.height = target.height;
        const context = canvas.getContext('2d');
        context?.drawImage(img, 0, 0, target.width, target.height);

        canvas.toBlob(
          (blob) => {
            if (!blob) {
              resolve(file);
            } else {
              const newFile = new File([blob], file.name, {
                type: file.type,
                lastModified: Date.now(),
              });

              resolve(newFile);
            }
          },
          file.type,
          options?.quality ?? 1,
        );
      };

      img.src = URL.createObjectURL(file);
    }
  });
}

export function compressFiles(
  files: File[],
  options?: CompressOptions,
): Promise<File[]> {
  return Promise.all(files.map((file) => compressFile(file, options)));
}
