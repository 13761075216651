import { size, height, weight } from 'theme/styles/size';

export type FontFamilies = 'value' | 'inter';

export enum FontValue {
  value = 'Value Serif',
  inter = 'Inter',
}

export type FontFamilyTypes = {
  [FontFamily in FontFamilies]: FontValue;
};

export const fontFamily: FontFamilyTypes = {
  value: FontValue.value,
  inter: FontValue.inter,
};

interface Font {
  fontSize: string;
  fontFamily: string;
  lineHeight: string;
  fontWeight?: string;
  textTransform?: string;
}

export type FontStyles =
  | 'heading1'
  | 'heading2'
  | 'heading3'
  | 'heading4'
  | 'heading5'
  | 'heading6'
  | 'heading7'
  | 'body1'
  | 'body2'
  | 'body3'
  | 'label';

export type fontTypes = {
  [font in FontStyles]: Font;
};

export const font: fontTypes = {
  heading1: {
    fontSize: size.size8,
    fontFamily: fontFamily.value,
    lineHeight: height.height7,
    fontWeight: weight.bold,
  },
  heading2: {
    fontSize: size.size7,
    fontFamily: fontFamily.value,
    lineHeight: height.height5,
    fontWeight: weight.bold,
  },
  heading3: {
    fontSize: size.size6,
    fontFamily: fontFamily.value,
    lineHeight: height.height5,
    fontWeight: weight.bold,
  },
  heading4: {
    fontSize: size.size5,
    fontFamily: fontFamily.value,
    lineHeight: height.height4,
    fontWeight: weight.bold,
  },
  heading5: {
    fontSize: size.size4,
    fontFamily: fontFamily.value,
    lineHeight: height.height4,
    fontWeight: weight.bold,
  },
  heading6: {
    fontSize: size.size3,
    fontFamily: fontFamily.value,
    lineHeight: height.height3,
    fontWeight: weight.bold,
  },
  heading7: {
    fontSize: size.size4,
    fontFamily: fontFamily.inter,
    lineHeight: height.height3,
    fontWeight: weight.medium,
  },
  body1: {
    fontSize: size.size3,
    fontFamily: fontFamily.inter,
    lineHeight: height.height4,
  },
  body2: {
    fontSize: size.size2,
    fontFamily: fontFamily.inter,
    lineHeight: height.height2,
  },
  body3: {
    fontSize: size.size1,
    fontFamily: fontFamily.inter,
    lineHeight: height.height1,
  },
  label: {
    fontSize: size.size1,
    fontFamily: fontFamily.inter,
    lineHeight: height.height2,
    textTransform: 'uppercase',
    fontWeight: weight.bold,
  },
};
