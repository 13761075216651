import { useViewport } from 'hooks/useViewport';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface ProfilePictureProps {
  url?: string;
  size: 'small' | 'large';
}

const Image = styled.img`
  object-fit: cover;
  border-radius: 50%;
`;

const ProfilePicture: FC<ProfilePictureProps> = ({ url, size, children }) => {
  const { isMobile } = useViewport();
  const { t } = useTranslation();
  if (!url) {
    return <>{children}</>;
  }
  const getSize = () => {
    switch (size) {
      case 'large':
        return 128;
      case 'small':
      default:
        return isMobile ? 48 : 64;
    }
  };
  const displayedSize = getSize();
  return (
    <Image
      src={url}
      width={displayedSize}
      height={displayedSize}
      alt={t('profile_picture')}
    />
  );
};

export default ProfilePicture;
