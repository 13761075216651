import { FC } from 'react';
import { useTranslation } from 'react-i18next';

// Hooks

// Components
import { Row, Col, Text, Flex } from 'components/Layout';
import { Permission } from 'components/User';
import { Button, IconButton } from 'components/Buttons';

// Type

// Container

import { AdminAppRoutes } from 'AdminApp';
import { theme } from 'theme';
import { DealInfos } from 'container/admin';
import { useHistory, useRouteMatch } from 'react-router';
import { get } from 'lodash';
import { getRouteWithParams } from 'utils/router';
import { Role } from '../../types/resources';

const CreateManualOpportunity: FC = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const match = useRouteMatch();
  const dealId = get(match, 'params.dealId', '');

  return (
    <Permission roles={[Role.ADMIN, Role.SALES]} redirect={'/not-found'}>
      <Row marginBottom={{ xs: 'space32', md: 'space48' }}>
        <Col xs={12} justify="between" marginBottom={{ xs: 'space12' }}>
          <Flex marginBottom={{ xs: 'space16' }} marginRight={{ xs: 'space8' }}>
            <IconButton
              iconName="Folder"
              color={theme.colors.white}
              backgroundColor={theme.colors.red1}
              rounded
            />
            <Flex alignItems="center">
              <Text
                content={t('forcePayment')}
                fontStyle={'heading5'}
                marginLeft={{ xs: 'space16' }}
                weight="bold"
              />
            </Flex>
          </Flex>
          <Flex>
            <Button
              content="Accéder au récapitulatif"
              iconLeft={{ name: 'ArrowRight' }}
              size="small"
              onClick={() =>
                history.push(
                  getRouteWithParams(AdminAppRoutes.QUOTE_RESUME, {
                    id: dealId,
                  }),
                )
              }
            />
          </Flex>
        </Col>
        <Col>
          <DealInfos dealId={dealId} />
        </Col>
      </Row>
    </Permission>
  );
};

export default CreateManualOpportunity;
