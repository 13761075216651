import { FC, useRef } from 'react';
import { WidgetProps } from '@rjsf/core';
import styled from 'styled-components';

// Components
import { Flex } from 'components/Layout/Flex';
import { Text } from 'components/Layout/Text';
import { theme } from 'theme';
import checkIcon from '../../../assets/svg/check.svg';

const Checkbox = styled.input`
  appearance: none;
  border: 1px solid ${theme.colors.gray4};
  height: 24px;
  border-radius: 5px;
  min-width: 24px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;

  &:checked {
    position: relative;
    background-color: ${theme.colors.green1};
    border: 1px solid ${theme.colors.green1};
    transition: background-color 0.2s ease-in-out;
  }

  &:before {
    height: 100%;
    content: url(${checkIcon});
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1px;
  }

  &:disabled {
    background: ${theme.colors.gray3};
    &:before {
      content: '';
    }
  }
`;

const RequiredAsterisk = styled.span`
  color: red;
  padding: 0 5px;
`;

export const CheckboxWidget: FC<WidgetProps> = ({
  disabled,
  label,
  value,
  required,
  readonly,
  onChange,
}) => {
  const disabledCls = disabled || readonly ? 'disabled' : '';
  const _onClick = () => onChange(!value);
  const checkboxEl = useRef(value);

  return (
    <Flex className={`checkbox ${disabledCls}`}>
      <Checkbox
        type="checkbox"
        required={required}
        readOnly={readonly}
        onClick={_onClick}
        defaultChecked={value}
        ref={checkboxEl}
      />
      <Flex
        onClick={() => {
          _onClick();
          checkboxEl.current.checked = !value;
        }}
        style={{ cursor: 'pointer' }}
      >
        <Text
          dangerouslySetInnerHTML={{ __html: label }}
          fontStyle="body2"
          marginLeft={{ xs: 'space16' }}
        >
          <RequiredAsterisk>*</RequiredAsterisk>
        </Text>
      </Flex>
    </Flex>
  );
};
