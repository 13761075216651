import { FC, useEffect, useMemo, useState } from 'react';
import { Column, SortingRule } from 'react-table';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

// Components
import Table from 'components/Table/Table';

import { TextEllipsis } from 'components/Text';
import { IconButton, OpportunityStatusButton } from 'components/Buttons';
import { getTypeByStatus } from 'utils/status';
import { format } from 'date-fns';
import { useHistory } from 'react-router';
import config from 'config/app';
import { Card } from 'components/Cards';
import { Flex, Text } from 'components/Layout';
import { theme } from 'theme';
import { getRouteWithParams } from 'utils/router';
import { AdminAppRoutes } from 'AdminApp';
import { ProcedureFull, SortDirection } from '../../../../types/resources';
import { useApi } from '../../../../hooks/useApi';

const CustomCard = styled(Card)`
  padding: 0;

  tbody {
    display: block;
    max-height: 220px;
  }
`;

const Head = styled(Flex)`
  padding: ${theme.spacing.space16} ${theme.spacing.space24};
`;

const WithInvalidMessageOpportunities: FC = () => {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [sortKey, setSortKey] = useState<string | undefined>(undefined);
  const [sortDirection, setSortDirection] = useState<SortDirection | undefined>(
    undefined,
  );
  const history = useHistory();
  const pageSize = 5;
  const [showTable, toggleShowTable] = useState<boolean>(true);

  const { execute: getProcedures, state: getProceduresState } =
    useApi<ProcedureFull[]>(`/procedures`);
  const procedures = getProceduresState.data?.value ?? [];

  useEffect(() => {
    getProcedures({
      query: {
        page: page !== undefined ? page + 1 : 1,
        items: pageSize,
        has_unprocessed_messages: true,
        sort: sortKey,
        direction: sortDirection,
      },
    });
  }, [page, sortKey, sortDirection]);

  const onLoadMore = ({
    pageIndex,
    sortBy,
  }: {
    pageIndex?: number;
    sortBy?: SortingRule<ProcedureFull>[];
  }) => {
    if (pageIndex !== undefined) {
      setPage(pageIndex);
    }

    const _sortKey = sortBy?.[0]?.id;
    if (_sortKey !== undefined) {
      setSortKey(_sortKey);
    }

    const _sortDesc = sortBy?.[0]?.desc;
    if (_sortDesc !== undefined) {
      setSortDirection(_sortDesc ? SortDirection.DESC : SortDirection.ASC);
    }
  };

  const columns = useMemo<Column<ProcedureFull>[]>(
    () => [
      {
        Header: t('opportunity.columns.name') || 'Noms',
        accessor: 'reference',
        disableSortBy: true,
        Cell: ({ row, value }) => (
          <TextEllipsis
            fontStyle="body2"
            content={`${value} - ${row.original.name}`}
            weight="medium"
          />
        ),
      },
      {
        Header: t('opportunity.columns.step') || 'Étape du dossier',
        id: 'step.type',
        accessor: 'current_step',
        disableSortBy: true,
        Cell: ({ value }) => (
          <TextEllipsis
            fontStyle="body2"
            content={t(`opportunity.steps.${value.step}`)}
            weight="medium"
          />
        ),
      },
      {
        Header: t('opportunity.columns.step_status') || 'Statut',
        id: 'step.status',
        accessor: 'current_step',
        disableSortBy: true,
        width: 100,
        Cell: ({ value }) => (
          <OpportunityStatusButton
            type={getTypeByStatus(value.status)}
            text={t(`enums:${value.status}`)}
          />
        ),
      },
      {
        Header: t('opportunity.columns.updated_at') || 'Dernière action',
        accessor: 'updated_at',
        width: 100,
        Cell: ({ value }) => (
          <TextEllipsis
            fontStyle="body2"
            content={format(new Date(value), config.dateFormat)}
            weight="medium"
          />
        ),
      },
      {
        Header: t('opportunity.columns.unprocessed_messages'),
        accessor: 'unprocessed_messages',
        disableSortBy: true,
        width: 120,
        Cell: ({ value }) => (
          <TextEllipsis
            fontStyle="body2"
            content={String(value)}
            weight="medium"
          />
        ),
      },
    ],
    [history],
  );

  const onRowClick = (opportunity: ProcedureFull) => {
    history.push({
      pathname: getRouteWithParams(AdminAppRoutes.OPPORTUNITY_MESSAGING, {
        id: opportunity.id,
      }),
    });
  };

  return (
    <CustomCard>
      <Head
        alignItems="center"
        onClick={() => toggleShowTable(!showTable)}
        cursor="pointer"
      >
        <Flex direction={{ xs: 'column' }}>
          <Flex alignItems="center">
            <IconButton
              iconName="Folder"
              color={theme.colors.salmon2}
              backgroundColor={theme.colors.salmon3}
              rounded
              stroke="regular"
            />
            <Flex alignItems="end">
              <Text
                content={t('opportunity.opportunities_with_messages')}
                fontStyle="heading5"
                marginRight={{ xs: 'space8' }}
                marginLeft={{ xs: 'space8' }}
                style={{ lineHeight: '1.125rem' }}
              />
              <Text
                fontStyle="body3"
                style={{ lineHeight: '0.75rem' }}
                content={t('opportunities', {
                  count: getProceduresState.data?.pagination?.total ?? 0,
                })}
              />
            </Flex>
          </Flex>
        </Flex>
        {/* <Flex marginLeft={{ xs: 'auto' }} marginRight={{ xs: 'none' }}>
          {data && data.opportunities.totalCount > 0 && (
            <Flex>
              <Icon name={showTable ? 'ChevronDown' : 'ChevronUp'} />
            </Flex>
          )}
        </Flex> */}
      </Head>
      <Flex
        paddingLeft={{ xs: 'space24' }}
        paddingRight={{ xs: 'space24' }}
        paddingBottom={{ xs: 'space24' }}
      >
        {showTable && (
          <Table<ProcedureFull>
            loading={getProceduresState.loading}
            data={procedures || []}
            columns={columns}
            totalCount={getProceduresState.data?.pagination?.total ?? 0}
            pageSize={5}
            loadMore={onLoadMore}
            onRowClick={onRowClick}
          />
        )}
      </Flex>
    </CustomCard>
  );
};

export default WithInvalidMessageOpportunities;
