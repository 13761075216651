import { FC, useEffect } from 'react';

import { Container, Row, Col, Flex } from 'components/Layout';
import { RegisteredLettersInfo, RegisteredLettersList } from 'container/client';
import { ViewGate } from 'container/common';

// Data
import { useCustomer } from '../../hooks/useCustomer';
import {
  Consent,
  Letter,
  ProcedureFull,
  ProcedureSpouseFull,
  ProcedureStepType,
} from '../../types/resources';
import { useApi } from '../../hooks/useApi';
import RegisteredLettersConsent from '../../container/client/RegisteredLetters/RegisteredLettersConsent';

const ClientRegisteredLetters: FC = () => {
  const { customer } = useCustomer();
  const { execute: getProcedure, state: getProcedureState } =
    useApi<ProcedureFull>(`/procedures/${customer?.procedure_id}`);
  const procedure = getProcedureState.data?.value;

  const {
    execute: getDivorceAgreementLetters,
    state: getDivorceAgreementLettersState,
  } = useApi<Letter[]>(
    `/procedures/${procedure?.id}/divorce-agreements/${procedure?.divorce_agreement_id}/letters`,
  );
  const divorceAgreementLetters =
    getDivorceAgreementLettersState.data?.value ?? [];

  const { execute: getConsents, state: getConsentsState } = useApi<Consent[]>(
    `/procedures/${customer?.procedure_id}/consents`,
    { method: 'GET' },
  );
  const consents = getConsentsState.data?.value ?? [];

  const { execute: getSpouses, state: getSpousesState } = useApi<
    ProcedureSpouseFull[]
  >(`/procedures/${procedure?.id}/spouses`);
  const spouses = getSpousesState.data?.value ?? [];

  useEffect(() => {
    if (customer?.procedure_id) {
      getProcedure({ query: { customer_id: customer.id } });
    }
  }, [customer?.procedure_id]);

  useEffect(() => {
    if (procedure) {
      getConsents();
      getDivorceAgreementLetters();
      getSpouses();
    }
  }, [procedure]);

  if (!procedure) return null;

  return (
    <ViewGate procedure={procedure} steps={[ProcedureStepType.AGREEMENT_SENT]}>
      <Container fluid="xl">
        <Row marginTop={{ xs: 'space32' }}>
          <Col xs={12} md={4} vertical>
            <Flex marginBottom={{ xs: 'space32' }}>
              <RegisteredLettersInfo />
            </Flex>
          </Col>
          <Col xs={12} md={8}>
            <Row fullWidth>
              {!procedure.notary_partner && (
                <RegisteredLettersConsent
                  consents={consents}
                  spouses={spouses}
                />
              )}
              <RegisteredLettersList
                letters={divorceAgreementLetters}
                spouses={spouses}
              />
            </Row>
          </Col>
        </Row>
      </Container>
    </ViewGate>
  );
};

export default ClientRegisteredLetters;
