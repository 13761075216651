import { IconButton, OpportunityStatusButton } from 'components/Buttons';
import { Flex, Text } from 'components/Layout';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { theme } from 'theme';
import { getFullname } from 'utils/user';

interface UserValidationProps {
  user: { first_name?: string; last_name?: string };
  locked?: boolean;
  valid?: boolean;
  validationDate?: string;
  letters?: string;
}

const UserValidation: FC<UserValidationProps> = ({
  user,
  valid,
  locked,
  validationDate,
  letters,
}) => {
  const { t } = useTranslation();

  const statusType = locked ? 'danger' : valid ? 'success' : 'warning';
  const statusText = locked
    ? t('opportunity.status.LOCKED')
    : valid
    ? t('validated')
    : t('opportunity.status.IN_PROGRESS');

  return (
    <Flex alignItems="center" marginRight={{ xs: 'space32' }}>
      <IconButton
        iconName="User"
        letters={letters}
        color={theme.colors.green1}
        backgroundColor={theme.colors.green5}
        rounded
        stroke="regular"
      />
      <Text
        fontStyle="body2"
        weight="medium"
        content={getFullname(user)}
        marginLeft={{ xs: 'space16' }}
        marginRight={{ xs: 'space16' }}
        color={theme.colors.gray6}
      />
      <OpportunityStatusButton
        type={statusType}
        text={statusText}
        hoverText={valid ? validationDate : ''}
      />
    </Flex>
  );
};

export default UserValidation;
