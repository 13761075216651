import { UiSchemaCustom } from '.';

const wedding: UiSchemaCustom[] = [
  {
    'ui:order': [
      'DATE_MARIAGE',
      'COMMUNE_MARIAGE',
      'MARIAGE_ADRESSE',
      'CP_COMMUNE_MARIAGE',
      'WD_PAYS_MARIAGE',
      'WD_PARTIES_MARIAGE_RECONNU',
      'PREMIERE_RESIDENCE',
      'WD_MARIAGE_TRANSCRIPTION',
    ],
    COMMUNE_MARIAGE: {
      'ui:field': 'CustomField',
      'ui:options': {
        label: true,
        inputType: 'text',
        uppercase: true,
      },
      'ui:placeholder': 'Paris',
    },
    MARIAGE_ADRESSE: {
      'ui:field': 'CustomField',
      'ui:options': {
        label: true,
        inputType: 'text',
        uppercase: true,
      },
      'ui:placeholder': '2 rue de la paix',
    },
    CP_COMMUNE_MARIAGE: {
      'ui:field': 'CustomField',
      'ui:options': {
        label: true,
        inputType: 'text',
      },
      'ui:placeholder': '75000',
    },
    DATE_MARIAGE: {
      'ui:field': 'CustomDatepicker',
      'ui:placeholder': 'Choisir une date',
      'ui:options': {
        maxToday: true,
        openTo: 'year',
        view: ['year', 'month', 'day'],
      },
    },
    WD_PAYS_MARIAGE: {
      'ui:widget': 'select',
      'ui:placeholder': 'Choisir un pays',
    },
    WD_PARTIES_MARIAGE_RECONNU: {
      'ui:widget': 'radio',
    },
    WD_MARIAGE_TRANSCRIPTION: {
      'ui:widget': 'radio',
    },
    PREMIERE_RESIDENCE: {
      'ui:widget': 'radio',
    },
  },
  {
    'ui:order': ['NOM_PAT'],
    NOM_PAT: {
      'ui:widget': 'radio',
    },
  },
  {
    'ui:order': [
      'SEPARATION',
      'SEPARATION_DATE',
      'MARIAGE_CONTRAT',
      'ANY_CHILDREN',
      'CONTRAT_TYPE',
      'REGIME_MAT',
      'CONTRAT_DATE',
      'WD_PRENOM_NOTAIRE',
      'WD_NOM_NOTAIRE',
      'WD_ADRESSE_NOTAIRE',
      'WD_VILLE_NOTAIRE',
      'WD_CP_NOTAIRE',
    ],
    SEPARATION: {
      'ui:widget': 'radio',
    },
    SEPARATION_DATE: {
      'ui:field': 'CustomDatepicker',
      'ui:placeholder': 'Choisir une date',
      'ui:options': {
        maxToday: true,
        openTo: 'day',
        view: ['day', 'month', 'year'],
      },
    },
    MARIAGE_CONTRAT: {
      'ui:widget': 'radio',
    },
    ANY_CHILDREN: {
      'ui:widget': 'radio',
    },
    WD_VILLE_NOTAIRE: {
      'ui:field': 'CustomField',
      'ui:options': {
        label: true,
        inputType: 'text',
        uppercase: true,
      },
      'ui:placeholder': 'Paris',
    },
    WD_CP_NOTAIRE: {
      'ui:field': 'CustomField',
      'ui:options': {
        label: true,
        inputType: 'text',
      },
      'ui:placeholder': '75000',
    },
    WD_PRENOM_NOTAIRE: {
      'ui:field': 'CustomField',
      'ui:options': {
        label: true,
        inputType: 'text',
        uppercase: true,
      },
      'ui:placeholder': 'Jean',
    },
    WD_NOM_NOTAIRE: {
      'ui:field': 'CustomField',
      'ui:options': {
        label: true,
        inputType: 'text',
        uppercase: true,
      },
      'ui:placeholder': 'Dupont',
    },
    WD_ADRESSE_NOTAIRE: {
      'ui:field': 'CustomField',
      'ui:options': {
        label: true,
        inputType: 'text',
        uppercase: true,
      },
      'ui:placeholder': '1 rue de la paix',
    },
    REGIME_MAT: {
      'ui:widget': 'select',
      'ui:placeholder': 'Choisir un régime',
    },
    CONTRAT_DATE: {
      'ui:field': 'CustomDatepicker',
      'ui:placeholder': 'Choisir une date',
      'ui:options': {
        maxToday: true,
        openTo: 'year',
        view: ['year', 'month', 'day'],
      },
    },
    CONTRAT_TYPE: {
      'ui:widget': 'select',
      'ui:placeholder': 'Choisir un contrat de mariage',
    },
  },
];

export default wedding;
