import { FC, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { Flex } from 'components/Layout/Flex';
import { Text } from 'components/Layout/Text';
import {
  Breadcrumbs,
  ProspectEstimatesTable,
  ProspectUpdateForm,
} from 'container/admin';
import format from 'date-fns/format';
import { useRouteMatch } from 'react-router';
import { get } from 'lodash';
import { useApi } from '../../hooks/useApi';
import { InlineButton } from '../../components/Buttons';
import { theme } from '../../theme';
import { Prospect } from '../../types/resources';
import styled from 'styled-components';
import { env } from '../../config/env';

const ActionsContainer = styled.div`
  margin-top: ${theme.spacing.space48};
  border-radius: 8px;
  padding: ${theme.spacing.space16};
  background-color: ${theme.colors.salmon3};
`;

const ProspectsDetails: FC = () => {
  const { t } = useTranslation();
  const match = useRouteMatch();
  const prospectId = get(match, 'params.id', '');
  const { execute: getProspect, state: getProspectState } = useApi<Prospect>(
    `/prospects/${prospectId}`,
  );
  const prospect = getProspectState.data?.value;

  useEffect(() => {
    getProspect();
  }, []);

  const handleViewInPipedrive = useCallback(() => {
    if (prospect?.pipedrive_person_id) {
      window.open(
        `${env.pipedrive.url}/person/${prospect.pipedrive_person_id}`,
        '_blank',
      );
    }
  }, [prospect?.pipedrive_person_id]);

  const handlePhoneCall = useCallback(() => {
    if (prospect?.phone) {
      window.open(`tel:${prospect.phone}`);
    }
  }, [prospect?.phone]);

  const handleSendMail = useCallback(() => {
    if (prospect?.email) {
      window.open(`mailto:${prospect.email}`);
    }
  }, [prospect?.email]);

  return (
    <>
      <Breadcrumbs name={prospect?.email} />
      <Flex marginBottom={{ xs: 'space48' }} justify="between">
        <Text content={t('prospect.details.title')} fontStyle="heading2" />

        {prospect && (
          <Flex direction={{ xs: 'column' }} alignItems={'end'}>
            <Text
              content={t('updated_at', {
                updated_at: format(
                  new Date(prospect.updated_at),
                  "dd/MM/YYY 'à' HH:mm",
                ),
              })}
              fontStyle="body3"
            />
            <Text
              content={t('prospect.pipedrive_sync_date', {
                pipedrive_sync_date: prospect.pipedrive_sync_date
                  ? format(
                      new Date(prospect.pipedrive_sync_date),
                      "dd/MM/YYY 'à' HH:mm",
                    )
                  : t('never').toLowerCase(),
              })}
              fontStyle="body3"
            />
          </Flex>
        )}
      </Flex>

      {prospect && <ProspectUpdateForm prospect={prospect} />}

      {prospect && (
        <>
          <Flex marginTop={{ xs: 'space48' }}>
            <Text content={t('prospect.estimates')} fontStyle="heading3" />
          </Flex>
          <ProspectEstimatesTable prospect={prospect} />
        </>
      )}

      {prospect && (
        <ActionsContainer>
          <Flex direction={{ xs: 'column' }}>
            {prospect.pipedrive_person_id && (
              <InlineButton
                onClick={handleViewInPipedrive}
                iconColor={theme.colors.salmon1}
                text={t('prospect.pipedrive_label')}
                backgroundColor={theme.colors.white}
                iconName="ArrowRight"
                hoverBackgroundColor={theme.colors.salmon2}
                hoverIconColor={theme.colors.white}
                style={{ width: 'fit-content' }}
              />
            )}

            {prospect.email && (
              <InlineButton
                onClick={handleSendMail}
                iconColor={theme.colors.salmon1}
                text={t('prospect.send_mail')}
                backgroundColor={theme.colors.white}
                iconName="Send"
                hoverBackgroundColor={theme.colors.salmon2}
                hoverIconColor={theme.colors.white}
                style={{ width: 'fit-content', marginTop: '12px' }}
              />
            )}

            {prospect.phone && (
              <InlineButton
                onClick={handlePhoneCall}
                iconColor={theme.colors.salmon1}
                text={t('prospect.call')}
                backgroundColor={theme.colors.white}
                iconName="Call"
                hoverBackgroundColor={theme.colors.salmon2}
                hoverIconColor={theme.colors.white}
                style={{ width: 'fit-content', marginTop: '12px' }}
              />
            )}
          </Flex>
        </ActionsContainer>
      )}
    </>
  );
};

export default ProspectsDetails;
