import { FC, useState } from 'react';
import styled from 'styled-components';
import { Redirect } from 'react-router';

import logoBlack from 'assets/logo/logo-black.svg';

import { ClientAppRoutes } from 'ClientApp';

import { Text } from 'components/Layout';

import { md } from 'theme/styles/mediaQueries';
import { theme } from 'theme';
import { useTranslation } from 'react-i18next';
import { ForgottenPasswordForm } from '../../container/public';
import { useAuth } from '../../hooks/useAuth';

const BackgroundWrapper = styled.div`
  background-image: url('/auth-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  height: calc(100vh - 70px);
  display: flex;

  ${md(`
    padding: ${theme.spacing.space40} 0;
  `)}
`;

const FormWrapper = styled.div`
  margin: auto;
  max-width: 600px;
  padding: ${theme.spacing.space56} ${theme.spacing.space40};
  background: ${theme.colors.gray1};
  border: 1px solid ${theme.colors.gray3};

  ${md(`
    border-radius: ${theme.spacing.space24};
  `)}
`;

const Image = styled.img``;

const ForgottenPassword: FC = () => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const [success, setSuccess] = useState(false);

  if (user) {
    return <Redirect to={ClientAppRoutes.HOME} />;
  }

  return (
    <>
      <BackgroundWrapper>
        <FormWrapper>
          <Image src={logoBlack} alt="WeDivorce" />
          <Text
            content={t('forgottenPassword.title')}
            fontStyle="heading4"
            color={theme.colors.black}
            marginTop={{ xs: 'space32' }}
            marginBottom={{ xs: 'space24' }}
          />
          {success ? (
            <>
              <Text
                content={t('forgottenPassword.success1')}
                fontStyle="body1"
                color={theme.colors.black}
                marginBottom={{ xs: 'space32' }}
              />
              <Text
                content={t('forgottenPassword.success2')}
                fontStyle="body1"
                color={theme.colors.black}
              />
            </>
          ) : (
            <>
              <Text
                content={t('forgottenPassword.subTitle')}
                fontStyle="body1"
                color={theme.colors.black}
                marginBottom={{ xs: 'space32' }}
              />
              <ForgottenPasswordForm onSuccess={() => setSuccess(true)} />
            </>
          )}
        </FormWrapper>
      </BackgroundWrapper>
    </>
  );
};

export default ForgottenPassword;
