import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

// Components
import { ClientCard, StickerCard } from '..';
import { Flex, Text } from 'components/Layout';
import { IconList } from 'components/Text';
import { IconProps } from 'components/Images/Icon';

import { theme } from 'theme';

const Card = styled(ClientCard)`
  height: auto;
`;

const AppointmentInfoCard: FC = () => {
  const { t } = useTranslation();
  const items = [
    {
      icon: 'ArrowDownCircle' as IconProps['name'],
      content: t('appointment.infos.tip.1'),
    },
    {
      icon: 'ArrowDownCircle' as IconProps['name'],
      content: t('appointment.infos.tip.2'),
    },
    {
      icon: 'ArrowDownCircle' as IconProps['name'],
      content: t('appointment.infos.tip.3'),
      align: 'top',
    },
  ];
  return (
    <Card
      iconName="Notification"
      title={t('appointment.infos.title')}
      rightSide={
        <IconList title={t('appointment.infos.tip.title')} items={items} />
      }
      leftSide={
        <Flex marginTop={{ xs: 'space16' }}>
          <StickerCard padding={theme.spacing.space16}>
            <Text
              fontStyle="body1"
              weight="bold"
              content={t('appointment.infos.date')}
              marginBottom={{ xs: 'space8' }}
            />
            <Text
              fontStyle="body2"
              content={t('appointment.infos.description')}
            />
          </StickerCard>
        </Flex>
      }
    />
  );
};

export default AppointmentInfoCard;
