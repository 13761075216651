import { UiSchemaCustom } from '.';

const properties: UiSchemaCustom[] = [
  {
    'ui:order': ['AUTRE_BIEN'],
    AUTRE_BIEN: {
      'ui:classNames': 'task-title foo-bar',
      items: {
        'ui:order': [
          'WD_TYPE_BIEN',
          'WD_STATUT_BIEN',
          'WD_SORT_BIEN',
          'WD_VENTE_DATE',
          'WD_PRECISIONS',
        ],
        'ui:options': {
          fullWidth: true,
          withoutMargin: true,
        },
        WD_TYPE_BIEN: {
          'ui:widget': 'select',
          'ui:placeholder': 'Choisir un type de bien',
        },
        WD_STATUT_BIEN: {
          'ui:widget': 'select',
          'ui:placeholder': 'Bien commun',
        },
        WD_SORT_BIEN: {
          'ui:widget': 'select',
          'ui:placeholder': 'Le bien est vendu',
        },
        WD_VENTE_DATE: {
          'ui:field': 'CustomDatepicker',
          'ui:placeholder': 'Choisir une date',
        },
        WD_PRECISIONS: {
          'ui:field': 'CustomTextArea',
          'ui:placeholder': 'Précisions supplémentaires...',
        },
      },
    },
    AUTRE_BIEN_COUNT: {
      'ui:widget': 'hidden',
    },
  },
  // plus d'actualité
  // {
  //   'ui:order': [
  //     'AUTRE_BIEN_COUNT',
  //     'WD_NOM_NOTAIRE_BIEN',
  //     'WD_PRENOM_NOTAIRE_BIEN',
  //     'WD_ADRESSE_NOTAIRE_BIEN',
  //     'WD_VILLE_NOTAIRE_BIEN',
  //     'WD_CP_NOTAIRE_BIEN',
  //     'WD_PAYS_NOTAIRE_BIEN',
  //   ],

  //   WD_NOM_NOTAIRE_BIEN: {
  //     'ui:field': 'CustomField',
  //     'ui:placeholder': 'Dupont',
  //   },
  //   WD_PRENOM_NOTAIRE_BIEN: {
  //     'ui:field': 'CustomField',
  //     'ui:placeholder': 'Jean',
  //   },
  //   WD_ADRESSE_NOTAIRE_BIEN: {
  //     'ui:field': 'CustomField',
  //     'ui:placeholder': '12 rue des Lilas',
  //   },
  //   WD_VILLE_NOTAIRE_BIEN: {
  //     'ui:field': 'CustomField',
  //     'ui:placeholder': 'Paris',
  //   },
  //   WD_CP_NOTAIRE_BIEN: {
  //     'ui:field': 'CustomField',
  //     'ui:placeholder': '75020',
  //   },
  //   WD_PAYS_NOTAIRE_BIEN: {
  //     'ui:select': 'select',
  //     'ui:placeholder': 'Choisir un pays',
  //   },
  // },
];

export default properties;
