import { Dispatch, useEffect, useReducer } from 'react';
import { ensureTrailingSlash, removeLeadingSlash } from '../utils/url';

type QueryStringValue = string | number | boolean | Date;
type QueryStringArrayValue = string[] | number[] | boolean[] | Date[];
type QueryParams = Record<
  string,
  QueryStringValue | QueryStringArrayValue | undefined
>;

function encodeValue(value: QueryStringValue) {
  return value instanceof Date ? value.toISOString() : String(value);
}

function addSearchParams(url: URL, queryParams: QueryParams) {
  for (const key in queryParams) {
    const value = queryParams[key];

    if (Array.isArray(value)) {
      const encodedKey = `${encodeURIComponent(key)}[]`;

      for (const valueElement of value) {
        if (value !== undefined) {
          url.searchParams.append(encodedKey, encodeValue(valueElement));
        }
      }
    } else {
      if (value !== undefined) {
        url.searchParams.set(encodeURIComponent(key), encodeValue(value));
      }
    }
  }
}

function computeBodyHeaders(
  bodyParams?: object | FormData,
  headersParams?: Record<string, string>,
) {
  if (!bodyParams) {
    return { body: undefined, headers: headersParams ?? {} };
  } else if (bodyParams instanceof FormData) {
    return {
      body: bodyParams,
      headers: {
        ...(headersParams ?? {}),
      },
    };
  } else {
    return {
      body: JSON.stringify(bodyParams),
      headers: { ...(headersParams ?? {}), 'Content-Type': 'application/json' },
    };
  }
}

function computePaginationData(
  contentRangeHeader: string | null,
): PaginationMetadata | undefined {
  if (!contentRangeHeader) return;

  const [unit, paging] = contentRangeHeader.split(' ');
  const [range, total] = paging.split('/');
  const [lower, upper] = range.split('-');

  return {
    unit,
    total: Number(total),
    range: [Number(lower), Number(upper)],
  };
}

const initialState = {
  success: false,
  loading: false,
  data: undefined,
  error: undefined,
  response: undefined,
};

function fetchReducer<T>(state: State<T>, action: Action<T>): State<T> {
  switch (action.type) {
    case 'loading':
      return {
        success: false,
        loading: true,
        data: state.data,
        error: undefined,
        response: undefined,
      };
    case 'success':
      return {
        success: true,
        loading: false,
        data: action.payload,
        error: undefined,
        response: action.response,
      };
    case 'error':
      return {
        success: false,
        loading: false,
        data: undefined,
        error: action.payload,
        response: action.response,
      };
    default:
      return state;
  }
}

async function parseJson<T>(response: Response) {
  try {
    return (await response.json()) as T;
  } catch {
    return null as unknown as T;
  }
}

export type FetchOptions<T> = {
  method?: 'GET' | 'POST' | 'DELETE' | 'PUT' | 'PATCH';
  cache?: boolean;
  onSuccess?: (value: Data<T>) => void | Promise<void>;
  onError?: (err: Error | RequestError) => void;
};

type RequestOptions<T> = {
  body?: object;
  headers?: Record<string, string>;
  query?: QueryParams;
  endpoint?: string;
  method?: 'GET' | 'POST' | 'DELETE' | 'PUT' | 'PATCH';
  onSuccess?: (value: Data<T>) => void | Promise<void>;
  onError?: (err: Error | RequestError) => void;
};

export class RequestError {
  constructor(public response: Response) {}
}

export interface State<T> {
  data?: Data<T>;
  loading: boolean;
  success: boolean;
  error?: Error | RequestError;
  response?: Response | null;
}

const cache: Record<string, Data<any>> = {};

export type PaginationMetadata = {
  unit: string;
  total: number;
  range: [number, number];
};

type Data<T> = {
  value: T;
  pagination?: PaginationMetadata;
  location?: string;
};

type Action<T> =
  | { type: 'loading' }
  | { type: 'success'; payload: Data<T>; response: Response | null }
  | { type: 'error'; payload: Error | RequestError; response: Response | null };

export type CancelFunction = () => void;
export type ExecuteFunction<T> = (requestOptions?: RequestOptions<T>) => void;

export type UseFetchReturn<T> = {
  state: State<T>;
  cancel: CancelFunction;
  execute: ExecuteFunction<T>;
};

export function useFetch<T = unknown>(
  baseUrl: string,
  endpoint: string,
  fetchOptions?: FetchOptions<T>,
): UseFetchReturn<T> {
  const abortController = new AbortController();

  const [state, dispatch] = useReducer(
    fetchReducer,
    initialState,
    (arg) => arg,
  ) as [State<T>, Dispatch<Action<T>>];

  const cancel = () => {
    abortController.abort();
  };

  const execute = async (requestOptions?: RequestOptions<T>) => {
    dispatch({ type: 'loading' });

    const method = fetchOptions?.method ?? requestOptions?.method ?? 'GET';
    const requestEndpoint = requestOptions?.endpoint ?? endpoint;
    const url = new URL(
      removeLeadingSlash(requestEndpoint),
      ensureTrailingSlash(baseUrl),
    );
    const { body, headers } = computeBodyHeaders(
      requestOptions?.body,
      requestOptions?.headers,
    );

    if (requestOptions?.query) {
      addSearchParams(url, requestOptions.query);
    }

    const urlStr = url.toString();

    if (fetchOptions?.cache) {
      const currentCache = cache[urlStr];
      if (currentCache) {
        dispatch({ type: 'success', payload: currentCache, response: null });
        await requestOptions?.onSuccess?.(currentCache);
        await fetchOptions?.onSuccess?.(currentCache);
        return;
      }
    }

    try {
      const response = await fetch(urlStr, {
        ...requestOptions,
        method,
        body,
        headers,
        credentials: 'include',
      });

      if (!response.ok) {
        throw new RequestError(response);
      }

      const value = await parseJson<T>(response);
      const pagination = computePaginationData(
        response.headers.get('Content-Range'),
      );
      const payload: Data<T> = {
        value,
        pagination,
        location: response.headers.get('Location') ?? undefined,
      };

      if (fetchOptions?.cache) {
        cache[urlStr] = payload;
      }

      dispatch({ type: 'success', payload, response });
      await requestOptions?.onSuccess?.(payload);
      await fetchOptions?.onSuccess?.(payload);
    } catch (error: unknown) {
      if (error instanceof Error) {
        dispatch({ type: 'error', payload: error, response: null });
        requestOptions?.onError?.(error);
        fetchOptions?.onError?.(error);
      } else if (error instanceof RequestError) {
        dispatch({ type: 'error', payload: error, response: error.response });
        requestOptions?.onError?.(error);
        fetchOptions?.onError?.(error);
      }
    }
  };

  useEffect(() => {
    return () => {
      cancel();
    };
  }, []);

  return { execute, cancel, state };
}
