import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export default function ScrollToTop() {
  const { pathname } = useLocation();

  const history = useHistory();

  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scroll({ top: 0, left: 0 });
    });
    return () => {
      unlisten();
    };
  }, [history]);

  useEffect(() => {
    window.scroll({ top: 0, left: 0 });
  }, [pathname]);

  return null;
}
