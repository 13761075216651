import { env } from '../config/env';
import slugify from 'slugify';
import { useState } from 'react';

interface DownloadParams {
  url: string;
  filename: string;
  init?: RequestInit;
  onComplete?: () => void;
}

interface DownloadOpportunityParams {
  opportunityId: string;
  filename: string;
  onComplete?: () => void;
}

interface DownloadOpportunity {
  loading: boolean;
  onDownload: ({
    opportunityId,
    onComplete,
  }: DownloadOpportunityParams) => Promise<void>;
}

export function generateFileName(
  document_title: string,
  procedure_reference?: string,
) {
  const prefix = procedure_reference ? `${procedure_reference}-` : '';
  const slug = slugify(document_title, { lower: true, strict: true });
  return `${prefix}${slug}.pdf`;
}

export async function downloadFileNew(
  url: string,
  name: string,
  procedure_reference?: string,
  preserve_name?: boolean,
) {
  const response = await fetch(url);
  if (!response.ok || response.status !== 200) {
    throw Error('invalid blob');
  }

  const blob = await response.blob();
  const link = document.createElement('a');
  const filename = preserve_name
    ? name
    : generateFileName(name, procedure_reference);

  link.href = URL.createObjectURL(blob);
  link.setAttribute('download', filename);

  link.click();
}

export async function downloadFile({
  url,
  filename,
  init,
  onComplete,
}: DownloadParams) {
  const response = await fetch(url, init);

  if (response.ok && response.status === 200) {
    const blob = await response.blob();
    const link = document.createElement('a');

    link.href = URL.createObjectURL(blob);
    link.setAttribute('download', filename);

    link.click();
    onComplete?.();
  } else {
    throw new Error('Erreur lor du téléchargement');
  }
}

export const useDownloadOpportunity = (): DownloadOpportunity => {
  const [loading, setLoading] = useState(false);

  return {
    loading,
    onDownload: async ({ opportunityId, filename, onComplete }) => {
      setLoading(true);

      try {
        await downloadFile({
          onComplete: onComplete,
          url: `${env.api.url}/api/procedures/${opportunityId}/download`,
          filename: `${filename}.zip`,
          init: {
            credentials: 'include',
            method: 'GET',
          },
        });
      } finally {
        setLoading(false);
      }
    },
  };
};
