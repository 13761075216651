import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { Flex } from 'components/Layout/Flex';
import { Text } from 'components/Layout/Text';
import { Breadcrumbs, ProductUpdateForm } from 'container/admin';
import { useApi } from '../../hooks/useApi';
import { useRouteMatch } from 'react-router';
import { get } from 'lodash';
import { Product } from '../../types/resources';
import format from 'date-fns/format';

const ProductsDetails: FC = () => {
  const { t } = useTranslation();
  const match = useRouteMatch();
  const productId = get(match, 'params.id', '');
  const { execute: getProduct, state: getProductState } = useApi<Product>(
    `/products/${productId}`,
  );
  const product = getProductState.data?.value;

  useEffect(() => {
    getProduct();
  }, []);

  return (
    <>
      <Breadcrumbs name={product?.code} />
      <Flex
        marginBottom={{ xs: 'space16' }}
        alignItems="center"
        justify="between"
      >
        <Text content={t('product.details')} fontStyle="heading2" />
        {product && (
          <Text
            content={t('updated_at', {
              updated_at: format(
                new Date(product.updated_at),
                "dd/MM/YYY 'à' HH:mm",
              ),
            })}
            fontStyle="body3"
          />
        )}
      </Flex>
      {product && <ProductUpdateForm product={product} />}
    </>
  );
};

export default ProductsDetails;
