import { ChangeEvent, FC, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';

import { IconButton } from 'components/Buttons';
import { theme } from 'theme';
import { md } from 'theme/styles/mediaQueries';
import { Flex, Text } from '../../Layout';
import useToaster from '../../../hooks/useToaster';
import { useTranslation } from 'react-i18next';

const ATTACHMENTS_LIMIT = 5;

type AttachmentFileProps = {
  attachment: File;
  onDelete: (attachment: File) => void;
};

const AttachmentContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: ${theme.spacing.space4};

  padding: ${theme.spacing.space4} ${theme.spacing.space8};
  background-color: ${theme.colors.green1};
  border-radius: 10px;
`;

const AttachmentFile: FC<AttachmentFileProps> = ({ attachment, onDelete }) => {
  return (
    <AttachmentContainer>
      <IconButton
        style={{ padding: 0 }}
        size={'xs'}
        iconName={'CloseSquare'}
        color={'white'}
        backgroundColor={'transparent'}
        onClick={() => onDelete(attachment)}
      />

      <Text
        fontStyle={'body3'}
        content={attachment.name}
        color={theme.colors.white}
      />
    </AttachmentContainer>
  );
};

const Wrapper = styled.div<{ disabled: boolean }>`
  background-color: ${(props) =>
    props.disabled ? theme.colors.gray3 : theme.colors.white};
  padding: ${theme.spacing.space12} ${theme.spacing.space16};

  ${md(`
    position: static;
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
    padding: ${theme.spacing.space16} ${theme.spacing.space24};
  `)}
`;

const InputWrapper = styled.div<{ focus: boolean; disabled?: boolean }>`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.space16};
  background-color: ${({ disabled }) =>
    disabled ? theme.colors.gray3 : theme.colors.gray1};
  border: 1px solid;
  border-color: ${({ focus }) =>
    focus ? theme.colors.green1 : theme.colors.gray3};
  border-radius: 12px;
  padding: 0 20px;
`;

const Input = styled.textarea`
  padding: ${theme.spacing.space16} 0;
  background: transparent;
  border: none;
  width: 100%;
  outline: none;
  resize: none;
`;
const InputAttachments = styled.input`
  display: none;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.space16};
`;

interface ConversationCardFooterProps {
  onClickSend: (props: { message: string; attachments: File[] }) => void;
  defaultContent?: string;
  canSendMessage: boolean;
}

const DEFAULT_MAX_SIZE = 10 * 1000 * 1000; // 10 Mo

const ConversationCardFooter: FC<ConversationCardFooterProps> = ({
  onClickSend,
  canSendMessage,
  defaultContent = '',
}) => {
  const { t } = useTranslation();
  const { error: toasterError } = useToaster();
  const [message, setMessage] = useState(defaultContent || '');
  const [attachments, setAttachments] = useState<File[]>([]);
  const attachmentsRef = useRef<HTMLInputElement>(null);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const [inputFocus, setInputFocus] = useState(false);

  const buttonSendColorProps = useMemo(() => {
    const buttonSendActive =
      canSendMessage && (message.length > 0 || attachments.length > 0);

    return {
      color: buttonSendActive ? theme.colors.white : theme.colors.gray5,
      backgroundColor: buttonSendActive
        ? theme.colors.green1
        : theme.colors.gray3,
    };
  }, [message, attachments, canSendMessage]);

  const buttonAttachmentsColorProps = useMemo(() => {
    const buttonSendActive =
      canSendMessage && attachments.length < ATTACHMENTS_LIMIT;

    return {
      color: buttonSendActive ? theme.colors.white : theme.colors.gray5,
      backgroundColor: buttonSendActive
        ? theme.colors.green1
        : theme.colors.gray3,
    };
  }, [attachments, canSendMessage]);

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(e.target.value);
  };

  const handleAttachmentsChange = (e: ChangeEvent<HTMLInputElement>) => {
    const eventFiles = e.target.files;

    if (!eventFiles) return;

    setAttachments((prevState) => {
      const filesArray = Array.from(eventFiles);
      const attachments = [...prevState];

      for (const file of filesArray) {
        const existing = attachments.find(
          (a) =>
            a.name === file.name &&
            a.size === file.size &&
            a.type === file.type,
        );

        if (file.size > DEFAULT_MAX_SIZE) {
          toasterError(t('files.too_large', { name: file.name }));
          continue;
        }

        if (!existing && attachments.length < ATTACHMENTS_LIMIT) {
          attachments.push(file);
        }
      }

      return attachments;
    });
  };

  const handleClick = () => {
    if (message.length === 0 && attachments.length === 0) return;

    onClickSend({ message, attachments });
    setMessage('');
    setAttachments([]);
  };

  const handleAttachmentsClick = () => {
    attachmentsRef.current?.click();
  };

  const handleDeleteAttachment = (attachment: File) => {
    setAttachments((prevState) => prevState.filter((a) => a !== attachment));
  };

  return (
    <Wrapper disabled={!canSendMessage}>
      {canSendMessage && (
        <InputWrapper focus={inputFocus} disabled={!canSendMessage}>
          <Input
            value={message}
            ref={textAreaRef}
            onChange={handleChange}
            placeholder={'Ecrivez votre message ici'}
            onFocus={() => setInputFocus(true)}
            onBlur={() => setInputFocus(false)}
            rows={3}
            disabled={!canSendMessage}
          />
          <InputAttachments
            ref={attachmentsRef}
            id={'attachments'}
            type="file"
            multiple
            size={5}
            accept="image/jpeg, image/png, application/pdf"
            onChange={handleAttachmentsChange}
            disabled={!canSendMessage}
          />
          <ButtonsWrapper>
            <IconButton
              iconName="Send"
              stroke="regular"
              rounded
              onClick={
                canSendMessage && (message.length > 0 || attachments.length > 0)
                  ? handleClick
                  : undefined
              }
              {...buttonSendColorProps}
            />
            <IconButton
              iconName="Folder"
              stroke="regular"
              rounded
              onClick={
                canSendMessage && attachments.length < ATTACHMENTS_LIMIT
                  ? handleAttachmentsClick
                  : undefined
              }
              {...buttonAttachmentsColorProps}
            />
          </ButtonsWrapper>
        </InputWrapper>
      )}

      {!canSendMessage && (
        <Text fontStyle={'body1'} content={t('messaging.pending')} />
      )}

      <Flex
        style={{
          marginTop: theme.spacing.space8,
          flexWrap: 'wrap',
          columnGap: theme.spacing.space8,
          rowGap: theme.spacing.space8,
        }}
      >
        {attachments.map((a, index) => (
          <AttachmentFile
            key={index}
            attachment={a}
            onDelete={handleDeleteAttachment}
          />
        ))}
      </Flex>
    </Wrapper>
  );
};

export default ConversationCardFooter;
