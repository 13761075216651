import { FC, useEffect } from 'react';
import styled from 'styled-components';
import format from 'date-fns/format';

// Utils
import { theme } from 'theme';

// Components
import { Col, Container, Row } from 'components/Layout';

// Data
import { FormalityFile } from 'container/client';
import { ViewGate } from 'container/common';
import { useCustomer } from '../../hooks/useCustomer';
import {
  CivilStatus,
  ProcedureFull,
  ProcedureStepType,
} from '../../types/resources';
import { useApi } from '../../hooks/useApi';
import { Ghost } from '../../components/Loading';
import { ClientCard, TipCard } from '../../components/Cards';
import { useTranslation } from 'react-i18next';
import config from '../../config/app';

const CustomContainer = styled(Container)`
  padding-top: ${theme.spacing.space32};
  padding-bottom: ${theme.spacing.space32};
`;

const ClientFormalityCivilStatus: FC = () => {
  const { t } = useTranslation();
  const { customer } = useCustomer();
  const step = ProcedureStepType.MARITAL_STATUS;

  const { execute: getProcedure, state: getProcedureState } =
    useApi<ProcedureFull>('');
  const procedure = getProcedureState.data?.value;

  const { execute: getCivilStatus, state: getCivilStatusState } = useApi<
    CivilStatus[]
  >(`/procedures/${procedure?.id}/civil-status`);
  const civilStatus = getCivilStatusState.data?.value[0];

  useEffect(() => {
    if (customer?.procedure_id) {
      getProcedure({
        endpoint: `/procedures/${customer.procedure_id}`,
        query: { customer_id: customer.id },
      });
    }
  }, [customer?.procedure_id]);

  useEffect(() => {
    if (procedure) {
      getCivilStatus();
    }
  }, [procedure]);

  if (!procedure) return null;

  const content = civilStatus?.received_at
    ? t(`formality_letters.demand_${step}.received`, {
        date: format(new Date(civilStatus.received_at), 'dd/MM/yyyy'),
      })
    : civilStatus?.requested_at
    ? t(`formality_letters.demand_${step}.description_date`, {
        date: format(new Date(civilStatus.requested_at), config.dateFormat),
      })
    : '';

  return (
    <ViewGate procedure={procedure} steps={[ProcedureStepType.MARITAL_STATUS]}>
      <CustomContainer fluid="xl">
        <Row>
          <Col xs={12} md={4} vertical alignSelf="auto">
            {civilStatus ? (
              <ClientCard
                iconName="Document"
                title={t(`formality_letters.demand_${step}.title`)}
              >
                <TipCard
                  title={t(`formality_letters.demand_${step}.notify`)}
                  type="info"
                  content={content}
                />
              </ClientCard>
            ) : (
              <Ghost width="100%" height="254px" shape="rect" rx={24} />
            )}
          </Col>
          <Col xs={12} md={8}>
            <FormalityFile
              procedure={procedure}
              step={step}
              letter={{
                date: civilStatus?.received_at ?? civilStatus?.requested_at,
                document_id: civilStatus?.document_id,
              }}
            />
          </Col>
        </Row>
      </CustomContainer>
    </ViewGate>
  );
};

export default ClientFormalityCivilStatus;
