import { FC } from 'react';
import { WidgetProps } from '@rjsf/core';

import { Flex } from 'components/Layout/Flex';
import { InputPlusMinus } from 'components/FormTemplate/Fields/InputPlusMinus';

import { theme } from 'theme';
import { Label } from 'components/Text/Label';
import { IconButton } from 'components/Buttons/IconButton';
import { AllFormsData } from 'wrapper/FormWrapper';
import { useViewport } from 'hooks/useViewport';

export const UpDownWidget: FC<WidgetProps> = (props) => {
  const { value, label, formContext, id, onChange, schema } = props;
  const { withInfoAllFormData, toggleInfoPanel, setFieldInfoPanel } =
    formContext;
  const name = id.replace('root_', '') as keyof AllFormsData;
  const { isMobile } = useViewport();
  const withInfo = withInfoAllFormData[name];

  const _onChange = (val: number) => {
    onChange(schema.type === 'string' ? val.toString() : val);
  };

  const removeValue = () => _onChange(parseInt(value) - 1);
  const addValue = () => _onChange(parseInt(value) + 1);

  return (
    <Flex alignItems="start" direction={{ xs: 'column' }}>
      {label && (
        <Flex
          alignItems="center"
          marginBottom={{ xs: 'space16', md: 'space24' }}
        >
          <Label
            dangerouslySetInnerHTML={{ __html: label }}
            fontStyle={isMobile ? 'body1' : 'heading7'}
            weight="medium"
          />
          {withInfo && (
            <Flex
              paddingLeft={{ xs: 'space16' }}
              onClick={() => {
                setFieldInfoPanel(name);
                toggleInfoPanel(true);
              }}
              cursor="pointer"
            >
              <IconButton
                iconName="InfoCircle"
                color={theme.colors.black}
                backgroundColor={theme.colors.beige}
                rounded
                size={isMobile ? 'large' : 'medium'}
                stroke="regular"
              />
            </Flex>
          )}
        </Flex>
      )}
      <InputPlusMinus
        value={value}
        removeValue={removeValue}
        addValue={addValue}
      />
    </Flex>
  );
};
