import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Data
// Components
import { Col, Flex, Row, Text } from 'components/Layout';

// Utils
import styled from 'styled-components';
import {
  Consent,
  ConsentStatus,
  DocumentFull,
  ProcedureFull,
  ProcedureSpouseFull,
} from '../../../types/resources';
import { Button, OpportunityStatusButton } from '../../../components/Buttons';
import { useApi } from '../../../hooks/useApi';
import Table from '../../../components/Table/Table';
import { theme } from '../../../theme';
import { Column } from 'react-table';
import { TextEllipsis } from '../../../components/Text';
import { DateInput } from '../../../components/Forms';
import { parseISO } from 'date-fns';
import { InputText } from '../../../components/FormTemplate/Fields/InputText';
import { FileModal } from '../../../components/Modal';

const Container = styled.div`
  tbody {
    overflow: inherit;
  }
`;

type SpouseConsent = {
  id: string;
  spouse: ProcedureSpouseFull;
  consent: Consent | null;
};

type ConsentSpousesProps = {
  procedure: ProcedureFull;
  spouses: ProcedureSpouseFull[];
  consents: Consent[];
  refetchConsents: () => void;
};

const ConsentSpouses: FC<ConsentSpousesProps> = ({
  procedure,
  spouses,
  consents,
  refetchConsents,
}) => {
  const { t } = useTranslation();
  const [selectedReceipt, setSelectedReceipt] = useState<DocumentFull | null>(
    null,
  );

  const { execute: getProcedureDocument } = useApi<DocumentFull>(
    `/procedures/${procedure.id}/documents`,
  );

  const spouseConsents = spouses.map((spouse) => {
    const consent = consents.find((c) => c.customer_id === spouse.spouse_id);

    return { id: spouse.spouse_id, spouse, consent: consent ?? null };
  });

  const getTypeByStatus = (status?: ConsentStatus) => {
    switch (status) {
      case ConsentStatus.BOUNCED:
      case ConsentStatus.FAILED:
      case ConsentStatus.REFUSED:
      case ConsentStatus.EXPIRED:
        return 'danger';
      case undefined:
        return 'info';
      case ConsentStatus.CREATED:
      case ConsentStatus.SENT:
        return 'warning';
      default:
      case ConsentStatus.VALIDATED:
        return 'success';
    }
  };

  const handleOpenReceipt = (receiptId?: string | null) => {
    if (receiptId) {
      getProcedureDocument({
        endpoint: `/procedures/${procedure.id}/documents/${receiptId}`,
        onSuccess: (value) => {
          setSelectedReceipt(value.value);
        },
      });
    }
  };

  const columns: Column<SpouseConsent>[] = [
    {
      Header: t('consents.columns.name'),
      id: 'spouse_name',
      accessor: 'spouse',
      width: 120,
      disableSortBy: true,
      Cell: ({ value }) => (
        <Flex alignItems="center" height="100%">
          <TextEllipsis
            fontStyle="body1"
            content={value.spouse.full_name}
            weight="medium"
          />
        </Flex>
      ),
    },
    {
      Header: t('consents.columns.status'),
      id: 'consent_status',
      accessor: 'spouse',
      width: 100,
      disableSortBy: true,
      Cell: ({ cell }) => {
        const consent = cell.row.original.consent;

        return (
          <Flex alignItems="center" height="100%">
            <OpportunityStatusButton
              type={getTypeByStatus(consent?.status)}
              text={t(`consents.statuses.${consent?.status}`, {
                defaultValue: t(`consents.statuses.TO_SEND`),
              })}
            />
          </Flex>
        );
      },
    },
    {
      Header: t('consents.columns.type'),
      id: 'consent_send_type',
      accessor: 'spouse',
      width: 165,
      disableSortBy: true,
      Cell: () => (
        <Flex>
          <InputText
            onChange={() => {}}
            readOnly
            disabled
            width="165px"
            value={'Consentement'}
          />
        </Flex>
      ),
    },
    {
      Header: t('consents.columns.send_date'),
      id: 'consent_send_date',
      accessor: 'spouse',
      disableSortBy: true,
      width: 120,
      Cell: ({ cell }) => {
        const consent = cell.row.original.consent;

        return (
          <DateInput
            disableOpenPicker={true}
            inputStyle={{ padding: '1rem', textAlign: 'center' }}
            onChange={() => {}}
            value={consent?.sent_at ? parseISO(consent.sent_at) : null}
            disabled
          />
        );
      },
    },
    {
      Header: t('consents.columns.received_date'),
      id: 'consent_validated_at',
      accessor: 'spouse',
      disableSortBy: true,
      width: 120,
      Cell: ({ cell }) => {
        const consent = cell.row.original.consent;
        return (
          <DateInput
            disableOpenPicker={true}
            inputStyle={{ padding: '1rem', textAlign: 'center' }}
            onChange={() => {}}
            value={
              consent?.validated_at ? parseISO(consent.validated_at) : null
            }
            disabled
          />
        );
      },
    },
    {
      Header: t('consents.columns.proof'),
      id: 'consent_receipt',
      accessor: 'spouse',
      disableSortBy: true,
      width: 130,
      Cell: ({ cell }) => {
        const consent = cell.row.original.consent;
        const status = consent?.status;
        const disabled =
          !status || status !== ConsentStatus.VALIDATED || !consent?.receipt_id;

        return (
          <Flex alignItems="center" height="100%">
            <Button
              size="small"
              onClick={() => {
                if (consent?.receipt_id) {
                  handleOpenReceipt(consent.receipt_id);
                }
              }}
              content={t('consents.receipt.view')}
              primary
              disabled={disabled}
            />
          </Flex>
        );
      },
    },
    {
      Header: t('consents.columns.action'),
      id: 'consent_action',
      accessor: 'spouse',
      disableSortBy: true,
      width: 80,
      Cell: ({ cell, value }) => {
        const { execute: upsertConsent, state: createConsentState } = useApi(
          `/procedures/${value.procedure_id}/spouses/${value.spouse_id}/consent`,
          {
            method: 'PUT',
            onSuccess: () => refetchConsents(),
          },
        );

        const consent = cell.row.original.consent;
        const status = consent?.status;
        const canResend =
          status &&
          ![
            ConsentStatus.CREATED,
            ConsentStatus.SENT,
            ConsentStatus.VALIDATED,
          ].includes(status);
        const canSend = !status;
        const actionText = !status ? t('consents.send') : t('consents.resend');
        const action = () => {
          if (canSend || canResend) {
            upsertConsent({
              body: { customer_id: cell.row.original.spouse.spouse_id },
            });
          }
        };

        return (
          <Flex alignItems="center" height="100%">
            <Button
              size="small"
              onClick={action}
              content={actionText}
              backgroundColor={theme.colors.green1}
              textColor={theme.colors.white}
              $loading={createConsentState.loading}
              disabled={!canSend && !canResend}
            />
          </Flex>
        );
      },
    },
  ];

  return (
    <Row>
      <Col xs={12}>
        <Table<SpouseConsent>
          pageCount={2}
          getRowId={(originalRow) => originalRow.spouse.spouse_id}
          data={spouseConsents}
          columns={columns}
          pageSize={2}
          totalCount={spouseConsents.length || 0}
          loadMore={() => {}}
          noSeparator
          backgroundColor={theme.colors.beige}
          rounded
          asCard
          disableSortBy
        />
      </Col>

      {selectedReceipt && (
        <FileModal
          opened={Boolean(selectedReceipt)}
          onClose={() => setSelectedReceipt(null)}
          refetchDocuments={refetchConsents}
          hideTip
          document={selectedReceipt}
          procedure={procedure}
        />
      )}
    </Row>
  );
};

interface ConsentBlocProps {
  procedure: ProcedureFull;
  spouses: ProcedureSpouseFull[];
  refetchProcedure: () => void;
  consents: Consent[];
  refetchConsents: () => void;
}

const ConsentBloc: FC<ConsentBlocProps> = ({
  procedure,
  spouses,
  refetchProcedure,
  consents,
  refetchConsents,
}) => {
  const { t } = useTranslation();
  const showConsents = !procedure.notary_partner;
  const cannotCancel = consents.length > 0;

  const { execute: updateNotaryPartner, state: updateNotaryPartnerState } =
    useApi(`/procedures/${procedure.id}/notary-partner`, {
      method: 'PUT',
      onSuccess: () => {
        refetchProcedure();
      },
    });

  return (
    <Container>
      <Text
        fontStyle={'heading2'}
        content={t('consents.title')}
        marginBottom={{ xs: 'space16' }}
      />

      {showConsents ? (
        <Flex direction={{ xs: 'column' }}>
          <ConsentSpouses
            spouses={spouses}
            consents={consents}
            refetchConsents={refetchConsents}
            procedure={procedure}
          />

          <Flex justify={'end'}>
            <Button
              content={t('consents.cancel')}
              size="small"
              onClick={() => {
                updateNotaryPartner({ body: { notary_partner: true } });
              }}
              $loading={updateNotaryPartnerState.loading}
              marginTop={{ xs: 'space16' }}
              disabled={cannotCancel}
            />
          </Flex>
        </Flex>
      ) : (
        <>
          <Text
            fontStyle={'body2'}
            content={t('consents.description')}
            marginBottom={{ xs: 'space16' }}
          />
          <Button
            content={t('consents.confirm')}
            size="small"
            primary
            onClick={() => {
              updateNotaryPartner({ body: { notary_partner: false } });
            }}
            $loading={updateNotaryPartnerState.loading}
            marginBottom={{ xs: 'space16' }}
          />
        </>
      )}
    </Container>
  );
};

export default ConsentBloc;
