import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Text } from '../../../components/Layout';
import {
  ProcedureStepStatus,
  ProcedureStepType,
  Role,
} from '../../../types/resources';
import { useApi } from '../../../hooks/useApi';
import { useAuth } from '../../../hooks/useAuth';
import { theme } from '../../../theme';
import { Icon } from '../../../components/Images';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

type ManagerDashboard = {
  [t in ProcedureStepType]: { [s in ProcedureStepStatus]: number };
} & {
  UNPROCESSED_MESSAGES: number;
  UNPROCESSED_COMMENTS: number;
  AGREEMENT_LOCKED: number;
};

type ManagerDashboardItemProps = {
  title: string;
  count?: number;
  filters: {};
};

const Container = styled.div``;
const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.2rem;
`;

const Circle = styled.div`
  height: 28px;
  width: 28px;
  background-color: white;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ManagerDashboardItem: FC<ManagerDashboardItemProps> = ({
  title,
  count,
  filters,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const onClick = () => {
    const params = new URLSearchParams(filters);
    history.push({ pathname: '/dossiers', search: params.toString() });
  };

  return (
    <Flex
      onClick={onClick}
      paddingTop={{ xs: 'space16' }}
      paddingBottom={{ xs: 'space16' }}
      paddingLeft={{ xs: 'space16' }}
      paddingRight={{ xs: 'space16' }}
      direction={{ xs: 'column' }}
      style={{
        borderRadius: '8px',
        backgroundColor: theme.colors.salmon3,
        cursor: 'pointer',
      }}
    >
      <Flex flexGrow={1}>
        <Text fontStyle="heading6" content={title} />
      </Flex>

      <Flex direction={{ xs: 'column' }}>
        <Text
          fontStyle="heading4"
          color={theme.colors.salmon1}
          marginTop={{ xs: 'space8' }}
          content={t('managers.dashboard.procedures_count', { count })}
        />

        <Flex marginTop={{ xs: 'space8' }} alignItems="center">
          <Circle color={theme.colors.white}>
            <Icon name={'ArrowRight'} size={'small'} />
          </Circle>
          <Text
            marginLeft={{ xs: 'space8' }}
            fontStyle="body3"
            content={t('managers.dashboard.link')}
            weight="bold"
            style={{ textDecoration: 'underline' }}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

const ManagerDashboard: FC = () => {
  const { t } = useTranslation();
  const { user } = useAuth();

  const { execute: getDashboard, state: getDashboardState } =
    useApi<ManagerDashboard>(`/managers/${user?.id}/dashboard`);
  const dashboard = getDashboardState.data?.value;

  useEffect(() => {
    if (user?.role === Role.MANAGER) {
      getDashboard();
    }
  }, [user]);

  return (
    <Container>
      <Text
        content={t('task_today')}
        fontStyle="heading3"
        marginBottom={{ xs: 'space32' }}
      />

      <Grid style={{ marginBottom: '2rem' }}>
        <ManagerDashboardItem
          title={t('managers.dashboard.items.UNPROCESSED_MESSAGES')}
          count={dashboard?.UNPROCESSED_MESSAGES}
          filters={{ has_unprocessed_messages: true }}
        />

        <ManagerDashboardItem
          title={t('managers.dashboard.items.UNPROCESSED_COMMENTS')}
          count={dashboard?.UNPROCESSED_COMMENTS}
          filters={{ has_unprocessed_comments: true }}
        />

        <ManagerDashboardItem
          title={t('managers.dashboard.items.DOCUMENT_IN_REVIEW')}
          count={dashboard?.DOCUMENT.IN_REVIEW}
          filters={{
            step_type: ProcedureStepType.DOCUMENT,
            step_status: ProcedureStepStatus.IN_REVIEW,
          }}
        />

        <ManagerDashboardItem
          title={t('managers.dashboard.items.VERIFICATION_IN_PROGRESS')}
          count={dashboard?.VERIFICATION.IN_PROGRESS}
          filters={{
            step_type: ProcedureStepType.VERIFICATION,
            step_status: ProcedureStepStatus.IN_PROGRESS,
          }}
        />

        <ManagerDashboardItem
          title={t('managers.dashboard.items.VERIFICATION_IN_REVIEW')}
          count={dashboard?.VERIFICATION.IN_REVIEW}
          filters={{
            step_type: ProcedureStepType.VERIFICATION,
            step_status: ProcedureStepStatus.IN_REVIEW,
          }}
        />

        <ManagerDashboardItem
          title={t('managers.dashboard.items.WRITING_AGREEMENT_IN_PROGRESS')}
          count={dashboard?.WRITING_AGREEMENT.IN_PROGRESS}
          filters={{
            step_type: ProcedureStepType.WRITING_AGREEMENT,
            step_status: ProcedureStepStatus.IN_PROGRESS,
          }}
        />

        <ManagerDashboardItem
          title={t('managers.dashboard.items.REVIEW_AGREEMENT_LOCKED')}
          count={dashboard?.REVIEW_AGREEMENT.LOCKED}
          filters={{
            step_type: ProcedureStepType.REVIEW_AGREEMENT,
            step_status: ProcedureStepStatus.LOCKED,
          }}
        />

        <ManagerDashboardItem
          title={t('managers.dashboard.items.REVIEW_AGREEMENT_IN_REVIEW')}
          count={dashboard?.REVIEW_AGREEMENT.IN_REVIEW}
          filters={{
            step_type: ProcedureStepType.REVIEW_AGREEMENT,
            step_status: ProcedureStepStatus.IN_REVIEW,
          }}
        />

        <ManagerDashboardItem
          title={t('managers.dashboard.items.AGREEMENT_SENT_IN_PROGRESS')}
          count={dashboard?.AGREEMENT_SENT.IN_PROGRESS}
          filters={{
            step_type: ProcedureStepType.AGREEMENT_SENT,
            step_status: ProcedureStepStatus.IN_PROGRESS,
          }}
        />

        <ManagerDashboardItem
          title={t('managers.dashboard.items.AGREEMENT_SENT_IN_REVIEW')}
          count={dashboard?.AGREEMENT_SENT.IN_REVIEW}
          filters={{
            step_type: ProcedureStepType.AGREEMENT_SENT,
            step_status: ProcedureStepStatus.IN_REVIEW,
          }}
        />

        <ManagerDashboardItem
          title={t('managers.dashboard.items.SIGNATURE_IN_REVIEW')}
          count={dashboard?.SIGNATURE.IN_REVIEW}
          filters={{
            step_type: ProcedureStepType.SIGNATURE,
            step_status: ProcedureStepStatus.IN_REVIEW,
          }}
        />

        <ManagerDashboardItem
          title={t('managers.dashboard.items.ARCHIVE_SIGN_IN_PROGRESS')}
          count={dashboard?.ARCHIVE_SIGN.IN_PROGRESS}
          filters={{
            step_type: ProcedureStepType.ARCHIVE_SIGN,
            step_status: ProcedureStepStatus.IN_PROGRESS,
          }}
        />

        <ManagerDashboardItem
          title={t('managers.dashboard.items.NOTARY_IN_PROGRESS')}
          count={dashboard?.NOTARY.IN_PROGRESS}
          filters={{
            step_type: ProcedureStepType.NOTARY,
            step_status: ProcedureStepStatus.IN_PROGRESS,
          }}
        />

        <ManagerDashboardItem
          title={t('managers.dashboard.items.NOTARY_IN_REVIEW')}
          count={dashboard?.NOTARY.IN_REVIEW}
          filters={{
            step_type: ProcedureStepType.NOTARY,
            step_status: ProcedureStepStatus.IN_REVIEW,
          }}
        />

        <ManagerDashboardItem
          title={t('managers.dashboard.items.MARITAL_STATUS_IN_PROGRESS')}
          count={dashboard?.MARITAL_STATUS.IN_PROGRESS}
          filters={{
            step_type: ProcedureStepType.MARITAL_STATUS,
            step_status: ProcedureStepStatus.IN_PROGRESS,
          }}
        />

        <ManagerDashboardItem
          title={t('managers.dashboard.items.MARITAL_STATUS_IN_REVIEW')}
          count={dashboard?.MARITAL_STATUS.IN_REVIEW}
          filters={{
            step_type: ProcedureStepType.MARITAL_STATUS,
            step_status: ProcedureStepStatus.IN_REVIEW,
          }}
        />

        <ManagerDashboardItem
          title={t('managers.dashboard.items.FINAL_SENDING_IN_PROGRESS')}
          count={dashboard?.FINAL_SENDING.IN_PROGRESS}
          filters={{
            step_type: ProcedureStepType.FINAL_SENDING,
            step_status: ProcedureStepStatus.IN_PROGRESS,
          }}
        />
      </Grid>
    </Container>
  );
};

export default ManagerDashboard;
