import { FC } from 'react';
import styled from 'styled-components';
import Lottie from 'lottie-react';
import animationData from '../../assets/loader.json';

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Loader: FC = () => {
  return (
    <Container>
      <Lottie
        animationData={animationData}
        loop
        autoplay
        rendererSettings={{ preserveAspectRatio: 'xMidYMid slice' }}
        height={60}
        width={382}
      />
    </Container>
  );
};

export default Loader;
