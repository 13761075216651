import styled from 'styled-components';

import { Text } from 'components/Layout';

const TextEllipsis = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export default TextEllipsis;
