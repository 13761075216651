import { FC } from 'react';
import { useHistory } from 'react-router';

import { Dropdown } from 'components/Select';
import { getRouteWithParams } from 'utils/router';
import { AdminAppRoutes } from 'AdminApp';
import { DocumentFull } from '../../../types/resources';
import { formatDocumentTitle } from '../../../utils/format';

interface DocumentsDropdownProps {
  opportunityId: string;
  document: DocumentFull;
  documents: DocumentFull[];
}

const DocumentsDropdown: FC<DocumentsDropdownProps> = ({
  opportunityId,
  document,
  documents,
}) => {
  const history = useHistory();

  if (documents.length === 0) return <></>;

  const options = documents.map((document) => ({
    value: document.id,
    label: formatDocumentTitle(document),
  }));

  const onChange = (value: string) => {
    history.push(
      getRouteWithParams(AdminAppRoutes.DOCUMENT, {
        opportunityId,
        documentId: value,
      }),
    );
  };

  return <Dropdown options={options} id={document.id} onChange={onChange} />;
};

export default DocumentsDropdown;
