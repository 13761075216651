import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';

// Components
import { Flex } from 'components/Layout/Flex';

// data

// Utils
import { useQueryParams } from 'hooks/useQueryParams';
import { toggleSearchParams } from 'utils/search';
import InputSelect from '../../../components/FormTemplate/Fields/InputSelect';
import InputSelectAsync from '../../../components/FormTemplate/Fields/InputSelectAsync';
import { Customer, Estimate, PaymentStatus } from '../../../types/resources';
import { useApi } from '../../../hooks/useApi';

const PaymentsListFilters: FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const query = useQueryParams();
  const searchParams = new URLSearchParams(location.search);
  const [customerId, setCustomerId] = useState<string>(
    query.get('customer_id') ?? '',
  );
  const [estimateId, setEstimateId] = useState<string>(
    query.get('estimate_id') ?? '',
  );
  const status = query.get('status') ?? '';

  const { execute: getCustomers } = useApi<Customer[]>(
    '/customers?sort=full_name&direction=asc',
  );

  const statusOptions = Object.values(PaymentStatus).map((s) => ({
    value: s,
    label: t(`payments.statuses.${s}`),
  }));

  const { execute: getEstimates, state: getEstimatesState } =
    useApi<Estimate[]>('/estimates');

  const estimates = getEstimatesState.data?.value ?? [];

  const estimateOptions = estimates.map((e) => ({
    value: e.id,
    label: e.name,
  }));

  useEffect(() => {
    getCustomers();
    getEstimates();
  }, []);

  useEffect(() => {
    setEstimateId('');
    handleEstimateIdChange('');
    getEstimates({
      query: { customer_id: customerId ? customerId : undefined },
    });
  }, [customerId]);

  const handleCustomerSearch = (
    val: string,
    callback: (options: any[]) => void,
  ) => {
    const query: Record<string, string> = {};
    if (val) {
      query.search = val;
    }

    getCustomers({
      query,
      onSuccess: (result) => {
        callback(
          result.value.map((c) => ({
            value: c.id,
            label: c.full_name,
          })),
        );
      },
    });
  };

  const handleCustomerIdChange = (val?: Customer['id']) => {
    setCustomerId(val ?? '');
    toggleSearchParams('customer_id', val, searchParams);
    history.replace({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  };

  const handleEstimateIdChange = (val?: Estimate['id']) => {
    setEstimateId(val ?? '');
    toggleSearchParams('estimate_id', val, searchParams);
    history.replace({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  };

  const handleStatusChange = (val?: PaymentStatus) => {
    toggleSearchParams('status', val, searchParams);
    history.replace({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  };

  return (
    <Flex marginTop={{ xs: 'space40' }} justify="start">
      <Flex>
        <InputSelectAsync
          onChange={handleCustomerIdChange}
          loadOptions={handleCustomerSearch}
          defaultOptions
          label={t('payments.customer')}
          labelWeight="bold"
          labelUppercase={true}
          placeholder={t('payments.filters.search_by_customer')}
          width="256px"
          size="small"
          isClearable
          value={customerId && customerId !== '' ? customerId : ''}
        />
      </Flex>
      <Flex marginLeft={{ xs: 'space32' }}>
        <InputSelect
          key={Date.now()}
          onChange={handleEstimateIdChange}
          options={estimateOptions}
          label={t('payments.estimate')}
          labelWeight="bold"
          labelUppercase={true}
          placeholder={t('payments.filters.search_by_estimate')}
          width="256px"
          size="small"
          isClearable
          value={estimateId && estimateId !== '' ? estimateId : ''}
        />
      </Flex>

      <Flex marginLeft={{ xs: 'space32' }}>
        <InputSelect
          onChange={handleStatusChange}
          options={statusOptions}
          label={t('payments.status')}
          labelWeight="bold"
          labelUppercase={true}
          placeholder={t('payments.filters.search_by_status')}
          width="256px"
          size="small"
          isClearable
          value={status && status !== '' ? status : ''}
        />
      </Flex>
    </Flex>
  );
};

export default PaymentsListFilters;
