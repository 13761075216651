import { FC, useEffect, useState } from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import frLocale from 'date-fns/locale/fr';

// Styles
import { GlobalStyle } from 'theme/global';

// Components
import { ViewportProvider } from 'hooks/useViewport';
import { AuthProvider } from './context';
import AppRouter from './AppRouter';
import { ToasterProvider, ToasterWrapper } from './context/ToasterContext';
import { initEnv } from './config/env';

const App: FC = () => {
  const [configLoading, setConfigLoading] = useState(true);

  useEffect(() => {
    initEnv().then(() => setConfigLoading(false));
  }, []);

  if (configLoading) {
    return null;
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={frLocale}>
      <GlobalStyle />
      <ViewportProvider>
        <ToasterProvider>
          <ToasterWrapper />
          <AuthProvider>
            <AppRouter />
          </AuthProvider>
        </ToasterProvider>
      </ViewportProvider>
    </LocalizationProvider>
  );
};

export default App;
