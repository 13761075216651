import { FC, useEffect } from 'react';

import { Flex } from 'components/Layout/Flex';
import { TipCard } from 'components/Cards';
import { OptionCards, PlanCards } from 'container/public';
import { ObjectFieldTemplateProps } from '@rjsf/core';
import { AllFormsData } from 'wrapper/FormWrapper';

import { useViewport } from 'hooks/useViewport';
import InputSelect from './Fields/InputSelect';
import { Department, Option, Plan } from '../../types/resources';
import { useApi } from '../../hooks/useApi';

type PropertiesProps = ObjectFieldTemplateProps['properties'][number] & {
  formData: AllFormsData;
};

const CardsPlan: FC<PropertiesProps> = ({ content }) => {
  const _onClick = (plan: Plan) => {
    content.props.onChange({ ...plan });
  };

  return <PlanCards onClick={_onClick} checked={content.props.formData} />;
};

const CardsOption: FC<PropertiesProps> = ({ content }) => {
  const productToShow = {
    code: content.props.schema.items.properties.code.enum[0],
  } as Option;

  const _onClick = (option: Option) => {
    const options: Option[] = content.props.formData || [];
    const foundIndex = options.findIndex((o) => o.id === option.id);

    if (options && foundIndex > -1) {
      content.props.onChange(options.filter((o) => o.id !== option.id));
    } else {
      content.props.onChange([...options, option]);
    }
  };

  return (
    <OptionCards
      onClick={_onClick}
      checked={content.props.formData}
      showOnly={productToShow}
    />
  );
};

const RegionMap: FC<PropertiesProps> = ({ content }): JSX.Element => {
  const { isMobile } = useViewport();
  const { execute: getDepartments, state: getDepartmentsState } = useApi<
    Department[]
  >('/departments', { cache: true });
  const departments = getDepartmentsState.data?.value ?? [];
  const departmentOptions = departments.map((d) => ({
    value: d.code,
    label: d.full_name,
  }));

  const _onClick = (department_code: Department['code']) => {
    const selectedDepartment = departments.find(
      (d) => d.code === department_code,
    );
    content.props.onChange({ ...selectedDepartment });
  };

  useEffect(() => {
    getDepartments();
  }, []);

  return (
    <Flex direction={{ xs: 'column' }} marginBottom={{ xs: 'space8' }}>
      <InputSelect
        placeholder={'Choisir un département'}
        options={departmentOptions}
        onChange={_onClick}
        value={content.props.formData?.code}
        required
      />
      <Flex marginTop={{ xs: 'space48' }} marginBottom={{ xs: 'space8' }}>
        <TipCard
          title={'Où puis-je signer ma convention ?'}
          content={
            'En fin de procédure, vous serez invité à signer votre convention de divorce avec votre conjoint et vos avocats, au choix dans l’une de nos 15 villes en France.'
          }
          type="info"
          accordion={isMobile}
        />
      </Flex>
    </Flex>
  );
};

const TipCardInfo: FC<PropertiesProps> = ({ content }): JSX.Element => {
  const { isMobile } = useViewport();
  const handleClick = () => {
    content.props.schema.const.src &&
      window.open(content.props.schema.const.src, '_blank');
  };

  return (
    <Flex marginTop={{ xs: 'space8' }} marginBottom={{ xs: 'space8' }}>
      <TipCard
        title={content.props.schema.title}
        content={content.props.schema.description}
        onClick={handleClick}
        buttonText={content.props.schema.link}
        type="info"
        accordion={isMobile && content.props.schema.description}
      />
    </Flex>
  );
};

const TipCardDanger: FC<PropertiesProps> = ({ content }): JSX.Element => {
  const handleClick = () => {
    content.props.schema.const.src &&
      window.open(content.props.schema.const.src, '_blank');
  };
  return (
    <Flex marginBottom={{ xs: 'space8' }}>
      <TipCard
        title={content.props.schema.title}
        content={content.props.schema.description}
        onClick={handleClick}
        buttonText={content.props.schema.const.link}
        type="danger"
      />
    </Flex>
  );
};

export default {
  region: RegionMap,
  tip_card_info: TipCardInfo,
  tip_card_danger: TipCardDanger,
  plan: CardsPlan,
  options: CardsOption,
};
