import { FC } from 'react';
import { useLocation } from 'react-router';

import { Permission } from 'components/User';
import { AdminAppRoutes } from 'AdminApp';
import { getRouteWithParams } from 'utils/router';

/** Container */
import { ClosedView } from 'container/common';
import {
  DocumentView,
  DocumentsVerification,
  ConventionView,
  AdministrativeDocuments,
  FormView,
  Appointment,
  RegisteredLetters,
  CivilStatusPage,
  NotarialDeedPage,
  ConventionWritingView,
  FinalSendingPage,
} from 'container/admin';

import { Views } from 'types/nav';
import { Col, Row } from 'components/Layout';
import {
  ProcedureFull,
  ProcedureSpouseFull,
  ProcedureStepStatus,
  ProcedureStepType,
  Role,
} from '../../../types/resources';

interface OpportunityViewsProps {
  procedure: ProcedureFull;
  spouses: ProcedureSpouseFull[];
  refetchProcedure: () => void;
}

interface AdminGateProps {
  procedure: ProcedureFull;
}

const AdminGate: FC<AdminGateProps> = ({ procedure, children }) => {
  const adminOnly = [Role.ADMIN, Role.MANAGER, Role.LAWYER];
  const redirect = getRouteWithParams(AdminAppRoutes.CASES_DETAILS, {
    id: procedure.id,
  });
  return (
    <Permission roles={adminOnly} redirect={redirect}>
      {children}
    </Permission>
  );
};

const OpportunityViews: FC<OpportunityViewsProps> = ({
  procedure,
  spouses,
  refetchProcedure,
  children,
}) => {
  const closureStep = procedure.steps.find(
    (s) => s.step === ProcedureStepType.CLOSED,
  );
  const search = useLocation().search;
  const view = new URLSearchParams(search).get('view');

  if (
    closureStep?.status === ProcedureStepStatus.VALIDATED &&
    view !== 'administrative-documents'
  )
    return <ClosedView procedure={procedure} />;
  switch (view) {
    case Views.Forms:
      return (
        <AdminGate procedure={procedure}>
          <FormView
            procedure={procedure}
            refetchProcedure={refetchProcedure}
            spouses={spouses}
          />
        </AdminGate>
      );
    case Views.Documents:
      return (
        <AdminGate procedure={procedure}>
          <DocumentView
            procedure={procedure}
            refetchProcedure={refetchProcedure}
            spouses={spouses}
          />
        </AdminGate>
      );
    case Views.Verify:
      return (
        <AdminGate procedure={procedure}>
          <DocumentsVerification
            procedure={procedure}
            refetchProcedure={refetchProcedure}
          />
        </AdminGate>
      );
    case Views.AdministrativeDocuments:
      return (
        <AdminGate procedure={procedure}>
          <Row marginBottom={{ xs: 'space40' }}>
            <Col xs={12} vertical>
              <AdministrativeDocuments
                procedure={procedure}
                spouses={spouses}
              />
            </Col>
          </Row>
        </AdminGate>
      );
    case Views.ConventionWriting:
      return (
        <ConventionWritingView
          procedure={procedure}
          spouses={spouses}
          refetchProcedure={refetchProcedure}
        />
      );
    case Views.Convention:
      return (
        <ConventionView
          procedure={procedure}
          refetchProcedure={refetchProcedure}
        />
      );
    case Views.Recommended:
      return (
        <AdminGate procedure={procedure}>
          <RegisteredLetters
            procedure={procedure}
            spouses={spouses}
            refetchProcedure={refetchProcedure}
          />
        </AdminGate>
      );
    case Views.Appointment:
      return (
        <AdminGate procedure={procedure}>
          <Appointment
            procedure={procedure}
            refetchProcedure={refetchProcedure}
          />
        </AdminGate>
      );
    case Views.Notary:
      return (
        <AdminGate procedure={procedure}>
          <NotarialDeedPage
            procedure={procedure}
            refetchProcedure={refetchProcedure}
          />
        </AdminGate>
      );
    case Views.Civil:
      return (
        <AdminGate procedure={procedure}>
          <CivilStatusPage
            procedure={procedure}
            refetchProcedure={refetchProcedure}
          />
        </AdminGate>
      );
    case Views.FinalSend:
      return (
        <AdminGate procedure={procedure}>
          <FinalSendingPage
            procedure={procedure}
            refetchProcedure={refetchProcedure}
          />
        </AdminGate>
      );
    case Views.Global:
    default:
      return <>{children}</>;
  }
};

export default OpportunityViews;
