import { FC } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Flex } from 'components/Layout';
import { FormItem } from 'components/Forms';
import { List } from 'components/List';
import { Ghost } from 'components/Loading';

import { getRouteWithParams } from 'utils/router';
import { AdminAppRoutes } from 'AdminApp';
import { ClientAppRoutes } from 'ClientApp';
import { useAuth } from '../../../hooks/useAuth';
import { FormFull, FormStatus } from '../../../types/resources';

interface FormListProps {
  title: string;
  count: string;
  data?: FormFull[];
  opportunityId: string;
  onDelete?: (form: FormFull) => void;
}

const FormList: FC<FormListProps> = ({
  title,
  count,
  data,
  opportunityId,
  onDelete,
}) => {
  const { t } = useTranslation();
  const { isCustomer, user } = useAuth();
  const history = useHistory();

  const goToForm = (id: string) => {
    let pathname;

    if (!isCustomer) {
      pathname = getRouteWithParams(AdminAppRoutes.FORM, {
        opportunityId,
        formId: id,
      });
    } else {
      pathname = getRouteWithParams(ClientAppRoutes.FORM, {
        id,
        opportunityId,
      });
    }
    history.push(pathname);
  };

  if (data?.length === 0) return null;

  if (!data) {
    return (
      <List title={title} count={count}>
        {[...Array(5)].map((_, index) => (
          <Flex key={`form_${title}_${index}`} marginBottom={{ xs: 'space16' }}>
            <Ghost width="100%" height={90} shape="rect" rx={24} />
          </Flex>
        ))}
      </List>
    );
  }

  return (
    <List title={title} count={count}>
      {data &&
        data.map((form, index) => {
          const toComplete = form.status === FormStatus.TO_COMPLETE;
          const completed = form.status === FormStatus.COMPLETED;
          const toReview = form.status === FormStatus.IN_REVIEW;
          const validate = form.status === FormStatus.VALIDATED;
          const buttonLabel = toComplete
            ? t('forms_opportunity.button.to_complete')
            : completed
            ? t('forms_opportunity.button.completed')
            : toReview
            ? t('forms_opportunity.button.to_review')
            : validate
            ? t('forms_opportunity.button.validate')
            : t('forms_opportunity.see');

          const editionsCount = form.answers.reduce<number>(
            (count, { updated_by_id }) => {
              if (updated_by_id && updated_by_id !== user?.id) {
                return count + 1;
              }
              return count;
            },
            0,
          );

          return (
            <Flex
              key={`form_item_${index}`}
              width="100%"
              marginBottom={{
                xs: `${index === data.length - 1 ? 'space40' : 'space16'}`,
              }}
            >
              <FormItem
                form={form}
                onClick={() => goToForm(form.id)}
                onDelete={onDelete}
                label={!isCustomer ? t('forms_opportunity.see') : buttonLabel}
                count={editionsCount}
                isAdmin={!isCustomer}
              />
            </Flex>
          );
        })}
    </List>
  );
};

export default FormList;
