import { FC, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Data

// Components
import { Button } from 'components/Buttons';
import { TextArea } from 'components/FormTemplate/Fields/TextArea';
import { Flex } from 'components/Layout';
import { Modal, ModalHeader } from 'components/Modal';
import { InputText } from 'components/FormTemplate/Fields/InputText';
import { Appointment } from '../../../types/resources';
import { useApi } from '../../../hooks/useApi';

interface AppointmentModalPlaceCardProps {
  opportunityId: string;
  appointment: Appointment;
  refetchAppointment: () => void;
  opened: boolean;
  onClose: () => void;
}

const AppointmentModalPlaceCard: FC<AppointmentModalPlaceCardProps> = ({
  opportunityId,
  appointment,
  refetchAppointment,
  opened,
  onClose,
}) => {
  const { t } = useTranslation();
  const [notes, setNotes] = useState<string>('');
  const [address, setAddress] = useState<string>('');

  const {
    execute: updateAppointment,
    state: { loading: updateAppointmentLoading },
  } = useApi(`/procedures/${opportunityId}/appointments/${appointment.id}`, {
    method: 'PATCH',
    onSuccess: () => {
      refetchAppointment();
      onClose();
    },
  });

  useEffect(() => {
    setNotes(appointment.notes || '');
    setAddress(appointment.address || '');
  }, [appointment]);

  const onSubmit = () => {
    updateAppointment({
      body: {
        notes,
        address,
      },
    });
  };

  return (
    <Modal opened={opened} onClose={onClose} width="720px">
      <ModalHeader
        content={t('appointment.details.button.update')}
        iconName="Calendar"
        onClose={onClose}
      />
      <Flex
        marginBottom={{ xs: 'space16' }}
        expand
        direction={{ xs: 'column' }}
      >
        <Flex marginBottom={{ xs: 'space16' }} marginTop={{ xs: 'space16' }}>
          <InputText
            width="100%"
            onChange={(e) => setAddress(e.target.value)}
            value={address}
            label={t('appointment.place') as string}
          />
        </Flex>
        <Flex marginBottom={{ xs: 'space16' }} marginTop={{ xs: 'space16' }}>
          <TextArea
            placeholder={t('appointment.notes_placeholder')}
            width="100%"
            onChange={(e) => setNotes(e.target.value)}
            value={notes}
            label={t('appointment.notes') as string}
          />
        </Flex>
      </Flex>
      <Button
        content={t('appointment.modify')}
        primary
        $loading={updateAppointmentLoading}
        onClick={onSubmit}
        disabled={!address}
      />
    </Modal>
  );
};

export default AppointmentModalPlaceCard;
