import { FC, useState } from 'react';
import styled from 'styled-components';

import { theme } from 'theme';
import { Modal } from 'components/Modal/Modal';
import { useTranslation } from 'react-i18next';
import { OptionCard } from 'components/Cards/OptionCard';
import { ModalFooter, ModalHeader } from 'components/Modal';
import { md } from 'theme/styles/mediaQueries';
import { SubmitModalProps } from '.';
import { useViewport } from 'hooks/useViewport';
import ProductCard from 'components/Cards/ProductCard';
import { Option } from '../../types/resources';

const OptionGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px 16px;
  margin-bottom: ${theme.spacing.space24};
  ${md(`
    margin-bottom: ${theme.spacing.space16};
  `)}
`;

interface OptionModalProps extends SubmitModalProps {
  selected: Option[];
  options: Option[];
}

const OptionModal: FC<OptionModalProps> = ({
  onClose,
  options,
  selected,
  loading,
  onSubmit,
  opened,
}) => {
  const { isMobile } = useViewport();
  const { t } = useTranslation();
  const [checked, setChecked] = useState<Option[]>(selected);

  const onClick = (option: Option) => {
    const newOptions = [...checked];
    const foundIndex = newOptions.findIndex((o) => o.id === option.id);

    if (newOptions && foundIndex > -1) {
      newOptions.splice(foundIndex, 1);
    } else {
      newOptions.push(option);
    }

    setChecked(newOptions);
  };

  return (
    <Modal opened={opened} onClose={onClose}>
      <ModalHeader
        iconName="Edit"
        content={t('quote.resume.modify_option')}
        onClose={onClose}
      />
      <OptionGrid>
        {options?.map((option) => {
          const { description, name, id, price, label } = option;

          if (isMobile) {
            return (
              <ProductCard
                key={`product-card-${id}`}
                title={name}
                label={label}
                content={description}
                priceDescription={t('quote.resume.taxes_price')}
                price={t('quote.resume.price', { price: price / 2 })}
                seeMoreText={t('quote.resume.see_more')}
                onClick={() => onClick(option)}
                checked={Boolean(checked?.find((o) => o.id === option.id))}
              />
            );
          }

          return (
            <OptionCard
              key={id}
              title={name}
              label={label}
              content={description}
              priceDescription={t('quote.resume.taxes_price')}
              textButton={t(
                isMobile
                  ? 'quote.resume.option_button_mobile'
                  : 'quote.resume.option_button',
                {
                  name: name.toLowerCase(),
                },
              )}
              price={t('quote.resume.price', { price: price / 2 })}
              onClick={() => onClick(option)}
              checked={Boolean(checked?.find((o) => o.id === option.id))}
            />
          );
        })}
      </OptionGrid>
      <ModalFooter
        onClose={onClose}
        disabled={checked === selected || loading}
        loading={loading}
        onSubmit={() => {
          onSubmit({ options_ids: [...checked].map((o) => o.id) }, () => {
            onClose();
          });
        }}
      />
    </Modal>
  );
};

export default OptionModal;
