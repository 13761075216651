import { IconButton } from 'components/Buttons';
import { Flex, Text } from 'components/Layout';
import { FC } from 'react';
import styled from 'styled-components';
import { theme } from 'theme';
import { formatDate } from 'utils/formatDate';
import { formatHours } from 'utils/formatHours';

interface NoteStepperProps {
  date: Date;
  isLastItem: boolean;
  processed: boolean;
}

const StepperContainer = styled.div`
  position: absolute;
  left: -150px;
  height: 100%;
`;

const StyledFlex = styled(Flex)`
  position: relative;
`;

const Progress = styled.div`
  position: absolute;
  top: 0;
  right: ${theme.spacing.space40};
  width: 4px;
  height: calc(100% + ${theme.spacing.space24});
  background-color: ${theme.colors.gray4};
  z-index: 2;
  transform: translateX(50%);
`;

const IconContainer = styled.span`
  position: absolute;
  top: 0;
  right: ${theme.spacing.space40};
  z-index: 2;
  transform: translateX(50%) translateY(-0.5rem);
`;

const Circle = styled.span`
  position: absolute;
  top: 0;
  right: ${theme.spacing.space40};
  width: ${theme.spacing.space16};
  height: ${theme.spacing.space16};
  border-radius: 50%;
  background-color: ${theme.colors.gray4};
  z-index: 2;
  transform: translateX(50%);
`;

const NoteStepper: FC<NoteStepperProps> = ({ date, isLastItem, processed }) => {
  return (
    <StepperContainer>
      <StyledFlex
        direction={{ xs: 'column' }}
        marginTop={{ xs: 'space40' }}
        paddingRight={{ xs: 'space80' }}
        height={isLastItem ? 'auto' : '100%'}
      >
        <Text
          fontStyle="body3"
          content={formatDate(date)}
          weight="medium"
          marginBottom={{ xs: 'space8' }}
        />

        <Text fontStyle="body3" content={formatHours(date)} />

        {!isLastItem && <Progress />}

        {processed ? (
          <IconContainer>
            <IconButton
              iconName="ShieldDone"
              color={theme.colors.white}
              backgroundColor={theme.colors.green1}
              rounded
              size="small"
            />
          </IconContainer>
        ) : (
          <Circle />
        )}
      </StyledFlex>
    </StepperContainer>
  );
};

export default NoteStepper;
