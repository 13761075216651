import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

// Components
import { Text } from 'components/Layout/Text';

import { getStepPercentage } from 'utils/math';

import { Flex } from 'components/Layout';
import { theme } from 'theme';
import { formatDate } from 'utils/formatDate';
import { FC } from 'react';
import { addDays } from 'date-fns/esm';

interface ProgressProps {
  percentage: number;
}

const Card = styled.div`
  background-color: ${theme.colors.beige};
  border-radius: ${theme.spacing.space16};
  padding: ${theme.spacing.space16};
  width: 100%;
`;

const Bar = styled.div`
  position: relative;
  width: 100%;
  height: ${theme.spacing.space8};
  background-color: ${theme.colors.green4};
  border-radius: ${theme.spacing.space16};
  transition: 0.5s width ease-in-out;
`;

const Progress = styled.div<ProgressProps>`
  position: absolute;
  inset: 0;
  width: ${(props) => props.percentage + '%'};
  max-width: 100%;
  height: ${theme.spacing.space8};
  background-color: ${theme.colors.green1};
  border-radius: ${theme.spacing.space16};
  transition: 0.5s width ease-in-out;
`;

interface WithdrawalDelayProps {
  startDate: Date;
  duration: number;
  withComment?: boolean;
}

const WithdrawalDelay: FC<WithdrawalDelayProps> = ({
  startDate,
  duration,
  withComment = true,
}) => {
  const { t } = useTranslation();

  // Get the end Date
  const endDateCounter = addDays(new Date(startDate), duration);

  // Get the daysLeft
  const datetimeLeft = endDateCounter.getTime() - new Date().getTime();
  const daysLeft = Math.ceil(datetimeLeft / (1000 * 60 * 60 * 24));

  const getPercentage = () => {
    if (duration - daysLeft > duration) return 100;
    if (duration - daysLeft < 0) return 0;
    return Number(getStepPercentage(duration - daysLeft, duration).toFixed(0));
  };

  const percentage = getPercentage();

  const getCountDays = () => {
    if (duration - daysLeft > duration) return duration;
    if (duration - daysLeft < 0) return 0;
    return duration - daysLeft;
  };

  return (
    <Card>
      <Flex
        justify="between"
        alignItems="center"
        marginBottom={{ xs: 'space24' }}
      >
        <Text
          content={t('withdrawal_delay.title')}
          fontStyle="body1"
          color={theme.colors.black}
        />
        <Text
          content={t('withdrawal_delay.date_count', {
            count: getCountDays(),
            max: duration,
          })}
          fontStyle="body1"
          weight="bold"
        />
      </Flex>
      {withComment && (
        <Text
          content={t('withdrawal_delay.description', { duration })}
          fontStyle="body2"
          color={theme.colors.gray6}
          marginBottom={{ xs: 'space24' }}
        />
      )}
      <Text
        content={t('withdrawal_delay.card_end_date')}
        fontStyle="body1"
        weight="medium"
        color={theme.colors.gray5}
      />
      <Text
        content={formatDate(endDateCounter)}
        fontStyle="body2"
        weight="bold"
        marginTop={{ xs: 'space8' }}
        marginBottom={{ xs: 'space16' }}
      />
      <Bar>
        <Progress percentage={percentage} />
      </Bar>
    </Card>
  );
};

export default WithdrawalDelay;
