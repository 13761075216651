import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { ClientCard, TipCard } from 'components/Cards';
import { Flex, Requires, Text } from 'components/Layout';
import { Modal } from 'components/Modal';
import { Button, IconButton } from 'components/Buttons';

import { theme } from 'theme';
import { useAuth } from '../../../hooks/useAuth';
import { DivorceAgreementFull, ProcedureFull } from '../../../types/resources';
import { useApi } from '../../../hooks/useApi';
import { useForm } from 'react-hook-form';
import { TextArea } from '../../../components/FormTemplate/Fields/TextArea';

type ConventionValidationAskEditModalData = {
  reason: string;
};

type ConventionValidationAskEditModalProps = {
  opened: boolean;
  loading: boolean;
  onConfirm: (data: ConventionValidationAskEditModalData) => void;
  onClose: () => void;
};

const Form = styled.form``;

const ConventionValidationAskEditModal: FC<
  ConventionValidationAskEditModalProps
> = ({ opened, loading, onConfirm, onClose }) => {
  const { t } = useTranslation();
  const {
    register,
    reset,
    formState: { errors },
    handleSubmit,
  } = useForm<ConventionValidationAskEditModalData>({
    defaultValues: { reason: undefined },
  });

  const onSubmitValid = (data: ConventionValidationAskEditModalData) => {
    onConfirm(data);
  };

  useEffect(() => {
    if (opened) {
      reset();
    }
  }, [opened]);

  return (
    <Modal opened={opened}>
      <Flex direction={{ xs: 'column' }}>
        <Flex marginBottom={{ xs: 'space24', md: 'space16' }}>
          <IconButton
            iconName="Danger"
            color={theme.colors.white}
            backgroundColor={theme.colors.salmon1}
            rounded
          />
          <Flex alignItems="center">
            <Text
              content={t('convention.validation.waiting.modal.title')}
              fontStyle={'heading5'}
              marginLeft={{ xs: 'space16' }}
              weight="bold"
            />
          </Flex>
        </Flex>
        <Text
          fontStyle="body1"
          content={t('convention.validation.waiting.modal.description')}
          marginBottom={{ xs: 'space16' }}
        />

        <Form
          onSubmit={handleSubmit(onSubmitValid)}
          style={{ marginBottom: theme.spacing.space24 }}
        >
          <TextArea
            {...register('reason', {
              required: t('error.form.required').toString(),
            })}
            error={errors.reason}
            placeholder={t('convention.validation.waiting.modal.placeholder')}
            width="100%"
          />

          <Flex
            direction={{ xs: 'column', md: 'row' }}
            marginTop={{ xs: 'space24' }}
          >
            <Button
              content={t('convention.validation.waiting.modal.cancel')}
              marginRight={{ xs: 'none', md: 'space16' }}
              onClick={onClose}
            />
            <Button
              content={t('convention.validation.waiting.modal.validate')}
              type="submit"
              $loading={loading}
              primary
            />
          </Flex>
        </Form>
      </Flex>
    </Modal>
  );
};

interface ConventionValidationProps {
  procedure: ProcedureFull;
  agreement: DivorceAgreementFull;
  refetchProcedure: () => void;
  refetchAgreement: () => void;
}

const TextList = styled.ul``;
const TextLi = styled.li`
  list-style-position: inside;
  line-height: 1.125rem;
`;
const InlineText = styled(Text)`
  display: inline;
`;

const ConventionValidation: FC<ConventionValidationProps> = ({
  procedure,
  agreement,
  refetchProcedure,
  refetchAgreement,
}) => {
  const [modalValidationState, setModalValidationState] =
    useState<boolean>(false);
  const [modalWaitingState, setModalWaitingState] = useState<boolean>(false);
  const { t } = useTranslation();
  const { user } = useAuth();

  const {
    execute: validateAgreement,
    state: { loading: validateAgreementLoading },
  } = useApi(
    `procedures/${procedure.id}/divorce-agreements/${agreement.id}/validate`,
    {
      method: 'POST',
      onSuccess: () => {
        onClose();
        refetchProcedure();
      },
    },
  );

  const {
    execute: lockAgreement,
    state: { loading: lockAgreementLoading },
  } = useApi(
    `procedures/${procedure.id}/divorce-agreements/${agreement.id}/lock`,
    {
      method: 'POST',
      onSuccess: () => {
        onClose();
        refetchAgreement();
      },
    },
  );

  const onValidate = () => {
    validateAgreement();
  };

  const onWaiting = (data: ConventionValidationAskEditModalData) => {
    lockAgreement({ body: data });
  };

  const onClose = () => {
    setModalValidationState(false);
    setModalWaitingState(false);
  };

  const validated = agreement.validations.some((v) => v.user_id === user?.id);
  const waiting = agreement.locked;
  const buttons = [
    {
      content: t('convention.validation.validate'),
      primary: true,
      fullWidth: true,
      disabled: validated || waiting,
      $loading: validateAgreementLoading,
      onClick: () => setModalValidationState(true),
    },
    !validated
      ? {
          content: t('convention.validation.write_comment'),
          fullWidth: true,
          disabled: waiting || validated,
          $loading: lockAgreementLoading,
          onClick: () => setModalWaitingState(true),
        }
      : null,
  ];
  return (
    <>
      <Flex marginBottom={{ xs: 'space32' }} width="100%">
        <ClientCard
          iconName="Document"
          title={t('convention.validation.title')}
          buttons={buttons}
          onClick={() => setModalValidationState(true)}
          buttonsDirections={{ xs: 'column' }}
        >
          <Requires value={validated}>
            <Text
              fontStyle="body2"
              content={t('convention.validation.validated')}
              color={theme.colors.gray6}
            />
          </Requires>
          <Requires value={waiting}>
            <Text
              fontStyle="body2"
              content={t('convention.validation.no_validation')}
              color={theme.colors.gray6}
            />
          </Requires>
          <Requires value={!validated && !waiting}>
            <Text
              fontStyle="body2"
              color={theme.colors.gray6}
              content={t('convention.validation.long_text.first')}
              marginBottom={{ xs: 'space16' }}
            />
            <Text
              fontStyle="body2"
              color={theme.colors.gray6}
              content={t('convention.validation.long_text.second')}
            />
            <br />
            <TextList>
              {[...Array(5)].map((_, index) => (
                <TextLi key={`long_text_bullet_${index}`}>
                  <InlineText
                    fontStyle="body2"
                    content={t(
                      `convention.validation.long_text.bullet.${index + 1}`,
                    )}
                    color={theme.colors.gray6}
                  />
                </TextLi>
              ))}
            </TextList>
            <Text
              fontStyle="body2"
              color={theme.colors.gray6}
              content={t('convention.validation.long_text.third')}
              marginBottom={{ xs: 'space16' }}
            />
            <Text
              fontStyle="body2"
              color={theme.colors.gray6}
              content={t('convention.validation.long_text.fourth')}
            />
          </Requires>
        </ClientCard>
      </Flex>
      <Requires value={waiting}>
        <TipCard
          type="info"
          title={t('convention.validation.waiting.title')}
          icon="InfoSquare"
          content={t('convention.validation.waiting.description')}
        />
      </Requires>

      <Modal opened={modalValidationState}>
        <Flex direction={{ xs: 'column' }}>
          <Flex marginBottom={{ xs: 'space24', md: 'space16' }}>
            <IconButton
              iconName="Danger"
              color={theme.colors.white}
              backgroundColor={theme.colors.salmon1}
              rounded
            />
            <Flex alignItems="center">
              <Text
                content={t('convention.validation.modal.title')}
                fontStyle={'heading5'}
                marginLeft={{ xs: 'space16' }}
                weight="bold"
              />
            </Flex>
          </Flex>
          <Text
            fontStyle="body1"
            content={t('convention.validation.modal.description')}
            marginBottom={{ xs: 'space16' }}
          />
          <Flex direction={{ xs: 'column', md: 'row' }}>
            <Button
              content={t('convention.validation.modal.cancel')}
              marginRight={{ xs: 'none', md: 'space16' }}
              marginBottom={{ xs: 'space16', md: 'none' }}
              onClick={onClose}
            />
            <Button
              content={t('convention.validation.modal.validate')}
              onClick={onValidate}
              $loading={validateAgreementLoading}
              primary
            />
          </Flex>
        </Flex>
      </Modal>

      <ConventionValidationAskEditModal
        opened={modalWaitingState}
        loading={lockAgreementLoading}
        onClose={onClose}
        onConfirm={onWaiting}
      />
    </>
  );
};

export default ConventionValidation;
