import { UiSchemaCustom } from '.';

const incomes: UiSchemaCustom[] = [
  {
    'ui:order': [
      'PRESTATION_COMP',
      'BENEF_PRESTA_COMP',
      'MODALITES_VERSEMENT_PC',
      'WD_PRESTA_CAPITAL_FREQUENCE',
      'WD_PRESTA_CAPITAL_DATE',
      'WD_PRESTA_CAPITAL_MONTANT',
      'WD_PRESTA_RENTE_DUREE',
      'WD_PRESTA_RENTE_MONTANT',
      'WD_AUTRES_PRECISIONS',
    ],
    PRESTATION_COMP: {
      'ui:widget': 'radio',
    },
    MODALITES_VERSEMENT_PC: {
      'ui:widget': 'select',
      'ui:placeholder': 'Modalités de la prestation compensatoire',
    },
    WD_PRESTA_CAPITAL_DATE: {
      'ui:field': 'CustomDatepicker',
      'ui:placeholder': 'Choisir une date',
    },
    WD_PRESTA_CAPITAL_FREQUENCE: {
      'ui:field': 'CustomField',
      'ui:placeholder': 'Saisir le nombre de fois',
      'ui:options': {
        inputType: 'number',
      },
    },
    WD_PRESTA_CAPITAL_MONTANT: {
      'ui:field': 'CustomCurrency',
      'ui:placeholder': 'Montant de la prestation en euros',
    },
    WD_PRESTA_RENTE_DUREE: {
      'ui:field': 'CustomField',
      'ui:placeholder': 'Durée de la rente en mois',
      'ui:options': {
        inputType: 'text',
      },
    },
    WD_PRESTA_RENTE_MONTANT: {
      'ui:field': 'CustomCurrency',
      'ui:placeholder': 'Montant de la rente en euros',
    },
    BENEF_PRESTA_COMP: {
      'ui:widget': 'select',
      'ui:placeholder': 'Choisir une option',
    },
    WD_AUTRES_PRECISIONS: {
      'ui:field': 'CustomTextArea',
      'ui:placeholder': 'Précisions supplémentaires...',
    },
  },
];

export default incomes;
