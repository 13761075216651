import { FC, MouseEventHandler, useState } from 'react';
import styled from 'styled-components';

import { Text } from 'components/Layout';
import { IconButton } from './IconButton';
import { theme } from 'theme';
import { useViewport } from 'hooks/useViewport';

const Button = styled.button`
  display: flex;
  align-items: center;
  cursor: pointer;
  border: none;
`;

interface AddButtonProps {
  onClick: MouseEventHandler<HTMLDivElement | HTMLButtonElement>;
  content: string;
}

const AddButton: FC<AddButtonProps> = ({ onClick, content }) => {
  const { isMobile } = useViewport();
  const [hoverState, setHoverState] = useState(false);

  return (
    <Button
      onClick={onClick}
      onMouseEnter={() => setHoverState(true)}
      onMouseLeave={() => setHoverState(false)}
    >
      <IconButton
        iconName="Plus"
        color={hoverState ? theme.colors.salmon2 : theme.colors.salmon1}
        backgroundColor={theme.colors.white}
        style={isMobile ? { marginLeft: '-4px' } : {}}
        stroke="regular"
      />
      <Text content={content} fontStyle="body2" weight="medium" />
    </Button>
  );
};

export default AddButton;
