import { FC, useEffect } from 'react';
import styled from 'styled-components';

// Components
import { useTranslation } from 'react-i18next';
import { OptionCard } from 'components/Cards/OptionCard';
import { Ghost } from 'components/Loading';
import ProductCard from 'components/Cards/ProductCard';
import { useViewport } from 'hooks/useViewport';
import { useApi } from '../../hooks/useApi';
import { Option } from '../../types/resources';

const OptionGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px 16px;
`;

export interface OptionCardsProps {
  onClick: (option: Option) => void;
  checked: Option[];
  showOnly?: Option;
}

const OptionCards: FC<OptionCardsProps> = ({ onClick, checked, showOnly }) => {
  const { t } = useTranslation();
  const { isMobile } = useViewport();
  const { execute, state } = useApi<Option[]>(
    '/products?type=OPTION&enabled=true',
    { cache: true },
  );

  useEffect(() => {
    execute();
  }, []);

  return (
    <OptionGrid>
      {state.loading ? (
        <>
          <Ghost width="100%" height={400} shape="rect" rx={24} />
        </>
      ) : (
        state.data?.value.map((option) => {
          const { description, name, id, price, code, label } = option;

          if (showOnly && code !== showOnly.code) {
            return null;
          }

          if (isMobile) {
            return (
              <ProductCard
                key={`product-card-${id}`}
                title={name}
                label={label}
                content={description}
                priceDescription={t('quote.resume.taxes_price')}
                seeMoreText={t('quote.resume.see_more')}
                price={t('quote.resume.price', { price: price / 2 })}
                onClick={() => onClick(option)}
                checked={Boolean(checked?.find((o) => o.code === code))}
              />
            );
          }

          return (
            <OptionCard
              key={`option-card-${id}`}
              title={name}
              label={label}
              content={description}
              priceDescription={t('quote.resume.taxes_price')}
              textButton={t('quote.resume.option_button', {
                name: name,
              })}
              price={t('quote.resume.price', { price: price / 2 })}
              onClick={() => onClick(option)}
              checked={Boolean(checked?.find((o) => o.code === code))}
            />
          );
        })
      )}
    </OptionGrid>
  );
};

export default OptionCards;
