import InputCheckbox from 'components/FormTemplate/Fields/InputCheckbox';
import { Icon } from 'components/Images';
import { Flex, Text } from 'components/Layout';
import { FC, MouseEventHandler, useState } from 'react';
import styled from 'styled-components';
import { theme } from 'theme';

interface ProductCardProps {
  title: string;
  label?: string | null;
  content?: string | null;
  priceDescription: string;
  price?: string;
  checked: boolean;
  seeMoreText: string;
  onClick?: MouseEventHandler<HTMLDivElement>;
  boldPriceDescription?: boolean;
}

const CardContainer = styled.div`
  background-color: ${theme.colors.beige};
  padding: ${theme.spacing.space32};
  border-radius: ${theme.spacing.space24};
`;

const CursorPointer = styled(Flex)`
  cursor: pointer;
`;

const CheckboxContainer = styled.div``;

interface IconContainerProps {
  isOpen: boolean;
}

const IconContainer = styled(Flex)<IconContainerProps>`
  height: 24px;
  transform: ${(props) => (props.isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
  transition: transform 0.3s ease-in-out;
`;

interface TextContainerProps {
  isOpen: boolean;
}

const TextContainer = styled.div<TextContainerProps>`
  opacity: ${(props) => (props.isOpen ? 1 : 0)};
  transition: opacity 0.3s ease-in-out;
`;

export const ProductCard: FC<ProductCardProps> = ({
  title,
  checked,
  label,
  onClick,
  price,
  content,
  seeMoreText,
  priceDescription,
  boldPriceDescription = false,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <CardContainer>
      <CursorPointer
        marginBottom={{ xs: label ? 'space24' : 'none' }}
        onClick={onClick}
      >
        <CheckboxContainer>
          <InputCheckbox
            fontWeight="bold"
            checked={checked}
            justify="center"
            align="center"
            rounded
            checkPoint
          />
        </CheckboxContainer>
        <Text
          content={title}
          fontStyle="heading4"
          marginLeft={{ xs: 'space12' }}
        />
      </CursorPointer>
      {label && (
        <Flex direction={{ xs: 'column' }}>
          <Text content={label} fontStyle="body2" weight="bold" />
        </Flex>
      )}
      {content && (
        <Flex direction={{ xs: 'column' }}>
          <CursorPointer
            alignItems="center"
            marginTop={{ xs: 'space24' }}
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          >
            <IconContainer justify="center" alignItems="center" isOpen={isOpen}>
              <Icon name="ChevronDown" primaryColor={theme.colors.salmon1} />
            </IconContainer>
            <Text
              content={seeMoreText}
              fontStyle="body2"
              color={theme.colors.salmon1}
              weight="medium"
              marginLeft={{ xs: 'space16' }}
            />
          </CursorPointer>
          <TextContainer isOpen={isOpen}>
            {isOpen && (
              <Text
                content={content}
                fontStyle="body1"
                color={theme.colors.gray6}
                marginTop={{ xs: 'space24' }}
                weight="regular"
              />
            )}
          </TextContainer>
        </Flex>
      )}
      <Flex direction={{ xs: 'column' }} marginTop={{ xs: 'space24' }}>
        {price && (
          <Text
            content={price}
            fontStyle="heading2"
            color={theme.colors.salmon1}
            marginBottom={{ xs: 'space8' }}
          />
        )}
        <Text
          content={priceDescription}
          fontStyle="body1"
          weight={boldPriceDescription ? 'bold' : 'regular'}
          color={
            boldPriceDescription ? theme.colors.green1 : theme.colors.gray5
          }
        />
      </Flex>
    </CardContainer>
  );
};

export default ProductCard;
