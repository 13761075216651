import { FC, useState } from 'react';
import styled from 'styled-components';

import { Modal } from 'components/Modal/Modal';
import { ModalHeader } from 'components/Modal';
import { SubmitModalProps } from '.';
import { Flex, Text } from 'components/Layout';
import { Button } from 'components/Buttons';
import { theme } from 'theme';
import { TipCard } from 'components/Cards';
import { useTranslation } from 'react-i18next';
import { EstimateUpdate } from '../../types/resources';

const CustomModal = styled(Modal)`
  display: flex;
  flex-direction: column;
`;

const Grid = styled.div`
  display: grid;
  gap: ${theme.spacing.space16};
  justify-content: start;
  justify-items: start;
  grid-auto-flow: column;
  margin-top: ${theme.spacing.space16};
`;

interface EnumOption {
  label: string;
  value: string | boolean;
}

interface QuestionModalProps extends SubmitModalProps {
  question: string;
  title: string;
  value: boolean;
  field: keyof EstimateUpdate;
  enumOptions: EnumOption[];
}

const QuestionModal: FC<QuestionModalProps> = ({
  question,
  title,
  onClose,
  value,
  field,
  onSubmit,
  loading,
  opened,
  enumOptions,
  withTipCard,
}) => {
  const [valueState, setValueState] = useState<string | boolean>(value);
  const { t } = useTranslation();
  return (
    <CustomModal height="auto" opened={opened} onClose={onClose}>
      <ModalHeader iconName="Edit" content={title} onClose={onClose} />
      <Text content={question} fontStyle="body1" />
      <Grid>
        {enumOptions.map(({ label, value }, index) => {
          const selectedValue = value.toString() == valueState.toString();
          return (
            <div key={`question-${index}`}>
              <Button
                primary={selectedValue}
                $loading={selectedValue && loading}
                disabled={loading}
                onClick={() => {
                  setValueState(value);
                  onSubmit(
                    {
                      [field]: value,
                    },
                    () => {
                      onClose();
                    },
                  );
                }}
                value={value as string}
                content={label}
              />
            </div>
          );
        })}
      </Grid>
      {withTipCard && (
        <Flex marginTop={{ xs: 'space16' }}>
          <TipCard title={t('updateDealNote')} type="danger" icon="Danger" />
        </Flex>
      )}
    </CustomModal>
  );
};

export default QuestionModal;
