import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { ClientCard } from 'components/Cards';
import { Ghost } from 'components/Loading';
import { IconButton } from 'components/Buttons';
import { Flex, Text } from 'components/Layout';
import { AddressModal } from 'components/Modal';

// Utils
import { theme } from 'theme';
import { formatAddress } from 'utils/format';
import { Customer, CustomerFull } from '../../../types/resources';
import { useApi } from '../../../hooks/useApi';

interface AddressCardProps {
  customerId: Customer['id'] | null;
  index: number;
  disabled?: boolean;
}

export type UpdateAddressInput = {
  city: string;
  post_code: string;
  street: string;
};

const AddressCard: FC<AddressCardProps> = ({ customerId, index, disabled }) => {
  const [modalState, setModalState] = useState<boolean>(false);
  const { t } = useTranslation();

  const { execute: getCustomer, state: getCustomerState } =
    useApi<CustomerFull>(`/customers/${customerId}`);
  const customer = getCustomerState.data?.value;

  const { execute: updateCustomer } = useApi(`/customers/${customerId}`, {
    method: 'PATCH',
    onSuccess: () => {
      onClose();
      getCustomer();
    },
  });

  useEffect(() => {
    if (customerId) {
      getCustomer();
    }
  }, [customerId]);

  const onOpen = () => {
    setModalState(true);
  };
  const onClose = () => {
    setModalState(false);
  };

  if (!customer)
    return <Ghost width="100%" height="296px" shape="rect" rx={24} />;

  const buttons = [
    {
      disabled,
      content: t('formality_letters.address.button'),
      primary: true,
      fullWidth: true,
      onClick: onOpen,
    },
  ];

  const title = t('formality_letters.address.title', { index });

  const onSubmit = (data: UpdateAddressInput) => {
    updateCustomer({ body: { address: data } });
  };

  return (
    <ClientCard iconName="User" title={title} buttons={buttons}>
      <Flex marginBottom={{ xs: 'space16' }} alignItems="center">
        <IconButton
          iconName="User"
          color={theme.colors.green1}
          backgroundColor={theme.colors.green2}
          rounded
          stroke="regular"
        />
        <Text
          fontStyle="body2"
          content={customer.full_name}
          color={theme.colors.gray6}
          marginLeft={{ xs: 'space8' }}
          weight="medium"
        />
      </Flex>
      <Flex marginBottom={{ xs: 'space32' }}>
        <Text
          fontStyle="body2"
          content={formatAddress(customer.address)}
          color={theme.colors.gray6}
        />
      </Flex>
      <AddressModal
        opened={modalState}
        onClose={onClose}
        onSubmit={onSubmit}
        title={title}
        address={customer.address}
      />
    </ClientCard>
  );
};

export default AddressCard;
