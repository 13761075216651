import styled from 'styled-components';
import get from 'lodash/get';

import { Flex } from 'components/Layout/Flex';
import { Info } from 'components/Text/Info';
import ComponentsProperties from 'components/FormTemplate/ComponentsProperties';
import { ObjectFieldTemplateProps } from '@rjsf/core';
import { AllFormsData, FormType } from 'wrapper/FormWrapper';
import { theme } from 'theme';
import { lg } from 'theme/styles/mediaQueries';
import { useViewport } from 'hooks/useViewport';
import { isEmpty } from 'lodash';

const FlexResponsive = styled(Flex)<{ fullWidth: boolean }>`
  width: ${(props) =>
    props.fullWidth ? '100%' : `calc(100% - ${theme.spacing.space16} * 2)`};
  display: grid;
  row-gap: ${theme.spacing.space32};

  ${(props) =>
    lg(`
    width: ${
      props.fullWidth ? '100%' : `calc(100% - ${theme.spacing.space80} * 2)`
    };
  `)};
`;

export const ObjectFormTemplate = <T extends AllFormsData>(
  props: ObjectFieldTemplateProps<T>,
) => {
  const { isMobile } = useViewport();
  const {
    description,
    uiSchema,
    title,
    properties,
    formData,
    formContext,
    schema,
  } = props;

  const required = !isEmpty(schema.required);

  const fullWidth = get(uiSchema, 'ui:options.fullWidth', false);
  const withoutMargin = get(uiSchema, 'ui:options.withoutMargin', false);

  return (
    <>
      <Flex
        id="objectFormTemplate" // more simply to identify/debug dom element
        direction={{ xs: 'column' }}
        alignItems="start"
        justify="start"
        marginTop={{
          xs: withoutMargin ? 'none' : 'space16',
          md: withoutMargin ? 'none' : 'space48',
        }}
      >
        {title && (
          <Info
            message={title}
            displayTitle={true}
            displayImage={true}
            description={description}
            showRequired={
              (formContext.formType as FormType) === 'CLIENT_FORM' && required
            }
          />
        )}
        <FlexResponsive
          marginTop={{
            xs: withoutMargin ? 'none' : isMobile ? 'space16' : 'space48',
          }}
          marginBottom={{ xs: withoutMargin ? 'none' : 'space48' }}
          marginLeft={{
            xs: fullWidth || withoutMargin ? 'none' : 'space16',
            md: fullWidth || withoutMargin ? 'none' : 'space16',
            lg: fullWidth || withoutMargin ? 'none' : 'space80',
          }}
          marginRight={{
            xs: fullWidth || withoutMargin ? 'none' : 'space16',
            md: fullWidth || withoutMargin ? 'none' : 'space16',
            lg: fullWidth || withoutMargin ? 'none' : 'space80',
          }}
          direction={{ xs: 'column' }}
          alignContent="start"
          fullWidth={fullWidth}
        >
          {properties.map(({ content, name }) => {
            if (Object.keys(ComponentsProperties).includes(name)) {
              const ComponentProperties = get(
                ComponentsProperties,
                name,
                <></>,
              );
              return (
                // @ts-ignore
                <ComponentProperties
                  id="properties"
                  key={`custom-properties-${name}`}
                  content={content}
                  formData={formData}
                />
              );
            }

            return (
              <Flex id="properties" key={name} width="100%">
                {content}
              </Flex>
            );
          })}
        </FlexResponsive>
      </Flex>
    </>
  );
};
