import { FC, forwardRef, InputHTMLAttributes } from 'react';

import { Input } from 'components/FormTemplate/Fields/Input';
import RequiredField from 'components/Forms/Utils/RequiredField';

import { Flex } from 'components/Layout/Flex';
import styled from 'styled-components';
import { Label } from 'components/Text/Label';
import { theme } from 'theme';
import { NumericFormat } from 'react-number-format';
import { FieldError } from 'react-hook-form';
import { Text } from 'components/Layout';
import { determineIfIsFieldErrorOrString } from 'utils/errors';

const InputContainer = styled.div`
  position: relative;
`;

const Span = styled.span`
  position: absolute;
  right: ${theme.spacing.space24};
  top: 50%;
  transform: translateY(-50%);
`;

const CustomInput = styled(Input)`
  padding-right: ${theme.spacing.space48};
`;

export interface InputCurrencyProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  label?: string;
  error?: FieldError | string;
  readonly?: boolean;
  value?: string;
  onChange: (value: any) => void;
  isReturnString?: boolean;
}

export const InputCurrency: FC<InputCurrencyProps> = forwardRef(
  (props: InputCurrencyProps, ref) => {
    const {
      id,
      required,
      readonly,
      disabled,
      onChange,
      onBlur,
      name,
      value,
      label,
      placeholder,
      error,
      isReturnString = false,
    } = props;

    return (
      <Flex direction={{ xs: 'column' }}>
        {label && (
          <Flex alignItems="center" marginBottom={{ xs: 'space8' }}>
            <RequiredField required={required}>
              <Label dangerouslySetInnerHTML={{ __html: label }} />
            </RequiredField>
          </Flex>
        )}
        <InputContainer>
          <NumericFormat
            getInputRef={ref as any}
            thousandSeparator={' '}
            customInput={CustomInput}
            decimalSeparator={','}
            decimalScale={2}
            onValueChange={(values) => {
              const { value, floatValue } = values;
              if (isReturnString) {
                return onChange(value);
              } else {
                return onChange(floatValue);
              }
            }}
            value={value}
            valueIsNumericString
            onBlur={onBlur}
            name={name}
            id={id}
            disabled={disabled}
            placeholder={placeholder}
            readOnly={readonly}
          />
          <Span>€</Span>
        </InputContainer>
        {error && (
          <Text
            content={
              error && determineIfIsFieldErrorOrString(error)
                ? error.message
                : error
            }
            fontStyle="body2"
            color={theme.colors.red1}
            marginTop={{ xs: 'space8' }}
          />
        )}
      </Flex>
    );
  },
);

InputCurrency.displayName = 'InputCurrency';
