import { AdminAppRoutes } from 'AdminApp';
import { ClientAppRoutes } from 'ClientApp';
import { PublicAppRoutes } from 'PublicApp';

export const getRouteWithParams = (
  route: ClientAppRoutes | PublicAppRoutes | AdminAppRoutes | string,
  params: Record<string, string>,
): string => {
  if (!params) return route;
  let updatedRoute = `${route}`;
  const paramsKeys = Object.keys(params);
  paramsKeys.forEach((key) => {
    updatedRoute = updatedRoute.replace(`:${key}`, params[key]);
  });
  return updatedRoute;
};

type AvailableSpec = 'width' | 'height' | 'left' | 'top';

type Specs = {
  [spec in AvailableSpec]?: number;
};

export const windowOpen = (route: string, target: string, specs: Specs) => {
  let stringSpec = '';
  const specsKeys = Object.keys(specs);
  specsKeys.forEach((key) => {
    const specKey = key as AvailableSpec;
    stringSpec = `${stringSpec},${key}=${specs[specKey]}`;
  });
  window.open(route, target, stringSpec);
};
