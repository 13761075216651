import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { CustomerFull } from '../types/resources';
import { useApi } from '../hooks/useApi';
import { useAuth } from '../hooks/useAuth';

type ICustomerContext = {
  customer: CustomerFull | null;
  setCustomer: (user: CustomerFull) => void;
};

export const CustomerContext = createContext<ICustomerContext>({
  customer: null,
  setCustomer: () => {},
});

export const CustomerProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
  const { user } = useAuth();

  const { execute: getCustomer, state: getCustomerState } =
    useApi<CustomerFull>(`/customers/${user?.id}`, {
      onSuccess: (response) => {
        setCustomer(response.value);
      },
    });
  const data = getCustomerState.data?.value ?? null;

  const [customer, setCustomer] = useState<CustomerFull | null>(data);

  const refresh = useCallback(() => {
    if (user?.id) {
      getCustomer();
    }
  }, [user?.id]);

  useEffect(() => {
    refresh();
  }, [user?.id]);

  return (
    <CustomerContext.Provider value={{ customer, setCustomer }}>
      {children}
    </CustomerContext.Provider>
  );
};
