// Components
import { Col, Flex, Row } from 'components/Layout';
import { FC, useCallback, useEffect, useState } from 'react';

// Data
import { AppointmentCard } from 'container/common';
import AppointmentModalPlaceCard from './AppointmentModalPlaceCard';
import AppointmentTakingCard from 'container/common/Appointment/AppointmentTakingCard';
import { ListItem } from 'components/List';
import { useTranslation } from 'react-i18next';
import {
  Appointment as AppointmentType,
  DivorceAgreementFull,
  ProcedureFull,
  Signature,
} from '../../../types/resources';
import { useApi } from '../../../hooks/useApi';
import { env } from '../../../config/env';
import { Button } from '../../../components/Buttons';

interface AppointmentProps {
  procedure: ProcedureFull;
  refetchProcedure: () => void;
}

const Appointment: FC<AppointmentProps> = ({ procedure, refetchProcedure }) => {
  const { t } = useTranslation();
  const [openedModalAppointmentPlace, toggleModalAppointmentPlace] =
    useState<boolean>(false);

  const { execute: getDivorceAgreement, state: getDivorceAgreementState } =
    useApi<DivorceAgreementFull>(
      `/procedures/${procedure?.id}/divorce-agreements/${procedure?.divorce_agreement_id}`,
    );
  const divorceAgreement = getDivorceAgreementState.data?.value;

  const { execute: getSignature, state: getSignatureState } = useApi<Signature>(
    `/procedures/${procedure.id}/signatures/${divorceAgreement?.signature_id}`,
  );
  const signature = getSignatureState.data?.value;

  const { execute: getAppointment, state: getAppointmentState } =
    useApi<AppointmentType>(
      `/procedures/${procedure.id}/appointments/${procedure.signature_appointment_id}`,
    );
  const appointment = getAppointmentState.data?.value;

  const { execute: validateAppointment, state: validateAppointmentState } =
    useApi(
      `/procedures/${procedure.id}/appointments/${appointment?.id}/validate`,
      {
        method: 'POST',
        onSuccess: () => {
          getAppointment();
        },
      },
    );
  const validateAppointmentLoading = validateAppointmentState.loading;
  const canValidateAppointment = appointment && !appointment.validated_at;

  const validateAppointmentCb = useCallback(() => {
    if (procedure && appointment) {
      validateAppointment();
    }
  }, [procedure, appointment]);

  useEffect(() => {
    if (procedure.signature_appointment_id) {
      getAppointment();
    }

    if (procedure.divorce_agreement_id) {
      getDivorceAgreement();
    }
  }, [procedure]);

  useEffect(() => {
    if (divorceAgreement?.signature_id) {
      getSignature();
    }
  }, [divorceAgreement]);

  return (
    <>
      <Row marginBottom={{ xs: 'space32' }}>
        <Col xs={12}>
          <ListItem
            title={t('appointment.items_to_sign')}
            iconName="Folder"
            buttons={[
              {
                content: t('appointment.docusign_access'),
                disabled: !signature?.external_id,
                onClick: () =>
                  signature?.external_id &&
                  window.open(
                    `${env.docusign.url}/documents/details/${signature.external_id}`,
                    '_blank',
                  ),
              },
            ]}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6} alignSelf="auto">
          <AppointmentTakingCard
            procedure={procedure}
            refetchProcedure={refetchProcedure}
            agreement={divorceAgreement}
          />
        </Col>
        <Col xs={12} md={6} alignSelf="auto">
          <AppointmentCard
            procedure={procedure}
            appointment={appointment}
            refetchProcedure={refetchProcedure}
            customButtons={[
              {
                content: t('appointment.details.button.update'),
                primary: true,
                fullWidth: true,
                onClick: () => toggleModalAppointmentPlace(true),
              },
            ]}
          />
          {appointment && (
            <AppointmentModalPlaceCard
              opportunityId={procedure.id}
              appointment={appointment}
              refetchAppointment={getAppointment}
              opened={openedModalAppointmentPlace}
              onClose={() => toggleModalAppointmentPlace(false)}
            />
          )}
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Flex marginTop={{ xs: 'space16' }} justify="end">
            <Button
              primary
              content={t('appointment.validate')}
              disabled={!canValidateAppointment}
              onClick={validateAppointmentCb}
              $loading={validateAppointmentLoading}
            />
          </Flex>
        </Col>
      </Row>
    </>
  );
};

export default Appointment;
