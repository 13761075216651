import { FC, useCallback, useState } from 'react';
import styled from 'styled-components';
import isEqual from 'lodash/isEqual';

import { theme } from 'theme';
import { Modal } from 'components/Modal/Modal';
import { Flex, Text } from 'components/Layout';
import { ModalFooter, ModalHeader } from 'components/Modal';
import { InputPlusMinus } from 'components/FormTemplate/Fields/InputPlusMinus';
import { md } from 'theme/styles/mediaQueries';
import { SubmitModalProps } from '.';
import { useTranslation } from 'react-i18next';
import { TipCard } from 'components/Cards';
import { ProductCode } from '../../types/resources';

const InputGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 32px 24px;
  grid-template-areas:
    '.'
    '.';
  margin-bottom: ${theme.spacing.space40};

  ${md(`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 16px 24px;
    grid-template-areas: '. .';
    margin-bottom: ${theme.spacing.space16};
  `)}
`;

export type Key = 'nbAdultChildren' | 'nbYoungChildren';

export type UpdateDealInput = {
  hasProperties?: boolean;
  nbAdultChildren?: number;
  nbYoungChildren?: number;
  products?: ProductCode[];
};

export type ChildrenElement = Pick<
  Record<
    keyof UpdateDealInput,
    {
      content: string;
      key: Key;
      value: number;
    }
  >,
  Key
>;

interface QuantityModalProps extends SubmitModalProps {
  elements: ChildrenElement;
  title: string;
}

const QuantityModal: FC<QuantityModalProps> = ({
  elements,
  title,
  onClose,
  onSubmit,
  loading,
  opened,
  withTipCard,
}) => {
  const { t } = useTranslation();
  const [updatedElement, setUpdatedElement] =
    useState<ChildrenElement>(elements);

  const onClickPlusMinus = useCallback(
    (key: Key, action: 'add' | 'remove') => {
      setUpdatedElement({
        ...updatedElement,
        [key]: {
          ...updatedElement[key],
          value:
            action === 'add'
              ? (updatedElement[key].value += 1)
              : (updatedElement[key].value -= 1),
        },
      });
    },
    [elements],
  );

  const toggleOnSubmit = useCallback(() => {
    const data = {
      adult_children: updatedElement.nbAdultChildren.value || 0,
      minor_children: updatedElement.nbYoungChildren.value || 0,
    };

    const callback = () => {
      onClose();
    };
    onSubmit(data, callback);
  }, [updatedElement]);

  return (
    <Modal opened={opened} onClose={onClose} height="auto">
      <ModalHeader iconName="Edit" content={title} onClose={onClose} />
      <InputGrid>
        {Object.entries(updatedElement).map(([key, { content, value }]) => {
          return (
            <Flex
              direction={{ xs: 'column' }}
              alignItems="center"
              key={`quantity_modal_${content}`}
            >
              <Text
                content={content}
                fontStyle="body1"
                color={theme.colors.gray6}
                marginBottom={{ xs: 'space16' }}
              />
              <InputPlusMinus
                value={value}
                removeValue={() => onClickPlusMinus(key as Key, 'remove')}
                addValue={() => onClickPlusMinus(key as Key, 'add')}
              />
            </Flex>
          );
        })}
      </InputGrid>
      {withTipCard && (
        <Flex marginBottom={{ xs: 'space16' }}>
          <TipCard title={t('updateDealNote')} type="danger" icon="Danger" />
        </Flex>
      )}
      <ModalFooter
        onClose={onClose}
        disabled={isEqual(elements, updatedElement) || loading}
        loading={loading}
        onSubmit={toggleOnSubmit}
      />
    </Modal>
  );
};

export default QuantityModal;
