import { FC } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { Controller, useForm } from 'react-hook-form';
import { InputText } from 'components/FormTemplate/Fields/InputText';
import { AdminAppRoutes } from 'AdminApp';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { theme } from 'theme';
import { toString } from 'lodash';
import { Flex } from 'components/Layout';
import { Button } from 'components/Buttons';
import { InputCurrency } from 'components/FormTemplate/Fields/InputCurrency';
import { useApi } from '../../../hooks/useApi';
import { PaymentFull, PaymentStatus } from '../../../types/resources';
import InputSelectAsync from '../../../components/FormTemplate/Fields/InputSelectAsync';
import InputSelect from '../../../components/FormTemplate/Fields/InputSelect';
import useToaster from '../../../hooks/useToaster';

type UpdatePaymentInput = {
  amount?: number;
  status?: PaymentStatus;
  paypal_id?: string | null;
  alma_id?: string | null;
  customer_id?: string;
  estimate_id?: string;
};

const Form = styled.form`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 38px 32px;
  width: 928px;
  margin-top: ${theme.spacing.space48};
`;

type PaymentUpdateFormProps = {
  payment: PaymentFull;
};

const PaymentUpdateForm: FC<PaymentUpdateFormProps> = ({ payment }) => {
  const history = useHistory();
  const { success: toasterSuccess, error: toasterError } = useToaster();
  const { t } = useTranslation();
  const {
    control,
    register,
    formState: { errors },
    setValue,
    handleSubmit,
  } = useForm<UpdatePaymentInput>({
    defaultValues: payment,
  });
  const {
    execute: updatePayment,
    state: { loading },
  } = useApi(`/payment/${payment.id}`, { method: 'PATCH' });

  const initialCustomerOption = {
    label: payment.customer.full_name,
    value: payment.customer.id,
  };

  const initialEstimateOption = {
    value: payment.estimate.id,
    label: `${payment.estimate.name} (${payment.estimate.price}€)`,
  };

  const paymentStatusOptions = Object.values(PaymentStatus).map((status) => ({
    value: status,
    label: t(`payments.statuses.${status}`),
  }));

  const onSubmit = async (data: UpdatePaymentInput) => {
    if (data) {
      updatePayment({
        body: {
          amount: data.amount,
          paypal_id: data.paypal_id || undefined,
          alma_id: data.alma_id || undefined,
          customer_id: data.customer_id,
          estimate_id: data.estimate_id,
        },
        onSuccess: () => {
          toasterSuccess(t('payments.update.success'));
          goBack();
        },
        onError: () => {
          toasterError(t('payments.update.error'));
        },
      });
    }
  };

  const goBack = () => {
    history.push(AdminAppRoutes.PAYMENTS);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        control={control}
        name="customer_id"
        rules={{ required: t('error.form.required').toString() }}
        render={({ field, fieldState: { error } }) => (
          <InputSelectAsync
            label={t('payments.customer')}
            placeholder={t('payments.customer')}
            loadOptions={(val, callback) => {
              callback([initialCustomerOption]);
            }}
            defaultValue={initialCustomerOption}
            defaultOptions
            error={error}
            {...field}
            required
            disabled
          />
        )}
      />

      <Controller
        control={control}
        name="estimate_id"
        rules={{ required: t('error.form.required').toString() }}
        render={({ field, fieldState: { error } }) => (
          <InputSelect
            key={Date.now()}
            label={t('payments.estimate')}
            placeholder={t('payments.estimate')}
            options={[initialEstimateOption]}
            error={error}
            {...field}
            required
            disabled
          />
        )}
      />

      <InputText
        {...register('paypal_id')}
        error={errors.paypal_id}
        label={t('payments.update.paypal_id')}
        placeholder={t('payments.update.paypal_id')}
        width="100%"
        disabled
      />
      <InputText
        {...register('alma_id')}
        error={errors.alma_id}
        label={t('payments.update.alma_id')}
        placeholder={t('payments.update.alma_id')}
        width="100%"
        disabled
      />

      <Controller
        control={control}
        name="amount"
        render={({ field, fieldState: { error } }) => (
          <InputCurrency
            {...field}
            value={toString(field.value)}
            onChange={(value) => {
              setValue(`amount`, value);
            }}
            error={error}
            label={t('payments.amount')}
            placeholder={t('payments.amount')}
            required
            disabled
          />
        )}
      />

      <Controller
        control={control}
        name="status"
        rules={{ required: t('error.form.user_role').toString() }}
        render={({ field, fieldState: { error } }) => (
          <InputSelect
            label={t('payments.status')}
            placeholder={t('payments.status')}
            options={paymentStatusOptions}
            error={error}
            {...field}
            onChange={(e) => {
              field.onChange(e);
            }}
            required
            disabled
          />
        )}
      />

      <Flex>
        <Button
          onClick={goBack}
          content={t('back')}
          marginRight={{ sm: 'space24' }}
        />
        <Button
          content={t('payments.update.submit')}
          type="submit"
          $loading={loading}
          primary
          disabled
        />
      </Flex>
    </Form>
  );
};

export default PaymentUpdateForm;
