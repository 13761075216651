import { Permission } from 'components/User';
import React, { FC, MouseEventHandler } from 'react';
import styled from 'styled-components';

import { theme } from 'theme';
import { Role } from '../../types/resources';

interface StyledRimlessButtonType {
  fontWeight?: number | string;
  color?: string;
}

const StyledRimlessButton = styled.button<StyledRimlessButtonType>`
  display: block;
  border: none;
  background: transparent;
  padding: 5px 15px;
  font-size: ${theme.font.body2.fontSize};
  line-height: ${theme.font.body2.lineHeight};
  color: ${(props) => props.color || 'initial'};
  font-weight: ${(props) => props.fontWeight || 'initial'};
  margin-right: ${theme.spacing.space16};
  cursor: ${(props) => props.disabled && 'not-allowed'};

  &.active {
    color: #ef7051;
    background: #f5f0ea;
    border-radius: ${theme.spacing.space8};
    font-weight: bold;
  }

  ${(props) =>
    !props.disabled &&
    `
    &:hover {
      color: #ef7051;
      background: #f5f0ea;
      border-radius: ${theme.spacing.space8};
    }
  `};
`;

interface Tabulation {
  id: string;
  name: string;
  onClick: MouseEventHandler<HTMLButtonElement>;
  className: string;
  disabled: boolean;
  roles: Role[];
}

interface TabulationProps {
  list: Tabulation[];
}

/**
 *
 * Displays chip inline - used on admin to switch between views
 *
 * @param {TabulationArray} list
 * @returns {ReactElement}
 */
export const Tabulations: FC<TabulationProps> = ({ list }) => {
  return (
    <>
      {list.map(({ name, disabled, roles, ...props }) => {
        return (
          <Permission key={props.id} roles={roles}>
            <StyledRimlessButton
              {...props}
              color={disabled ? theme.colors.gray5 : theme.colors.gray6}
              fontWeight={500}
              disabled={disabled}
            >
              {name}
            </StyledRimlessButton>
          </Permission>
        );
      })}
    </>
  );
};
