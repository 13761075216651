export type Alignments =
  | 'center'
  | 'start'
  | 'end'
  | 'between'
  | 'baseline'
  | 'around'
  | 'stretch';

export const alignments = {
  center: 'center',
  start: 'flex-start',
  end: 'flex-end',
  between: 'space-between',
  baseline: 'baseline',
  around: 'space-around',
  stretch: 'stretch',
};

export type Directions = 'row' | 'rowReverse' | 'column' | 'columnReverse';

export const directions = {
  row: 'row',
  rowReverse: 'row-reverse',
  column: 'column',
  columnReverse: 'column-reverse',
};

export type Positions =
  | 'absolute'
  | 'relative'
  | 'fixed'
  | 'static'
  | 'inherit'
  | 'initial';

export const positions = {
  absolute: 'absolute',
  relative: 'relative',
  fixed: 'fixed',
  static: 'static',
  inherit: 'inherit',
  initial: 'initial',
};
