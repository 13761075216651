import { FC } from 'react';
import styled from 'styled-components';

import { theme } from 'theme';

import { Text } from 'components/Layout';

interface ChipProps {
  content: string;
  onClick?: () => void;
}

const Wrapper = styled.div`
  padding: ${theme.spacing.space8} ${theme.spacing.space16};
  background-color: ${theme.colors.green1};
  border-radius: ${theme.spacing.space64};
  cursor: ${(props) => (props.onClick ? 'pointer' : 'default')};
`;

const Chip: FC<ChipProps> = ({ content, onClick }) => {
  return (
    <Wrapper onClick={onClick}>
      <Text
        fontStyle="label"
        color={theme.colors.white}
        content={content}
        whiteSpace="nowrap"
      />
    </Wrapper>
  );
};

export default Chip;
