import { FC } from 'react';

import { theme } from 'theme';
import styled from 'styled-components';

import roundedArrow from 'assets/svg/rounded-arrow.svg';

// Components
import { Flex } from 'components/Layout/Flex';
import { Text } from 'components/Layout/Text';
import { Icon, IconProps } from 'components/Images/Icon';

interface NotificationProps {
  title: string;
  description: string;
  iconName: IconProps['name'];
  isShow: boolean;
}

const Card = styled.div`
  background-color: ${theme.colors.gray3};
  border-radius: ${theme.spacing.space16};
  padding: ${theme.spacing.space24};
  width: 300px;
  position: absolute;
  top: -15px;
  left: 100%;
  z-index: 3;

  &:before {
    content: url(${roundedArrow});
    position: absolute;
    top: 30px;
    left: 0;
    transform: translateX(-100%);
  }
`;

export const Notification: FC<NotificationProps> = ({
  title,
  description,
  iconName,
  isShow,
}) => {
  return (
    <>
      {isShow && (
        <Card>
          <Flex alignItems="center" marginBottom={{ xs: 'space16' }}>
            <Flex width={theme.spacing.space24}>
              <Icon name={iconName} primaryColor={theme.colors.black} />
            </Flex>
            <Text
              content={title}
              fontStyle="body1"
              weight="bold"
              marginLeft={{ xs: 'space16' }}
            />
          </Flex>
          <Text
            dangerouslySetInnerHTML={{ __html: description }}
            fontStyle="body2"
            color={theme.colors.gray6}
          />
        </Card>
      )}
    </>
  );
};
