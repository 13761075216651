import { FC, useEffect } from 'react';
import styled from 'styled-components';
import format from 'date-fns/format';

// Utils
import { theme } from 'theme';

// Components
import { Col, Container, Row } from 'components/Layout';

// Data
import { FormalityFile } from 'container/client';
import { ViewGate } from 'container/common';
import { useCustomer } from '../../hooks/useCustomer';
import {
  NotarialDeed,
  ProcedureFull,
  ProcedureStepType,
} from '../../types/resources';
import { useApi } from '../../hooks/useApi';
import { Ghost } from '../../components/Loading';
import { ClientCard, TipCard } from '../../components/Cards';
import { useTranslation } from 'react-i18next';
import config from '../../config/app';

const CustomContainer = styled(Container)`
  padding-top: ${theme.spacing.space32};
  padding-bottom: ${theme.spacing.space32};
`;

const ClientFormalityNotary: FC = () => {
  const { t } = useTranslation();
  const { customer } = useCustomer();
  const step = ProcedureStepType.NOTARY;

  const { execute: getProcedure, state: getProcedureState } =
    useApi<ProcedureFull>('');
  const procedure = getProcedureState.data?.value;

  const { execute: getNotarialDeeds, state: getNotarialDeedsState } = useApi<
    NotarialDeed[]
  >(`/procedures/${procedure?.id}/notarial-deeds`);
  const notarialDeed = getNotarialDeedsState.data?.value[0];

  useEffect(() => {
    if (customer?.procedure_id) {
      getProcedure({
        endpoint: `/procedures/${customer.procedure_id}`,
        query: { customer_id: customer.id },
      });
    }
  }, [customer?.procedure_id]);

  useEffect(() => {
    if (procedure) {
      getNotarialDeeds();
    }
  }, [procedure]);

  if (!procedure) return null;

  const content = notarialDeed?.received_at
    ? t(`formality_letters.demand_${step}.received`, {
        date: format(new Date(notarialDeed.received_at), 'dd/MM/yyyy'),
      })
    : notarialDeed?.requested_at
    ? t(`formality_letters.demand_${step}.description_date`, {
        date: format(new Date(notarialDeed.requested_at), config.dateFormat),
      })
    : '';

  return (
    <ViewGate procedure={procedure} steps={[ProcedureStepType.NOTARY]}>
      <CustomContainer fluid="xl">
        <Row>
          <Col xs={12} md={4} vertical alignSelf="auto">
            {notarialDeed ? (
              <ClientCard
                iconName="Document"
                title={t(`formality_letters.demand_${step}.title`)}
              >
                <TipCard
                  title={t(`formality_letters.demand_${step}.notify`)}
                  type="info"
                  content={content}
                />
              </ClientCard>
            ) : (
              <Ghost width="100%" height="254px" shape="rect" rx={24} />
            )}
          </Col>
          <Col xs={12} md={8}>
            <FormalityFile
              procedure={procedure}
              step={step}
              letter={{
                date: notarialDeed?.received_at ?? notarialDeed?.requested_at,
                document_id: notarialDeed?.document_id,
              }}
            />
          </Col>
        </Row>
      </CustomContainer>
    </ViewGate>
  );
};

export default ClientFormalityNotary;
