import { FC } from 'react';
import styled from 'styled-components';
import { IconlyIcon } from 'types/react-iconly';

import { theme } from 'theme';
import { CustomIcon } from 'theme/styles/icons';

import { Button } from 'components/Buttons';
import { Text } from 'components/Layout';
import { md } from 'theme/styles/mediaQueries';

interface FullPageProps {
  onSubmit?: () => void;
  onClose?: () => void;
  headerIcon?: keyof typeof IconlyIcon | keyof typeof CustomIcon;
  headerButton: string;
  headerTitle: string;
  footerButton?: string;
}

interface MainProps {
  hasFooter: boolean;
}

const Page = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 20;
  background-color: ${theme.colors.gray1};
`;

const Header = styled.header`
  position: sticky;
  top: 0;
  width: 100vw;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 ${theme.spacing.space16};
  background-color: ${theme.colors.green1};

  ${md(`
    padding: 0 ${theme.spacing.space72};
  `)}
`;

const GhostElement = styled.div`
  width: 230px;
`;

const Main = styled.main<MainProps>`
  padding: ${theme.spacing.space40} 0;
  overflow-y: scroll;
  height: calc(100vh - 70px); // header and footer height
  ${(props) =>
    props.hasFooter &&
    `
      height: calc(100vh - 70px * 2); // header and footer height
  `}
`;

const Footer = styled.footer`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${theme.spacing.space16} ${theme.spacing.space8};
  background-color: ${theme.colors.white};

  ${md(`
    padding: 16px 116px;
  `)}
`;

const FullPage: FC<FullPageProps> = ({
  onClose,
  onSubmit,
  children,
  headerIcon,
  headerButton,
  headerTitle,
  footerButton,
}) => {
  const hasFooter = !!footerButton;
  return (
    <Page>
      <Header>
        <Button
          content={headerButton}
          iconLeft={headerIcon ? { name: headerIcon } : undefined}
          onClick={onClose}
          backgroundColor={theme.colors.green1}
          textColor={theme.colors.white}
          borderColor={theme.colors.white}
          size="small"
        />
        <Text
          content={headerTitle}
          fontStyle="heading4"
          weight="bold"
          color={theme.colors.white}
        />
        <GhostElement />
      </Header>
      <Main hasFooter={hasFooter}>{children}</Main>
      {hasFooter && (
        <Footer>
          <Button
            content={footerButton}
            onClick={onSubmit}
            primary
            size="small"
          />
        </Footer>
      )}
    </Page>
  );
};

export default FullPage;
