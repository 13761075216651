import { ChangeEvent, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';

// Components
import { Flex } from 'components/Layout/Flex';
import { InputText } from 'components/FormTemplate/Fields/InputText';
import InputSelect from 'components/FormTemplate/Fields/InputSelect';

// data

// Utils
import { theme } from 'theme';
import { useQueryParams } from 'hooks/useQueryParams';
import { toggleSearchParams } from 'utils/search';
import { Department, Role } from '../../../types/resources';

type UsersListFiltersProps = {
  departments: Department[];
};

const UsersListFilters: FC<UsersListFiltersProps> = ({ departments }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const query = useQueryParams();
  const searchParams = new URLSearchParams(location.search);
  const departmentOptions = departments.map((d) => ({
    value: d.code,
    label: d.full_name,
  }));
  const roleOptions = [
    {
      value: Role.ADMIN,
      label: t(`role.admin`),
    },
    {
      value: Role.LAWYER,
      label: t(`role.lawyer`),
    },
    {
      value: Role.MANAGER,
      label: t(`role.manager`),
    },
    {
      value: Role.SALES,
      label: t(`role.sales`),
    },
  ];

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    toggleSearchParams('search', e?.target.value, searchParams);
    history.replace({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  };

  const handleRoleChange = (val: Role) => {
    toggleSearchParams('role', val, searchParams);
    history.replace({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  };

  const handleDepartmentChange = (val: Department['code']) => {
    toggleSearchParams('department', val, searchParams);
    history.replace({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  };

  const RoleValue = query.get('role') || '';
  const DepartmentValue = query.get('department') || '';
  const SearchValue = query.get('search') || '';

  return (
    <Flex marginTop={{ xs: 'space40' }} justify="start">
      <Flex>
        <InputText
          onChange={handleSearchChange}
          label={t('filter.search')}
          labelUppercase={true}
          labelFontStyle="label"
          placeholder={t('filter.search_user')}
          width="288px"
          labelWeight="bold"
          $size="small"
          value={SearchValue && SearchValue !== '' ? SearchValue : ''}
          icon={{ name: 'Search', primaryColor: theme.colors.salmon1 }}
        />
      </Flex>
      <Flex marginLeft={{ xs: 'space32' }}>
        <InputSelect
          onChange={handleRoleChange}
          options={roleOptions}
          label={t('user.user_role')}
          labelWeight="bold"
          labelUppercase={true}
          placeholder={t('filter.search_by_role')}
          width="256px"
          size="small"
          isClearable
          value={RoleValue && RoleValue !== '' ? RoleValue : ''}
        />
      </Flex>
      <Flex marginLeft={{ xs: 'space32' }}>
        <InputSelect
          onChange={handleDepartmentChange}
          options={departmentOptions}
          placeholder={t('filter.search_by_department')}
          label={t('user.department')}
          labelWeight="bold"
          labelUppercase={true}
          width="296px"
          size="small"
          isClearable
          value={
            DepartmentValue && DepartmentValue !== '' ? DepartmentValue : ''
          }
        />
      </Flex>
    </Flex>
  );
};

export default UsersListFilters;
