import { UiSchemaCustom } from '.';

const children: UiSchemaCustom[] = [
  {
    'ui:order': [
      'TYPE_GARDE',
      'JOUR_CHGT_GARDE',
      'HORAIRE_CHGT_GARDE',
      'PENSION_ALIM',
      'MONTANT_MENSUEL_PENSION',
      'PENSION_BENEF',
    ],
    TYPE_GARDE: {
      'ui:widget': 'select',
      'ui:placeholder': 'Préciser la garde',
    },
    JOUR_CHGT_GARDE: {
      'ui:widget': 'select',
      'ui:placeholder': 'Choisir le jour',
    },
    HORAIRE_CHGT_GARDE: {
      'ui:widget': 'select',
      'ui:placeholder': 'Choisir un horaire',
    },
    PENSION_ALIM: {
      'ui:widget': 'radio',
    },
    MONTANT_MENSUEL_PENSION: {
      'ui:field': 'CustomCurrency',
      'ui:placeholder': '300',
    },
    PENSION_BENEF: {
      'ui:widget': 'select',
      'ui:placeholder': 'Choisir un bénéficiaire',
    },
  },
  {
    'ui:order': ['ALLOC', 'ALLOC_MONTANT', 'ALLOC_SORT'],
    ALLOC: {
      'ui:widget': 'radio',
    },
    ALLOC_MONTANT: {
      'ui:field': 'CustomCurrency',
      'ui:placeholder': '300',
    },
    ALLOC_SORT: {
      'ui:widget': 'select',
      'ui:placeholder': 'Choisir un bénéficiaire',
    },
  },
  {
    'ui:order': ['WD_AUTRES_ACCORDS_FI', 'PRECISIONS_GARDE_TEXT'],
    WD_AUTRES_ACCORDS_FI: {
      'ui:field': 'CustomTextArea',
      'ui:placeholder': 'Nos accords financiers',
    },
    PRECISIONS_GARDE_TEXT: {
      'ui:field': 'CustomTextArea',
      'ui:placeholder': 'Précisions sur la garde',
    },
  },
];

export default children;
