import { forwardRef } from 'react';
import countries from 'i18n-iso-countries';

// Components
import InputSelect, { InputSelectSingleProps } from './InputSelect';

countries.registerLocale(require('i18n-iso-countries/langs/fr.json'));

type InputCountryProps = Omit<InputSelectSingleProps, 'type' | 'options'>;

const InputCountry = forwardRef((props: InputCountryProps, ref) => {
  const data = countries.getNames('fr', { select: 'official' });
  const options = Object.keys(data)
    .map((countryCode) => {
      const name = data[countryCode];
      return {
        value: name,
        label: name,
      };
    })
    .sort((a, b) =>
      a.label == data['FR'] ? -1 : b.label == data['FR'] ? 1 : 0,
    );

  return <InputSelect ref={ref} {...props} options={options} />;
});

InputCountry.displayName = 'InputCountry';

export default InputCountry;
