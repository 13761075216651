import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Flex, Text } from 'components/Layout';
import { IconButton } from 'components/Buttons';
import { Icon, ProfilePicture } from 'components/Images';

import { theme } from 'theme';

import { Manager } from '../../../types/resources';
import { Tooltip } from 'react-tooltip';
import { IconProps } from '../../../components/Images/Icon';
import { useViewport } from '../../../hooks/useViewport';
import { TextLink } from '../../../components/Text';

interface ManagerBookingItemProps {
  icon: IconProps['name'];
  onClick: () => void;
}
const ManagerBookingItem: FC<ManagerBookingItemProps> = ({ icon, onClick }) => {
  const { t } = useTranslation();

  return (
    <Flex alignItems="center">
      <Icon
        name={icon}
        primaryColor={theme.colors.salmon1}
        filled
        fill="none"
        style={{ marginRight: theme.spacing.space16 }}
      />
      <TextLink
        onClick={onClick}
        fontStyle={'body2'}
        weight={'bold'}
        content={t('manager.book_meeting')}
      />
    </Flex>
  );
};

interface ManagerInfoItemProps {
  icon: IconProps['name'];
  content: string;
}
const ManagerInfoItem: FC<ManagerInfoItemProps> = ({ icon, content }) => {
  return (
    <Flex alignItems="center">
      <Icon
        name={icon}
        primaryColor={theme.colors.salmon1}
        filled
        fill="none"
        style={{ marginRight: theme.spacing.space16 }}
      />
      <Text fontStyle={'body3'} content={content} color={theme.colors.black} />
    </Flex>
  );
};

interface ManagerInfoProps {
  manager?: Manager | null;
  onBookMeeting: () => void;
}

const ManagerInfo: FC<ManagerInfoProps> = ({ manager, onBookMeeting }) => {
  const { t } = useTranslation();
  const { isMobile } = useViewport();
  const name = manager?.full_name ?? t('opportunity.no_assignement');

  return (
    <Flex alignItems="center" id={`manager_info`}>
      <ProfilePicture size="small">
        <IconButton
          iconName="User"
          color={theme.colors.salmon2}
          backgroundColor={theme.colors.salmon3}
          rounded
          size="extra-extra-large"
        />
      </ProfilePicture>
      <Flex direction={{ xs: 'column' }} marginLeft={{ xs: 'space16' }}>
        <Text fontStyle="body1" content={t('your_manager_wedivorce')} />

        <Flex alignItems={'center'}>
          <Text fontStyle="body1" content={name} weight="bold" />
          <Icon
            name="InfoSquare"
            primaryColor={theme.colors.salmon1}
            filled
            style={{
              marginLeft: theme.spacing.space8,
              marginRight: theme.spacing.space8,
            }}
          />
        </Flex>
      </Flex>
      {manager && (
        <Tooltip
          anchorSelect={`#manager_info`}
          place={isMobile ? 'bottom' : 'left'}
          clickable
          noArrow
          style={{
            padding: 0,
            zIndex: '1',
            backgroundColor: theme.colors.white,
            boxShadow: '1px 2px 4px rgba(0, 0, 0, 0.25)',
            borderRadius: theme.spacing.space8,
          }}
        >
          <Flex
            direction={{ xs: 'column' }}
            style={{
              backgroundColor: theme.colors.white,
              padding: theme.spacing.space12,
              borderRadius: theme.spacing.space8,
              rowGap: theme.spacing.space12,
            }}
          >
            {manager.support_booking_url && (
              <ManagerBookingItem icon="headphones" onClick={onBookMeeting} />
            )}
            {manager.support_days && (
              <ManagerInfoItem icon="Calendar" content={manager.support_days} />
            )}
            {manager.support_hours && (
              <ManagerInfoItem
                icon="TimeSquare"
                content={manager.support_hours}
              />
            )}
          </Flex>
        </Tooltip>
      )}
    </Flex>
  );
};

export default ManagerInfo;
