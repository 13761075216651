import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IconlyIcon } from 'types/react-iconly';
import styled from 'styled-components';

import { ClientCard } from 'components/Cards';
import { Modal, ModalFooter, ModalHeader } from 'components/Modal';
import { Flex, Text } from 'components/Layout';
import { InputPlusMinus } from 'components/FormTemplate/Fields/InputPlusMinus';
import { isEqual } from 'lodash';
import { theme } from 'theme';
import { ProcedureFull, ProcedureSpouseFull } from '../../../types/resources';
import { useApi } from '../../../hooks/useApi';

const Card = styled(ClientCard)`
  height: auto;
`;

const LawyerConsultationCard: FC<{
  procedure: ProcedureFull;
  spouses: ProcedureSpouseFull[];
  onUpdateSpouses: () => void;
}> = ({ procedure, spouses, onUpdateSpouses }) => {
  const { t } = useTranslation();
  const [showModal, toggleModal] = useState(false);
  const spouseLead = spouses.find((s) => s.number === 1);
  const spouseFollower = spouses.find((s) => s.number === 2);

  const [numberOfConsultationSpouseLead, setNumberOfConsultationSpouseLead] =
    useState(spouseLead?.lawyer_consultation ?? 0);
  const [
    numberOfConsultationSpouseFollower,
    setNumberOfConsultationSpouseFollower,
  ] = useState(spouseFollower?.lawyer_consultation ?? 0);

  const {
    execute: updateSpousesProcedures,
    state: updateSpousesProceduresState,
  } = useApi(`/procedures/${procedure.id}/spouses`, { method: 'PATCH' });
  const loading = updateSpousesProceduresState.loading;

  const onSubmit = async () => {
    if (!spouseFollower || !spouseLead) return null;

    updateSpousesProcedures({
      body: [
        {
          spouse_id: spouseLead.spouse_id,
          lawyer_consultation: numberOfConsultationSpouseLead,
        },
        {
          spouse_id: spouseFollower.spouse_id,
          lawyer_consultation: numberOfConsultationSpouseFollower,
        },
      ],
      onSuccess: () => {
        toggleModal(false);
        onUpdateSpouses();
      },
    });
  };

  const buttons = [
    {
      content: t('opportunity.consultation.adminButton'),
      primary: true,
      iconRight: { name: IconlyIcon.ArrowRightSquare },
      fullWidth: true,
    },
  ];

  return (
    <>
      <Card
        iconName={IconlyIcon.People}
        title={t('opportunity.consultation.adminTitle', {
          count:
            (spouseFollower?.lawyer_consultation || 0) +
            (spouseLead?.lawyer_consultation || 0),
        })}
        buttons={buttons}
        onClick={() => toggleModal(true)}
      />
      <Modal opened={showModal} onClose={() => toggleModal(false)}>
        <ModalHeader
          iconName="Edit"
          content={t('opportunity.consultation.title')}
        />
        <Flex
          direction={{ xs: 'row' }}
          alignItems="center"
          marginBottom={{ xs: 'space32' }}
        >
          <Flex
            direction={{ xs: 'column' }}
            alignItems="center"
            marginRight={{ xs: 'space16' }}
          >
            <Text
              content={spouseLead?.spouse.full_name}
              fontStyle="body1"
              color={theme.colors.gray6}
              marginBottom={{ xs: 'space16' }}
            />
            <InputPlusMinus
              value={numberOfConsultationSpouseLead}
              removeValue={() =>
                setNumberOfConsultationSpouseLead(
                  numberOfConsultationSpouseLead - 1,
                )
              }
              addValue={() =>
                setNumberOfConsultationSpouseLead(
                  numberOfConsultationSpouseLead + 1,
                )
              }
            />
          </Flex>
          <Flex direction={{ xs: 'column' }} alignItems="center">
            <Text
              content={spouseFollower?.spouse.full_name}
              fontStyle="body1"
              color={theme.colors.gray6}
              marginBottom={{ xs: 'space16' }}
            />
            <InputPlusMinus
              value={numberOfConsultationSpouseFollower}
              removeValue={() =>
                setNumberOfConsultationSpouseFollower(
                  numberOfConsultationSpouseFollower - 1,
                )
              }
              addValue={() =>
                setNumberOfConsultationSpouseFollower(
                  numberOfConsultationSpouseFollower + 1,
                )
              }
            />
          </Flex>
        </Flex>
        <ModalFooter
          onClose={() => toggleModal(false)}
          disabled={
            (isEqual(
              numberOfConsultationSpouseLead,
              spouseLead?.lawyer_consultation,
            ) &&
              isEqual(
                numberOfConsultationSpouseFollower,
                spouseFollower?.lawyer_consultation,
              )) ||
            loading
          }
          loading={loading}
          onSubmit={onSubmit}
        />
      </Modal>
    </>
  );
};

export default LawyerConsultationCard;
