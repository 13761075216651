import { FC } from 'react';

// Components

// Containers
import { CasesList } from 'container/admin';

import { useQueryParams } from 'hooks/useQueryParams';
import { isUserAllowed } from 'utils/user';
import { useAuth } from '../../hooks/useAuth';
import {
  ProcedureStepStatus,
  ProcedureStepType,
  Role,
} from '../../types/resources';

const Cases: FC = () => {
  const { user } = useAuth();
  const query = useQueryParams();
  const stepStatus = query.get('step_status') as ProcedureStepStatus;
  const stepType = query.get('step_type') as ProcedureStepType;
  const search = query.get('search');
  const hasUnprocessedMessages = !query.has('has_unprocessed_messages')
    ? undefined
    : query.get('has_unprocessed_messages') === 'true';

  const hasUnprocessedComments = !query.has('has_unprocessed_comments')
    ? undefined
    : query.get('has_unprocessed_comments') === 'true';

  const lawyerId = isUserAllowed(user?.role, [Role.ADMIN])
    ? query.get('lawyer_id')
    : isUserAllowed(user?.role, [Role.LAWYER])
    ? user?.id
    : undefined;

  const managerId = isUserAllowed(user?.role, [Role.ADMIN])
    ? query.get('manager_id')
    : isUserAllowed(user?.role, [Role.MANAGER])
    ? user?.id
    : undefined;

  const express = query.get('express') === 'true';
  const premium = query.get('premium') === 'true';

  return (
    <CasesList
      stepStatus={stepStatus}
      stepType={stepType}
      search={search}
      lawyerId={lawyerId}
      managerId={managerId}
      hasUnprocessedMessages={hasUnprocessedMessages}
      hasUnprocessedComments={hasUnprocessedComments}
      express={express}
      premium={premium}
    />
  );
};

export default Cases;
