import { FC } from 'react';
import styled from 'styled-components';

export const Span = styled.span`
  color: ${(props) => props.color || 'red'};
  padding: 0 5px;

  &:after {
    content: '*';
  }
`;

const RequiredContainer = styled.div`
  display: inline-block;
`;

type Position = 'before' | 'after';

interface RequiredFieldProps {
  required?: boolean;
  color?: string;
  positionStar?: Position; // to put star before the text
}

const RequiredField: FC<RequiredFieldProps> = ({
  children,
  required = false,
  color,
  positionStar = 'after',
}) => (
  <RequiredContainer>
    {required && positionStar === 'before' && <Span color={color} />}
    {children}
    {required && positionStar === 'after' && <Span color={color} />}
  </RequiredContainer>
);

export default RequiredField;
