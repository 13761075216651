import { FC, useEffect, useState } from 'react';
import { FieldProps } from '@rjsf/core';

import RequiredField from 'components/Forms/Utils/RequiredField';

import get from 'lodash/get';
import { Flex } from 'components/Layout/Flex';
import { Label } from 'components/Text/Label';
import { replaceLabelWithContext } from '../../Forms/Utils/ReplaceLabel';
import { InputCurrency } from 'components/FormTemplate/Fields/InputCurrency';

export const CurrencyField: FC<FieldProps> = ({
  id,
  required,
  readonly,
  disabled,
  schema,
  uiSchema,
  onChange,
  formData,
  name,
  formContext,
}) => {
  const [value, setValue] = useState(formData || '');
  const title = get(schema, 'title', '');
  const label = replaceLabelWithContext(title, formContext.data);

  const placeholder = get(uiSchema, 'ui:placeholder', null);

  useEffect(() => {
    setValue(formData);
  }, [formData]);

  return (
    <Flex direction={{ xs: 'column' }}>
      {label && (
        <Flex
          alignItems="center"
          marginBottom={{ xs: 'space16', md: 'space24' }}
        >
          <RequiredField required={required}>
            <Label dangerouslySetInnerHTML={{ __html: label }} />
          </RequiredField>
        </Flex>
      )}
      <InputCurrency
        readOnly={readonly}
        id={id}
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        disabled={disabled}
        required={required}
        isReturnString
      />
    </Flex>
  );
};
