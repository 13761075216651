import { UiSchemaCustom } from '.';

const spouses: UiSchemaCustom[] = [
  {
    'ui:order': ['PARTIE1_CIVILITE', 'PARTIE1_NOM', 'PARTIE1_PRENOM'],
    PARTIE1_CIVILITE: {
      'ui:widget': 'radio',
    },
    PARTIE1_PRENOM: {
      'ui:field': 'CustomField',
      'ui:placeholder': 'Jean, David, Marc',
      'ui:options': {
        uppercase: true,
      },
    },
    PARTIE1_NOM: {
      'ui:field': 'CustomField',
      'ui:placeholder': 'Durand',
      'ui:options': {
        uppercase: true,
      },
    },
  },
  {
    'ui:order': [
      'PARTIE1_DATE_NAISSANCE',
      'PARTIE1_VILLE_NAISSANCE',
      'WD_PARTIE1_PAYS_NAISSANCE',
      'PARTIE1_DPT_NAISSANCE',
      'PARTIE1_NATIONALITE',
      'PARTIE1_COMPREHENSION',
      'WD_PARTIE1_STATUT_PRO',
      'PARTIE1_PROFESSION',
    ],

    PARTIE1_PROFESSION: {
      'ui:field': 'CustomField',
      'ui:placeholder': 'Musicien',
    },
    WD_PARTIE1_STATUT_PRO: {
      'ui:widget': 'select',
      'ui:placeholder': 'Choisir un statut professionnel',
    },
    PARTIE1_DATE_NAISSANCE: {
      'ui:field': 'CustomDatepicker',
      'ui:placeholder': 'Choisir une date',
      'ui:options': {
        maxToday: true,
        openTo: 'year',
        view: ['year', 'month', 'day'],
      },
    },
    PARTIE1_VILLE_NAISSANCE: {
      'ui:field': 'CustomField',
      'ui:placeholder': 'Paris',
      'ui:options': {
        uppercase: true,
      },
    },
    PARTIE1_DPT_NAISSANCE: {
      'ui:widget': 'select',
      'ui:placeholder': 'Choisir un département de naissance',
    },
    WD_PARTIE1_PAYS_NAISSANCE: {
      'ui:widget': 'select',
      'ui:placeholder': 'Choisir un pays de naissance',
    },
    PARTIE1_NATIONALITE: {
      'ui:widget': 'select',
      'ui:placeholder': 'Choisir une nationalité',
    },
    PARTIE1_COMPREHENSION: {
      'ui:widget': 'radio',
    },
  },
  {
    'ui:order': ['PARTIE1_ADRESSE', 'PARTIE1_CP', 'PARTIE1_VILLE'],
    PARTIE1_VILLE: {
      'ui:field': 'CustomField',
      'ui:placeholder': 'Paris',
      'ui:options': {
        uppercase: true,
      },
    },
    PARTIE1_CP: {
      'ui:field': 'CustomField',
      'ui:placeholder': '75000',
    },
    PARTIE1_ADRESSE: {
      'ui:field': 'CustomField',
      'ui:placeholder': '1 rue de la paix',
      'ui:options': {
        uppercase: true,
      },
    },
  },
  {
    'ui:order': [
      'PARTIE1_POST_IS_TRUE',
      'PARTIE1_DATE_POST',
      'PARTIE1_ADRESSE_POST',
      'PARTIE1_VILLE_POST',
      'PARTIE1_CP_POST',
    ],
    PARTIE1_VILLE_POST: {
      'ui:field': 'CustomField',
      'ui:options': {
        label: true,
        inputType: 'text',
        uppercase: true,
      },
      'ui:placeholder': 'Paris',
    },
    PARTIE1_CP_POST: {
      'ui:field': 'CustomField',
      'ui:options': {
        label: true,
        inputType: 'text',
      },
      'ui:placeholder': '75000',
    },
    PARTIE1_ADRESSE_POST: {
      'ui:field': 'CustomField',
      'ui:options': {
        label: true,
        inputType: 'text',
        uppercase: true,
      },
      'ui:placeholder': '1 rue de la paix',
    },
    PARTIE1_DATE_POST: {
      'ui:field': 'CustomDatepicker',
      'ui:placeholder': 'mm/aaaa',
      'ui:options': {
        inputFormat: 'MM/yyyy',
        mask: '__/____',
        view: ['month', 'year'],
      },
    },
    PARTIE1_POST_IS_TRUE: {
      'ui:widget': 'radio',
    },
  },
  {
    'ui:order': [
      'PARTIE1_TYPE_REVENU',
      'PARTIE1_NET_MENSUEL',
      'REVENUS1_YESNO',
      'REVENUS1',
    ],
    PARTIE1_NET_MENSUEL: {
      'ui:field': 'CustomCurrency',
      'ui:placeholder': '1 600',
    },
    // PARTIE1_TYPE_REVENU: {
    //   'ui:widget': 'select',
    //   'ui:placeholder': 'Choisir un type de revenu',
    // },
    REVENUS1_YESNO: {
      'ui:widget': 'radio',
    },
    REVENUS1: {
      items: {
        'ui:order': ['TYPE', 'MONTANT'],
        'ui:options': {
          fullWidth: true,
          withoutMargin: true,
        },
        MONTANT: {
          'ui:field': 'CustomCurrency',
          'ui:placeholder': '1600',
        },
        TYPE: {
          'ui:widget': 'select',
          'ui:placeholder': 'Choisir un type de revenu',
        },
      },
    },
  },
];

export default spouses;
