import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { RolesOpportunityCard } from 'components/Cards';
import { Modal, SpousesInformationModal } from 'components/Modal';

import { AssignModalProps } from 'container/admin/AssignModal/AssignModal';

import { useModal } from 'hooks/useModal';
import { useViewport } from 'hooks/useViewport';
import { AssignSingleModal } from 'container/admin';
import { EstimateFull, ProcedureFull, Role } from '../../../types/resources';
import { useAuth } from '../../../hooks/useAuth';

interface RolesOpportunityListProps {
  procedure: ProcedureFull;
  refetchProcedure?: () => void;
  estimate?: EstimateFull;
  editable?: boolean;
}

const RolesOpportunityList: FC<RolesOpportunityListProps> = ({
  procedure,
  refetchProcedure,
  estimate,
  editable = true,
}) => {
  const { t } = useTranslation();
  const { isMobile } = useViewport();
  const { user, isCustomer } = useAuth();
  const [modalState, setModalState] = useModal(false);
  const [modalContent, setModalContent] = useState<AssignModalProps['content']>(
    {
      title: '',
      type: Role.MANAGER,
      opportunityIds: [],
      role: Role.LAWYER,
      assignedRoleId: '',
      spouseId: undefined,
    },
  );

  const handleCloseModal = () => {
    setModalState(false);
    refetchProcedure?.();
  };

  const [isSpouseInformationModalOpen, setIsSpouseInformationModalOpen] =
    useModal(false);

  const handleOpenAssignModal = (
    assignModalContent: AssignModalProps['content'],
  ) => {
    setModalContent(assignModalContent);
    setModalState(true);
  };

  return (
    <>
      <RolesOpportunityCard
        title={t('roles_opportunity.roles_opportunity')}
        isAdmin={!isCustomer}
        isLead={user?.id === procedure.spouse1_id}
        procedure={procedure}
        estimate={estimate}
        handleOpenAssignModal={handleOpenAssignModal}
        handleOpenSpousesInformationModal={() =>
          setIsSpouseInformationModalOpen(true)
        }
        editable={editable}
      />

      {procedure.spouse1 && procedure.spouse2 && (
        <SpousesInformationModal
          opened={isSpouseInformationModalOpen}
          onClose={() => setIsSpouseInformationModalOpen(false)}
          spouseLead={procedure.spouse1}
          spouseFollower={procedure.spouse2}
          title={t('opportunity.spouses_information_modal.title')}
        />
      )}
      <Modal
        opened={modalState}
        width="83vw"
        full={isMobile}
        onClose={handleCloseModal}
      >
        <AssignSingleModal onClose={handleCloseModal} content={modalContent} />
      </Modal>
    </>
  );
};

export default RolesOpportunityList;
