import { FC } from 'react';

// Data
// Utils
import {
  ProcedureFull,
  ProcedureStepType,
  Role,
} from '../../../types/resources';
import { useAuth } from '../../../hooks/useAuth';
import { Redirect } from 'react-router-dom';
import { ClientAppRoutes } from '../../../ClientApp';
import { getRouteWithParams } from '../../../utils/router';
import { AdminAppRoutes } from '../../../AdminApp';

interface ViewGateProps {
  procedure: ProcedureFull;
  steps: ProcedureStepType[];
}

const ViewGate: FC<ViewGateProps> = ({ procedure, steps, children }) => {
  const { user } = useAuth();
  const isAllowed =
    user?.role && [Role.ADMIN, Role.MANAGER, Role.LAWYER].includes(user.role);
  const allStepsUnlocked = steps.some((s) =>
    procedure.steps.some((ps) => ps.step === s),
  );

  if (!allStepsUnlocked) {
    return (
      <Redirect
        to={
          !isAllowed
            ? ClientAppRoutes.HOME
            : getRouteWithParams(AdminAppRoutes.CASES_DETAILS, {
                id: procedure.id,
              })
        }
      />
    );
  }

  return <>{children}</>;
};

export default ViewGate;
